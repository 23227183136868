import { Flex, FlexProps, Text, Tooltip } from "@chakra-ui/react";
import { ReactNode, memo } from "react";

export function Template({
  children,
  description,
  title,
  mapKey,
  containerProps,
}: {
  children: ReactNode;
  description?: string;
  title: string;
  mapKey?: string;
  containerProps?: FlexProps;
}) {
  return (
    <Flex
      id={mapKey ? `mailing-filter-${mapKey}` : undefined}
      flexWrap="wrap"
      pos="relative"
      alignItems="flex-start"
      w="100%"
      bg="gray.50"
      flexDir="column"
      p="10px 20px"
      borderRadius="6"
      border="1px solid var(--chakra-colors-custom-gray)"
      gridArea={["Espécie", "UF"].includes(title) ? "auto / span 2" : undefined}
      {...containerProps}
    >
      {description ? (
        <Tooltip label={description}>
          <Text fontSize="18" mb="8px">
            {title}
          </Text>
        </Tooltip>
      ) : (
        <Text fontSize="18" mb="8px">
          {title}
        </Text>
      )}
      {children}
    </Flex>
  );
}

export default memo(Template);
