import { MailingFilterOption, MailingFilterProps } from "../../types";
import { RadioFilter } from "./radio-filter";
import {
  Flex,
  InputProps,
  Tag,
  TagCloseButton,
  TagLabel,
} from "@chakra-ui/react";
import { InputSearch, InputSearchProps } from "components/input-search";
import { Template } from "../mailing-template";
import { Checkbox } from "components/checkbox";
import { useEffect } from "react";

export function SecaoCnaeSearch({
  title,
  description,
  mapKey,
  onChangeFilter,
  operators,
  filtersValues,
  invalidFields,
  inputProps,
  options,
  inputSearchProps,
  getOptionByValue,
  parseValue,
  sequentialSearch,
}: {
  invalidFields: { [k: string]: string };
  inputProps?: InputProps;
  options: { name: string; value: string }[];
  inputSearchProps: Partial<InputSearchProps>;
  getOptionByValue: (value: any) => { name: string; value: any };
  parseValue?: (value: any) => any;
  sequentialSearch?: boolean;
} & MailingFilterProps) {
  const currFilter = filtersValues.get(mapKey);
  const checkbox = filtersValues.get("CONSIDERAR_SECAO_CNAES_SECUNDARIAS");
  const filterValue = currFilter?.value ?? [];
  const errorMessage = invalidFields[mapKey];

  const deleteCheckBox = () => {
    onChangeFilter("CONSIDERAR_SECAO_CNAES_SECUNDARIAS", (prev) => {
      prev.delete("CONSIDERAR_SECAO_CNAES_SECUNDARIAS");
      return new Map(prev.entries());
    });
  };

  const setCheckboxValue = (
    isChecked: boolean | null,
    op: MailingFilterOption = "equal"
  ) => {
    onChangeFilter("CONSIDERAR_SECAO_CNAES_SECUNDARIAS", (prev) => {
      if (isChecked != null) {
        prev.set("CONSIDERAR_SECAO_CNAES_SECUNDARIAS", {
          op,
          value: [isChecked],
        });
      } else {
        prev.set("CONSIDERAR_SECAO_CNAES_SECUNDARIAS", { op, value: [false] });
      }
      return new Map(prev.entries());
    });
  };

  return (
    <Template title={title} description={description} mapKey={mapKey}>
      <RadioFilter
        mapKey={mapKey}
        operators={operators}
        onChangeFilter={(k, v) => {
          onChangeFilter(k, v);
          if (filtersValues.get(mapKey)) {
            setCheckboxValue(null);
          } else {
            deleteCheckBox();
          }
        }}
        value={currFilter?.op}
      />
      {currFilter?.op === "in" || currFilter?.op === "not_in" ? (
        <Flex
          flexDir="column"
          borderRadius="6"
          border="1px solid var(--chakra-colors-custom-gray)"
          w="500px"
        >
          <InputSearch
            onChange={(optionValue) => {
              const isInclude = filterValue.includes(optionValue);
              const value = parseValue?.(optionValue) ?? optionValue;
              if (isInclude) {
                onChangeFilter(mapKey, (prev) => {
                  const newValue = filterValue.filter((crr) => crr !== value);
                  prev.set(mapKey, { ...currFilter!, value: newValue });
                  return new Map(prev.entries());
                });
              } else {
                onChangeFilter(mapKey, (prev) => {
                  const newValue = [...new Set([...filterValue, value])];
                  prev.set(mapKey, { ...currFilter!, value: newValue });
                  return new Map(prev.entries());
                });
              }
            }}
            searchKeys={["name", "value"]}
            checkIsInclude={(option) =>
              filterValue.includes(
                parseValue?.(option["value"]) ?? option["value"]
              )
            }
            options={options}
            inputProps={{ ...inputProps }}
            errorMessage={errorMessage}
            partialSearch
            sequentialSearch={sequentialSearch}
            {...inputSearchProps}
          />
          <Flex
            px="10px"
            py={filterValue.length ? "10px" : undefined}
            gap="10px"
            flexWrap="wrap"
          >
            {filterValue.map((optionValue, index) => {
              const parseValue = getOptionByValue(optionValue);
              return (
                <Tag
                  key={optionValue}
                  variant="solid"
                  colorScheme={currFilter?.op === "not_in" ? "red" : "facebook"}
                >
                  <TagLabel>{`${parseValue?.value} - ${parseValue?.name}`}</TagLabel>
                  <TagCloseButton
                    onClick={() => {
                      const newValue = filterValue.filter(
                        (_, i) => i !== index
                      );
                      onChangeFilter(mapKey, (prev) => {
                        prev.set(mapKey, { ...currFilter!, value: newValue });
                        return new Map(prev.entries());
                      });
                    }}
                  />
                </Tag>
              );
            })}
          </Flex>
        </Flex>
      ) : null}

      {currFilter ? (
        <Checkbox
          mt="8px"
          onChange={(isChecked) => setCheckboxValue(isChecked)}
          isChecked={!!checkbox?.value?.[0]}
        >
          Pesquisar também nas CNAEs secundárias
        </Checkbox>
      ) : null}
    </Template>
  );
}
