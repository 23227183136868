import api from "api/api";
import apiDigitacao from "api/api-digitacao";
import { Convenio } from "components/atendimentos-components/atendimento-form/types";
import { Toast } from "components/toast";
import { Dispatch, SetStateAction } from "react";
import { TabelaProps, UsuarioBanco } from "./types";

export function SimulacaoApi() {
  async function getUsuariosBancos(
    banco: string,
    setUsuariosBancos: Dispatch<
      SetStateAction<
        { name: string; value: string; completedUser: UsuarioBanco }[]
      >
    >,
    setLoading?: Dispatch<SetStateAction<boolean>>
  ) {
    setLoading?.(true);
    try {
      const { data } = await api.get<UsuarioBanco[]>(
        `usuarios-bancos/digitadores/${banco}`
      );
      if (!data?.length) {
        Toast({
          title: `Não há usuários cadastrados para o banco selecionado`,
          duration: 5000,
        });
      }
      setUsuariosBancos(
        data.map((u) => ({
          name: u.username!,
          value: u.username!,
          completedUser: u,
        }))
      );
    } catch (error) {
      Toast({
        title: "Erro ao buscar lista de Usuario Banco",
        status: "error",
      });
    } finally {
      setLoading?.(false);
    }
  }

  async function getTabelasDigitacao(
    banco: string,
    convenio: Convenio,
    setTabelas: Dispatch<
      SetStateAction<
        { name: string; value: string; completedTable: TabelaProps }[]
      >
    >,
    setLoading?: Dispatch<SetStateAction<boolean>>
  ) {
    setLoading?.(true);
    try {
      const { data } = await apiDigitacao.get<TabelaProps[]>(
        `api/tabelas-digitacao/enable`,
        { params: { banco, convenio } }
      );
      if (!data.length) {
        Toast({
          title: "Não há tabelas para o banco selecionado",
          status: "error",
        });
      }
      setTabelas(
        data.map((table) => ({
          name: table.descricao!,
          value: table.codigo!,
          completedTable: table,
        }))
      );
    } catch (e) {
      Toast({
        title: "Erro ao buscar lista de tabelas",
        status: "error",
      });
    } finally {
      setLoading?.(false);
    }
  }

  return { getUsuariosBancos, getTabelasDigitacao };
}
