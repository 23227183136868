import { DeleteIcon, DownloadIcon, HamburgerIcon } from "@chakra-ui/icons";
import { IconButton, Link, Tooltip } from "@chakra-ui/react";
import api from "api/api";
import DynamicTable from "components/dynamic-table";
import { RowStatus } from "components/dynamic-table/row-status";
import { Column } from "components/dynamic-table/types";
import { openModalConfirm } from "components/modal-confirm-new";
import { useEffect, useRef, useState } from "react";
import { useEventListener } from "services/events";
import { openMailingOptions } from "../mailing-options-modal";
import { IconButtonWithLoading } from "./icon-button-with-loading";
import { downloadMailing } from "./functions";
import { MailingTableRowDetails } from "./mailing-table-row-details";
import { dropdownConvenioAtendimento } from "components/atendimentos-components/atendimento-form/fields-data";
import { isColomboUser } from "utils/is-colombo-user";
import { useApplicationContext } from "contexts/ApplicationContext";
import {
  TipoOperacaoMailing,
  dropdownTipoOperacaoMailing,
} from "../mailing-upload-modal/consts";
import { Convenio } from "components/atendimentos-components/atendimento-form/types";
import { Toast } from "components/toast";

export interface MailingTableRow {
  dataCriacao: string;
  name: string;
  id: number;
  qtdSolicitada: number;
  qtdGerada: number;
  status:
    | "PENDING_GENERATION"
    | "PENDING_INSERT"
    | "FINISHED"
    | "ERROR_GENERATION"
    | "ERROR_INSERT";
  subConvenio: string;
  currentPipelineOrdem: null | number;
  filtrosJson: "[]";
  origem?: "API_NOVA_VENDAS" | "MANUAL";
  tipoOperacao: TipoOperacaoMailing;
  convenio: Convenio;
  bancosFrom: number[];
  bancosTo: number[];
  digitacaoLote: boolean;
}

export function MailingsTable({
  setLoading,
  loading,
}: {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  loading: boolean;
}) {
  const [mailings, setMailings] = useState<MailingTableRow[]>([]);
  const downloadRef = useRef<HTMLAnchorElement>(null);

  const { user } = useApplicationContext();
  const visaoUsuarioExpecifico = user.userData.customerId === 1692;

  const getMailings = async (withOutSave?: boolean) => {
    if (!withOutSave) setLoading(true);
    try {
      const { data } = await api.get("/mailings");
      setMailings(data);
    } catch (e) {
    } finally {
      if (!withOutSave) setLoading(false);
    }
  };

  useEventListener("update-mailings-table", getMailings);

  const deleteMailing = async (id: number) => {
    try {
      const { data } = await api.delete(`/mailings/${id}`);
      setMailings((mailings) =>
        mailings.filter((currMailing) => currMailing.id !== id)
      );
    } catch (e) {
      Toast({
        title: "Ocorreu um erro ao deletar mailing",
      });
    }
  };

  useEffect(() => {
    getMailings();
  }, []);

  useEffect(() => {
    const interval = setTimeout(() => {
      if (mailings.find((curr) => curr.status.includes("PENDING")))
        getMailings(true);
      else setMailings([...mailings]);
    }, 15000);
    return () => clearTimeout(interval);
  }, [mailings]);

  const columns: Column[] = [
    {
      name: "Data Criação",
      render: ({ dataCriacao }) => new Date(dataCriacao).toLocaleString(),
    },
    {
      name: "Convênio",
      key: "convenio",
      render: (row) => {
        const convenio = dropdownConvenioAtendimento.find(
          (curr) => curr.value === row.convenio
        )?.name;
        return <>{convenio ?? row.convenio}</>;
      },
    },
    {
      name: "Tipo Operação",
      render: (row) => {
        return (
          dropdownTipoOperacaoMailing.find(
            (curr) => curr.value === row.tipoOperacao
          )?.name || ""
        );
      },
    },
    {
      name: "Nome",
      key: "name",
    },
    {
      name: "Solicitada",
      key: "qtdSolicitada",
    },
    {
      name: "Gerada",
      key: "qtdGerada",
    },
    {
      name: "Status (Geração)",
      render: ({ status }) => {
        const parse: any = {
          PENDING_GENERATION: "Pendente",
          PENDING_INSERT: "Processando",
          FINISHED: "Concluído",
          ERROR_GENERATION: "Erro gerando Mailing",
          ERROR_INSERT: "Erro inserindo Mailing",
        };
        return (
          <RowStatus status={status} size="sm">
            {parse[status]}
          </RowStatus>
        );
      },
    },
    {
      name: "Ações",
      render: ({ id, name, status, origem, ...rest }) => {
        return (
          <>
            {!isColomboUser(user) || visaoUsuarioExpecifico ? (
              <Tooltip label="Opções Mailing">
                <IconButton
                  mr="8px"
                  icon={<HamburgerIcon width="20px" height="20px" />}
                  aria-label=""
                  onClick={(e) => {
                    e.stopPropagation();
                    openMailingOptions({ id, name, status, ...rest });
                  }}
                  disabled={
                    status !== "FINISHED" || rest.currentPipelineOrdem !== null
                  }
                />
              </Tooltip>
            ) : null}

            <IconButtonWithLoading
              mr="8px"
              tooltipMessage="Download"
              icon={<DownloadIcon />}
              aria-label=""
              onClick={async (e) => {
                e.stopPropagation();
                downloadMailing(id, name, downloadRef);
              }}
              isDisabled={status !== "FINISHED" || origem === "PIPELINE"}
            />

            <Tooltip label="Excluir">
              <IconButton
                icon={<DeleteIcon />}
                variant="danger"
                aria-label=""
                onClick={(e) => {
                  e.stopPropagation();
                  openModalConfirm({
                    onConfirm: async () => deleteMailing(id),
                    message: "Deseja excluir o mailing?",
                    confirmButtonStyle: {
                      children: "Sim",
                      leftIcon: <DeleteIcon />,
                    },
                  });
                }}
              />
            </Tooltip>
          </>
        );
      },
    },
  ];

  return (
    <>
      <Link display="none" ref={downloadRef} />
      <DynamicTable
        columns={columns}
        rows={mailings}
        isLoading={loading}
        RowInsideRender={MailingTableRowDetails}
      />
    </>
  );
}
