import {
  AddIcon,
  CloseIcon,
  DeleteIcon,
  EditIcon,
  RepeatIcon,
} from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  Grid,
  IconButton,
  Input,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { Popover } from "components/popover";
import { Checkbox } from "components/checkbox";
import { CustomModal } from "components/custom-modal";
import { Dropdown } from "components/dropdown";
import DynamicTable from "components/dynamic-table";
import { GetLayout } from "components/get-layout";
import { useApplicationContext } from "contexts/ApplicationContext";
import { useEffect, useState } from "react";
import { FaSave } from "react-icons/fa";
import { TbCoin, TbCoinOff } from "react-icons/tb";
import api from "../api/api";
import { openModalConfirm } from "components/modal-confirm-new";
import { Toast } from "components/toast";
import { Filter2Icon } from "components/vectors/filter-icon";
import { NewDropdown } from "components/dropdown/new-dropdown";

export function Empresas() {
  const { user } = useApplicationContext();
  const { userData } = user;
  const [empresas, setEmpresas] = useState([]);
  const [comerciais, setComerciais] = useState([]);
  const [regionais, setRegionais] = useState([]);
  const [empresaDialog, setEmpresaDialog] = useState({
    id: "",
    enabled: true,
    name: "",
    codigoNova: "",
    email: "",
    telefone: "",
    regionalCustomer: { id: userData.customerId },
    enabled3cplus: false,
    token3cplus: "",
  });
  const [openEmpresaDialog, setOpenEmpresaDialog] = useState(false);
  const [loadingCashbackPending, setLoadingCashbackPending] = useState(false);
  const [cashbackFilter, setCashbackFilter] = useState(null);
  const [filtros, setFiltros] = useState({ nomeOrCodigo: ""});
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({ name: false, token3cplus: false });
  const [loadingSave, setLoadingSave] = useState(false);

  const getFilteredEmpresas = () => {
    let filteredCustomers = [...empresas];
    if (filtros.nomeOrCodigo.trim() !== "") {
      filteredCustomers = filteredCustomers.filter(
        (c) =>
          (c.name || "")
            .toUpperCase()
            .includes(filtros.nomeOrCodigo.trim().toUpperCase()) ||
          (c.codigoNova || "")
            .toUpperCase()
            .includes(filtros.nomeOrCodigo.trim().toUpperCase())
      );
    } else if (cashbackFilter !== null) {
      console.log("entrando no filtro de cashback");
      filteredCustomers = filteredCustomers.filter(
        (c) =>
          (c.cashback === cashbackFilter)
      );
    }
    return filteredCustomers;
  };

  const onChangeFiltro = (field, e) => {
    setFiltros({ ...filtros, [field]: e.target.value });
  };

  const loadEmpresas = async () => {
    try {
      let response = await api.get("/customers");
      setEmpresas(response.data);
    } catch (error) {
      setEmpresas([]);
      Toast({
        title: "Erro ao carregar empresas",
        status: "error",
      });
    }
  };

  const atualizar = async () => {
    setLoading(true);
    await loadEmpresas();
    setLoading(false);
  };

  const loadRegionais = async () => {
    try {
      const { data } = await api.get("/customers/regionais");
      setRegionais(data);
    } catch (error) {
      Toast({
        title: "Erro ao carregar regionais",
        status: "error",
      });
    }
  };

  const loadComerciais = async () => {
    try {
      const { data } = await api.get("/usuarios/comerciais");
      setComerciais(data);
    } catch (error) {
      Toast({
        title: "Erro ao carregar comerciais",
        status: "error",
      });
    }
  };

  useEffect(() => {
    const load = async () => {
      setLoading(true);
      await loadComerciais();
      await loadRegionais();
      await loadEmpresas();
      setLoading(false);
    };
    load();
  }, []);

  const submitCashbackStatus = async (status, customerId) => {
    try {
      setLoadingCashbackPending(true);
      await api.get(`/customers/${customerId}/change-cashback?status=${status}`);
      status = status === "ENABLED" ? "Ativo" : "Inativo";
      Toast({
        title: "Status do Cashback alterado para: " + status,
        status: "success"
      });
      await loadEmpresas();
    } catch (e) {
      console.log(e);
      Toast({
        title: "Erro ao alterar status do Cashback para: " + status,
        status: "success"
      });
    } finally {
      setLoadingCashbackPending(false);
    }
  }

  const openNew = () => {
    setErrors({});
    setEmpresaDialog({
      id: "",
      enabled: true,
      name: "",
      codigoNova: "",
      email: "",
      telefone: "",
      enabled3cplus: false,
      token3cplus: "",
      regionalCustomer: { id: userData.customerId },
    });
    setOpenEmpresaDialog(true);
  };

  const hideDialog = () => {
    setOpenEmpresaDialog(false);
  };

  const onChangeEmpresaDialog = (field, value) => {
    setErrors({ ...errors, [field]: false });

    if ("regionalCustomer" === field) {
      setEmpresaDialog((prevState) => ({
        ...prevState,
        regionalCustomer: { id: value },
      }));
    } else if ("comercialUser" === field) {
      setEmpresaDialog((prevState) => ({
        ...prevState,
        comercialUser: { id: value },
      }));
    } else {
      setEmpresaDialog((prevState) => ({ ...prevState, [field]: value }));
    }
  };

  const onChangeEmpresaDialogCheckbox = (field, isChecked) => {
    setEmpresaDialog({ ...empresaDialog, [field]: isChecked });
  };

  const saveOrUpdateEmpresa = async () => {
    const hasNameError =
      !empresaDialog.name || empresaDialog.name?.trim().length === 0;
    const hasTokenError =
      empresaDialog.enabled3cplus && !empresaDialog.token3cplus?.trim().length;
    setErrors({
      name: hasNameError,
      token3cplus: hasTokenError,
      codigoNova: !empresaDialog.codigoNova,
    });
    if ([hasNameError, hasTokenError, !empresaDialog.codigoNova].includes(true))
      return;
    let tempEmpresa = {
      ...empresaDialog,
      token3cplus: empresaDialog.token3cplus?.trim(),
      name: empresaDialog.name?.trim(),
      codigoNova: empresaDialog.codigoNova?.trim(),
      email: empresaDialog.email?.trim(),
      telefone: empresaDialog.telefone?.trim(),
    };
    setLoadingSave(true);
    try {
      if (!empresaDialog.id) {
        await api.post("/customers", tempEmpresa);
      } else {
        await api.put(`/customers/${tempEmpresa.id}`, tempEmpresa);
      }
      setOpenEmpresaDialog(false);
      await loadEmpresas();
    } catch (error) {
      const errorMessage = {
        codigo_nova_exists: "Código Nova já cadastrado",
        not_allowed_create_colombo: "Código Nova não permitido",
        allowed_only_create_colombo: "Código Nova não permitido",
      };
      if (error?.response?.data?.message === "codigo_nova_exists")
        setErrors({ ...errors, codigoNova: true });
      Toast({
        title:
          errorMessage[error?.response?.data?.message] ??
          "Erro ao salvar empresa!",
        status: "error",
      });
    } finally {
      setLoadingSave(false);
    }
  };

  const editEmpresa = (empresa) => {
    setErrors({ name: "" });
    setEmpresaDialog({ ...empresa });
    setOpenEmpresaDialog(true);
  };

  const acceptDeleteEmpresa = async (rowData) => {
    setLoading(true);
    try {
      await api.delete("/customers/" + rowData.id);
    } catch (error) {
      Toast({
        title: "Erro ao excluir empresa!",
        status: "error",
      });
    }
    await loadEmpresas();
    setLoading(false);
  };

  const confirmDeleteEmpresa = (rowData) => {
    openModalConfirm({
      message: `Deseja excluir "${rowData.name}"?`,
      onConfirm: () => acceptDeleteEmpresa(rowData),
      confirmButtonStyle: { variant: "danger", leftIcon: <DeleteIcon /> },
    });
  };

  const empresaDialogFooter = (
    <>
      <Button
        leftIcon={<FaSave />}
        onClick={saveOrUpdateEmpresa}
        isLoading={loadingSave}
        loadingText="Salvando"
      >
        Salvar
      </Button>
      <Button
        variant="outline"
        leftIcon={<CloseIcon w="11px" h="11px" />}
        onClick={hideDialog}
      >
        Cancelar
      </Button>
    </>
  );

  const columns = [
    {
      name: "Ativo",
      render: (row) => (row.enabled ? "SIM" : "NÃO"),
    },
    {
      name: "Cód. Nova",
      key: "codigoNova",
    },
    {
      name: "Nome",
      key: "name",
    },
    {
      name: "CNPJ/CPF",
      key: "cnpj",
    },
    {
      name: "Regional",
      render: (row) => row.regionalCustomer && row.regionalCustomer.name,
    },
    {
      name: "Cashback",
      render: (row) => row.cashback === "PENDING" ? "Pendente"
        : row.cashback === "ENABLED" ? "Ativo" : "Inativo",
    },
    {
      name: "Ações",
      render: (row) => {
        return (
          <Flex gap="5px">
            <IconButton
              icon={<EditIcon size="20" />}
              mr="8px"
              onClick={() => editEmpresa(row)}
              variant="outline"
            />
            {userData.type === "SUPER" || userData.type === "ASCOM" ? (
              <>
                <Tooltip label="Ativar Cashback" bg="gray.200" color="text">
                  <IconButton
                    icon={<TbCoin size="20" />}
                    onClick={() => submitCashbackStatus("ENABLED", row.id)}
                    mr="8px"
                    isDisabled={row.cashback === "ENABLED"}
                    color="green"
                    variant="outline"
                    isLoading={loadingCashbackPending}
                  />
                </Tooltip>
                <Tooltip label="Inativar Cashback" bg="gray.200" color="text">
                  <IconButton
                    icon={<TbCoinOff size="20" />}
                    onClick={() => submitCashbackStatus("DISABLED", row.id)}
                    mr="8px"
                    isDisabled={row.cashback === "DISABLED"}
                    color="red"
                    variant="outline"
                    isLoading={loadingCashbackPending}
                  />
                </Tooltip>
              </>
            ) : null}
            <IconButton
              icon={<DeleteIcon size="20" />}
              variant="outline"
              onClick={() => confirmDeleteEmpresa(row)}
              color="red"
            />
          </Flex>
        );
      },
    },
  ];

  const cashbackList = [
    {
      name: "Selecione",
      value: null
    },
    {
      name: "Ativo",
      value: "ENABLED"
    },
    {
      name: "Inativo",
      value: "DISABLED"
    },
    {
      name: "Pendente",
      value: "PENDING"
    },
  ];

  const cashbackConvert = (value) => {
    if (value === null || value === "") return "Selecione";
    if (value === "PENDING") return "Pendente";
    if (value === "ENABLED") return "Ativo";
    if (value === "DISABLED") return "Inativo";
  }

  return (
    <>
      {/* <ConfirmDialog dialogData={confirmDialogData} /> */}
      <GetLayout>
        <Text fontSize="25" fontWeight="bold" mb="20px">
          Empresas
        </Text>

        <Flex w="100%" alignItems="center" justifyItems="center" gap="10px" mb="20px">
          <Box w="25%">
            <Text mb="8px">Filtrar por Nome ou Código Nova:</Text>
            <Input
              value={filtros.nomeOrCodigo || ""}
              onChange={(e) => onChangeFiltro("nomeOrCodigo", e)}
            />
          </Box>
          <Box mt="30px">
            <Popover
              button={
                <Button
                  variant="ghost"
                  alignItems="center"
                  cursor="pointer"
                  _hover={{ bg: "gray.200" }}
                  px="10px"
                  borderRadius="6px"
                  leftIcon={<Filter2Icon />}
                  fontSize="14px"
                  fontWeight="medium"
                >
                  Filtrar por
                </Button>
              }
              title="Filtrar por"
              withOutCloseButton
              popupStyles={{ minWidth: "320px", minHeight: "150px" }}
              contentProps={{ padding: "0px" }}
            >
              {() => (
                <>
                  <Box overflow="auto" maxHeight="350px" padding="8px">
                    <Box mb="20px">
                      <Text fontSize="14px" fontWeight="medium" mb="8px">
                        Cashback
                      </Text>
                      <NewDropdown
                        options={cashbackList}
                        w="100%"
                        value={cashbackConvert(cashbackFilter)}
                        onChange={(e) => setCashbackFilter(e.value)}
                      />
                    </Box>
                  </Box>
                </>
              )}
            </Popover>
          </Box>
          <Box w="37.5%">
            <Text mb="8px">&nbsp;</Text>
            <Button w="100%" leftIcon={<AddIcon />} onClick={openNew}>
              Adicionar Empresa
            </Button>
          </Box>
          <Box w="37.5%">
            <Text mb="8px">&nbsp;</Text>
            <Button
              w="100%"
              leftIcon={<RepeatIcon />}
              onClick={atualizar}
              isLoading={loading}
              loadingText="Atualizando"
            >
              Atualizar
            </Button>
          </Box>
        </Flex>
        <DynamicTable
          rows={getFilteredEmpresas()}
          columns={columns}
          isLoading={loading}
        />
        <CustomModal
          isOpen={openEmpresaDialog}
          modalTitle="Empresa"
          size="4xl"
          modalFooter={empresaDialogFooter}
          onClose={hideDialog}
        >
          <Grid templateColumns="85px auto" gap="16px" mb="16px">
            <Checkbox
              isChecked={empresaDialog.enabled}
              onChange={(isChecked) =>
                onChangeEmpresaDialogCheckbox("enabled", isChecked)
              }
            >
              Ativo
            </Checkbox>
            <Box>
              <Text mb="8px">Nome da Empresa</Text>
              <Input
                value={empresaDialog.name || ""}
                isInvalid={errors.name}
                onChange={(e) => onChangeEmpresaDialog("name", e.target.value)}
              />
            </Box>
          </Grid>
          <Grid templateColumns="1fr 1fr" gap="16px" mb="16px">
            <Box>
              <Text mb="8px">CNPJ</Text>
              <Input
                value={empresaDialog.cnpj || ""}
                onChange={(e) => onChangeEmpresaDialog("cnpj", e.target.value)}
              />
            </Box>
            <Box>
              <Text mb="8px">Código Nova</Text>
              <Input
                value={empresaDialog.codigoNova || ""}
                autoComplete="new-password"
                onChange={(e) => {
                  onChangeEmpresaDialog("codigoNova", e.target.value);
                }}
                isInvalid={errors.codigoNova}
              />
            </Box>
          </Grid>
          <Grid templateColumns="1fr 1fr" gap="16px" mb="16px">
            <Box>
              <Text mb="8px">Regional Responsável</Text>
              <Dropdown
                w="100%"
                value={empresaDialog.regionalCustomer?.id}
                options={regionais}
                optionValue="id"
                disabled={regionais && regionais.length === 1}
                onChange={(value) =>
                  onChangeEmpresaDialog("regionalCustomer", value)
                }
                placeholder="Selecione..."
              />
            </Box>
            <Box>
              <Text mb="8px">Comercial Responsável</Text>
              <Dropdown
                w="100%"
                value={empresaDialog.comercialUser?.id}
                options={comerciais}
                optionValue="id"
                id="comercialUserDialog"
                onChange={(value) =>
                  onChangeEmpresaDialog("comercialUser", value)
                }
                placeholder="Selecione..."
              />
            </Box>
          </Grid>
          <Grid templateColumns="1fr 1fr" gap="16px" mb="16px">
            <Box>
              <Text mb="8px">E-mail</Text>
              <Input
                value={empresaDialog.email || ""}
                onChange={(e) => onChangeEmpresaDialog("email", e.target.value)}
              />
            </Box>
            <Box>
              <Text mb="8px">Telefone</Text>
              <Input
                value={empresaDialog.telefone || ""}
                onChange={(e) =>
                  onChangeEmpresaDialog("telefone", e.target.value)
                }
              />
            </Box>
          </Grid>
          {userData.type === "SUPER" ? (
            <Grid templateColumns="85px auto" gap="16px" mb="16px">
              <Box>
                <Text mb="8px">&nbsp;</Text>
                <Checkbox
                  isChecked={empresaDialog.enabled3cplus}
                  onChange={(isChecked) =>
                    onChangeEmpresaDialogCheckbox("enabled3cplus", isChecked)
                  }
                >
                  3cplus
                </Checkbox>
              </Box>
              <Box>
                <Text mb="8px">Token 3cplus</Text>
                <Input
                  value={empresaDialog.token3cplus || ""}
                  isInvalid={errors.token3cplus}
                  onChange={(e) =>
                    onChangeEmpresaDialog("token3cplus", e.target.value)
                  }
                />
              </Box>
            </Grid>
          ) : null}
        </CustomModal>
      </GetLayout>
    </>
  );
}
