import { Box, Center, Flex, Grid, Text } from "@chakra-ui/react";
import { AttendanceChatProps } from "../chats-context/types";
import { UserIcon } from "components/vectors/user-icon";
import { DoubleCheck } from "components/vectors/whatsapp-icons";
import {
  convenioColors,
  initialKanbanColumns,
  parseConvenio,
} from "components/atendimentos-components/kanban-and-table-atendimento/kanban/parts/utils";
import { TabulacaoType } from "components/atendimentos-components/kanban-and-table-atendimento/kanban/parts/types";
import api from "api/api";
import { Toast } from "components/toast";
import { Dropdown } from "components/dropdown";
import { dropdownTabulacao } from "components/atendimentos-components/atendimento-form/fields-data";
import { memo, useState } from "react";
import { CheckIcon, ChevronDownIcon, CloseIcon } from "@chakra-ui/icons";
import { openModalConfirm } from "components/modal-confirm-new";
import { updateAtendimento } from "components/atendimentos-components/pagina-atendimento/pagined-form/parts/utils";
import { phoneFormat } from "utils/string-formats";
import {
  AttendanceActionsTypes,
  FormControl,
} from "contexts/attendance-context/actions-reducer";

export const greenWhatsapp = {
  500: "rgb(36, 211, 102)",
  600: "#1FA855",
  700: "#076058",
};
export const grayWhatsapp = {
  200: "rgb(240, 242, 245)",
  300: "rgb(223, 229, 231)",
  350: "#BCBCBC",
  400: "#667781",
  600: "#3b4a54",
};

function ConversationAttendanceCard({
  formControl,
  dispatch,
  selectedAttendance,
  setSelectedAttendance,
  ...props
}: AttendanceChatProps & {
  setAttendance: (data: AttendanceChatProps) => void;
  formControl: FormControl;
  dispatch: React.Dispatch<AttendanceActionsTypes>;
  selectedAttendance: AttendanceChatProps | undefined;
  setSelectedAttendance: React.Dispatch<
    React.SetStateAction<AttendanceChatProps | undefined>
  >;
}) {
  const [isLoading, setIsLoading] = useState(false);
  let {
    lastMessage,
    nome,
    etapa,
    convenio,
    tabulacao,
    id,
    setAttendance,
    unreadMessage,
    telefoneContatoNormalizado,
  } = props;
  const lastMessageTime = lastMessage?.timestamp
    ? new Date(lastMessage?.timestamp! * 1000 || "")
    : null;

  const hours = lastMessageTime?.getHours();
  const minutes = lastMessageTime?.getMinutes();
  const lastMessageTimeFormat = lastMessage?.timestamp
    ? `${hours}:${minutes! < 10 ? "0" : ""}${minutes}`
    : null;
  const messageLimit = 20;
  const slicedMessage =
    lastMessage?.body?.length! > messageLimit
      ? lastMessage?.body?.slice(0, messageLimit).trim() + "..."
      : lastMessage?.body;

  const nameLimit = 18;
  const slicedName =
    nome?.length! > nameLimit ? nome?.slice(0, nameLimit).trim() + "..." : nome;

  const tabulacaoLabel = dropdownTabulacao.find(
    (option) => option.value === tabulacao
  )?.name;

  const changeSelectedAttendance = () => {
    dispatch({ type: "closeAttendance" });
    setSelectedAttendance({ ...props, unreadMessage: false });
  };

  return (
    <Flex
      bg={selectedAttendance?.id === props.id ? "#f5f6f6" : "#fff"}
      _hover={{ bg: "#f5f6f6" }}
      pl="15px"
      onClick={() => {
        if (props.id !== selectedAttendance?.id) {
          if (formControl.pendingChanges) {
            openModalConfirm({
              message: (
                <Text>
                  Há alterações pendentes,{" "}
                  <Text as="span" fontWeight="semibold">
                    deseja salvar antes de mudar de atendimento?
                  </Text>
                </Text>
              ),
              variant: "normal",
              confirmButtonStyle: { children: "Salvar" },
              rejectButtonStyle: { children: "Descartar" },
              onConfirm: async () => {
                try {
                  await updateAtendimento({ formControl, dispatch });
                  changeSelectedAttendance();
                } catch (e) {}
              },
              onReject: () => changeSelectedAttendance(),
            });
          } else {
            if (etapa === "PENDENTE") {
              openModalConfirm({
                message: "Deseja iniciar o atendimento?",
                onConfirm: () => {
                  changeSelectedAttendance();
                },
                confirmButtonStyle: {
                  variant: undefined,
                  leftIcon: <CheckIcon />,
                },
                rejectButtonStyle: {
                  leftIcon: <CloseIcon w="12px" h="12px" />,
                },
              });
            } else {
              changeSelectedAttendance();
            }
          }
        }
      }}
      cursor="pointer"
      borderLeft={`4px solid ${
        initialKanbanColumns.find((col) => col.colId === etapa)?.color
      }`}
    >
      <Grid
        alignItems="center"
        templateColumns="calc(49px + 8px) auto calc(34px + 15px)"
        w="100%"
      >
        {/* Profile Photo */}
        <Box py="10px" pr="8px">
          <Center rounded="full" h="49px" w="49px" bg={grayWhatsapp[350]}>
            <UserIcon />
          </Center>
        </Box>
        {/* Name & last message */}
        <Flex
          flexDir="column"
          justifyContent="center"
          borderBottom={`1px solid ${grayWhatsapp[200]}`}
          // h="calc(49px + 10px + 10px)"
          pr="8px"
          h="100px"
        >
          {/* Name */}
          <Text
            fontWeight={unreadMessage ? "medium" : "normal"}
            fontSize={{ base: "16px", "2xl": "16px" }}
            color="#111b21"
          >
            {slicedName ||
              phoneFormat(telefoneContatoNormalizado, {
                withCountryNumber: telefoneContatoNormalizado?.length! > 11,
              }) ||
              "(sem nome)"}
          </Text>
          {/*  */}
          {/* Last Message Preview */}
          <Flex
            alignItems="center"
            fontSize={{ base: "13px", "2xl": "14px" }}
            fontWeight={unreadMessage ? "medium" : "normal"}
            h="20px"
            color={unreadMessage ? grayWhatsapp[600] : grayWhatsapp[400]}
          >
            {lastMessage?.body ? (
              <Text as="span" mr="4px" transform="translateY(-1.5px)">
                <DoubleCheck
                  width={{ base: "15px", "2xl": "16px" }}
                  height={{ base: "15px", "2xl": "16px" }}
                />
              </Text>
            ) : null}
            {lastMessage?.body ? slicedMessage : null}&nbsp;
          </Flex>
          {/*  */}
          {/* Tag Blocks */}
          <Flex alignItems="center" mt="8px">
            {convenio ? (
              <Flex
                alignItems="center"
                borderRadius="6px"
                fontWeight="medium"
                fontSize={{ base: "9px", "2xl": "11px" }}
                h="27px"
                p={{ base: "2px 6px", "2xl": "3px 8px" }}
                mr="8px"
                lineHeight="1"
                bg={convenioColors.get(convenio!) ?? "#F3EBA4"}
              >
                {parseConvenio(convenio!)}
              </Flex>
            ) : null}
            <Dropdown
              onChange={(toTabulacao) => {
                if (toTabulacao !== tabulacao) {
                  changeTabulacao({
                    fromTabulacao: tabulacao!,
                    toTabulacao,
                    attendanceId: id,
                    setAttendance,
                    setIsLoading,
                    atendimento: props,
                  });
                }
              }}
              overflow="hidden"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
              }}
              containerOptionProps={{
                onClick: (e) => {
                  e.stopPropagation();
                  e.preventDefault();
                },
              }}
              value={tabulacao}
              borderRadius="6px"
              options={dropdownTabulacao}
              border="1px solid #D5D5E5"
              fontWeight="medium"
              fontSize={{ base: "9px", "2xl": "11px" }}
              size="xs"
              h="27px"
              p={{ base: "2px 6px", "2xl": "3px 8px" }}
              variant="outline"
              iconSpacing="3px"
              menuListProps={{ fontSize: "12px" }}
              isLoading={isLoading}
              rightIcon={<ChevronDownIcon w="17px" h="17px" />}
            >
              {tabulacaoLabel?.length! > 7
                ? tabulacaoLabel?.slice(0, 7) + "..."
                : tabulacaoLabel}
            </Dropdown>
          </Flex>
        </Flex>
        {/* Time infos and unread icon */}
        <Flex
          flexDir="column"
          alignItems="flex-end"
          justifyContent="center"
          borderBottom={`1px solid ${grayWhatsapp[200]}`}
          pr="15px"
          h="100px"
        >
          <Text
            fontSize="12px"
            mb="4px"
            fontWeight="semibold"
            h="24px"
            color={unreadMessage ? greenWhatsapp[600] : grayWhatsapp[400]}
          >
            {lastMessageTimeFormat}
          </Text>
          <Center
            bg={unreadMessage ? greenWhatsapp[600] : undefined}
            w="20px"
            h="20px"
            rounded="full"
          ></Center>

          {etapa ? (
            <Flex
              mt="8px"
              alignItems="center"
              borderRadius="6px"
              fontWeight="medium"
              fontSize={{ base: "9px", "2xl": "11px" }}
              h="27px"
              p={{ base: "2px 6px", "2xl": "3px 8px" }}
              ml="8px"
              lineHeight="1"
              whiteSpace={{ base: "normal", "2xl": "nowrap" }}
              bg={
                initialKanbanColumns.find((col) => col.colId === etapa)
                  ?.color ?? "#F3EBA4"
              }
              color="white"
            >
              {initialKanbanColumns.find((col) => col.colId === etapa)?.colName}
            </Flex>
          ) : null}
        </Flex>
      </Grid>
    </Flex>
  );
}

export default memo(ConversationAttendanceCard, (prevProps, currProps) => {
  const propsCompare: any = {
    dataAtualizacao: prevProps.dataAtualizacao === currProps.dataAtualizacao,
    etapa: prevProps.etapa === currProps.etapa,
    nome: prevProps.nome === currProps.nome,
    telefoneContatoNormalizado:
      prevProps.telefoneContatoNormalizado ===
      currProps.telefoneContatoNormalizado,
    usuario: prevProps.usuarioNome === currProps.usuarioNome,
    mensagem: prevProps.lastMessage?.body === currProps.lastMessage?.body,
    timestamp:
      prevProps.lastMessage?.timestamp === currProps.lastMessage?.timestamp,
    convenio: prevProps.convenio === currProps.convenio,
    selectedAttendance:
      (prevProps.selectedAttendance?.id === prevProps.id) ===
      (currProps.selectedAttendance?.id === currProps.id),
    tabulacao: prevProps.tabulacao === currProps.tabulacao,
  };

  const isEquals = !Object.keys(propsCompare).find((k) => !propsCompare[k]);
  return isEquals;
});

const changeTabulacao = async ({
  fromTabulacao,
  toTabulacao,
  attendanceId,
  setAttendance,
  setIsLoading,
  atendimento,
}: {
  fromTabulacao: TabulacaoType | null;
  toTabulacao: TabulacaoType | null;
  attendanceId: number;
  setAttendance: (data: AttendanceChatProps) => void;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  atendimento: AttendanceChatProps;
}) => {
  setIsLoading(true);
  try {
    await api.post(`/atendimentos/${attendanceId}/change-tabulacao`, {
      fromTabulacao,
      toTabulacao,
    });
    setAttendance({ ...atendimento, tabulacao: toTabulacao });
  } catch (e) {
    Toast({ title: "Erro ao tabular", status: "error" });
  } finally {
    setIsLoading(false);
  }
};
