export const selectOperator: any = {
  ignore: "",
  range: "between",
  "0": "equal",
  "1": "equal",
  equal: "in",
  "not_in-codbanco": "not_in",
  "equal-codbanco": "in",
};

export const selectValue: any = {
  ignore: [],
  "0": [0],
  "1": [1],
  range: [0, 0],
  equal: [0],
  in: [0],
  "not_in-codbanco": [],
  "equal-codbanco": [],
};

export const banks = [
  { value: "001", name: "Banco do Brasil", shortName: "BB" },
  { value: "003", name: "Banco da Amazônia", shortName: "B. Amazônia" },
  { value: "004", name: "Banco do Nordeste", shortName: "B. Nordeste" },
  { value: "012", name: "Banco Inbursa", shortName: "B. Inbursa" },
  { value: "021", name: "Banestes", shortName: "B. Banestes" },
  { value: "025", name: "Banco Alfa", shortName: "B. Alfa" },
  { value: "029", name: "Banco Itau Consignado", shortName: "Itau Consig" },
  { value: "033", name: "Banco Santander", shortName: "B. Santander" },
  { value: "036", name: "Banco Bradesco - BBI", shortName: "Bradesco BBI" },
  { value: "037", name: "Banco do Estado do Pará", shortName: "B. Pará" },
  { value: "040", name: "Banco Cargill", shortName: "B. Cargill" },
  { value: "041", name: "Banrisul", shortName: "Banrisul" },
  { value: "047", name: "Banco do Estado do Sergipe", shortName: "B. Sergipe" },
  { value: "062", name: "Hipercard", shortName: "B. Hipercard" },
  { value: "063", name: "Banco Bradescard", shortName: "B. Bradescard" },
  { value: "065", name: "Banco AndBank", shortName: "B. AndBank" },
  { value: "066", name: "Banco Morgan Stanley", shortName: "Morgan Stanley" },
  { value: "069", name: "Banco Crefisa", shortName: "B. Crefisa" },
  { value: "070", name: "Banco de Brasília - BRB", shortName: "B. Brasília" },
  { value: "074", name: "Banco J.Safra", shortName: "J.Safra" },
  { value: "075", name: "Banco ABN Amro", shortName: "ABN Amro" },
  { value: "076", name: "Banco KDB", shortName: "KDB" },
  {
    value: "079",
    name: "Original do Agronegócio S.A",
    shortName: "B. Agronegócio",
  },
  { value: "081", name: "PagBank", shortName: "PagBank" },
  { value: "096", name: "Banco B3", shortName: "B3" },
  { value: "104", name: "Caixa Econômica Federal", shortName: "B. CEF" },
  { value: "107", name: "Banco BOCOM BBM", shortName: "BOCOM BBM" },
  { value: "121", name: "Banco Agibank", shortName: "Agibank" },
  { value: "184", name: "Banco Itaú BBA", shortName: "Itaú BBA" },
  { value: "208", name: "BTG Pactual", shortName: "BTG" },
  { value: "212", name: "Banco Original", shortName: "Original" },
  { value: "213", name: "Banco Arbi", shortName: "Arbi" },
  { value: "217", name: "Banco Joh Deere", shortName: "Joh Deere" },
  { value: "218", name: "Banco BS2", shortName: "BS2" },
  {
    value: "222",
    name: "Banco Credit Agricole Brasil",
    shortName: "Agricole BR",
  },
  { value: "224", name: "Banco Fibra", shortName: "Fibra" },
  { value: "233", name: "Banco Cifra", shortName: "Cifra" },
  { value: "237", name: "Banco Bradesco", shortName: "B. Bradesco" },
  { value: "241", name: "Banco Clássico", shortName: "Clássico" },
  { value: "243", name: "Banco Máxima", shortName: "B. Máxima" },
  { value: "246", name: "Banco ABC Brasil", shortName: "ABC Brasil" },
  { value: "249", name: "Banco Investcred Unibanco", shortName: "Investcred" },
  {
    value: "250",
    name: "Banco de Crédito e Varejo",
    shortName: "Crédito e Varejo",
  },
  { value: "254", name: "Paraná Banco", shortName: "B. Paraná" },
  { value: "265", name: "Banco Fator", shortName: "Fator" },
  { value: "266", name: "Banco Cédula", shortName: "Cédula" },
  { value: "318", name: "Banco BMG", shortName: "BMG" },
  { value: "341", name: "Itau Unibanco", shortName: "Itau" },
  {
    value: "366",
    name: "Banco Societe Generale Brasil",
    shortName: "Societe Generale BR",
  },
  {
    value: "370",
    name: "Banco Mizuho do Brasil",
    shortName: "Banco Mizuho do BR",
  },
  { value: "376", name: "Banco JP Morgan", shortName: "JP Morgan" },
  { value: "389", name: "Banco Mercantil do Brasil", shortName: "Mercantil" },
  { value: "394", name: "Bradesco Financiamentos", shortName: "Bradesco Fin." },
  { value: "399", name: "Kirton Bank", shortName: "Kirton Bank" },
  { value: "412", name: "Banco Capital", shortName: "Capital" },
  { value: "422", name: "Banco Safra", shortName: "Safra" },
  { value: "456", name: "Banco MUFG", shortName: "MUFG" },
  {
    value: "464",
    name: "Banco Sumitomo Mitsui Brasileiro",
    shortName: "Sumitomo Mitsui",
  },
  { value: "477", name: "Citibank", shortName: "Citibank" },
  { value: "479", name: "Banco ItauBank", shortName: "ItauBank" },
  { value: "487", name: "Deutsche Bank", shortName: "Deutsche" },
  { value: "488", name: "JPMorgan Chase Bank", shortName: "JPMorgan Chase" },
  { value: "492", name: "ING Bank", shortName: "ING" },
  {
    value: "495",
    name: "Banco La Provincia de Buenos Aires",
    shortName: "Banco La Provincia de Buenos Aires",
  },
  {
    value: "505",
    name: "Banco Credit Suisse",
    shortName: "Banco Credit Suisse",
  },
  { value: "600", name: "Banco Luso Brasileiro", shortName: "Banco Luso" },
  {
    value: "604",
    name: "Banco Industrial do Brasil",
    shortName: "Indust. do Brasil",
  },
  { value: "610", name: "Banco VR", shortName: "VR" },
  { value: "611", name: "Banco Paulista", shortName: "Paulista" },
  { value: "612", name: "Banco Guanabara", shortName: "Guanabara" },
  { value: "613", name: "Omni Banco", shortName: "Omni" },
  { value: "623", name: "Banco Pan", shortName: "Pan" },
  { value: "626", name: "Banco C6 - FICSA", shortName: "C6" },
  { value: "630", name: "Banco SmartBank", shortName: "SmartBank" },
  { value: "633", name: "Banco Rendimento", shortName: "Rendimento" },
  { value: "634", name: "Banco Triângulo", shortName: "Triângulo" },
  { value: "637", name: "Banco Sofisa", shortName: "Sofisa" },
  { value: "643", name: "Banco Pine", shortName: "Pine" },
  {
    value: "652",
    name: "Itau Unibanco Holding",
    shortName: "Itau Uni Holding",
  },
  { value: "653", name: "Banco Indusval", shortName: "Indusval" },
  { value: "654", name: "Banco A.J. Renner", shortName: "A.J. Renner" },
  { value: "655", name: "Banco Votorantim", shortName: "Votorantim" },
  { value: "707", name: "Banco Daycoval", shortName: "Daycoval" },
  { value: "739", name: "Banco Cetelem", shortName: "Cetelem" },
  { value: "741", name: "Banco Ribeirao Preto", shortName: "Ribeirao Preto" },
  { value: "743", name: "Banco Semear", shortName: "Semear" },
  { value: "745", name: "Banco Citibank", shortName: "Citibank" },
  { value: "746", name: "Banco Modal", shortName: "Modal" },
  {
    value: "747",
    name: "Banco Rabobank International Brasil",
    shortName: "Rabobank International BR",
  },
  { value: "748", name: "Banco Cooperativo Sicredi", shortName: "Sicredi" },
  { value: "751", name: "Scotiabank Brasil", shortName: "Scotiabank BR" },
  { value: "752", name: "BNP Paribas Brasil", shortName: "BNP Paribas BR" },
  { value: "753", name: "Novo Banco Continental", shortName: "Continental" },
  {
    value: "756",
    name: "Banco Cooperativo do Brasil",
    shortName: "Cooperativo do BR",
  },
  { value: "757", name: "KEB HANA do Brasil", shortName: "KEB HANA do BR" },
  { value: "935", name: "FACTA Financeira", shortName: "FACTA" },
  {
    value: "329",
    name: "Qi Sociedade de Crédito Direto",
    shortName: "Qi Sociedade de Crédito Direto",
  },
  { value: "901", name: "CCB Brasil S.A", shortName: "CCB BR" },
];

export const bancosAbreviados = [
  { value: "001", name: "BB" },
  { value: "003", name: "Amazônia" },
  { value: "004", name: "Nordeste" },
  { value: "012", name: "Banco Inbursa" },
  { value: "021", name: "Banestes" },
  { value: "025", name: "Alfa" },
  { value: "029", name: "Itau Consignado" },
  { value: "033", name: "Santander" },
  { value: "036", name: "Bradesco" },
  { value: "037", name: "Banco do Pará" },
  { value: "040", name: "Cargill" },
  { value: "041", name: "Banrisul" },
  { value: "047", name: "Banco de Sergipe" },
  { value: "062", name: "Hipercard" },
  { value: "063", name: "Bradescard" },
  { value: "065", name: "AndBank" },
  { value: "066", name: "Morgan Stanley" },
  { value: "069", name: "Crefisa" },
  { value: "070", name: "Banco de Brasília" },
  { value: "074", name: "J.Safra" },
  { value: "075", name: "ABN Amro" },
  { value: "076", name: "KDB" },
  { value: "079", name: "Agronegócio S.A" },
  { value: "081", name: "PagBank" },
  { value: "096", name: "B3" },
  { value: "104", name: "Caixa" },
  { value: "107", name: "BOCOM BBM" },
  { value: "121", name: "Agibank" },
  { value: "184", name: "Itaú BBA" },
  { value: "208", name: "BTG" },
  { value: "212", name: "Original" },
  { value: "213", name: "Arbi" },
  { value: "217", name: "Joh Deere" },
  { value: "218", name: "BS2" },
  { value: "222", name: "Agricole Brasil" },
  { value: "224", name: "Fibra" },
  { value: "233", name: "Cifra" },
  { value: "237", name: "Bradesco" },
  { value: "241", name: "Clássico" },
  { value: "243", name: "Máxima" },
  { value: "246", name: "ABC Brasil" },
  { value: "249", name: "Investcred" },
  { value: "250", name: "Crédito e Varejo" },
  { value: "254", name: "Paraná" },
  { value: "265", name: "Fator" },
  { value: "266", name: "Cédula" },
  { value: "318", name: "BMG" },
  // { value: "320", name: "Banco China Construction Bank" },
  { value: "341", name: "Itau" },
  { value: "366", name: "Societe Generale Brasil" },
  { value: "370", name: "Banco Mizuho do Brasil" },
  { value: "376", name: "JP Morgan" },
  { value: "389", name: "Mercantil do Brasil" },
  { value: "394", name: "Bradesco Financiamentos" },
  { value: "399", name: "Kirton Bank" },
  { value: "412", name: "Capital" },
  { value: "422", name: "Safra" },
  { value: "456", name: "MUFG" },
  { value: "464", name: "Sumitomo Mitsui" },
  { value: "477", name: "Citibank" },
  { value: "479", name: "ItauBank" },
  { value: "487", name: "Deutsche" },
  { value: "488", name: "JPMorgan Chase" },
  { value: "492", name: "ING" },
  { value: "495", name: "Banco La Provincia de Buenos Aires" },
  { value: "505", name: "Banco Credit Suisse" },
  { value: "600", name: "Banco Luso" },
  { value: "604", name: "Industrial do Brasil" },
  { value: "610", name: "VR" },
  { value: "611", name: "Paulista" },
  { value: "612", name: "Guanabara" },
  { value: "613", name: "Omni" },
  { value: "623", name: "Pan" },
  { value: "626", name: "C6 - FICSA" },
  { value: "630", name: "SmartBank" },
  { value: "633", name: "Rendimento" },
  { value: "634", name: "Triângulo" },
  { value: "637", name: "Sofisa" },
  { value: "643", name: "Pine" },
  { value: "652", name: "Itau Unibanco Holding" },
  { value: "653", name: "Indusval" },
  { value: "654", name: "A.J. Renner" },
  { value: "655", name: "Votorantim" },
  { value: "707", name: "Daycoval" },
  { value: "739", name: "Cetelem" },
  { value: "741", name: "Ribeirao Preto" },
  { value: "743", name: "Semear" },
  { value: "745", name: "Citibank" },
  { value: "746", name: "Modal" },
  { value: "747", name: "Rabobank International Brasil" },
  { value: "748", name: "Cooperativo Sicredi" },
  { value: "751", name: "Scotiabank Brasil" },
  { value: "752", name: "BNP Paribas Brasil" },
  { value: "753", name: "Continental" },
  { value: "756", name: "Cooperativo do Brasil" },
  { value: "757", name: "KEB HANA do Brasil" },
  { value: "935", name: "FACTA Financeira" },
  { value: "329", name: "Qi Sociedade de Crédito Direto" },
  { value: "901", name: "CCB Brasil S.A" },
];
