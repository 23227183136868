import {
  Flex,
  InputProps,
  Tag,
  TagCloseButton,
  TagLabel,
} from "@chakra-ui/react";
import { Template } from "../mailing-template";
import { MailingFilterProps } from "../../types";
import { RadioFilter } from "./radio-filter";
import { InputField } from "components/input-field";

export function KeyWordsFilter({
  title,
  description,
  mapKey,
  onChangeFilter,
  operators,
  filtersValues,
  inputProps,
  invalidFields,
}: {
  inputProps?: InputProps;
  invalidFields: { [k: string]: string };
} & MailingFilterProps) {
  const currentFilter = filtersValues.get(mapKey);
  const filterValue = currentFilter?.value ?? [];
  const errorMessage = invalidFields[mapKey];

  return (
    <Template title={title} description={description} mapKey={mapKey}>
      <RadioFilter
        mapKey={mapKey}
        operators={operators}
        onChangeFilter={onChangeFilter}
        value={currentFilter?.op}
      />
      {currentFilter?.op === "in" || currentFilter?.op === "not_in" ? (
        <Flex
          flexDir="column"
          borderRadius="6"
          border="1px solid var(--chakra-colors-custom-gray)"
          w="500px"
        >
          <InputField
            inputProps={{
              onKeyDown: ({ currentTarget, key }) => {
                if (key === "Enter") {
                  const value =
                    inputProps?.type === "number"
                      ? parseInt(currentTarget.value)
                      : currentTarget.value
                          .toUpperCase()
                          .normalize("NFD")
                          .replace(/[\u0300-\u036f]/g, "");

                  if (!filterValue.includes(value) && value) {
                    onChangeFilter(mapKey, (prev) => {
                      prev.set(mapKey, {
                        ...currentFilter!,
                        value: [...filterValue, value],
                      });
                      return new Map(prev.entries());
                    });
                  }
                  currentTarget.value = "";
                }
              },
              border: "none",
              mb: filterValue.length ? "10px" : undefined,
              ...inputProps,
            }}
            errorMessage={errorMessage}
          />
          <Flex
            px="10px"
            py={filterValue.length ? "10px" : undefined}
            gap="10px"
            flexWrap="wrap"
          >
            {filterValue.map((item, index) => (
              <Tag
                key={item}
                variant="solid"
                colorScheme={
                  currentFilter?.op === "not_in" ? "red" : "facebook"
                }
              >
                <TagLabel>{item}</TagLabel>
                <TagCloseButton
                  onClick={() => {
                    onChangeFilter(
                      mapKey,
                      (prev) =>
                        new Map(
                          prev
                            .set(mapKey, {
                              ...currentFilter!,
                              value: filterValue.filter((_, i) => i !== index),
                            })
                            .entries()
                        )
                    );
                  }}
                />
              </Tag>
            ))}
          </Flex>
        </Flex>
      ) : null}
    </Template>
  );
}
