import { useRef } from "react";

export function useTiming({
  dispatch,
  timing = 3000,
}: {
  dispatch: () => void;
  timing?: number;
}) {
  const whileLoading = useRef<ReturnType<typeof setInterval> | null>(null);
  const timer = useRef<ReturnType<typeof setTimeout> | null>(null);
  const loading = useRef(false);

  const resetTimer = () => {
    if (timer.current) {
      clearTimeout(timer.current);
      timer.current = null;
    }
    if (whileLoading.current) {
      clearInterval(whileLoading.current);
      whileLoading.current = null;
    }
  };

  const autoUpdateTimingControl = () => {
    resetTimer();
    if (!loading.current) {
      timer.current = setTimeout(() => {
        dispatch();
        timer.current = null;
      }, timing);
    } else {
      whileLoading.current = setInterval(() => {
        if (!loading.current) autoUpdateTimingControl();
      }, 400);
    }
  };

  return { resetTimer, autoUpdateTimingControl };
}
