import {
  Box,
  Button,
  Center,
  Flex,
  FlexProps,
  Grid,
  IconButton,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import AttendanceInput from "../../../attendance-field/attendance-input";
import { Toast } from "components/toast";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useAttendanceContext } from "contexts/attendance-context";
import { useApplicationContext } from "contexts/ApplicationContext";
import {
  Attendance,
  AttendanceMargens,
} from "components/atendimentos-components/atendimento-form/types";
import {
  calcularLimiteCartao,
  calcularSaqueCartao,
  dropdownSimNao,
} from "components/atendimentos-components/atendimento-form/fields-data";
import AttendanceDropdown from "../../../attendance-field/attendance-dropdown";
import { Accordion } from "../../../accordion";
import { Checkbox } from "components/checkbox";
import { dropdownBancos } from "components/usuarios-bancos-components/consts";
import {
  onKeyDownOmitNegativeNumbers,
  onlyNumberValidMask,
  percentualMaskKit,
} from "utils/string-formats";
import {
  GovDataRow,
  mapeamentoHigienizacaoCronowiseGOV,
  valorLiberadoCliente,
} from "../utils";
import { ChevronDownIcon } from "components/vectors/chevron-down-icon";
import {
  ErrorMessage,
  ErrorMessageList,
  InfoMessage,
  InfoMessageList,
} from "../contratos/error-message";
import { RepeatIcon } from "@chakra-ui/icons";
import { ModalCronowise, openModalCronowise } from "../../modal-cronowise";
import api from "api/api";
import { higienizadorConveniosOptions } from "components/usuarios-bancos-components/usuarios-bancos-modal/utils";
import {
  ModalMargensCronowise,
  openModalMargensCronowise,
} from "./modal-margens-cronowise";
import { FaEye } from "react-icons/fa";
import { openModalConfirm } from "components/modal-confirm-new";
import { SimulacaoFields } from "./simulacao-fields";
import { SimulacaoDataProps } from "./simulacao-fields/types";
import apiDigitacao from "api/api-digitacao";
import {
  ModalDigitacaoINSS,
  openModalDigitacaoINSS,
} from "./modal-digitacao-inss";
import { AttendanceTabKey } from "components/atendimentos-components/pagina-atendimento/attendance-data-form";

export interface CronowiseHigienizacaoResultProps {
  convenio: string;
  idSubConvenio: number;
  idBanco: number;
  finalizado: boolean;
  dataAtualizacao: string | null;
  error: "nenhum usuário ativo disponivel" | null;
  mensagem: null | string;
  resultado?: string;
}

export interface SimulacaoResultProps {
  banco?: string;
  valorLiberado?: null | number;
  observacao?: null | string;
  status?: "PENDENT" | "FINISHED";
  simulacao?: SimulacaoDataProps;
}

export function MargensAtendimentoFormPart({
  isLoading,
  setIsLoading,
  onChangeTab,
  telaAtendimento,
}: {
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  onChangeTab: (tabKey: AttendanceTabKey) => void;
  telaAtendimento: boolean;
}) {
  const [higienizacaoLoading, setHigienizacaoLoading] = useState(false);
  const [higienizacaoEmAndamento, setHigienizacaoEmAndamento] = useState(false);
  const [higienizacao, setHigienizacao] = useState<GovDataRow[] | null>(null);
  const [cronowiseMessages, setCronowiseMessages] = useState<InfoMessageList>();
  const [cronowiseErrors, setCronowiseErrors] = useState<ErrorMessageList>();
  const [simulacao, setSimulacao] = useState<SimulacaoResultProps>({});
  const [loadingSimulacao, setLoadingSimulacao] = useState(false);
  const [loadingDigitacao, setLoadingDigitacao] = useState(false);
  const { formControl, dispatch } = useAttendanceContext();
  const { user } = useApplicationContext();
  const attendance = formControl.values as Attendance;

  const convenio = attendance.convenio;

  const onChangeInput = useCallback(
    (fieldName: keyof Attendance, subKey?: keyof AttendanceMargens) =>
      (value: string) => {
        let objectPart = (attendance[fieldName] as any) || {};
        if (subKey) objectPart[subKey] = value;
        dispatch({
          type: "changeField",
          payload: { fieldName, data: subKey ? objectPart : value },
        });
        dispatch({
          type: "setInvalidField",
          payload: { fieldName, action: "clear" },
        });
      },
    [dispatch, attendance]
  );

  const onChangeDropdown = useCallback(
    (fieldName: keyof Attendance, subKey?: keyof AttendanceMargens) =>
      (newValue: any) => {
        let objectPart = (attendance[fieldName] as any) || {};
        if (subKey) objectPart[subKey] = newValue;

        dispatch({
          type: "changeField",
          payload: { fieldName, data: subKey ? objectPart : newValue },
        });
        dispatch({
          type: "setInvalidField",
          payload: { fieldName, action: "clear" },
        });
      },
    [dispatch, attendance]
  );

  function setIsChecked(margemField: keyof AttendanceMargens, value: boolean) {
    let data = (attendance.margens as any) || {};
    data[margemField] = value;

    dispatch({
      type: "changeField",
      payload: { fieldName: "margens", data },
    });
  }

  const setHigienizacaoInAttendance = (rows: GovDataRow[]) => {
    if (rows.length > 1) {
      openModalMargensCronowise({
        rows,
        onUpdateMargens: (selectedRow) => {
          mapeamentoHigienizacaoCronowiseGOV({
            attendance,
            data: selectedRow as any,
            dispatch,
          });
          Toast({ title: "Margens Atualizadas", status: "success" });
        },
      });
    } else {
      openModalConfirm({
        message: `Copiar dados de higienização para o atendimento?`,
        onConfirm: () => {
          mapeamentoHigienizacaoCronowiseGOV({
            attendance,
            data: rows[0] as any,
            dispatch,
          });
          Toast({ title: "Margens Atualizadas", status: "success" });
        },
        variant: "normal",
      });
    }
  };

  const getCronowiseHigienizacao = async ({
    firstRequest = false,
  }: { firstRequest?: boolean } = {}) => {
    setHigienizacaoLoading(true);
    try {
      let { data }: { data: CronowiseHigienizacaoResultProps[] } =
        await api.get(`/atendimento-margem-convenio/${attendance.id}`);

      setHigienizacaoEmAndamento(
        !!data.find((curr) => curr.finalizado === false)
      );

      const errorMessagesList = data
        .filter((crr) => !!crr.error)
        .sort((a: any, b: any) => {
          let curr = new Date(a.dataAtualizacao).getTime();
          let next = new Date(b.dataAtualizacao).getTime();

          if (curr > next) return -1;
          if (curr < next) return 1;
          return 0;
        })
        .map((curr) => ({
          errorTitle: `${
            higienizadorConveniosOptions.find((c) => c.value === curr.convenio)
              ?.name
          }${
            curr.idSubConvenio ? ` | Subconvênio: ${curr.idSubConvenio}` : ""
          }${curr.idBanco ? ` | Banco: ${curr.idBanco}` : ""}${
            curr.dataAtualizacao
              ? ` | Data: ${new Date(curr.dataAtualizacao).toLocaleString()}`
              : ""
          }`,
          errorList: [curr.error],
        })) as ErrorMessageList;

      const infoMessagesList = data
        .filter((crr) => !!crr.mensagem)
        .sort((a: any, b: any) => {
          if (a.dataAtualizacao == null || b.dataAtualizacao == null) return 0;
          let curr = new Date(a.dataAtualizacao).getTime();
          let next = new Date(b.dataAtualizacao).getTime();

          if (curr > next) return -1;
          if (curr < next) return 1;
          return 0;
        })
        .map((curr) => ({
          messageTitle: `${
            higienizadorConveniosOptions.find((c) => c.value === curr.convenio)
              ?.name
          }${
            curr.idSubConvenio ? ` | Subconvênio: ${curr.idSubConvenio}` : ""
          }${curr.idBanco ? ` | Banco: ${curr.idBanco}` : ""}${
            curr.dataAtualizacao
              ? ` | Data: ${new Date(curr.dataAtualizacao).toLocaleString()}`
              : ""
          }`,
          messageList: [curr.mensagem],
        })) as InfoMessageList;

      if (data.length) {
        const json = data[0].resultado;
        if (json) {
          let rows = JSON.parse(json).data.map((row: any) => ({
            ...row,
            dataAtualizacao: data[0].dataAtualizacao,
          }));

          if (!firstRequest && rows.length) {
            setHigienizacaoInAttendance(rows);
          }
          setHigienizacao(rows);
        }
      } else if (!firstRequest) {
        setHigienizacao(null);
      }

      setCronowiseErrors(errorMessagesList);
      setCronowiseMessages(infoMessagesList);
    } catch (e: any) {
      const errorMessage = e?.response?.data?.message;
      Toast({
        title: errorMessage ?? "Erro ao atualizar higienização",
      });
    } finally {
      setHigienizacaoLoading(false);
    }
  };

  useEffect(() => {
    return () => {
      setHigienizacao(null);
    };
  }, []);

  useEffect(() => {
    if (convenio === "GOV" && user.userData.customerId === 1)
      getCronowiseHigienizacao({ firstRequest: true });
  }, []);

  useEffect(() => {
    getSimulacaoResult();
  }, []);

  const getSimulacaoResult = async ({
    pending,
  }: { pending?: boolean } = {}) => {
    setLoadingSimulacao(true);
    try {
      const { data } = await apiDigitacao.get(
        `api/simulacoes/inss/atendimento/${attendance.id}/consultar`
      );

      setSimulacao(data);
    } catch (e) {
      // Toast({title: ""})
    } finally {
      setLoadingSimulacao(false);
    }
  };

  return (
    <>
      {convenio === "GOV" && user.userData.customerId === 1 ? (
        <>
          <Flex alignItems="center" justifyContent="space-between" mb="10px">
            <Box></Box>

            <Flex>
              <Box onClick={(e) => e.stopPropagation()} mr="8px">
                <InfoMessage
                  isMessage={!!cronowiseMessages?.length}
                  infoMessage={cronowiseMessages}
                />
              </Box>

              <Box onClick={(e) => e.stopPropagation()} mr="8px">
                <ErrorMessage
                  isError={!!cronowiseErrors?.length}
                  errorMessage={cronowiseErrors}
                />
              </Box>
              {higienizacao ? (
                <Button
                  leftIcon={<FaEye />}
                  onClick={(e) => {
                    setHigienizacaoInAttendance(higienizacao);
                  }}
                  size="sm"
                  borderRadius="5px"
                  px="8px"
                  h="28px"
                  variant="outline"
                  mr="8px"
                >
                  Ver Higienização
                </Button>
              ) : null}
              <Button
                leftIcon={<RepeatIcon />}
                isLoading={higienizacaoLoading}
                onClick={async (e) => {
                  e.stopPropagation();
                  if (higienizacaoEmAndamento) {
                    getCronowiseHigienizacao();
                  } else {
                    openModalCronowise();
                  }
                }}
                size="sm"
                borderRadius="5px"
                px="8px"
                h="28px"
                variant="outline"
              >
                {higienizacaoEmAndamento ? "Atualizar" : "Higienizar"}
              </Button>
            </Flex>
          </Flex>
          <ModalCronowise onUpdate={getCronowiseHigienizacao} />
        </>
      ) : null}

      <ModalDigitacaoINSS />
      <Accordion
        title={"Empréstimo Consignado"}
        containerProps={{ mb: "20px" }}
        renderButton={({ onToggle, isOpen }) => {
          return (
            <Flex justifyContent="space-between" alignItems="center">
              <Flex>
                <IconButton
                  aria-label=""
                  onClick={(e) => {
                    e.stopPropagation();
                    onToggle();
                  }}
                  size="xs"
                  borderRadius="full"
                  w="28px"
                  minW="28px"
                  h="28px"
                  icon={<ChevronDownIcon />}
                  transform={isOpen ? "rotate(180deg)" : ""}
                />
              </Flex>
            </Flex>
          );
        }}
      >
        {user.userData.customerId !== 1 ? (
          <Grid templateColumns="1fr 1fr" columnGap="20px" rowGap="40px">
            <AttendanceInput
              title="Margem livre (novo)"
              onChange={onChangeInput("margens", "valorMargemLivreNovo")}
              fieldName="margens.valorMargemLivreNovo"
              value={attendance.margens?.valorMargemLivreNovo}
              inputProps={{
                type: "number",
                onKeyDown: onKeyDownOmitNegativeNumbers,
              }}
              source="margens.fonteEmprestimoNovo"
              mask={onlyNumberValidMask}
            />
            <AttendanceDropdown
              title="Banco"
              onChange={onChangeDropdown("margens", "bancoMargemLivreNovo")}
              value={attendance.margens?.bancoMargemLivreNovo}
              fieldName="margens.bancoMargemLivreNovo"
              options={dropdownBancos}
            />
            <AttendanceInput
              title="Coeficiente (Novo)"
              onChange={onChangeInput("margens", "coeficienteNovo")}
              fieldName="margens.coeficienteNovo"
              value={attendance.margens?.coeficienteNovo}
              inputProps={{
                type: "number",
                onKeyDown: onKeyDownOmitNegativeNumbers,
              }}
              mask={onlyNumberValidMask}
            />
            <AttendanceInput
              title="Código tabela"
              onChange={onChangeInput("margens", "codigoTabela")}
              value={attendance.margens?.codigoTabela}
              fieldName="margens.codigoTabela"
            />

            <AttendanceInput
              title="Prazo"
              onChange={onChangeInput("margens", "prazoNovo")}
              value={attendance.margens?.prazoNovo}
              fieldName="margens.prazoNovo"
            />
            <AttendanceInput
              title="Valor liberado ao cliente"
              value={valorLiberadoCliente(attendance.margens)}
            />
            <AttendanceInput
              title="Margem Bruta"
              onChange={onChangeInput("margens", "margemBrutaEmprestimo")}
              value={attendance.margens?.margemBrutaEmprestimo}
              fieldName="margens.margemBrutaEmprestimo"
              source="margens.fonteEmprestimoNovo"
              inputProps={{ type: "number" }}
            />
          </Grid>
        ) : (
          <Grid templateColumns="1fr 1fr" columnGap="20px" rowGap="40px">
            {/* RESULTADOS SIMULACAO */}
            <SimulacaoFields
              simulacaoResult={simulacao}
              setSimulacao={setSimulacao}
              getSimulacaoResult={getSimulacaoResult}
              onChangeInput={onChangeInput}
              telaAtendimento={telaAtendimento}
            />
          </Grid>
        )}
      </Accordion>
      <Accordion
        title={"Cartão (RMC)"}
        containerProps={{ mb: "20px" }}
        renderButton={({ onToggle, isOpen }) => {
          return (
            <>
              <IconButton
                aria-label=""
                onClick={(e) => {
                  e.stopPropagation();
                  onToggle();
                }}
                size="xs"
                borderRadius="full"
                w="28px"
                minW="28px"
                h="28px"
                icon={<ChevronDownIcon />}
                transform={isOpen ? "rotate(180deg)" : ""}
              />
            </>
          );
        }}
      >
        <Grid
          templateColumns={telaAtendimento ? "1fr 1fr" : "1fr"}
          columnGap="20px"
          rowGap="40px"
        >
          <AttendanceDropdown
            title="Possui cartão (RMC)"
            onChange={onChangeDropdown("possuiCartaoRmc")}
            fieldName="possuiCartaoRmc"
            value={attendance.possuiCartaoRmc}
            options={dropdownSimNao}
          />
          <AttendanceDropdown
            title="Banco (RMC)"
            onChange={onChangeDropdown("margens", "bancoRMC")}
            fieldName="margens.bancoRMC"
            value={attendance.margens?.bancoRMC}
            options={dropdownBancos}
          />
          <AttendanceInput
            title="Valor margem cartão (RMC)"
            onChange={onChangeInput("margens", "valorMargemCartaoRMC")}
            fieldName="margens.valorMargemCartaoRMC"
            value={attendance.margens?.valorMargemCartaoRMC}
            inputProps={{
              type: "number",
              onKeyDown: onKeyDownOmitNegativeNumbers,
            }}
            mask={onlyNumberValidMask}
            source="margens.fonteRMC"
          />

          <AttendanceInput
            title="Multiplicador (RMC)"
            value={attendance.margens?.multiplicadorRMC}
            fieldName="margens.multiplicadorRMC"
            onChange={onChangeInput("margens", "multiplicadorRMC")}
            inputProps={{
              type: "number",
              onKeyDown: onKeyDownOmitNegativeNumbers,
            }}
            mask={onlyNumberValidMask}
            isVisible={() => !attendance.possuiCartaoRmc}
          />

          <AttendanceInput
            title="Valor limite cartão (RMC)"
            value={calcularLimiteCartao(
              attendance.margens?.valorMargemCartaoRMC,
              attendance.margens?.multiplicadorRMC
            )}
            isVisible={() => !attendance.possuiCartaoRmc}
          />
          <AttendanceInput
            title="Porcentagem saque (RMC)"
            onChange={onChangeDropdown("margens", "porcentagemSaqueRMC")}
            value={
              attendance.margens?.porcentagemSaqueRMC
                ? `${attendance.margens?.porcentagemSaqueRMC}%`
                : ""
            }
            fieldName="margens.porcentagemSaqueRMC"
            inputProps={{
              onKeyDown: (e) =>
                percentualMaskKit().onKeyDown(
                  e,
                  onChangeInput("margens", "porcentagemSaqueRMC")
                ),
            }}
            mask={percentualMaskKit().mask}
            isVisible={() => !attendance.possuiCartaoRmc}
          />
          <AttendanceInput
            title="Valor saque cartão (RMC)"
            value={calcularSaqueCartao(
              attendance.margens?.valorMargemCartaoRMC,
              attendance.margens?.multiplicadorRMC,
              attendance.margens?.porcentagemSaqueRMC
            )}
            isVisible={() => !attendance.possuiCartaoRmc}
          />
          <AttendanceInput
            title="Margem Bruta (RMC)"
            onChange={onChangeInput("margens", "margemBrutaCartaoRMC")}
            value={attendance.margens?.margemBrutaCartaoRMC}
            fieldName="margens.margemBrutaCartaoRMC"
            source="margens.fonteRMC"
            inputProps={{ type: "number" }}
          />
          <AttendanceInput
            title="Observação (RMC)"
            containerProps={{
              gridArea: telaAtendimento ? "auto / span 2" : "",
            }}
            value={attendance.margens?.observacaoRMC}
            onChange={onChangeInput("margens", "observacaoRMC")}
            fieldName="margens.observacaoRMC"
            isVisible={() => !attendance.possuiCartaoRmc}
            inputProps={{ as: "textarea", height: "60px", minH: "60px" }}
          />
        </Grid>
      </Accordion>
      <Accordion
        title={"Cartão Benefício (RCC)"}
        containerProps={{ mb: "20px" }}
        renderButton={({ onToggle, isOpen }) => {
          return (
            <>
              <IconButton
                aria-label=""
                onClick={(e) => {
                  e.stopPropagation();
                  onToggle();
                }}
                size="xs"
                borderRadius="full"
                w="28px"
                minW="28px"
                h="28px"
                icon={<ChevronDownIcon />}
                transform={isOpen ? "rotate(180deg)" : ""}
              />
            </>
          );
        }}
      >
        <Grid
          templateColumns={telaAtendimento ? "1fr 1fr" : "1fr"}
          columnGap="20px"
          rowGap="40px"
        >
          <AttendanceDropdown
            title="Possui Cartão (RCC)"
            onChange={onChangeDropdown("possuiCartaoBeneficio")}
            fieldName="possuiCartaoBeneficio"
            value={attendance.possuiCartaoBeneficio}
            options={dropdownSimNao}
          />{" "}
          <AttendanceDropdown
            title="Banco (RCC)"
            onChange={onChangeDropdown("margens", "bancoRCC")}
            value={attendance.margens?.bancoRCC}
            options={dropdownBancos}
          />
          <AttendanceInput
            title="Valor margem cartão (RCC)"
            onChange={onChangeInput("margens", "valorMargemCartaoRCC")}
            value={attendance.margens?.valorMargemCartaoRCC}
            fieldName="margens.valorMargemCartaoRCC"
            inputProps={{
              type: "number",
              onKeyDown: onKeyDownOmitNegativeNumbers,
            }}
            mask={onlyNumberValidMask}
            source="margens.fonteRCC"
          />
          <AttendanceInput
            title="Multiplicador (RCC)"
            value={attendance.margens?.multiplicadorRCC}
            onChange={onChangeInput("margens", "multiplicadorRCC")}
            fieldName="margens.multiplicadorRCC"
            inputProps={{
              type: "number",
              onKeyDown: onKeyDownOmitNegativeNumbers,
            }}
            mask={onlyNumberValidMask}
            isVisible={() => !attendance.possuiCartaoBeneficio}
          />
          <AttendanceInput
            title="Valor limite cartão (RCC)"
            value={calcularLimiteCartao(
              attendance.margens?.valorMargemCartaoRCC,
              attendance.margens?.multiplicadorRCC
            )}
            isVisible={() => !attendance.possuiCartaoBeneficio}
          />
          <AttendanceInput
            title="Porcentagem saque (RCC)"
            onChange={onChangeInput("margens", "porcentagemSaqueRCC")}
            value={
              attendance.margens?.porcentagemSaqueRCC
                ? `${attendance.margens?.porcentagemSaqueRCC}%`
                : ""
            }
            inputProps={{
              onKeyDown: (e) =>
                percentualMaskKit().onKeyDown(
                  e,
                  onChangeInput("margens", "porcentagemSaqueRCC")
                ),
            }}
            fieldName="margens.porcentagemSaqueRCC"
            mask={percentualMaskKit().mask}
            isVisible={() => !attendance.possuiCartaoBeneficio}
          />
          <AttendanceInput
            title="Valor saque cartão (RCC)"
            value={calcularSaqueCartao(
              attendance.margens?.valorMargemCartaoRCC,
              attendance.margens?.multiplicadorRCC,
              attendance.margens?.porcentagemSaqueRCC
            )}
            isVisible={() => !attendance.possuiCartaoBeneficio}
          />
          <AttendanceInput
            title="Margem Bruta (RCC)"
            onChange={onChangeInput("margens", "margemBrutaCartaoRCC")}
            value={attendance.margens?.margemBrutaCartaoRCC}
            fieldName="margens.margemBrutaCartaoRCC"
            source="margens.fonteRCC"
            inputProps={{ type: "number" }}
          />
          <AttendanceInput
            title="Observação (RCC)"
            containerProps={{
              gridArea: telaAtendimento ? "auto / span 2" : "",
            }}
            value={attendance.margens?.observacaoRCC}
            onChange={onChangeInput("margens", "observacaoRCC")}
            fieldName="margens.observacaoRCC"
            isVisible={() => !attendance.possuiCartaoBeneficio}
            inputProps={{ as: "textarea", height: "60px", minH: "60px" }}
          />
        </Grid>
      </Accordion>
      <Accordion
        title={"Empréstimo Pessoal"}
        containerProps={{ mb: "20px" }}
        renderButton={({ onToggle, isOpen }) => {
          return (
            <>
              <IconButton
                aria-label=""
                onClick={(e) => {
                  e.stopPropagation();
                  onToggle();
                }}
                size="xs"
                borderRadius="full"
                w="28px"
                minW="28px"
                h="28px"
                icon={<ChevronDownIcon />}
                transform={isOpen ? "rotate(180deg)" : ""}
              />
            </>
          );
        }}
      >
        <Grid
          templateColumns={telaAtendimento ? "1fr 1fr" : "1fr"}
          columnGap="20px"
          rowGap="40px"
        >
          <AttendanceDropdown
            title="Banco"
            onChange={onChangeDropdown("margens", "bancoCreditoPessoal")}
            value={attendance.margens?.bancoCreditoPessoal}
            options={dropdownBancos}
          />
          <AttendanceInput
            title="Valor crédito"
            value={attendance.margens?.valorCreditoPessoal}
            onChange={onChangeInput("margens", "valorCreditoPessoal")}
          />
          <AttendanceInput
            title="Prazo"
            value={attendance.margens?.prazoCreditoPessoal}
            onChange={onChangeInput("margens", "prazoCreditoPessoal")}
          />
          <AttendanceInput
            title="Valor liberado ao cliente"
            value={attendance.margens?.valorLiberadoClienteCreditoPessoal}
            onChange={onChangeInput(
              "margens",
              "valorLiberadoClienteCreditoPessoal"
            )}
          />
        </Grid>
      </Accordion>
      <ModalMargensCronowise />
    </>
  );
}
