import { Box, Button, Flex, Grid, Link, Spinner, Text } from "@chakra-ui/react";
import { CustomModal } from "components/custom-modal";
import { useEventListener, makeEvent } from "services/events";
import { useEffect, useState } from "react";
import { Checkbox } from "components/checkbox";
import { CloseIcon } from "@chakra-ui/icons";
import { FaSave } from "react-icons/fa";
import { useRef } from "react";
import { MailingTableRow } from "../mailing-table";
import { MailingOptionsAutoComplete } from "../mailing-table/mailing-table-row-details";
import api from "api/api";
import {
  digitacaoRule,
  booleanRule,
  validateMailingOptions,
  simulacaoPortabilidadeRule,
  higienizacaoRadioRule,
  calcularQuantidadeFiltroMailing,
} from "./modal-rules";
import { useApplicationContext } from "contexts/ApplicationContext";
import { MailingField } from "./parts/mailing-field";
import { RefinMailing } from "./parts/refin";
import { SaqueComplementar } from "./parts/saque-complementar";
import { Filter } from "./parts/filters";
import { dropdownBancos } from "components/usuarios-bancos-components/consts";
import {
  TipoOperacaoMailing,
  dropdownTipoOperacaoMailing,
} from "../mailing-upload-modal/consts";
import { banks } from "../mailing-modal-filter/mailing-filter/consts";
import { MargemCovenio } from "./parts/margem-convenio";
import { Toast } from "components/toast";
import { DigitacaoFilter } from "./parts/digitacao-filter";
import {
  dropdownBancosSimulacaoPortabilidade,
  formatTitle,
  initialDigitacaoPipelineFilterValues,
  initialEnviarParaValues,
  initialFilterValues,
  initialHigienizacaoValues,
  initialSimulacaoPortabilidade,
  initialTelefoniaValues,
  onChangeMailingStage,
} from "./variables";
import { especieCodeListConsignaveis } from "../mailing-modal-filter/consts";
import {
  dropdownEspecies,
  dropdownSimNao,
  dropdownTipoPagamento,
} from "components/atendimentos-components/atendimento-form/fields-data";
import { limitDigitsMask } from "utils/string-formats";
import { useTiming } from "./use-timing";

export interface MailingData extends MailingTableRow {
  enabledFields?: MailingOptionsAutoComplete;
}
export interface MailingOptionFilter {
  [key: string]: {
    isChecked: boolean;
    value: any;
  };
}
export interface Stage {
  stage:
    | "HIGIENIZACAO"
    | "SIMULACAO_PORTABILIDADE"
    | "FILTRO"
    | "TELEFONIA"
    | "ENVIA_WHATS"
    | "ENVIA_CAMPANHA_SMS"
    | "ENVIA_DISCADORA"
    | "ENVIA_ATENDIMENTO"
    | "DIGITACAO";
  configJson: any;
  ordem?: number;
}

export type MailingTiposOperacoes = MailingTableRow["tipoOperacao"];

export function MailingOptionsModal() {
  const [isOpen, setIsOpen] = useState(false);
  const [mailingData, setMailingData] = useState<Partial<MailingData>>({});
  const [isSaving, setIsSaving] = useState(false);
  const [addedFilters, setAddedFilters] =
    useState<MailingOptionFilter>(initialFilterValues);
  const [addedHigienizacoes, setAddedHigienizacoes] =
    useState<MailingOptionFilter>(initialHigienizacaoValues);
  const [simulacaoPortabilidade, setSimulacaoPortabilidade] =
    useState<MailingOptionFilter>(initialSimulacaoPortabilidade);
  const [digitacao, setDigitacao] = useState<MailingOptionFilter>(
    initialDigitacaoPipelineFilterValues
  );
  const [telefonia, setTelefonia] = useState(initialTelefoniaValues);
  const [enviarPara, setEnviarPara] = useState(initialEnviarParaValues);
  const [invalidFields, setInvalidFields] = useState<{
    [field: string]: string;
  }>({});
  const [isLoadingPreview, setIsLoadingPreview] = useState(false);
  const [enviarParaDiscadora, setEnviarParaDiscadora] = useState(false);
  const [presentFields, setPresentFields] = useState<string[]>([]);
  const [preview, setPreview] = useState<number | null>(null);
  const downloadRef = useRef<HTMLAnchorElement>(null);
  const { user } = useApplicationContext();

  const allFields: MailingOptionFilter = {
    // HIGIENIZACAO ORDEM 1
    ...addedHigienizacoes,
    // SIMULACAO PORTABILIDADE ORDEM 2
    ...simulacaoPortabilidade,
    // FILTROS ORDEM 3
    ...addedFilters,
    // TELEFONIA ORDEM 4
    ...telefonia,
    // DIGITACAO ORDEM 5
    ...digitacao,
    // ENVIAR PARA CAMPANHA ORDEM 6
    ...enviarPara,
  };
  const { autoUpdateTimingControl, resetTimer } = useTiming({
    dispatch: calcularQuantidadeFiltroMailing(
      setPreview,
      allFields,
      mailingData,
      setIsLoadingPreview
    ),
  });
  const isUpdate = !!mailingData.enabledFields;
  const isDisabled = !Object.keys(allFields).find(
    (key) => allFields[key].isChecked
  );
  const isDisabledFields = !!mailingData.enabledFields;
  const visaoUsuarioExpecifico = user.userData.customerId === 1692;
  const isUpdateAndRefinCarteira = !(
    isUpdate && mailingData.tipoOperacao === "REFIN_CARTEIRA"
  );

  const isVisibleMargemConvenio =
    (
      [
        "NOVO",
        "CARTAO_RMC_NOVO",
        "CARTAO_RCC_NOVO",
        "SAQUE_RMC",
        "SAQUE_RCC",
      ] as TipoOperacaoMailing[]
    ).includes(mailingData.tipoOperacao!) &&
    mailingData.convenio === "GOV" &&
    !visaoUsuarioExpecifico &&
    (user.userData.customerId === 1 ||
      user.userData.customerId === 1775 ||
      user.userData.customerId === 1950 ||
      user.userData.customerId === 1621 ||
      user.userData.customerId === 5803 ||
      user.userData.customerId === 8370);

  const portabilidadeOuBancoOperacaoLabel = new Map([
    ["NOVO", "Banco nova operação"],
    ["CARTAO_RMC_NOVO", "Banco nova operação"],
    ["CARTAO_RCC_NOVO", "Banco nova operação"],
    ["REFIN_CARTEIRA", "Banco Operação"],
    ["SAQUE_RMC", "Banco Operação"],
    ["SAQUE_RCC", "Banco Operação"],
    ["PORT", "Portabilidade"],
    ["PORT_COM_REFIN_DA_PORT", "Portabilidade"],
  ]).get(mailingData.tipoOperacao || "");

  const isShowSimulacaoPortabilidade = (
    [null, "PORT", "PORT_COM_REFIN_DA_PORT"] as (TipoOperacaoMailing | null)[]
  ).includes(mailingData.tipoOperacao!);
  const isSimulacaoRefinCarteiraPresentFields = !!presentFields.find(
    (k) => k === "REFIN" && mailingData.tipoOperacao === "REFIN_CARTEIRA"
  );
  const isHigienizacoesPresentFields = !!presentFields.find((k) => {
    if (isSimulacaoRefinCarteiraPresentFields && k === "REFIN") return false;
    return Object.keys(initialHigienizacaoValues).includes(k);
  });
  const isFiltersPresentFields = !!presentFields.find((k) =>
    Object.keys(initialFilterValues).includes(k)
  );
  const isSimulacaoPresentFields = !!presentFields.find((k) =>
    Object.keys(initialSimulacaoPortabilidade).includes(k)
  );
  const isDigitacaoPresentFields = !!presentFields.find((k) =>
    Object.keys(initialDigitacaoPipelineFilterValues).includes(k)
  );
  const isTelefoniaPresentFields = !!presentFields.find((k) =>
    Object.keys(initialTelefoniaValues).includes(k)
  );
  const isEnviarParaPresentFields = !!presentFields.find((k) =>
    Object.keys(initialEnviarParaValues).includes(k)
  );
  const isVisibleDigitacaoStage =
    (["NOVO", "REFIN_CARTEIRA"] as TipoOperacaoMailing[]).includes(
      mailingData.tipoOperacao!
    ) &&
    mailingData.convenio === "INSS" &&
    [935, 626, 318, undefined, null].includes(mailingData.bancosTo?.[0]!) &&
    isUpdateAndRefinCarteira;

  const isTipoOperacaoSaqueOrCartaoNovo = [
    "SAQUE_RMC",
    "SAQUE_RCC",
    "CARTAO_RMC_NOVO",
    "CARTAO_RCC_NOVO",
  ].includes(mailingData.tipoOperacao!);

  const onOpen = (mailingData: MailingData) => {
    setIsOpen(true);
    setMailingData(mailingData);
    if (mailingData.enabledFields) {
      const addedFilters = {
        ...initialFilterValues,
        ...mailingData.enabledFields.filters,
      } as MailingOptionFilter;
      const addedHigienizacoes = {
        ...initialHigienizacaoValues,
        ...mailingData.enabledFields.higienizacao,
      } as MailingOptionFilter;
      const addedSimulacaoPortabilidade = {
        ...initialSimulacaoPortabilidade,
        ...mailingData.enabledFields.simulacaoPortabilidade,
      } as MailingOptionFilter;
      const addedDigitacao = {
        ...initialDigitacaoPipelineFilterValues,
        ...mailingData.enabledFields.digitacao,
      } as MailingOptionFilter;
      setAddedHigienizacoes(addedHigienizacoes);
      setAddedFilters(addedFilters);
      setSimulacaoPortabilidade(addedSimulacaoPortabilidade);
      setDigitacao(addedDigitacao);
    }
  };

  const onClose = () => {
    setIsOpen(false);
    setEnviarParaDiscadora(false);
    setEnviarPara(initialEnviarParaValues);
    setTelefonia(initialTelefoniaValues);
    setAddedFilters(initialFilterValues);
    setAddedHigienizacoes(initialHigienizacaoValues);
    setSimulacaoPortabilidade(initialSimulacaoPortabilidade);
    setDigitacao(initialDigitacaoPipelineFilterValues);
    setInvalidFields({});
    setPresentFields([]);
    setPreview(null);
    resetTimer();
  };

  useEventListener("open-mailing-options", onOpen, undefined, {
    removeDidUnMount: true,
  });

  const handleSubmit = async () => {
    const { isValid, errors, formatedBody } = validateMailingOptions(
      allFields,
      mailingData as MailingData
    );

    if (!isValid) setInvalidFields(errors);

    const filters = formatedBody.pipelines.find(
      (curr) => curr.stage === "FILTRO"
    );

    if (!filters && isUpdate)
      return Toast({ title: "Selecione algum filtro!" });

    // prettier-ignore
    // return console.log({ formatedBody, errors, type: isValid ? "SUCCESS" : "ERROR" });

    if (!isValid) return;

    setIsSaving(true);
    try {
      const { data } = isUpdate
        ? await api.put(`/pipeline/${mailingData.id}`, filters)
        : await api.post("/pipeline", formatedBody);
      Toast({
        title: `Pipeline ${isUpdate ? "atualizado" : "criado"} com sucesso`,
        status: "success",
      });
      makeEvent("update-mailings-table");
      onClose();
    } catch (e: any) {
      const errorMessage =
        typeof e?.response?.data === "string" && e?.response?.data !== ""
          ? e?.response?.data
          : `Erro ao ${isUpdate ? "atualizar" : "criar"} pipeline`;

      Toast({
        title: errorMessage,
        status: "error",
        duration: 6000,
      });
    } finally {
      setIsSaving(false);
      makeEvent(`mailing-update-${mailingData.id}`);
    }
  };

  const messageRefinCarteira = (
    <Box
      bg="gray.100"
      border={"1px solid var(--chakra-colors-gray-200)"}
      p="4px"
      borderRadius="4px"
    >
      Será realizado um <b>clone do mailing</b> ao salvar.
    </Box>
  );

  const modalFooter = (
    <>
      {!isUpdateAndRefinCarteira ? messageRefinCarteira : null}
      <Button
        isLoading={isSaving}
        loadingText="Salvando"
        onClick={handleSubmit}
        leftIcon={<FaSave />}
        isDisabled={isDisabled}
      >
        Salvar
      </Button>
      <Button
        leftIcon={<CloseIcon w="12px" h="12px" />}
        variant="outline"
        onClick={onClose}
      >
        Cancelar
      </Button>
    </>
  );

  const isCustomerNovaSUPER =
    user.userData.customerId === 1 && user.userData.type === "SUPER";

  const getBancoOperacaoOptions: any = async (
    setIsLoading: (v: boolean) => void
  ) => {
    setIsLoading?.(true);
    try {
      const { data } = await api.get<string[]>(
        `/pipeline/${mailingData.id}/bancos-disponiveis-refin`
      );
      return data.map((banco) => ({ name: banco, value: banco }));
    } catch (e: any) {
      const errorMessage = e?.response?.data?.message;
      Toast({
        title: "Erro ao obter opções de Banco Operação",
        description: errorMessage,
        status: "error",
      });
      return [];
    } finally {
      setIsLoading?.(false);
    }
  };

  useEffect(() => {
    if (!isUpdateAndRefinCarteira) {
      autoUpdateTimingControl();
    }
  }, [addedFilters]);

  const refinCarteiraAndDigitacaolote = !(
    !mailingData.digitacaoLote && mailingData.tipoOperacao === "REFIN_CARTEIRA"
  );

  return (
    <CustomModal
      modalTitle="Opções do Mailing"
      isOpen={isOpen}
      onClose={onClose}
      modalFooter={modalFooter}
      size="6xl"
      closeOnOverlayClick={false}
      scroll="inside"
    >
      <Grid
        templateColumns={
          portabilidadeOuBancoOperacaoLabel &&
          (mailingData.bancosFrom?.length || mailingData.bancosTo?.length)
            ? "repeat(5, 1fr)"
            : "repeat(4, 1fr)"
        }
        w="100%"
        gap="10px"
      >
        <Box
          w="100%"
          bg="gray.100"
          border={"1px solid var(--chakra-colors-gray-200)"}
          p="4px"
          borderRadius="4px"
          mb="8px"
        >
          <Box fontSize="14">
            <Text as="span" fontWeight="bold" mb="8px">
              Data Criação:
            </Text>
            <Text>{new Date(mailingData.dataCriacao!).toLocaleString()}</Text>
          </Box>
        </Box>
        <Box
          w="100%"
          bg="gray.100"
          border={"1px solid var(--chakra-colors-gray-200)"}
          p="4px"
          borderRadius="4px"
          mb="8px"
        >
          <Box fontSize="14">
            <Text as="span" fontWeight="bold" mb="8px">
              Nome:
            </Text>
            <Text>{mailingData.name}</Text>
          </Box>
        </Box>
        <Box
          w="100%"
          bg="gray.100"
          border={"1px solid var(--chakra-colors-gray-200)"}
          p="4px"
          borderRadius="4px"
          mb="8px"
        >
          <Box fontSize="14">
            <Text as="span" fontWeight="bold" mb="8px">
              Convênio:
            </Text>
            <Text>{mailingData.convenio}</Text>
          </Box>
        </Box>
        <Box
          w="100%"
          bg="gray.100"
          border={"1px solid var(--chakra-colors-gray-200)"}
          p="4px"
          borderRadius="4px"
          mb="8px"
        >
          <Box fontSize="14">
            <Text as="span" fontWeight="bold" mb="8px">
              Tipo Operação:
            </Text>
            <Text>
              {
                dropdownTipoOperacaoMailing.find(
                  (option) => option.value === mailingData.tipoOperacao
                )?.name
              }
            </Text>
          </Box>
        </Box>
        {portabilidadeOuBancoOperacaoLabel &&
        (mailingData.bancosFrom?.length || mailingData.bancosTo?.length) ? (
          <Box
            w="100%"
            bg="gray.100"
            border={"1px solid var(--chakra-colors-gray-200)"}
            p="4px"
            borderRadius="4px"
            mb="8px"
          >
            <Box fontSize="14">
              <Text as="span" fontWeight="bold" mb="8px">
                {portabilidadeOuBancoOperacaoLabel}:
              </Text>
              {portabilidadeOuBancoOperacaoLabel === "Portabilidade" ? (
                <>
                  <Text>
                    De:{" "}
                    {mailingData.bancosFrom
                      ?.map(
                        (cod) =>
                          `${cod} - ${
                            banks.find((b) => Number(b.value) === cod)?.name
                          }`
                      )
                      .join(", ")}
                  </Text>
                  <Text>
                    Para:{" "}
                    {mailingData.bancosTo
                      ?.map(
                        (cod) =>
                          `${cod} - ${
                            banks.find((b) => Number(b.value) === cod)?.name
                          }`
                      )
                      .join(", ")}
                  </Text>
                </>
              ) : portabilidadeOuBancoOperacaoLabel === "Banco Operação" ? (
                <>
                  <Text>
                    {mailingData.bancosFrom
                      ?.map(
                        (cod) =>
                          `${cod} - ${
                            banks.find((b) => Number(b.value) === cod)?.name
                          }`
                      )
                      .join(", ")}
                  </Text>
                </>
              ) : portabilidadeOuBancoOperacaoLabel ===
                "Banco nova operação" ? (
                <>
                  <Text>
                    {mailingData.bancosTo
                      ?.map(
                        (cod) =>
                          `${cod} - ${
                            banks.find((b) => Number(b.value) === cod)?.name
                          }`
                      )
                      .join(", ")}
                  </Text>
                </>
              ) : null}
            </Box>
          </Box>
        ) : null}
      </Grid>

      <Link display="none" ref={downloadRef} />

      {isHigienizacoesPresentFields ? (
        <>
          <Text fontSize="20" fontWeight="bold">
            {formatTitle({
              title: "Higienizações",
              presentFields,
              isSimulacaoRefinCarteiraPresentFields,
            })}
          </Text>
          <hr style={{ margin: "4px 0 8px" }} />
        </>
      ) : null}
      <Grid templateColumns="repeat(4,1fr)" gap="10px">
        <MailingField
          isVisible={
            ["PORT", "PORT_COM_REFIN_DA_PORT", null].includes(
              mailingData.tipoOperacao!
            ) && !visaoUsuarioExpecifico
          }
          option={addedHigienizacoes}
          initialMalingOptionValue={initialHigienizacaoValues}
          set={onChangeMailingStage({
            setState: setAddedHigienizacoes,
            rule: higienizacaoRadioRule({ field: "REFIN" }),
          })}
          field="REFIN"
          title="Refin. Bancos"
          setInvalidFields={setInvalidFields}
          setPresentFields={setPresentFields}
          isDisabledCheckBox={isDisabledFields}
        >
          <RefinMailing
            mailingId={mailingData.id!}
            allModalData={allFields}
            onChangeHigienizacao={onChangeMailingStage({
              setState: setAddedHigienizacoes,
              rule: higienizacaoRadioRule({ field: "REFIN" }),
            })}
            addedFilters={addedHigienizacoes}
            filterKey="REFIN"
            isDisabled={isDisabledFields}
            errorMessage={invalidFields["REFIN"]}
            mailingData={mailingData}
          />
        </MailingField>

        <MailingField
          isVisible={
            [
              null,
              "CARTAO_RMC_NOVO",
              "CARTAO_RCC_NOVO",
              "SAQUE_RMC",
              "SAQUE_RCC",
            ].includes(mailingData.tipoOperacao!) && !visaoUsuarioExpecifico
          }
          initialMalingOptionValue={initialHigienizacaoValues}
          option={addedHigienizacoes}
          set={onChangeMailingStage({
            setState: setAddedHigienizacoes,
            rule: higienizacaoRadioRule({ field: "SAQUE_COMPLEMENTAR" }),
          })}
          field="SAQUE_COMPLEMENTAR"
          title="Saque Complementar"
          setInvalidFields={setInvalidFields}
          setPresentFields={setPresentFields}
          isDisabledCheckBox={isDisabledFields}
        >
          <SaqueComplementar
            allModalData={allFields}
            setAddedFilters={onChangeMailingStage({
              setState: setAddedHigienizacoes,
              rule: higienizacaoRadioRule({ field: "SAQUE_COMPLEMENTAR" }),
            })}
            addedFilters={addedHigienizacoes}
            filterKey="SAQUE_COMPLEMENTAR"
            isDisabled={isDisabledFields}
            errorMessage={invalidFields["SAQUE_COMPLEMENTAR"]}
            mailingData={mailingData}
          />
        </MailingField>
        <MailingField
          isVisible={isVisibleMargemConvenio}
          option={addedHigienizacoes}
          initialMalingOptionValue={initialHigienizacaoValues}
          set={onChangeMailingStage({
            setState: setAddedHigienizacoes,
            rule: higienizacaoRadioRule({ field: "MARGEM_CONVENIO" }),
          })}
          field="MARGEM_CONVENIO"
          title="Margem Convênio"
          setInvalidFields={setInvalidFields}
          setPresentFields={setPresentFields}
          isDisabledCheckBox={isDisabledFields}
        >
          <MargemCovenio
            mailingId={mailingData.id!}
            allModalData={allFields}
            onChangeHigienizacao={onChangeMailingStage({
              setState: setAddedHigienizacoes,
              rule: higienizacaoRadioRule({ field: "MARGEM_CONVENIO" }),
            })}
            addedFilters={addedHigienizacoes}
            filterKey="MARGEM_CONVENIO"
            isDisabled={isDisabledFields}
            errorMessage={invalidFields["MARGEM_CONVENIO"]}
            mailingData={mailingData}
          />
        </MailingField>
        <MailingField
          isVisible={
            !visaoUsuarioExpecifico &&
            isUpdateAndRefinCarteira &&
            refinCarteiraAndDigitacaolote
          }
          option={addedHigienizacoes}
          initialMalingOptionValue={initialHigienizacaoValues}
          set={onChangeMailingStage({
            setState: setAddedHigienizacoes,
            rule:
              mailingData.tipoOperacao === "REFIN_CARTEIRA"
                ? undefined
                : higienizacaoRadioRule({ field: "SIMULACAO_AUMENTO" }),
          })}
          field="SIMULACAO_AUMENTO"
          title="Busca contratos/cadastro"
          setInvalidFields={setInvalidFields}
          setPresentFields={setPresentFields}
          isDisabledCheckBox={isDisabledFields}
        >
          <></>
        </MailingField>
      </Grid>

      {isSimulacaoRefinCarteiraPresentFields ? (
        <>
          <Text mt="10px" fontSize="20" fontWeight="bold">
            {formatTitle({
              title: "Simulação",
              presentFields,
              isSimulacaoRefinCarteiraPresentFields,
            })}
          </Text>
          <hr style={{ margin: "4px 0 8px" }} />
        </>
      ) : null}
      <Grid templateColumns="repeat(4, 1fr)">
        <MailingField
          isVisible={
            ["REFIN_CARTEIRA"].includes(mailingData.tipoOperacao!) &&
            !visaoUsuarioExpecifico &&
            isUpdateAndRefinCarteira
          }
          option={addedHigienizacoes}
          initialMalingOptionValue={initialHigienizacaoValues}
          set={onChangeMailingStage({
            setState: setAddedHigienizacoes,
            // rule: higienizacaoRadioRule({ field: "REFIN" }),
          })}
          field="REFIN"
          title="Refin. Bancos"
          setInvalidFields={setInvalidFields}
          setPresentFields={setPresentFields}
          isDisabledCheckBox={isDisabledFields}
        >
          <RefinMailing
            mailingId={mailingData.id!}
            allModalData={allFields}
            onChangeHigienizacao={onChangeMailingStage({
              setState: setAddedHigienizacoes,
              // rule: higienizacaoRadioRule({ field: "REFIN" }),
            })}
            addedFilters={addedHigienizacoes}
            filterKey="REFIN"
            isDisabled={isDisabledFields}
            errorMessage={invalidFields["REFIN"]}
            mailingData={mailingData}
          />
        </MailingField>
      </Grid>

      {isSimulacaoPresentFields ? (
        <>
          <Text mt="10px" fontSize="20" fontWeight="bold">
            {formatTitle({
              title: "Simulação Portabilidade",
              presentFields,
              isSimulacaoRefinCarteiraPresentFields,
            })}
          </Text>
          <hr style={{ margin: "4px 0 8px" }} />
        </>
      ) : null}

      <Grid templateColumns="1fr 1fr 1fr 1fr" gap="10px" w="100%">
        <MailingField
          isVisible={
            isCustomerNovaSUPER &&
            isShowSimulacaoPortabilidade &&
            !visaoUsuarioExpecifico
          }
          option={simulacaoPortabilidade}
          initialMalingOptionValue={initialSimulacaoPortabilidade}
          set={onChangeMailingStage({
            setState: setSimulacaoPortabilidade,
            rule: simulacaoPortabilidadeRule(
              onChangeMailingStage({ setState: setAddedFilters })
            ),
          })}
          field="SIMULACAO_PORTABILIDADE"
          title="Simulação Portabilidade"
          setInvalidFields={setInvalidFields}
          setPresentFields={setPresentFields}
          isDisabledCheckBox={isDisabledFields}
        >
          <Filter
            type="dropdown"
            setAddedFilters={onChangeMailingStage({
              setState: setSimulacaoPortabilidade,
              rule: simulacaoPortabilidadeRule(
                onChangeMailingStage({ setState: setAddedFilters })
              ),
            })}
            addedFilters={simulacaoPortabilidade}
            filterKey="SIMULACAO_PORTABILIDADE"
            isDisabled={isDisabledFields}
            componentProps={{
              options: dropdownBancosSimulacaoPortabilidade,
            }}
            errorMessage={invalidFields["SIMULACAO_PORTABILIDADE"]}
          />
        </MailingField>
      </Grid>

      <>
        {isFiltersPresentFields ? (
          <>
            <Flex
              alignItems="center"
              w="100%"
              mt="10px"
              fontSize="20"
              fontWeight="bold"
              justifyContent="space-between"
            >
              <Text mr="8px">
                {formatTitle({
                  title: "Filtros",
                  presentFields,
                  isSimulacaoRefinCarteiraPresentFields,
                })}
                {isHigienizacoesPresentFields || isSimulacaoPresentFields ? (
                  <Text as="span" fontSize="16px">
                    {" "}
                    (Baseado no resultado anterior)
                  </Text>
                ) : null}
                :
              </Text>
              <Flex mx="auto">
                {preview != null ? (
                  <Box
                    transform={`translateX(-48px)`}
                    bg="gray.100"
                    border={"1px solid var(--chakra-colors-gray-200)"}
                    p="4px"
                    borderRadius="4px"
                    mb="8px"
                    fontSize="14"
                  >
                    <Flex alignItems="center">
                      Quantidade de leads prevista: {preview}{" "}
                      {isLoadingPreview ? <Spinner ml="8px" size="xs" /> : null}
                    </Flex>
                  </Box>
                ) : null}
                {/* <Button
                  onClick={}
                >Calcular quantidade de filtro</Button> */}
              </Flex>
            </Flex>
            <hr style={{ margin: "4px 0 8px" }} />
          </>
        ) : null}

        <Grid templateColumns="1fr 1fr 1fr 1fr" gap="10px" w="100%">
          <MailingField
            isVisible={isVisibleMargemConvenio}
            option={addedFilters}
            initialMalingOptionValue={initialFilterValues}
            set={onChangeMailingStage({
              setState: setAddedFilters,
            })}
            field="margem_disponivel_emprestimo"
            title="Margem Disponível Empréstimo"
            setInvalidFields={setInvalidFields}
            setPresentFields={setPresentFields}
          >
            <Filter
              type="between"
              setAddedFilters={onChangeMailingStage({
                setState: setAddedFilters,
              })}
              addedFilters={addedFilters}
              valueType="number"
              filterKey="margem_disponivel_emprestimo"
              errorMessage={invalidFields["margem_disponivel_emprestimo"]}
            />
          </MailingField>
          <MailingField
            isVisible={isVisibleMargemConvenio}
            option={addedFilters}
            initialMalingOptionValue={initialFilterValues}
            set={onChangeMailingStage({
              setState: setAddedFilters,
            })}
            field="margem_disponivel_rcc"
            title="Margem Disponível RCC"
            setInvalidFields={setInvalidFields}
            setPresentFields={setPresentFields}
          >
            <Filter
              type="between"
              setAddedFilters={onChangeMailingStage({
                setState: setAddedFilters,
              })}
              addedFilters={addedFilters}
              valueType="number"
              filterKey="margem_disponivel_rcc"
              errorMessage={invalidFields["margem_disponivel_rcc"]}
            />
          </MailingField>

          <MailingField
            isVisible={isVisibleMargemConvenio}
            option={addedFilters}
            initialMalingOptionValue={initialFilterValues}
            set={onChangeMailingStage({
              setState: setAddedFilters,
            })}
            field="margem_disponivel_rmc"
            title="Margem Disponível RMC"
            setInvalidFields={setInvalidFields}
            setPresentFields={setPresentFields}
          >
            <Filter
              type="between"
              setAddedFilters={onChangeMailingStage({
                setState: setAddedFilters,
              })}
              addedFilters={addedFilters}
              valueType="number"
              filterKey="margem_disponivel_rmc"
              errorMessage={invalidFields["margem_disponivel_rmc"]}
            />
          </MailingField>

          <MailingField
            isVisible={
              // ((allFields.REFIN.isChecked || noneChecked) &&
              ((!mailingData.tipoOperacao
                ? !allFields.SAQUE_COMPLEMENTAR.isChecked
                : true) &&
                !isTipoOperacaoSaqueOrCartaoNovo &&
                !visaoUsuarioExpecifico &&
                !isVisibleMargemConvenio) ||
              ["NOVO"].includes(mailingData.tipoOperacao!)
            }
            option={addedFilters}
            initialMalingOptionValue={initialFilterValues}
            set={onChangeMailingStage({
              setState: setAddedFilters,
            })}
            field="idade"
            title="Idade"
            setInvalidFields={setInvalidFields}
            setPresentFields={setPresentFields}
          >
            <Filter
              type="between"
              setAddedFilters={onChangeMailingStage({
                setState: setAddedFilters,
              })}
              addedFilters={addedFilters}
              filterKey="idade"
              errorMessage={invalidFields["idade"]}
              mask={limitDigitsMask(2, (v) => (v ? Number(v) : ""))}
            />
          </MailingField>
          <MailingField
            isVisible={
              (!mailingData.tipoOperacao
                ? !allFields.SAQUE_COMPLEMENTAR.isChecked
                : true) &&
              allFields.SIMULACAO_PORTABILIDADE.isChecked &&
              !isTipoOperacaoSaqueOrCartaoNovo &&
              !visaoUsuarioExpecifico &&
              !isVisibleMargemConvenio
            }
            option={addedFilters}
            initialMalingOptionValue={initialFilterValues}
            set={onChangeMailingStage({
              setState: setAddedFilters,
            })}
            field="contratos_enquadrados"
            title="Contratos enquadrados"
            setInvalidFields={setInvalidFields}
            setPresentFields={setPresentFields}
          >
            <></>
          </MailingField>
          <MailingField
            isVisible={
              // (allFields.REFIN.isChecked || noneChecked) &&
              (!mailingData.tipoOperacao
                ? !allFields.SAQUE_COMPLEMENTAR.isChecked
                : true) &&
              !isTipoOperacaoSaqueOrCartaoNovo &&
              !visaoUsuarioExpecifico &&
              !isVisibleMargemConvenio
            }
            option={addedFilters}
            initialMalingOptionValue={initialFilterValues}
            set={onChangeMailingStage({
              setState: setAddedFilters,
            })}
            field="quantidade_contratos"
            title="Quantidade de Contratos"
            setInvalidFields={setInvalidFields}
            setPresentFields={setPresentFields}
          >
            <Filter
              type="between"
              setAddedFilters={onChangeMailingStage({
                setState: setAddedFilters,
              })}
              addedFilters={addedFilters}
              valueType="number"
              filterKey="quantidade_contratos"
              errorMessage={invalidFields["quantidade_contratos"]}
            />
          </MailingField>
          <MailingField
            isVisible={
              // (allFields.REFIN.isChecked || noneChecked) &&
              (!mailingData.tipoOperacao
                ? !allFields.SAQUE_COMPLEMENTAR.isChecked
                : true) &&
              !isTipoOperacaoSaqueOrCartaoNovo &&
              !visaoUsuarioExpecifico &&
              !isVisibleMargemConvenio
            }
            option={addedFilters}
            initialMalingOptionValue={initialFilterValues}
            set={onChangeMailingStage({
              setState: setAddedFilters,
            })}
            field="banco_operacao"
            title="Banco Operação"
            setInvalidFields={setInvalidFields}
            setPresentFields={setPresentFields}
          >
            <Filter
              type="dropdown"
              setAddedFilters={onChangeMailingStage({
                setState: setAddedFilters,
              })}
              addedFilters={addedFilters}
              filterKey="banco_operacao"
              componentProps={
                mailingData.tipoOperacao === "REFIN_CARTEIRA" && isUpdate
                  ? { asyncOptions: getBancoOperacaoOptions }
                  : { options: dropdownBancos }
              }
              errorMessage={invalidFields["banco_operacao"]}
            />
          </MailingField>
          <MailingField
            isVisible={
              // (allFields.REFIN.isChecked || noneChecked) &&
              (!mailingData.tipoOperacao
                ? !allFields.SAQUE_COMPLEMENTAR.isChecked
                : true) &&
              !isTipoOperacaoSaqueOrCartaoNovo &&
              !visaoUsuarioExpecifico &&
              !isVisibleMargemConvenio
            }
            option={addedFilters}
            initialMalingOptionValue={initialFilterValues}
            set={onChangeMailingStage({
              setState: setAddedFilters,
            })}
            field="saldo_devedor"
            title="Saldo Devedor"
            setInvalidFields={setInvalidFields}
            setPresentFields={setPresentFields}
          >
            <Filter
              type="between"
              setAddedFilters={onChangeMailingStage({
                setState: setAddedFilters,
              })}
              addedFilters={addedFilters}
              valueType="number"
              filterKey="saldo_devedor"
              errorMessage={invalidFields["saldo_devedor"]}
            />
          </MailingField>
          <MailingField
            isVisible={
              // (allFields.REFIN.isChecked || noneChecked) &&
              (!mailingData.tipoOperacao
                ? !allFields.SAQUE_COMPLEMENTAR.isChecked
                : true) &&
              !isTipoOperacaoSaqueOrCartaoNovo &&
              !visaoUsuarioExpecifico &&
              !isVisibleMargemConvenio
            }
            option={addedFilters}
            initialMalingOptionValue={initialFilterValues}
            set={onChangeMailingStage({
              setState: setAddedFilters,
            })}
            field="taxa_contrato"
            title="Taxa Contrato"
            setInvalidFields={setInvalidFields}
            setPresentFields={setPresentFields}
          >
            <Filter
              type="between"
              setAddedFilters={onChangeMailingStage({
                setState: setAddedFilters,
              })}
              addedFilters={addedFilters}
              valueType="number"
              filterKey="taxa_contrato"
              errorMessage={invalidFields["taxa_contrato"]}
            />
          </MailingField>
          <MailingField
            isVisible={
              // (allFields.REFIN.isChecked || noneChecked) &&
              (!mailingData.tipoOperacao
                ? !allFields.SAQUE_COMPLEMENTAR.isChecked
                : true) &&
              !isTipoOperacaoSaqueOrCartaoNovo &&
              !visaoUsuarioExpecifico &&
              !isVisibleMargemConvenio
            }
            option={addedFilters}
            initialMalingOptionValue={initialFilterValues}
            set={onChangeMailingStage({
              setState: setAddedFilters,
            })}
            field="valor_parcela"
            title="Valor Parcela"
            setInvalidFields={setInvalidFields}
            setPresentFields={setPresentFields}
          >
            <Filter
              type="between"
              setAddedFilters={onChangeMailingStage({
                setState: setAddedFilters,
              })}
              addedFilters={addedFilters}
              valueType="number"
              filterKey="valor_parcela"
              errorMessage={invalidFields["valor_parcela"]}
            />
          </MailingField>
          <MailingField
            isVisible={
              // (allFields.REFIN.isChecked || noneChecked) &&
              (!mailingData.tipoOperacao
                ? !allFields.SAQUE_COMPLEMENTAR.isChecked
                : true) &&
              !isTipoOperacaoSaqueOrCartaoNovo &&
              !visaoUsuarioExpecifico &&
              !isVisibleMargemConvenio
            }
            option={addedFilters}
            initialMalingOptionValue={initialFilterValues}
            set={onChangeMailingStage({
              setState: setAddedFilters,
            })}
            field="valor_liberado"
            title="Valor Liquido ao Cliente"
            setInvalidFields={setInvalidFields}
            setPresentFields={setPresentFields}
          >
            <Filter
              type="between"
              setAddedFilters={onChangeMailingStage({
                setState: setAddedFilters,
              })}
              addedFilters={addedFilters}
              valueType="number"
              filterKey="valor_liberado"
              errorMessage={invalidFields["valor_liberado"]}
            />
          </MailingField>
          <MailingField
            isVisible={
              (!mailingData.tipoOperacao
                ? allFields.SAQUE_COMPLEMENTAR.isChecked
                : mailingData.tipoOperacao === "SAQUE_RMC" &&
                  !visaoUsuarioExpecifico) && !isVisibleMargemConvenio
            }
            option={addedFilters}
            initialMalingOptionValue={initialFilterValues}
            set={onChangeMailingStage({
              setState: setAddedFilters,
            })}
            field="valor_liberado_saque_rmc"
            title="Valor Máximo Saque RMC"
            setInvalidFields={setInvalidFields}
            setPresentFields={setPresentFields}
            // isDisabledCheckBox={true}
          >
            <Filter
              type="between"
              setAddedFilters={onChangeMailingStage({
                setState: setAddedFilters,
              })}
              addedFilters={addedFilters}
              valueType="number"
              filterKey="valor_liberado_saque_rmc"
              errorMessage={invalidFields["valor_liberado_saque_rmc"]}
            />
          </MailingField>
          <MailingField
            isVisible={
              (!mailingData.tipoOperacao
                ? allFields.SAQUE_COMPLEMENTAR.isChecked
                : mailingData.tipoOperacao === "SAQUE_RCC" &&
                  !visaoUsuarioExpecifico) && !isVisibleMargemConvenio
            }
            option={addedFilters}
            initialMalingOptionValue={initialFilterValues}
            set={onChangeMailingStage({
              setState: setAddedFilters,
            })}
            field="valor_liberado_saque_rcc"
            title="Valor Máximo Saque RCC"
            setInvalidFields={setInvalidFields}
            setPresentFields={setPresentFields}
            // isDisabledCheckBox={true}
          >
            <Filter
              type="between"
              setAddedFilters={onChangeMailingStage({
                setState: setAddedFilters,
              })}
              addedFilters={addedFilters}
              valueType="number"
              filterKey="valor_liberado_saque_rcc"
              errorMessage={invalidFields["valor_liberado_saque_rcc"]}
            />
          </MailingField>
          <MailingField
            isVisible={
              // (allFields.REFIN.isChecked || noneChecked) &&
              (!mailingData.tipoOperacao
                ? !allFields.SAQUE_COMPLEMENTAR.isChecked
                : true) &&
              !isTipoOperacaoSaqueOrCartaoNovo &&
              !visaoUsuarioExpecifico &&
              !isVisibleMargemConvenio
            }
            option={addedFilters}
            initialMalingOptionValue={initialFilterValues}
            set={onChangeMailingStage({
              setState: setAddedFilters,
            })}
            field="numero_parcelas_pagas"
            title="Número de Parcelas Pagas"
            setInvalidFields={setInvalidFields}
            setPresentFields={setPresentFields}
          >
            <Filter
              type="between"
              setAddedFilters={onChangeMailingStage({
                setState: setAddedFilters,
              })}
              addedFilters={addedFilters}
              valueType="number"
              filterKey="numero_parcelas_pagas"
              errorMessage={invalidFields["numero_parcelas_pagas"]}
            />
          </MailingField>
          <MailingField
            isVisible={
              (!mailingData.tipoOperacao
                ? !allFields.SAQUE_COMPLEMENTAR.isChecked
                : true) &&
              allFields.ANTECIPACAO_FGTS.isChecked &&
              !isTipoOperacaoSaqueOrCartaoNovo &&
              !visaoUsuarioExpecifico &&
              !isVisibleMargemConvenio
            }
            option={addedFilters}
            initialMalingOptionValue={initialFilterValues}
            set={onChangeMailingStage({
              setState: setAddedFilters,
            })}
            field="valor_antecipacao"
            title="Valor Antecipação"
            setInvalidFields={setInvalidFields}
            setPresentFields={setPresentFields}
          >
            <Filter
              type="input"
              setAddedFilters={onChangeMailingStage({
                setState: setAddedFilters,
              })}
              addedFilters={addedFilters}
              valueType="number"
              filterKey="valor_antecipacao"
              errorMessage={invalidFields["valor_antecipacao"]}
            />
          </MailingField>
          <MailingField
            isVisible={
              ((!mailingData.tipoOperacao
                ? !allFields.SAQUE_COMPLEMENTAR.isChecked
                : true) &&
                allFields.SIMULACAO_AUMENTO.isChecked &&
                !isTipoOperacaoSaqueOrCartaoNovo &&
                !visaoUsuarioExpecifico &&
                !isVisibleMargemConvenio) ||
              (["NOVO"].includes(mailingData.tipoOperacao!) &&
                !visaoUsuarioExpecifico)
            }
            option={addedFilters}
            initialMalingOptionValue={initialFilterValues}
            set={onChangeMailingStage({
              setState: setAddedFilters,
            })}
            field="margem_aumento"
            title="Margem Aumento"
            setInvalidFields={setInvalidFields}
            setPresentFields={setPresentFields}
          >
            <Filter
              type="between"
              setAddedFilters={onChangeMailingStage({
                setState: setAddedFilters,
              })}
              addedFilters={addedFilters}
              valueType="number"
              filterKey="margem_aumento"
              errorMessage={invalidFields["margem_aumento"]}
            />
          </MailingField>
          <Box gridArea="auto / span 2">
            <MailingField
              isVisible={
                ["NOVO"].includes(mailingData.tipoOperacao!) &&
                !visaoUsuarioExpecifico
              }
              option={addedFilters}
              initialMalingOptionValue={initialFilterValues}
              set={onChangeMailingStage({
                setState: setAddedFilters,
              })}
              field="especies"
              title="Espécie"
              setInvalidFields={setInvalidFields}
              setPresentFields={setPresentFields}
            >
              <Filter
                type="dropdown"
                setAddedFilters={onChangeMailingStage({
                  setState: setAddedFilters,
                })}
                addedFilters={addedFilters}
                componentProps={{
                  options: especieCodeListConsignaveis.map((cod) => ({
                    name: dropdownEspecies.find(({ value }) => value === cod)
                      ?.name,
                    value: cod,
                  })),
                }}
                filterKey="especies"
                errorMessage={invalidFields["especies"]}
              />
            </MailingField>
          </Box>
          <MailingField
            isVisible={
              ["NOVO"].includes(mailingData.tipoOperacao!) &&
              !visaoUsuarioExpecifico
            }
            option={addedFilters}
            initialMalingOptionValue={initialFilterValues}
            set={onChangeMailingStage({
              setState: setAddedFilters,
            })}
            field="possui_representante_legal"
            title="Representante legal"
            setInvalidFields={setInvalidFields}
            setPresentFields={setPresentFields}
          >
            <Filter
              type="dropdown"
              setAddedFilters={onChangeMailingStage({
                setState: setAddedFilters,
              })}
              addedFilters={addedFilters}
              componentProps={{
                options: dropdownSimNao,
                multiSelect: false,
              }}
              filterKey="possui_representante_legal"
              errorMessage={invalidFields["possui_representante_legal"]}
            />
          </MailingField>

          <MailingField
            isVisible={
              ["NOVO"].includes(mailingData.tipoOperacao!) &&
              !visaoUsuarioExpecifico
            }
            option={addedFilters}
            initialMalingOptionValue={initialFilterValues}
            set={onChangeMailingStage({
              setState: setAddedFilters,
            })}
            field="ddb"
            title="DDB"
            setInvalidFields={setInvalidFields}
            setPresentFields={setPresentFields}
          >
            <Filter
              type="between"
              setAddedFilters={onChangeMailingStage({
                setState: setAddedFilters,
              })}
              addedFilters={addedFilters}
              valueType="date"
              filterKey="ddb"
              errorMessage={invalidFields["ddb"]}
            />
          </MailingField>
          <MailingField
            isVisible={
              ["NOVO"].includes(mailingData.tipoOperacao!) &&
              !visaoUsuarioExpecifico
            }
            option={addedFilters}
            initialMalingOptionValue={initialFilterValues}
            set={onChangeMailingStage({
              setState: setAddedFilters,
            })}
            field="meio_pagamento"
            title="Meio Pagamento"
            setInvalidFields={setInvalidFields}
            setPresentFields={setPresentFields}
          >
            <Filter
              type="dropdown"
              setAddedFilters={onChangeMailingStage({
                setState: setAddedFilters,
              })}
              addedFilters={addedFilters}
              componentProps={{
                options: dropdownTipoPagamento,
                multiSelect: false,
              }}
              filterKey="meio_pagamento"
              errorMessage={invalidFields["meio_pagamento"]}
            />
          </MailingField>
          <MailingField
            isVisible={
              ["NOVO"].includes(mailingData.tipoOperacao!) &&
              !visaoUsuarioExpecifico
            }
            option={addedFilters}
            initialMalingOptionValue={initialFilterValues}
            set={onChangeMailingStage({
              setState: setAddedFilters,
            })}
            field="valor_beneficio"
            title="Valor Benefício"
            setInvalidFields={setInvalidFields}
            setPresentFields={setPresentFields}
          >
            <Filter
              type="between"
              setAddedFilters={onChangeMailingStage({
                setState: setAddedFilters,
              })}
              addedFilters={addedFilters}
              valueType="number"
              filterKey="valor_beneficio"
              errorMessage={invalidFields["valor_beneficio"]}
            />
          </MailingField>
        </Grid>
      </>

      {isTelefoniaPresentFields ? (
        <>
          <Text mt="10px" fontSize="20" fontWeight="bold">
            {formatTitle({
              title: "Telefonia",
              presentFields,
              isSimulacaoRefinCarteiraPresentFields,
            })}
            {visaoUsuarioExpecifico ? null : " "}
            {visaoUsuarioExpecifico ? null : (
              <Text as="span" fontSize="16px">
                (Baseado no resultado anterior)
              </Text>
            )}
            :
          </Text>
          <hr style={{ margin: "4px 0 8px" }} />{" "}
        </>
      ) : null}
      <Grid templateColumns="1fr 1fr 1fr" gap="10px" w="100%">
        <MailingField
          isVisible={isUpdateAndRefinCarteira}
          option={telefonia}
          initialMalingOptionValue={initialTelefoniaValues}
          set={onChangeMailingStage({
            setState: setTelefonia,
            rule: booleanRule,
          })}
          field="telefoniaApi"
          title="API Telefonia"
          setInvalidFields={setInvalidFields}
          setPresentFields={setPresentFields}
          isDisabledCheckBox={isDisabledFields}
        >
          <></>
        </MailingField>
      </Grid>

      <Box>
        {isDigitacaoPresentFields ? (
          <>
            <Text mt="10px" fontSize="20" fontWeight="bold">
              {formatTitle({
                title: "Digitação",
                presentFields,
                isSimulacaoRefinCarteiraPresentFields,
              })}
            </Text>
            <hr style={{ margin: "4px 0 8px" }} />
          </>
        ) : null}
        <MailingField
          isVisible={isVisibleDigitacaoStage}
          option={digitacao}
          initialMalingOptionValue={initialDigitacaoPipelineFilterValues}
          set={onChangeMailingStage({
            setState: setDigitacao,
            rule: digitacaoRule(mailingData.tipoOperacao),
          })}
          field="banco"
          title="Digitação"
          setInvalidFields={setInvalidFields}
          setPresentFields={setPresentFields}
        >
          <DigitacaoFilter
            mailingData={mailingData}
            set={onChangeMailingStage({
              setState: setDigitacao,
              rule: digitacaoRule(mailingData.tipoOperacao),
            })}
            currentDigitacao={digitacao}
            invalidFields={invalidFields}
            setInvalidFields={setInvalidFields}
          />
        </MailingField>
      </Box>

      {isEnviarParaPresentFields ? (
        <>
          <Text mt="10px" fontSize="20" fontWeight="bold">
            {formatTitle({
              title: "Enviar para",
              presentFields,
              isSimulacaoRefinCarteiraPresentFields,
            })}{" "}
            <Text as="span" fontSize="16px">
              (Baseado no resultado anterior)
            </Text>
            :
          </Text>
          <hr style={{ margin: "4px 0 8px" }} />
        </>
      ) : null}
      <Grid
        templateColumns={{ base: "1fr 1fr 1fr 1fr" }}
        justifyContent="flex-start"
      >
        <MailingField
          isVisible={isUpdateAndRefinCarteira}
          option={enviarPara}
          initialMalingOptionValue={initialEnviarParaValues}
          set={onChangeMailingStage({
            setState: setEnviarPara,
            rule: booleanRule,
          })}
          field="enviarParaCampanhaWhatsApp"
          title="WhatsApp"
          setInvalidFields={setInvalidFields}
          setPresentFields={setPresentFields}
          isDisabledCheckBox={isDisabledFields}
        >
          <></>
        </MailingField>

        {visaoUsuarioExpecifico ? null : (
          <>
            {isUpdateAndRefinCarteira ? (
              <Checkbox
                onClick={() => {
                  Toast({
                    title: "Serviço em desenvolvimento",
                  });
                }}
                // onChange={(isChecked) => setEnviarParaDiscadora(isChecked)}
                isChecked={enviarParaDiscadora}
                isDisabled={isDisabledFields}
              >
                Discadora
              </Checkbox>
            ) : null}
          </>
        )}
        <MailingField
          isVisible={isUpdateAndRefinCarteira}
          option={enviarPara}
          initialMalingOptionValue={initialEnviarParaValues}
          set={onChangeMailingStage({
            setState: setEnviarPara,
            rule: booleanRule,
          })}
          field="enviarParaCampanhaSMS"
          title="SMS"
          setInvalidFields={setInvalidFields}
          setPresentFields={setPresentFields}
          isDisabledCheckBox={isDisabledFields}
        >
          <></>
        </MailingField>
        <MailingField
          isVisible={!visaoUsuarioExpecifico && isUpdateAndRefinCarteira}
          option={enviarPara}
          initialMalingOptionValue={initialEnviarParaValues}
          set={onChangeMailingStage({
            setState: setEnviarPara,
            rule: booleanRule,
          })}
          field="enviaAtendimento"
          title="Atendimento"
          setInvalidFields={setInvalidFields}
          setPresentFields={setPresentFields}
          isDisabledCheckBox={isDisabledFields}
        >
          <></>
        </MailingField>
      </Grid>
    </CustomModal>
  );
}

export const openMailingOptions = (data: MailingData) =>
  makeEvent("open-mailing-options", data);
