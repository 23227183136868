import { Box, Flex, Grid } from "@chakra-ui/react";
import { DropdownField } from "components/dropdown-field";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import api from "api/api";
import { UsuarioBanco } from "components/usuarios-bancos-components/page";
import { Toast } from "components/toast";
import { initialDigitacaoPipelineFilterValues } from "../../variables";
import { MailingData, MailingOptionFilter } from "../..";
import { useApplicationContext } from "contexts/ApplicationContext";
import apiDigitacao from "api/api-digitacao";
import { TipoOperacaoMailing } from "components/mailing-components/mailing-upload-modal/consts";

interface TabelaDigitacaoProps {
  banco: string;
  codigo: string;
  convenio: string;
  descricao: string;
  prazo: null;
}

export function DigitacaoFilter({
  set,
  mailingData,
  currentDigitacao,
  invalidFields,
  setInvalidFields,
}: {
  currentDigitacao: MailingOptionFilter;
  mailingData: Partial<MailingData>;
  set: Dispatch<SetStateAction<MailingOptionFilter>>;
  invalidFields: { [field: string]: string };
  setInvalidFields: Dispatch<SetStateAction<{ [field: string]: string }>>;
}) {
  const [loading, setLoading] = useState(false);
  const [disabledBancos, setDisabledBancos] = useState(false);
  const [usuariosBancos, setUsuariosBancos] = useState<UsuarioBanco[]>([]);
  const [tabelas, setTabelas] = useState<TabelaDigitacaoProps[]>([]);
  const [loadingTabelas, setLoadingTabelas] = useState(false);

  const { user } = useApplicationContext();

  if (!currentDigitacao.usuarioDigitacaoSenha) {
    currentDigitacao.usuarioDigitacaoSenha =
      initialDigitacaoPipelineFilterValues.usuarioDigitacaoSenha;
  }
  const tipoOperacao = mailingData.tipoOperacao;
  const bancoOptions = ["REFIN_CARTEIRA"].includes(tipoOperacao!)
    ? bancosDigitacaoMailingRefinCarteira
    : bancosDigitacaoMailingPipeline;

  const tabelasOptions = tabelas.map((crrTabela) => ({
    name: crrTabela.descricao,
    value: crrTabela.codigo,
  }));
  const banco = currentDigitacao.banco.value;
  const tipoColunaMargem = currentDigitacao.tipoColunaMargem.value;
  const usuarioDigitacao = currentDigitacao.usuarioDigitacao.value;
  const tabela = currentDigitacao.tabela.value;
  const convenio = mailingData.convenio;
  const usuarioDataDigitacao = new Map<
    string,
    { password: string; cpfUsuarioCertificado: string | undefined }
  >();
  const currTabelaDesc = tabelasOptions.find(
    (crr) => crr.value === tabela
  )?.name;

  const usuariosDigitacaoOptions = usuariosBancos.map(
    ({ username, password, cpfUsuarioCertificado }) => {
      usuarioDataDigitacao.set(username, { password, cpfUsuarioCertificado });
      return { name: username, value: username };
    }
  );

  const getTabelasDigitacao = async () => {
    setLoadingTabelas(true);
    try {
      const { data } = await apiDigitacao.get<TabelaDigitacaoProps[]>(
        `api/tabelas-digitacao/enable?convenio=${convenio}&banco=${banco}`
      );
      if (!data?.length)
        Toast({ title: "Não há tabelas para o banco selecionado" });
      setTabelas(data);
    } catch (e) {
      Toast({ title: "Erro ao obter tabelas" });
    } finally {
      setLoadingTabelas(false);
    }
  };

  const setDigitacao = (
    k:
      | "banco"
      | "usuarioDigitacao"
      | "tabela"
      | "usuarioDigitacaoSenha"
      | "cpfUsuarioCertificado"
      | "tipoColunaMargem",
    value: { isChecked?: boolean; value?: any }
  ) => {
    set((prev) => ({ ...prev, [k]: { ...prev[k], ...value } }));

    setInvalidFields((errors) => {
      delete errors[k];
      return { ...errors };
    });
  };

  const getUsuariosBancos = async () => {
    setLoading(true);
    try {
      const { data } = await api.get<UsuarioBanco[]>(
        `usuarios-bancos/digitadores/${banco}`
      );
      if (!data?.length) {
        Toast({
          title: `Não há usuários cadastrados para o banco selecionado`,
          duration: 5000,
        });
      }
      setUsuariosBancos(data);
    } catch (error) {
      Toast({
        title: "Erro ao buscar lista de Usuario Banco",
        status: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (banco) {
      getUsuariosBancos();
      getTabelasDigitacao();
    }
  }, [banco]);

  useEffect(() => {
    if (mailingData.bancosTo?.[0]) {
      if (mailingData.bancosTo?.[0] === 935) {
        setDisabledBancos(true);
        setDigitacao("banco", { value: "FACTA" });
      }
      if (mailingData.bancosTo?.[0] === 626) {
        setDisabledBancos(true);
        setDigitacao("banco", { value: "C6" });
      }
      if (mailingData.bancosTo?.[0] === 318) {
        setDisabledBancos(true);
        setDigitacao("banco", { value: "BMG" });
      }
    }
    if (!currentDigitacao.usuarioDigitacaoSenha)
      setDigitacao(
        "usuarioDigitacaoSenha",
        initialDigitacaoPipelineFilterValues.usuarioDigitacaoSenha
      );
  }, []);

  return (
    <Box>
      <Grid
        templateColumns={
          tipoOperacao === "REFIN_CARTEIRA" ? "1fr 1fr 1fr" : "1fr 1fr"
        }
        gap="10px"
        mb="10px"
      >
        {tipoOperacao === "REFIN_CARTEIRA" ? null : (
          <Box>
            <DropdownField
              title="Campo para digitação"
              dropdownProps={{ w: "100%" }}
              onChange={(value) => setDigitacao("tipoColunaMargem", { value })}
              value={tipoColunaMargem}
              options={tipoColunaMargemOptions}
              errorMessage={invalidFields["tipoColunaMargem"]}
            />
          </Box>
        )}

        <Box>
          <DropdownField
            title="Banco"
            dropdownProps={{ w: "100%" }}
            onChange={(value) => {
              setDigitacao("banco", { value });
              setDigitacao("usuarioDigitacao", { value: "" });
              setDigitacao("usuarioDigitacaoSenha", { value: "" });
            }}
            value={banco}
            options={bancoOptions}
            isDisabled={disabledBancos}
            errorMessage={invalidFields["banco"]}
          />
        </Box>

        <Box>
          <DropdownField
            title="Tabela"
            dropdownProps={{
              w: "100%",
              children:
                currTabelaDesc?.length! > 66
                  ? currTabelaDesc?.slice(0, 66) + "..."
                  : undefined,
            }}
            onChange={(value) => setDigitacao("tabela", { value })}
            value={tabela}
            options={tabelasOptions}
            errorMessage={invalidFields["tabela"]}
            isLoading={loadingTabelas}
          />
        </Box>

        <Box>
          <DropdownField
            title="Usuário Digitação"
            dropdownProps={{ w: "100%" }}
            onChange={(username) => {
              setDigitacao("usuarioDigitacao", { value: username });
              if (banco === "BMG") {
                setDigitacao("usuarioDigitacaoSenha", {
                  value: usuarioDataDigitacao.get(username)?.password || "",
                });
              }
              if (banco === "C6") {
                setDigitacao("usuarioDigitacaoSenha", {
                  value: usuarioDataDigitacao.get(username)?.password || "",
                });
                setDigitacao("cpfUsuarioCertificado", {
                  value:
                    usuarioDataDigitacao.get(username)?.cpfUsuarioCertificado ||
                    "",
                });
              }
            }}
            value={usuarioDigitacao}
            options={usuariosDigitacaoOptions}
            isLoading={loading}
            errorMessage={invalidFields["usuarioDigitacao"]}
          />
        </Box>
      </Grid>
    </Box>
  );
}

const bancosDigitacaoMailingRefinCarteira = [
  { name: "C6", value: "C6" },
  { name: "Daycoval", value: "DAYCOVAL" },
];

export const bancosDigitacaoMailingPipeline = [
  { name: "Bmg", value: "BMG" },
  { name: "Facta", value: "FACTA" },
  { name: "C6", value: "C6" },
  { name: "iCred", value: "ICRED" },
  { name: "Presença", value: "PRESENCA" },
];

const tipoColunaMargemOptions = [
  { name: "Margem Aumento", value: "MARGEM_AUMENTO" },
  { name: "Margem Livre", value: "MARGEM_LIVRE" },
];
