import { AttendanceChatFilterProps } from ".";
import { AttendanceChatProps } from "../chats-context/types";

export function checkIsValidAttendance(
  filters: AttendanceChatFilterProps,
  currAtendimento: AttendanceChatProps
) {
  let result = true;
  if (Object.keys(filters).includes("convenio")) {
    result = currAtendimento.convenio === filters.convenio;
  }
  if (result && Object.keys(filters).includes("tabulacoes")) {
    result = !!filters.tabulacoes?.includes(currAtendimento.tabulacao!);
  }
  if (result && Object.keys(filters).includes("userIds")) {
    result = !!filters.userIds?.includes(currAtendimento.userId!);
  }
  if (result && Object.keys(filters).includes("onlyUnreadMessage")) {
    result = currAtendimento.unreadMessage === filters.onlyUnreadMessage;
  }
  if (result && Object.keys(filters).includes("fromDataCriacao")) {
    result =
      !!filters.fromDataCriacao &&
      !!currAtendimento.dataCriacao &&
      currAtendimento.dataCriacao >=
        new Date(filters.fromDataCriacao + "T00:00").getTime();
  }
  if (result && Object.keys(filters).includes("toDataCriacao")) {
    result =
      !!filters.toDataCriacao &&
      !!currAtendimento.dataCriacao &&
      currAtendimento.dataCriacao <
        new Date(filters.toDataCriacao + "T00:00").getTime() +
          24 * 60 * 60 * 1000;
  }
  if (result && Object.keys(filters).includes("etapas")) {
    result = !!filters.etapas?.includes(currAtendimento.etapa!);
  }
  return result;
}
