import { Box, Flex, InputProps } from "@chakra-ui/react";
import { defaultScroll } from "chakra/theme";
import { filterArrayBy } from "utils/filter-array-by";
import { InputField, InputFieldProps } from "components/input-field";
import { KeyboardEvent, useEffect, useRef, useState } from "react";
import { getRelativeRect } from "utils/relative-rect";
import { Checkbox } from "components/checkbox";

export interface InputSearchProps {
  options: any[];
  checkIsInclude: (option: any) => boolean;
  onChange: (value: any) => void;
  inputProps?: InputProps;
  isLoading?: boolean;
  optionValue?: string;
  optionLabel?: string;
  optionLabelRender?: (option: any) => any;
  searchKeys?: string[];
  errorMessage?: string;
  clearInput?: boolean;
  openOnFocus?: boolean;
  value?: string;
  isInvalid?: boolean;
  multiSelect?: boolean;
  resultLimit?: number;
  closeOnSelect?: boolean;
  inputFieldProps?: InputFieldProps;
  partialSearch?: boolean;
  sequentialSearch?: boolean;
}

export function InputSearch({
  options,
  onChange: onChangeValue,
  inputProps,
  isLoading,
  searchKeys = ["name"],
  optionLabel = "name",
  optionValue = "value",
  errorMessage,
  resultLimit = 50,
  checkIsInclude,
  optionLabelRender,
  clearInput = true,
  openOnFocus,
  value = "",
  isInvalid,
  multiSelect,
  closeOnSelect = true,
  inputFieldProps,
  partialSearch = false,
  sequentialSearch,
}: InputSearchProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [result, setResult] = useState<any[]>([]);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const partnerListRef = useRef<HTMLDivElement>(null);
  const input = useRef<HTMLInputElement>(null);
  const isOpened = isOpen && (openOnFocus || result.length);

  const handleScroll = (element: HTMLElement, parent: HTMLElement) => {
    const relRef = getRelativeRect(element, parent);
    const elHeight = element.clientHeight;
    const top = relRef.top + parent.scrollTop!;
    const scrollPos = parent.scrollTop! + parent.clientHeight;
    if (top + elHeight > scrollPos)
      parent.scrollTop = top + elHeight - parent.clientHeight;
    if (top < parent.scrollTop) parent.scrollTop = top;
  };

  const handleKeyPress = ({ key }: KeyboardEvent<HTMLInputElement>) => {
    const list = partnerListRef.current;
    if (key === "Escape") setIsOpen(false);
    else if (result.length) setIsOpen(true);

    if (key === "Enter" && isOpened) {
      const currResult = result[selectedIndex];
      if (currResult) {
        onChangeValue(currResult[optionValue]);
        if (!multiSelect) input.current?.blur();
        setIsOpen(false);
      }
    } else if (key === "ArrowUp" && isOpened) {
      const newCurrIndex = selectedIndex - 1;
      const currResult = result[newCurrIndex];
      if (!currResult) {
        const selectedHtml = list!.children[
          result.length - 1
        ] as HTMLDivElement;
        handleScroll(selectedHtml, list!);
        setSelectedIndex(result.length - 1);
        if (clearInput) input.current!.value = "";
      } else {
        const selectedHtml = list!.children[newCurrIndex] as HTMLDivElement;
        handleScroll(selectedHtml, list!);
        setSelectedIndex(newCurrIndex);
        if (clearInput) input.current!.value = "";
      }
    } else if (key === "ArrowDown" && isOpened) {
      const newCurrIndex = selectedIndex + 1;
      const currResult = result[newCurrIndex];
      if (!currResult) {
        const selectedHtml = list!.children[0] as HTMLDivElement;
        handleScroll(selectedHtml, list!);
        setSelectedIndex(0);
        if (clearInput) input.current!.value = "";
      } else {
        const selectedHtml = list!.children[newCurrIndex] as HTMLDivElement;
        handleScroll(selectedHtml, list!);
        setSelectedIndex(newCurrIndex);
        if (clearInput) input.current!.value = "";
      }
    }
  };

  useEffect(() => {
    if (openOnFocus) setResult(options.slice(0, resultLimit));
    if (input.current) input.current.value = value;
  }, [options]);

  useEffect(() => {
    if (!clearInput && input.current) input.current.value = value;
  }, [value]);

  const { onChange: onChangeInputValue, ...restInputProps } = inputProps || {};

  let currentOptions = result.slice(0, resultLimit);

  if (options.filter((item) => checkIsInclude(item))?.length && multiSelect) {
    const selecteds = options.filter((item) => checkIsInclude(item));
    console.log(selecteds);
    const unSelecteds = currentOptions.filter((item) => !checkIsInclude(item));
    currentOptions = [...selecteds, ...unSelecteds];
  }

  return (
    <Box w="100%" gap="16px">
      <Box w="100%" pos="relative">
        <InputField
          {...inputFieldProps}
          ref={input}
          onChange={async (e) => {
            const value = e.target.value;
            if (value) {
              setResult(
                filterArrayBy(options, value, searchKeys, {
                  partialSearch,
                  sequence: sequentialSearch,
                }).slice(0, resultLimit)
              );
            } else setResult(openOnFocus ? options : []);
            setSelectedIndex(-1);
            onChangeInputValue?.(e);
          }}
          inputProps={{
            onKeyDown: handleKeyPress,
            isDisabled: isLoading,
            ...restInputProps,
            onFocus: (e) => {
              setIsOpen(true);
              inputProps?.onFocus?.(e);
            },
            onBlur: (e) => {
              setIsOpen(false);
              inputProps?.onBlur?.(e);
            },
          }}
          errorMessage={errorMessage}
          popoverProps={{ containerStyles: { width: "100%" } }}
          isLoading={isLoading}
          isInvalid={isInvalid}
          withDropdown
        />

        {isOpened ? (
          <Box
            ref={partnerListRef}
            pos="absolute"
            bottom="-2px"
            left="0"
            right="0"
            zIndex="1"
            transform="translateY(100%)"
            bg="#fff"
            borderRadius="6px"
            overflow="auto"
            border="1px solid var(--chakra-colors-gray-200)"
            maxH={"240px"}
            sx={defaultScroll}
            onMouseDown={(e) => e.preventDefault()}
          >
            {currentOptions.map((item, index) => {
              const isInclude = checkIsInclude(item);
              const isSelected = index === selectedIndex;
              return (
                <Box
                  p="5px 10px"
                  key={`input-search-${index}`}
                  w="100%"
                  _hover={{ bg: "gray.100" }}
                  bg={
                    isInclude ? "gray.200" : isSelected ? "gray.100" : undefined
                  }
                  cursor="pointer"
                  onClick={(e: any) => {
                    onChangeValue(item[optionValue]);
                    setSelectedIndex(index);
                    if (clearInput) input.current!.value = "";
                    if (closeOnSelect) {
                      setIsOpen(false);
                      e.currentTarget.focus();
                    }
                  }}
                  textAlign="start"
                  as="button"
                >
                  <Flex alignItems="center">
                    {multiSelect ? (
                      <Checkbox
                        mr="5px"
                        isChecked={isInclude}
                        onClick={(e) => {}}
                      />
                    ) : undefined}{" "}
                    {optionLabelRender?.(item) ?? `${item[optionLabel]}`}
                  </Flex>
                </Box>
              );
            })}
          </Box>
        ) : null}
      </Box>
    </Box>
  );
}
