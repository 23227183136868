export const municipiosFGTSList = [
  { name: "GOIANIA", value: "9373", uf: "GO" },
  { name: "SANTA MARIA DE JETIBA", value: "5725", uf: "ES" },
  { name: "BARROSO", value: "4117", uf: "MG" },
  { name: "ITAPETININGA", value: "6547", uf: "SP" },
  { name: "IPATINGA", value: "4625", uf: "MG" },
  { name: "JAPARATUBA", value: "3165", uf: "SE" },
  { name: "VILA VELHA", value: "5703", uf: "ES" },
  { name: "ITUMBIARA", value: "9425", uf: "GO" },
  { name: "LAVRAS", value: "4763", uf: "MG" },
  { name: "BOA ESPERANCA", value: "5619", uf: "ES" },
  { name: "BATATAIS", value: "6217", uf: "SP" },
  { name: "PICOS", value: "1159", uf: "PI" },
  { name: "FORTALEZA", value: "1389", uf: "CE" },
  { name: "PEDRO AFONSO", value: "9525", uf: "TO" },
  { name: "SALVADOR", value: "3849", uf: "BA" },
  { name: "CAMPINAS", value: "6291", uf: "SP" },
  { name: "RIO DE JANEIRO", value: "6001", uf: "RJ" },
  { name: "VOTORANTIM", value: "7243", uf: "SP" },
  { name: "GUARAPARI", value: "5647", uf: "ES" },
  { name: "CAMAQUA", value: "8569", uf: "RS" },
  { name: "PIRAPORA", value: "5023", uf: "MG" },
  { name: "SAO PAULO", value: "7107", uf: "SP" },
  { name: "FAMA", value: "4503", uf: "MG" },
  { name: "CAJAMAR", value: "6285", uf: "SP" },
  { name: "PETROPOLIS", value: "5877", uf: "RJ" },
  { name: "PARANAVAI", value: "7749", uf: "PR" },
  { name: "BOTUCATU", value: "6249", uf: "SP" },
  { name: "PARAIBANO", value: "0853", uf: "MA" },
  { name: "RIO PARDO DE MINAS", value: "5111", uf: "MG" },
  { name: "RECIFE", value: "2531", uf: "PE" },
  { name: "TAUBATE", value: "7183", uf: "SP" },
  { name: "RIO BONITO", value: "5885", uf: "RJ" },
  { name: "MONTES CLAROS", value: "4865", uf: "MG" },
  { name: "SANTA CRUZ DO SUL", value: "8839", uf: "RS" },
  { name: "SAO JOSE DO BELMONTE", value: "2569", uf: "PE" },
  { name: "NOVA ODESSA", value: "6769", uf: "SP" },
  { name: "GUARUJA", value: "6475", uf: "SP" },
  { name: "GOVERNADOR VALADARES", value: "4553", uf: "MG" },
  { name: "PORTO VELHO", value: "0003", uf: "RO" },
  { name: "ITARARE", value: "6565", uf: "SP" },
  { name: "CAMPINA GRANDE DO SUL", value: "7477", uf: "PR" },
  { name: "SAO SEBASTIAO", value: "7115", uf: "SP" },
  { name: "CURVELO", value: "4417", uf: "MG" },
  { name: "RIO DOS CEDROS", value: "8289", uf: "SC" },
  { name: "SAO CARLOS", value: "7079", uf: "SP" },
  { name: "JUNDIAI", value: "6619", uf: "SP" },
  { name: "CAMPINA VERDE", value: "4221", uf: "MG" },
  { name: "JOAO PINHEIRO", value: "4725", uf: "MG" },
  { name: "DIADEMA", value: "6377", uf: "SP" },
  { name: "SANTAREM", value: "0535", uf: "PA" },
  { name: "MONTE ALEGRE", value: "0495", uf: "PA" },
  { name: "SARANDI", value: "8461", uf: "PR" },
  { name: "TERESOPOLIS", value: "5915", uf: "RJ" },
  { name: "POTIM", value: "2993", uf: "SP" },
  { name: "GRAVATAI", value: "8683", uf: "RS" },
  { name: "RIBEIRAO PRETO", value: "6969", uf: "SP" },
  { name: "NOVA MUTUM", value: "9937", uf: "MT" },
  { name: "DOMINGOS MARTINS", value: "5637", uf: "ES" },
  { name: "MATAO", value: "6687", uf: "SP" },
  { name: "PRADOPOLIS", value: "6919", uf: "SP" },
  { name: "CAMPOS ALTOS", value: "4229", uf: "MG" },
  { name: "OURINHOS", value: "6795", uf: "SP" },
  { name: "AFONSO CLAUDIO", value: "5601", uf: "ES" },
  { name: "VIANA", value: "5701", uf: "ES" },
  { name: "OURO FINO", value: "4919", uf: "MG" },
  { name: "CORUMBATAI", value: "6355", uf: "SP" },
  { name: "SOROCABA", value: "7145", uf: "SP" },
  { name: "MANAUS", value: "0255", uf: "AM" },
  { name: "MOGI DAS CRUZES", value: "6713", uf: "SP" },
  { name: "JOAO MONLEVADE", value: "4723", uf: "MG" },
  { name: "VITORIA", value: "5705", uf: "ES" },
  { name: "BELO HORIZONTE", value: "4123", uf: "MG" },
  { name: "ESMERALDAS", value: "4481", uf: "MG" },
  { name: "JITAUNA", value: "3667", uf: "BA" },
  { name: "CHAPECO", value: "8081", uf: "SC" },
  { name: "ANTONIO PRADO", value: "8515", uf: "RS" },
  { name: "SAO MANUEL", value: "7103", uf: "SP" },
  { name: "CASTANHAL", value: "0447", uf: "PA" },
  { name: "FORMIGA", value: "4521", uf: "MG" },
  { name: "ITAJAI", value: "8161", uf: "SC" },
  { name: "PIRACANJUBA", value: "9539", uf: "GO" },
  { name: "CONTAGEM", value: "4371", uf: "MG" },
  { name: "ESTANCIA", value: "3141", uf: "SE" },
  { name: "ILHEUS", value: "3573", uf: "BA" },
  { name: "NOVA IGUACU", value: "5869", uf: "RJ" },
  { name: "RESTINGA", value: "6955", uf: "SP" },
  { name: "LIMEIRA", value: "6639", uf: "SP" },
  { name: "MAIRINQUE", value: "6669", uf: "SP" },
  { name: "SAO LUIS", value: "0921", uf: "MA" },
  { name: "CARAGUATATUBA", value: "6311", uf: "SP" },
  { name: "CAMARAGIBE", value: "2629", uf: "PE" },
  { name: "ROLANDIA", value: "7825", uf: "PR" },
  { name: "FLORIANOPOLIS", value: "8105", uf: "SC" },
  { name: "NILOPOLIS", value: "5863", uf: "RJ" },
  { name: "FRANCA", value: "6425", uf: "SP" },
  { name: "NOVA SERRANA", value: "4903", uf: "MG" },
  { name: "TEOFILO OTONI", value: "5371", uf: "MG" },
  { name: "SANTOS", value: "7071", uf: "SP" },
  { name: "BELA VISTA DO TOLDO", value: "0896", uf: "SC" },
  { name: "UMUARAMA", value: "7935", uf: "PR" },
  { name: "OSASCO", value: "6789", uf: "SP" },
  { name: "ARARAS", value: "6165", uf: "SP" },
  { name: "BELEM", value: "0427", uf: "PA" },
  { name: "ECHAPORA", value: "6395", uf: "SP" },
  { name: "DUQUE DE CAXIAS", value: "5833", uf: "RJ" },
  { name: "LEME", value: "6635", uf: "SP" },
  { name: "SAO JOAO DE MERITI", value: "5901", uf: "RJ" },
  { name: "PIRAPORA DO BOM JESUS", value: "6883", uf: "SP" },
  { name: "ITACOATIARA", value: "0241", uf: "AM" },
  { name: "ITAGUAI", value: "5839", uf: "RJ" },
  { name: "RIO CLARO", value: "6979", uf: "SP" },
  { name: "BALNEARIO CAMBORIU", value: "8039", uf: "SC" },
  { name: "CAMPO GRANDE", value: "9051", uf: "MS" },
  { name: "AGUAS FORMOSAS", value: "4017", uf: "MG" },
  { name: "MALACACHETA", value: "4783", uf: "MG" },
  { name: "SAO BERNARDO DO CAMPO", value: "7075", uf: "SP" },
  { name: "SERRO", value: "5341", uf: "MG" },
  { name: "DIVINOPOLIS", value: "4445", uf: "MG" },
  { name: "NITEROI", value: "5865", uf: "RJ" },
  { name: "PALHOCA", value: "8233", uf: "SC" },
  { name: "POTE", value: "5047", uf: "MG" },
  { name: "GUARULHOS", value: "6477", uf: "SP" },
  { name: "MORRINHOS", value: "9473", uf: "GO" },
  { name: "JARAGUA", value: "9431", uf: "GO" },
  { name: "SAO LOURENCO", value: "5273", uf: "MG" },
  { name: "IACU", value: "3537", uf: "BA" },
  { name: "VENANCIO AIRES", value: "8955", uf: "RS" },
  { name: "MARINGA", value: "7691", uf: "PR" },
  { name: "BOMBINHAS", value: "5537", uf: "SC" },
  { name: "JOAO PESSOA", value: "2051", uf: "PB" },
  { name: "CRUZ MACHADO", value: "7533", uf: "PR" },
  { name: "PALMARES PAULISTA", value: "6803", uf: "SP" },
  { name: "ITAITUBA", value: "0471", uf: "PA" },
  { name: "AMERICANA", value: "6131", uf: "SP" },
  { name: "ADAMANTINA", value: "6101", uf: "SP" },
  { name: "CAMBE", value: "7471", uf: "PR" },
  { name: "PORTO ALEGRE", value: "8801", uf: "RS" },
  { name: "IGARAPE", value: "4601", uf: "MG" },
  { name: "ASSIS", value: "6179", uf: "SP" },
  { name: "ITABIRA", value: "4633", uf: "MG" },
  { name: "VARGINHA", value: "5413", uf: "MG" },
  { name: "MULITERNO", value: "5935", uf: "RS" },
  { name: "REGENTE FEIJO", value: "6949", uf: "SP" },
  { name: "MARICA", value: "5853", uf: "RJ" },
  { name: "TAQUARITINGA", value: "7175", uf: "SP" },
  { name: "SAO JOSE DOS PINHAIS", value: "7885", uf: "PR" },
  { name: "SAO GABRIEL DA PALHA", value: "5693", uf: "ES" },
  { name: "NOVA FRIBURGO", value: "5867", uf: "RJ" },
  { name: "INDAIATUBA", value: "6511", uf: "SP" },
  { name: "BEQUIMAO", value: "0737", uf: "MA" },
  { name: "TABOAO DA SERRA", value: "7157", uf: "SP" },
  { name: "SAO VICENTE", value: "7121", uf: "SP" },
  { name: "UBAITABA", value: "3943", uf: "BA" },
  { name: "PALMAS", value: "7733", uf: "PR" },
  { name: "FELIZ", value: "8659", uf: "RS" },
  { name: "SAO FRANCISCO DE ASSIS", value: "8865", uf: "RS" },
  { name: "QUIJINGUE", value: "3819", uf: "BA" },
  { name: "PENAPOLIS", value: "6847", uf: "SP" },
  { name: "BARRA LONGA", value: "4113", uf: "MG" },
  { name: "CERRO CORA", value: "1653", uf: "RN" },
  { name: "BRASILIA", value: "9701", uf: "DF" },
  { name: "ITABUNA", value: "3597", uf: "BA" },
  { name: "REDENCAO", value: "0567", uf: "PA" },
  { name: "ITAUNA", value: "4675", uf: "MG" },
  { name: "NOVA AURORA", value: "7965", uf: "PR" },
  { name: "SANTO ANTONIO DO MONTE", value: "5207", uf: "MG" },
  { name: "SAO GONCALO", value: "5897", uf: "RJ" },
  { name: "PATOS DE MINAS", value: "4959", uf: "MG" },
  { name: "FLORES DE GOIAS", value: "9359", uf: "GO" },
  { name: "JABOTICABAL", value: "6587", uf: "SP" },
  { name: "CALDAS NOVAS", value: "9289", uf: "GO" },
  { name: "RIO VERDE DE MATO GROSSO", value: "9147", uf: "MS" },
  { name: "PARNAIBA", value: "1153", uf: "PI" },
  { name: "ARARAQUARA", value: "6163", uf: "SP" },
  { name: "CATALAO", value: "9301", uf: "GO" },
  { name: "CAMPO MOURAO", value: "7483", uf: "PR" },
  { name: "CESARIO LANGE", value: "6333", uf: "SP" },
  { name: "ARARANGUA", value: "8027", uf: "SC" },
  { name: "SAO LEOPOLDO", value: "8877", uf: "RS" },
  { name: "VIDEIRA", value: "8379", uf: "SC" },
  { name: "MINEIROS DO TIETE", value: "6697", uf: "SP" },
  { name: "EXTERIOR", value: "9707", uf: "EX" },
  { name: "JOINVILLE", value: "8179", uf: "SC" },
  { name: "BLUMENAU", value: "8047", uf: "SC" },
  { name: "BELO VALE", value: "4127", uf: "MG" },
  { name: "BRAGANCA PAULISTA", value: "6251", uf: "SP" },
  { name: "MORUNGABA", value: "6741", uf: "SP" },
  { name: "LAGARTO", value: "3169", uf: "SE" },
  { name: "BETIM", value: "4133", uf: "MG" },
  { name: "UNIAO DO OESTE", value: "9973", uf: "SC" },
  { name: "BROTAS", value: "6259", uf: "SP" },
  { name: "MAJOR SALES", value: "0420", uf: "RN" },
  { name: "RIO DAS FLORES", value: "5889", uf: "RJ" },
  { name: "JARAGUA DO SUL", value: "8175", uf: "SC" },
  { name: "MIRACEMA", value: "5859", uf: "RJ" },
  { name: "CORONEL FABRICIANO", value: "4387", uf: "MG" },
  { name: "LAGOA DA PRATA", value: "4743", uf: "MG" },
  { name: "VARZEA DA ROCA", value: "3997", uf: "BA" },
  { name: "PRAIA GRANDE", value: "6921", uf: "SP" },
  { name: "MATINHA", value: "0829", uf: "MA" },
  { name: "ARAGUAINA", value: "9241", uf: "TO" },
  { name: "EMBU DAS ARTES", value: "6401", uf: "SP" },
  { name: "AQUIDAUANA", value: "9021", uf: "MS" },
  { name: "PARACAMBI", value: "5871", uf: "RJ" },
  { name: "SAO JOSE DOS CAMPOS", value: "7099", uf: "SP" },
  { name: "PRATAPOLIS", value: "5057", uf: "MG" },
  { name: "PALMEIRAS DE GOIAS", value: "9511", uf: "GO" },
  { name: "ROSARIO", value: "0891", uf: "MA" },
  { name: "BOA VISTA", value: "0301", uf: "RR" },
  { name: "PIRACICABA", value: "6875", uf: "SP" },
  { name: "SAO JOAO DEL REI", value: "5249", uf: "MG" },
  { name: "SAO LUIZ GONZAGA", value: "8881", uf: "RS" },
  { name: "SANTA TEREZINHA DE ITAIPU", value: "8467", uf: "PR" },
  { name: "NOVO HAMBURGO", value: "8771", uf: "RS" },
  { name: "VOTUPORANGA", value: "7245", uf: "SP" },
  { name: "UBERLANDIA", value: "5403", uf: "MG" },
  { name: "MOGI-GUACU", value: "6715", uf: "SP" },
  { name: "LINS", value: "6643", uf: "SP" },
  { name: "CACHOEIRO DE ITAPEMIRIM", value: "5623", uf: "ES" },
  { name: "BRODOWSKI", value: "6257", uf: "SP" },
  { name: "BARRA DO PIRAI", value: "5805", uf: "RJ" },
  { name: "MANAQUIRI", value: "9839", uf: "AM" },
  { name: "CORUMBA", value: "9063", uf: "MS" },
  { name: "SAO PEDRO", value: "7109", uf: "SP" },
  { name: "ALTINOPOLIS", value: "6119", uf: "SP" },
  { name: "ITAPECERICA", value: "4669", uf: "MG" },
  { name: "GUAXUPE", value: "4573", uf: "MG" },
  { name: "TREMEMBE", value: "7197", uf: "SP" },
  { name: "ANITA GARIBALDI", value: "8019", uf: "SC" },
  { name: "IGARASSU", value: "2435", uf: "PE" },
  { name: "GUAPIMIRIM", value: "2907", uf: "RJ" },
  { name: "CRUZILIA", value: "4415", uf: "MG" },
  { name: "ATIBAIA", value: "6181", uf: "SP" },
  { name: "IMPERATRIZ", value: "0803", uf: "MA" },
  { name: "BOM JESUS DO OESTE", value: "0902", uf: "SC" },
  { name: "ARACAJU", value: "3105", uf: "SE" },
  { name: "PATROCINIO", value: "4961", uf: "MG" },
  { name: "UBERABA", value: "5401", uf: "MG" },
  { name: "COLOMBO", value: "7513", uf: "PR" },
  { name: "PROMISSAO", value: "6933", uf: "SP" },
  { name: "FLORIANO", value: "1077", uf: "PI" },
  { name: "GUAICARA", value: "6445", uf: "SP" },
  { name: "PIUMHI", value: "5029", uf: "MG" },
  { name: "MACEIO", value: "2785", uf: "AL" },
  { name: "PILAR", value: "2837", uf: "AL" },
  { name: "CATANDUVA", value: "6323", uf: "SP" },
  { name: "MONCAO", value: "0837", uf: "MA" },
  { name: "VERANOPOLIS", value: "8959", uf: "RS" },
  { name: "CARANGOLA", value: "4265", uf: "MG" },
  { name: "SAO JOSE DO RIO PRETO", value: "7097", uf: "SP" },
  { name: "ALEM PARAIBA", value: "4029", uf: "MG" },
  { name: "OUVIDOR", value: "9507", uf: "GO" },
  { name: "CURITIBA", value: "7535", uf: "PR" },
  { name: "MAR VERMELHO", value: "2797", uf: "AL" },
  { name: "PARIQUERA-ACU", value: "6825", uf: "SP" },
  { name: "MARACANAU", value: "1585", uf: "CE" },
  { name: "CAIEIRAS", value: "6281", uf: "SP" },
  { name: "JORDAO", value: "0653", uf: "AC" },
  { name: "CAMPOS DOS GOYTACAZES", value: "5819", uf: "RJ" },
  { name: "ARACATUBA", value: "6155", uf: "SP" },
  { name: "PATOS", value: "2117", uf: "PB" },
  { name: "SERRA AZUL", value: "7129", uf: "SP" },
  { name: "BILAC", value: "6227", uf: "SP" },
  { name: "RIO VERDE", value: "9571", uf: "GO" },
  { name: "IAPU", value: "4585", uf: "MG" },
  { name: "SANTANA DE PARNAIBA", value: "7047", uf: "SP" },
  { name: "ARARUAMA", value: "5803", uf: "RJ" },
  { name: "PRESIDENTE OLEGARIO", value: "5067", uf: "MG" },
  { name: "PRESIDENTE ALVES", value: "6923", uf: "SP" },
  { name: "ALEXANIA", value: "9205", uf: "GO" },
  { name: "SANTA MARIA DA SERRA", value: "7041", uf: "SP" },
  { name: "BIRIGUI", value: "6229", uf: "SP" },
  { name: "RIO DOS BOIS", value: "0359", uf: "TO" },
  { name: "FIRMINOPOLIS", value: "9357", uf: "GO" },
  { name: "CANOAS", value: "8589", uf: "RS" },
  { name: "VERA CRUZ", value: "7235", uf: "SP" },
  { name: "VARGEM GRANDE DO SUL", value: "7231", uf: "SP" },
  { name: "FEIRA DE SANTANA", value: "3515", uf: "BA" },
  { name: "SAO JOAO DA BOA VISTA", value: "7083", uf: "SP" },
  { name: "CACADOR", value: "8057", uf: "SC" },
  { name: "SALINAS", value: "5139", uf: "MG" },
  { name: "CAPITAO ENEAS", value: "4253", uf: "MG" },
  { name: "SAO FIDELIS", value: "5895", uf: "RJ" },
  { name: "SENADOR GUIOMARD", value: "0153", uf: "AC" },
  { name: "TABAPORA", value: "0125", uf: "MT" },
  { name: "PRESIDENTE VENCESLAU", value: "6931", uf: "SP" },
  { name: "FOZ DO IGUACU", value: "7563", uf: "PR" },
  { name: "LONDRINA", value: "7667", uf: "PR" },
  { name: "PRESIDENTE PRUDENTE", value: "6929", uf: "SP" },
  { name: "MIRANTE DO PARANAPANEMA", value: "6705", uf: "SP" },
  { name: "SANTOS DUMONT", value: "5213", uf: "MG" },
  { name: "IVAIPORA", value: "7623", uf: "PR" },
  { name: "TAIUVA", value: "7165", uf: "SP" },
  { name: "SAO JOAO DO PARAISO", value: "5253", uf: "MG" },
  { name: "MIRANDOPOLIS", value: "6703", uf: "SP" },
  { name: "JUAZEIRO DO NORTE", value: "1447", uf: "CE" },
  { name: "JOACABA", value: "8177", uf: "SC" },
  { name: "MOGI MIRIM", value: "6717", uf: "SP" },
  { name: "MOSSORO", value: "1759", uf: "RN" },
  { name: "BAURU", value: "6219", uf: "SP" },
  { name: "JAPONVAR", value: "0630", uf: "MG" },
  { name: "PARANAGUA", value: "7745", uf: "PR" },
  { name: "MARTINOPOLE", value: "1459", uf: "CE" },
  { name: "SAO MARTINHO", value: "8337", uf: "SC" },
  { name: "MATO VERDE", value: "4819", uf: "MG" },
  { name: "ITU", value: "6579", uf: "SP" },
  { name: "FELICIO DOS SANTOS", value: "4507", uf: "MG" },
  { name: "PERUIBE", value: "6853", uf: "SP" },
  { name: "AGUAS DE LINDOIA", value: "6109", uf: "SP" },
  { name: "BAGE", value: "8531", uf: "RS" },
  { name: "MARABA", value: "0483", uf: "PA" },
  { name: "COTIA", value: "6361", uf: "SP" },
  { name: "ARAUJOS", value: "4077", uf: "MG" },
  { name: "PALMAS", value: "9733", uf: "TO" },
  { name: "RIO PIRACICABA", value: "5113", uf: "MG" },
  { name: "SALTO", value: "7005", uf: "SP" },
  { name: "LAGES", value: "8183", uf: "SC" },
  { name: "GOIANESIA DO PARA", value: "0627", uf: "PA" },
  { name: "CABO DE SANTO AGOSTINHO", value: "2357", uf: "PE" },
  { name: "GASPAR", value: "8117", uf: "SC" },
  { name: "MACAE", value: "5847", uf: "RJ" },
  { name: "AMARGOSA", value: "3319", uf: "BA" },
  { name: "MAGE", value: "5849", uf: "RJ" },
  { name: "CAMPOS DO JORDAO", value: "6295", uf: "SP" },
  { name: "FRANCISCO MORATO", value: "6427", uf: "SP" },
  { name: "CUIABA", value: "9067", uf: "MT" },
  { name: "RUSSAS", value: "1537", uf: "CE" },
  { name: "PIRES DO RIO", value: "9545", uf: "GO" },
  { name: "CRATO", value: "1385", uf: "CE" },
  { name: "BORACEIA", value: "6245", uf: "SP" },
  { name: "ARAPIRACA", value: "2705", uf: "AL" },
  { name: "CARMO", value: "5823", uf: "RJ" },
  { name: "LUZIANIA", value: "9445", uf: "GO" },
  { name: "JUIZ DE FORA", value: "4733", uf: "MG" },
  { name: "MADRE DE DEUS", value: "3293", uf: "BA" },
  { name: "LAURO DE FREITAS", value: "3685", uf: "BA" },
  { name: "ABAETE", value: "4003", uf: "MG" },
  { name: "CAPAO BONITO", value: "6305", uf: "SP" },
  { name: "IRACEMAPOLIS", value: "6529", uf: "SP" },
  { name: "DIANOPOLIS", value: "9341", uf: "TO" },
  { name: "MACAUBAL", value: "6663", uf: "SP" },
  { name: "SANTO AMARO DA IMPERATRIZ", value: "8309", uf: "SC" },
  { name: "CODAJAS", value: "0227", uf: "AM" },
  { name: "ICARA", value: "8137", uf: "SC" },
  { name: "DOIS CORREGOS", value: "6383", uf: "SP" },
  { name: "FRUTAL", value: "4541", uf: "MG" },
  { name: "PONTE NOVA", value: "5041", uf: "MG" },
  { name: "VAZANTE", value: "5419", uf: "MG" },
  { name: "TRINDADE", value: "9625", uf: "GO" },
  { name: "CORNELIO PROCOPIO", value: "7525", uf: "PR" },
  { name: "GUARAMIRIM", value: "8127", uf: "SC" },
  { name: "IBITINGA", value: "6493", uf: "SP" },
  { name: "PESQUEIRA", value: "2517", uf: "PE" },
  { name: "FONTE BOA", value: "0233", uf: "AM" },
  { name: "MONTE ALTO", value: "6727", uf: "SP" },
  { name: "CARAPICUIBA", value: "6313", uf: "SP" },
  { name: "ITAJUBA", value: "4647", uf: "MG" },
  { name: "TRES RIOS", value: "5919", uf: "RJ" },
  { name: "VITORIA DA CONQUISTA", value: "3965", uf: "BA" },
  { name: "VESPASIANO", value: "5425", uf: "MG" },
  { name: "ITAQUAQUECETUBA", value: "6563", uf: "SP" },
  { name: "GENERAL CARNEIRO", value: "7567", uf: "PR" },
  { name: "VALPARAISO DE GOIAS", value: "1066", uf: "GO" },
  { name: "GUARATINGUETA", value: "6469", uf: "SP" },
  { name: "SERTAOZINHO", value: "7135", uf: "SP" },
  { name: "IRECE", value: "3593", uf: "BA" },
  { name: "NOSSA SENHORA DO SOCORRO", value: "3195", uf: "SE" },
  { name: "BOM JARDIM", value: "5809", uf: "RJ" },
  { name: "SAO SIMAO", value: "7119", uf: "SP" },
  { name: "BOCA DO ACRE", value: "0215", uf: "AM" },
  { name: "SAQUAREMA", value: "5909", uf: "RJ" },
  { name: "BONITO", value: "3265", uf: "BA" },
  { name: "GENERAL SALGADO", value: "6439", uf: "SP" },
  { name: "ANAPOLIS", value: "9221", uf: "GO" },
  { name: "DRACENA", value: "6389", uf: "SP" },
  { name: "BRAGANCA", value: "0433", uf: "PA" },
  { name: "BARUERI", value: "6213", uf: "SP" },
  { name: "PEDERNEIRAS", value: "6835", uf: "SP" },
  { name: "VIRADOURO", value: "7239", uf: "SP" },
  { name: "ARTUR NOGUEIRA", value: "6175", uf: "SP" },
  { name: "IRITUIA", value: "0469", uf: "PA" },
  { name: "MACAPA", value: "0605", uf: "AP" },
  { name: "SAO RAIMUNDO NONATO", value: "1211", uf: "PI" },
  { name: "BALNEARIO DE PICARRAS", value: "8251", uf: "SC" },
  { name: "PICADA CAFE", value: "6021", uf: "RS" },
  { name: "TEIXEIRA", value: "2235", uf: "PB" },
  { name: "CATU", value: "3449", uf: "BA" },
  { name: "ICEM", value: "6497", uf: "SP" },
  { name: "RONDONOPOLIS", value: "9151", uf: "MT" },
  { name: "JALES", value: "6597", uf: "SP" },
  { name: "BEBEDOURO", value: "6221", uf: "SP" },
  { name: "PADRE BERNARDO", value: "9509", uf: "GO" },
  { name: "GRAMADO", value: "8681", uf: "RS" },
  { name: "CURRAL DE DENTRO", value: "0600", uf: "MG" },
  { name: "CATAGUASES", value: "4305", uf: "MG" },
  { name: "MURIAE", value: "4877", uf: "MG" },
  { name: "MIRADOR", value: "7703", uf: "PR" },
  { name: "JUAZEIRO", value: "3669", uf: "BA" },
  { name: "CRICIUMA", value: "8089", uf: "SC" },
  { name: "AIMORES", value: "4021", uf: "MG" },
  { name: "ESTEIO", value: "8651", uf: "RS" },
  { name: "MONTE ALEGRE DE GOIAS", value: "9467", uf: "GO" },
  { name: "JACAREI", value: "6589", uf: "SP" },
  { name: "JUQUIA", value: "6623", uf: "SP" },
  { name: "POCO VERDE", value: "3209", uf: "SE" },
  { name: "PORTEIRINHA", value: "5043", uf: "MG" },
  { name: "PACAJUS", value: "1493", uf: "CE" },
  { name: "TANGARA DA SERRA", value: "9185", uf: "MT" },
  { name: "TOLEDO", value: "7927", uf: "PR" },
  { name: "SAO BENTO DO SUL", value: "8311", uf: "SC" },
  { name: "BERTIOGA", value: "2965", uf: "SP" },
  { name: "VARZEA DA PALMA", value: "5415", uf: "MG" },
  { name: "JAGUARUNA", value: "8173", uf: "SC" },
  { name: "ESPINOSA", value: "4485", uf: "MG" },
  { name: "TAPEJARA", value: "8921", uf: "RS" },
  { name: "PROPRIA", value: "3213", uf: "SE" },
  { name: "POA", value: "6897", uf: "SP" },
  { name: "BORDA DA MATA", value: "4165", uf: "MG" },
  { name: "TIETE", value: "7191", uf: "SP" },
  { name: "SANTA MARGARIDA", value: "5157", uf: "MG" },
  { name: "ANGRA DOS REIS", value: "5801", uf: "RJ" },
  { name: "FRANCISCO BELTRAO", value: "7565", uf: "PR" },
  { name: "PLANALTO", value: "3801", uf: "BA" },
  { name: "TAQUARIVAI", value: "3063", uf: "SP" },
  { name: "WENCESLAU GUIMARAES", value: "3969", uf: "BA" },
  { name: "PINHEIRAL", value: "0778", uf: "RJ" },
  { name: "CEDRAL", value: "6327", uf: "SP" },
  { name: "SETE LAGOAS", value: "5343", uf: "MG" },
  { name: "CAPINZAL", value: "8075", uf: "SC" },
  { name: "LUCIARA", value: "9105", uf: "MT" },
  { name: "ITAJUIPE", value: "3611", uf: "BA" },
  { name: "GARANHUNS", value: "2419", uf: "PE" },
  { name: "BURITIZEIRO", value: "4187", uf: "MG" },
  { name: "BOITUVA", value: "6239", uf: "SP" },
  { name: "IGARAPE-ACU", value: "0463", uf: "PA" },
  { name: "CACHOEIRAS DE MACACU", value: "5815", uf: "RJ" },
  { name: "DUARTINA", value: "6391", uf: "SP" },
  { name: "ANDRADINA", value: "6141", uf: "SP" },
  { name: "SAO SEBASTIAO DO PARAISO", value: "5293", uf: "MG" },
  { name: "PACAEMBU", value: "6799", uf: "SP" },
  { name: "BOTELHOS", value: "4167", uf: "MG" },
  { name: "SAO JOAQUIM DA BARRA", value: "7089", uf: "SP" },
  { name: "MORRO DO CHAPEU", value: "3735", uf: "BA" },
  { name: "INHAPIM", value: "4617", uf: "MG" },
  { name: "CONCHAS", value: "6347", uf: "SP" },
  { name: "SAO MIGUEL DO PASSA QUATRO", value: "9751", uf: "GO" },
  { name: "SANTO ANDRE", value: "7057", uf: "SP" },
  { name: "SAO MIGUEL DAS MATAS", value: "3887", uf: "BA" },
  { name: "OLIVEIRA", value: "4911", uf: "MG" },
  { name: "ARACI", value: "3341", uf: "BA" },
  { name: "CONCEICAO DOS OUROS", value: "4355", uf: "MG" },
  { name: "CARMO DO RIO CLARO", value: "4287", uf: "MG" },
  { name: "TERESINA", value: "1219", uf: "PI" },
  { name: "CRISTALANDIA", value: "9323", uf: "TO" },
  { name: "URUACU", value: "9633", uf: "GO" },
  { name: "DOIS IRMAOS DO TOCANTINS", value: "9345", uf: "TO" },
  { name: "PAULISTA", value: "2513", uf: "PE" },
  { name: "ABREU E LIMA", value: "2631", uf: "PE" },
  { name: "PARAPUA", value: "6821", uf: "SP" },
  { name: "BACABAL", value: "0723", uf: "MA" },
  { name: "POMPEU", value: "5039", uf: "MG" },
  { name: "AGUAS BELAS", value: "2309", uf: "PE" },
  { name: "TUPACIGUARA", value: "5391", uf: "MG" },
  { name: "BOM SUCESSO", value: "4159", uf: "MG" },
  { name: "ITAUCU", value: "9423", uf: "GO" },
  { name: "LORENA", value: "6645", uf: "SP" },
  { name: "REGISTRO", value: "6953", uf: "SP" },
  { name: "ITATIBA", value: "6569", uf: "SP" },
  { name: "RIO BRILHANTE", value: "9143", uf: "MS" },
  { name: "TEIXEIRAS", value: "5369", uf: "MG" },
  { name: "IMBITUBA", value: "8143", uf: "SC" },
  { name: "BOM JARDIM DE MINAS", value: "4149", uf: "MG" },
  { name: "PINDORAMA", value: "6863", uf: "SP" },
  { name: "MASCOTE", value: "3719", uf: "BA" },
  { name: "TAPIRATIBA", value: "7173", uf: "SP" },
  { name: "XANXERE", value: "8383", uf: "SC" },
  { name: "OCARA", value: "1265", uf: "CE" },
  { name: "NOSSA SENHORA DAS DORES", value: "3191", uf: "SE" },
  { name: "MESQUITA", value: "1116", uf: "RJ" },
  { name: "PARA DE MINAS", value: "4941", uf: "MG" },
  { name: "IRANI", value: "8153", uf: "SC" },
  { name: "SANTA MARIA MADALENA", value: "5891", uf: "RJ" },
  { name: "ITABERA", value: "6535", uf: "SP" },
  { name: "CABACEIRAS DO PARAGUACU", value: "3267", uf: "BA" },
  { name: "ITATIAIA", value: "6003", uf: "RJ" },
  { name: "CHARQUEADAS", value: "8693", uf: "RS" },
  { name: "CABO FRIO", value: "5813", uf: "RJ" },
  { name: "AVARE", value: "6189", uf: "SP" },
  { name: "ITABIRITO", value: "4637", uf: "MG" },
  { name: "BOA ESPERANCA DO SUL", value: "6233", uf: "SP" },
  { name: "MIRAI", value: "4843", uf: "MG" },
  { name: "FORMOSO", value: "9363", uf: "GO" },
  { name: "ARAUA", value: "3107", uf: "SE" },
  { name: "CANAA", value: "4233", uf: "MG" },
  { name: "CANTA", value: "0028", uf: "RR" },
  { name: "CAFELANDIA", value: "6277", uf: "SP" },
  { name: "PARATI", value: "5875", uf: "RJ" },
  { name: "COXIM", value: "9065", uf: "MS" },
  { name: "URUPES", value: "7221", uf: "SP" },
  { name: "IGUAPE", value: "6507", uf: "SP" },
  { name: "MARILIA", value: "6681", uf: "SP" },
  { name: "PONTA GROSSA", value: "7777", uf: "PR" },
  { name: "CABO VERDE", value: "4189", uf: "MG" },
  { name: "SANTO ANTONIO DO JACINTO", value: "5205", uf: "MG" },
  { name: "VIRGINOPOLIS", value: "5437", uf: "MG" },
  { name: "ITAPEVA", value: "6549", uf: "SP" },
  { name: "ENTRE RIOS", value: "3509", uf: "BA" },
  { name: "SOLANEA", value: "2221", uf: "PB" },
  { name: "PARAGUACU", value: "4943", uf: "MG" },
  { name: "IBIA", value: "4589", uf: "MG" },
  { name: "BRUMADINHO", value: "4179", uf: "MG" },
  { name: "JAU", value: "6607", uf: "SP" },
  { name: "FARROUPILHA", value: "8655", uf: "RS" },
  { name: "TAIOBEIRAS", value: "5359", uf: "MG" },
  { name: "VOLTA REDONDA", value: "5925", uf: "RJ" },
  { name: "RIBEIRAO DAS NEVES", value: "5091", uf: "MG" },
  { name: "ALVORADA DO NORTE", value: "9215", uf: "GO" },
  { name: "SAO FELIX DO ARAGUAIA", value: "9183", uf: "MT" },
  { name: "BARRINHA", value: "6211", uf: "SP" },
  { name: "SOCORRO", value: "7143", uf: "SP" },
  { name: "BARBACENA", value: "4111", uf: "MG" },
  { name: "FLOR DA SERRA DO SUL", value: "5475", uf: "PR" },
  { name: "VALENCA", value: "5921", uf: "RJ" },
  { name: "CARATINGA", value: "4267", uf: "MG" },
  { name: "CAMPO MAIOR", value: "1043", uf: "PI" },
  { name: "ARACATI", value: "1321", uf: "CE" },
  { name: "BEZERROS", value: "2337", uf: "PE" },
  { name: "MAURILANDIA", value: "9457", uf: "GO" },
  { name: "SANTA CRUZ DO CAPIBARIBE", value: "2549", uf: "PE" },
  { name: "CONCEICAO DO ALMEIDA", value: "3465", uf: "BA" },
  { name: "ITAPEMA", value: "8163", uf: "SC" },
  { name: "PAINS", value: "4929", uf: "MG" },
  { name: "BUENOPOLIS", value: "4183", uf: "MG" },
  { name: "PIMENTA", value: "5009", uf: "MG" },
  { name: "MANDIRITUBA", value: "7679", uf: "PR" },
  { name: "BERURI", value: "0295", uf: "AM" },
  { name: "ITAOCARA", value: "5841", uf: "RJ" },
  { name: "GAMELEIRA DE GOIAS", value: "1072", uf: "GO" },
  { name: "CAXIAS DO SUL", value: "8599", uf: "RS" },
  { name: "GOIAS", value: "9377", uf: "GO" },
  { name: "SUMARE", value: "7149", uf: "SP" },
  { name: "CLAUDIO", value: "4331", uf: "MG" },
  { name: "MANGA", value: "4785", uf: "MG" },
  { name: "MONTE MOR", value: "6737", uf: "SP" },
  { name: "TIJUCAS DO SUL", value: "7925", uf: "PR" },
  { name: "ARAPORA", value: "2903", uf: "MG" },
  { name: "MOSTARDAS", value: "8751", uf: "RS" },
  { name: "ALVORADA DE MINAS", value: "4047", uf: "MG" },
  { name: "MONTE SANTO", value: "3731", uf: "BA" },
  { name: "CASIMIRO DE ABREU", value: "5825", uf: "RJ" },
  { name: "DIAS D'AVILA", value: "3087", uf: "BA" },
  { name: "TAGUATINGA", value: "9615", uf: "TO" },
  { name: "PIRAJU", value: "6877", uf: "SP" },
  { name: "ITAPURA", value: "6561", uf: "SP" },
  { name: "SAPIRANGA", value: "8899", uf: "RS" },
  { name: "SAO JOAO DO MANHUACU", value: "2677", uf: "MG" },
  { name: "CAMPO DO MEIO", value: "4225", uf: "MG" },
  { name: "PORTO RICO", value: "7783", uf: "PR" },
  { name: "SANTA RITA DO SAPUCAI", value: "5191", uf: "MG" },
  { name: "BICAS", value: "4137", uf: "MG" },
  { name: "ESTRELA", value: "8653", uf: "RS" },
  { name: "LABREA", value: "0251", uf: "AM" },
  { name: "RIO POMBA", value: "5115", uf: "MG" },
  { name: "SANTA HELENA", value: "7971", uf: "PR" },
  { name: "ITAGUACU", value: "5653", uf: "ES" },
  { name: "ITIRAPINA", value: "6573", uf: "SP" },
  { name: "SAO JOAO NEPOMUCENO", value: "5257", uf: "MG" },
  { name: "GUACUI", value: "5645", uf: "ES" },
  { name: "PARNAMIRIM", value: "1779", uf: "RN" },
  { name: "VARZEA GRANDE", value: "9167", uf: "MT" },
  { name: "JACUPIRANGA", value: "6593", uf: "SP" },
  { name: "SERRA", value: "5699", uf: "ES" },
  { name: "COLATINA", value: "5629", uf: "ES" },
  { name: "PITANGUEIRAS", value: "6891", uf: "SP" },
  { name: "CAETE", value: "4199", uf: "MG" },
  { name: "TUPA", value: "7201", uf: "SP" },
  { name: "GUIDOVAL", value: "4575", uf: "MG" },
  { name: "SAO CAETANO DE ODIVELAS", value: "0541", uf: "PA" },
  { name: "PEDRO LEOPOLDO", value: "4985", uf: "MG" },
  { name: "VACARIA", value: "8953", uf: "RS" },
  { name: "CORRENTE", value: "1057", uf: "PI" },
  { name: "CASCAVEL", value: "7493", uf: "PR" },
  { name: "MONTENEGRO", value: "8749", uf: "RS" },
  { name: "MOIPORA", value: "9465", uf: "GO" },
  { name: "ELDORADO", value: "6397", uf: "SP" },
  { name: "MUCURI", value: "3741", uf: "BA" },
  { name: "BURI", value: "6261", uf: "SP" },
  { name: "PARANAIBA", value: "9125", uf: "MS" },
  { name: "ALEGRE", value: "5603", uf: "ES" },
  { name: "HEITORAI", value: "9387", uf: "GO" },
  { name: "CARIACICA", value: "5625", uf: "ES" },
  { name: "BRITANIA", value: "9275", uf: "GO" },
  { name: "SANTOPOLIS DO AGUAPEI", value: "7069", uf: "SP" },
  { name: "PORTO CALVO", value: "2845", uf: "AL" },
  { name: "CRUZEIRO DO SUL", value: "7531", uf: "PR" },
  { name: "ITAPAJE", value: "1427", uf: "CE" },
  { name: "TARUMIRIM", value: "5367", uf: "MG" },
  { name: "RECREIO", value: "5081", uf: "MG" },
  { name: "AMPERE", value: "7417", uf: "PR" },
  { name: "SETE DE SETEMBRO", value: "1014", uf: "RS" },
  { name: "CLARO DOS POCOES", value: "4329", uf: "MG" },
  { name: "SANTA TEREZA DE GOIAS", value: "9587", uf: "GO" },
  { name: "UBA", value: "5397", uf: "MG" },
  { name: "BRASILANDIA DO SUL", value: "5521", uf: "PR" },
  { name: "AMERICO BRASILIENSE", value: "6133", uf: "SP" },
  { name: "CAPIVARI", value: "6309", uf: "SP" },
  { name: "VICOSA", value: "5427", uf: "MG" },
  { name: "APIAI", value: "6153", uf: "SP" },
  { name: "VISCONDE DO RIO BRANCO", value: "5441", uf: "MG" },
  { name: "BRUMADO", value: "3391", uf: "BA" },
  { name: "MIRASSOL", value: "6707", uf: "SP" },
  { name: "SANTANA DA BOA VISTA", value: "8843", uf: "RS" },
  { name: "URUGUAIANA", value: "8951", uf: "RS" },
  { name: "CRUZEIRO DO SUL", value: "0107", uf: "AC" },
  { name: "GRAO MOGOL", value: "4555", uf: "MG" },
  { name: "APARECIDA DO TABOADO", value: "9019", uf: "MS" },
  { name: "SAO GABRIEL", value: "8869", uf: "RS" },
  { name: "ITUVERAVA", value: "6583", uf: "SP" },
  { name: "PEREIRA BARRETO", value: "6849", uf: "SP" },
  { name: "NOVA ESPERANCA", value: "7721", uf: "PR" },
  { name: "SAO MATEUS", value: "5697", uf: "ES" },
  { name: "JUSSARA", value: "9439", uf: "GO" },
  { name: "SANTA TERESA", value: "5691", uf: "ES" },
  { name: "GLICERIO", value: "6443", uf: "SP" },
  { name: "MATO LEITAO", value: "6031", uf: "RS" },
  { name: "PARIPIRANGA", value: "3777", uf: "BA" },
  { name: "CARUARU", value: "2381", uf: "PE" },
  { name: "ITAPECERICA DA SERRA", value: "6545", uf: "SP" },
  { name: "MACHADO", value: "4779", uf: "MG" },
  { name: "POUSO ALEGRE", value: "5049", uf: "MG" },
  { name: "CAIABU", value: "6279", uf: "SP" },
  { name: "NOVA ALIANCA", value: "6757", uf: "SP" },
  { name: "NOVA GRANADA", value: "6761", uf: "SP" },
  { name: "BOM DESPACHO", value: "4147", uf: "MG" },
  { name: "APORA", value: "3337", uf: "BA" },
  { name: "TEIXEIRA DE FREITAS", value: "3993", uf: "BA" },
  { name: "GRAJAU", value: "0793", uf: "MA" },
  { name: "PONGAI", value: "6903", uf: "SP" },
  { name: "SAO CAETANO DO SUL", value: "7077", uf: "SP" },
  { name: "SAO PEDRO DO SUACUI", value: "5281", uf: "MG" },
  { name: "PINDAMONHANGABA", value: "6861", uf: "SP" },
  { name: "CAPITAO LEONIDAS MARQUES", value: "7489", uf: "PR" },
  { name: "PIUMA", value: "5683", uf: "ES" },
  { name: "NOVA ARACA", value: "8759", uf: "RS" },
  { name: "CIRIACO", value: "8607", uf: "RS" },
  { name: "CAMBARA", value: "7469", uf: "PR" },
  { name: "LUCELIA", value: "6649", uf: "SP" },
  { name: "BELFORD ROXO", value: "2909", uf: "RJ" },
  { name: "FERNANDOPOLIS", value: "6411", uf: "SP" },
  { name: "ARAGUATINS", value: "9243", uf: "TO" },
  { name: "SAO JOSE DO RIO PARDO", value: "7095", uf: "SP" },
  { name: "CONCEICAO DO JACUIPE", value: "3469", uf: "BA" },
  { name: "BARRA DO GARCAS", value: "9035", uf: "MT" },
  { name: "IGARAPAVA", value: "6503", uf: "SP" },
  { name: "SIGEFREDO PACHECO", value: "1379", uf: "PI" },
  { name: "COSMORAMA", value: "6359", uf: "SP" },
  { name: "CAPINOPOLIS", value: "4251", uf: "MG" },
  { name: "SAO LOURENCO DO SUL", value: "8879", uf: "RS" },
  { name: "TRES CORACOES", value: "5385", uf: "MG" },
  { name: "CARIRI DO TOCANTINS", value: "0327", uf: "TO" },
  { name: "TAIACU", value: "7163", uf: "SP" },
  { name: "ARACRUZ", value: "5611", uf: "ES" },
  { name: "SAO LOURENCO DA MATA", value: "2573", uf: "PE" },
  { name: "CERQUILHO", value: "6331", uf: "SP" },
  { name: "ITUIUTABA", value: "4683", uf: "MG" },
  { name: "CURRAL DE CIMA", value: "0476", uf: "PB" },
  { name: "BAYEUX", value: "1937", uf: "PB" },
  { name: "SERRA AZUL DE MINAS", value: "5329", uf: "MG" },
  { name: "BANDEIRA", value: "4103", uf: "MG" },
  { name: "SANTO ANTONIO DO ITAMBE", value: "5203", uf: "MG" },
  { name: "ITAI", value: "6537", uf: "SP" },
  { name: "NATAL", value: "1761", uf: "RN" },
  { name: "ITABERABA", value: "3595", uf: "BA" },
  { name: "PELOTAS", value: "8791", uf: "RS" },
  { name: "CALDAS", value: "4205", uf: "MG" },
  { name: "VITORIA DO MEARIM", value: "0951", uf: "MA" },
  { name: "UBIRATA", value: "7933", uf: "PR" },
  { name: "CAMBUI", value: "4211", uf: "MG" },
  { name: "BANDEIRANTES", value: "7445", uf: "PR" },
  { name: "BAIXO GUANDU", value: "5615", uf: "ES" },
  { name: "ALFREDO CHAVES", value: "5605", uf: "ES" },
  { name: "MARECHAL CANDIDO RONDON", value: "7683", uf: "PR" },
  { name: "PORTAO", value: "8799", uf: "RS" },
  { name: "ALTAMIRA", value: "0411", uf: "PA" },
  { name: "ERECHIM", value: "8637", uf: "RS" },
  { name: "PIRASSUNUNGA", value: "6887", uf: "SP" },
  { name: "BOM RETIRO DO SUL", value: "8547", uf: "RS" },
  { name: "SAO JOSE", value: "8327", uf: "SC" },
  { name: "SAO JERONIMO", value: "8871", uf: "RS" },
  { name: "LARANJAL PAULISTA", value: "6629", uf: "SP" },
  { name: "SANTA VITORIA", value: "5195", uf: "MG" },
  { name: "TERRA NOVA", value: "3933", uf: "BA" },
  { name: "PANAMBI", value: "8781", uf: "RS" },
  { name: "SAO FRANCISCO DE GOIAS", value: "9593", uf: "GO" },
  { name: "SAO GONCALO DO PARA", value: "5235", uf: "MG" },
  { name: "ICHU", value: "3567", uf: "BA" },
  { name: "ITAPEMIRIM", value: "5655", uf: "ES" },
  { name: "IRAPUA", value: "6531", uf: "SP" },
  { name: "GUAIBA", value: "8685", uf: "RS" },
  { name: "UCHOA", value: "7213", uf: "SP" },
  { name: "UNAI", value: "5407", uf: "MG" },
  { name: "PARACATU", value: "4939", uf: "MG" },
  { name: "SANTO ANTONIO DA PLATINA", value: "7859", uf: "PR" },
  { name: "BOCAINA DE MINAS", value: "4143", uf: "MG" },
  { name: "SANTA FE DO SUL", value: "7033", uf: "SP" },
  { name: "CAMPANHA", value: "4217", uf: "MG" },
  { name: "CONDE", value: "3471", uf: "BA" },
  { name: "CAREIRO DA VARZEA", value: "0965", uf: "AM" },
  { name: "BIGUACU", value: "8045", uf: "SC" },
  { name: "NOVA PETROPOLIS", value: "8767", uf: "RS" },
  { name: "GUAREI", value: "6471", uf: "SP" },
  { name: "OUROESTE", value: "0808", uf: "SP" },
  { name: "PEDRALVA", value: "4981", uf: "MG" },
  { name: "PASSOS", value: "4957", uf: "MG" },
  { name: "JOAO LISBOA", value: "0809", uf: "MA" },
  { name: "MONTE SANTO DE MINAS", value: "4863", uf: "MG" },
  { name: "RIO GRANDE", value: "8815", uf: "RS" },
  { name: "BRASILANDIA", value: "9045", uf: "MS" },
  { name: "TRES PONTAS", value: "5387", uf: "MG" },
  { name: "AGUDOS", value: "6113", uf: "SP" },
  { name: "SOURE", value: "0557", uf: "PA" },
  { name: "CAXAMBU", value: "4309", uf: "MG" },
  { name: "CARMO DE MINAS", value: "4281", uf: "MG" },
  { name: "JOSE BONIFACIO", value: "6615", uf: "SP" },
  { name: "POCOS DE CALDAS", value: "5035", uf: "MG" },
  { name: "NOVA VENECIA", value: "5677", uf: "ES" },
  { name: "CABECEIRAS DO PIAUI", value: "1299", uf: "PI" },
  { name: "ESTANCIA VELHA", value: "8649", uf: "RS" },
  { name: "BELEM DE MARIA", value: "2329", uf: "PE" },
  { name: "CONCEICAO DE IPANEMA", value: "4347", uf: "MG" },
  { name: "GUARAREMA", value: "6467", uf: "SP" },
  { name: "IBOTIRAMA", value: "3565", uf: "BA" },
  { name: "ITAPEVI", value: "6551", uf: "SP" },
  { name: "CAMPO FLORIDO", value: "4227", uf: "MG" },
  { name: "CASTELO", value: "5627", uf: "ES" },
  { name: "EXTREMA", value: "4501", uf: "MG" },
  { name: "XANGRI-LA", value: "5785", uf: "RS" },
  { name: "BOM JESUS DO NORTE", value: "5621", uf: "ES" },
  { name: "CERES", value: "9307", uf: "GO" },
  { name: "ITAPARICA", value: "3623", uf: "BA" },
  { name: "FRANCO DA ROCHA", value: "6429", uf: "SP" },
  { name: "ITAGUACU DA BAHIA", value: "3281", uf: "BA" },
  { name: "SERAFINA CORREA", value: "8909", uf: "RS" },
  { name: "JABOATAO DOS GUARARAPES", value: "2457", uf: "PE" },
  { name: "LIMOEIRO DO NORTE", value: "1453", uf: "CE" },
  { name: "ALFENAS", value: "4031", uf: "MG" },
  { name: "BANDEIRA DO SUL", value: "4105", uf: "MG" },
  { name: "TRAMANDAI", value: "8935", uf: "RS" },
  { name: "PORTO REAL DO COLEGIO", value: "2849", uf: "AL" },
  { name: "CACULE", value: "3399", uf: "BA" },
  { name: "LARANJEIRAS DO SUL", value: "7659", uf: "PR" },
  { name: "MANTENA", value: "4791", uf: "MG" },
  { name: "CAPAO DA CANOA", value: "8915", uf: "RS" },
  { name: "PORTO FRANCO", value: "0877", uf: "MA" },
  { name: "TERESINA DE GOIAS", value: "9759", uf: "GO" },
  { name: "ONDA VERDE", value: "6781", uf: "SP" },
  { name: "JANAUBA", value: "4701", uf: "MG" },
  { name: "GOUVEIA", value: "4551", uf: "MG" },
  { name: "CAMPO BELO", value: "4223", uf: "MG" },
  { name: "JANDIRA", value: "6601", uf: "SP" },
  { name: "SAO MIGUEL DO OESTE", value: "8339", uf: "SC" },
  { name: "TRES MARIAS", value: "4115", uf: "MG" },
  { name: "INHUMAS", value: "9395", uf: "GO" },
  { name: "ITACAMBIRA", value: "4639", uf: "MG" },
  { name: "BATAGUASSU", value: "9037", uf: "MS" },
  { name: "ITAPIRA", value: "6553", uf: "SP" },
  { name: "LAJEADO", value: "8729", uf: "RS" },
  { name: "DOIS VIZINHOS", value: "7541", uf: "PR" },
  { name: "ESTRELA DE ALAGOAS", value: "2643", uf: "AL" },
  { name: "PARAISO DO TOCANTINS", value: "9519", uf: "TO" },
  { name: "APARECIDA DE GOIANIA", value: "9227", uf: "GO" },
  { name: "IPANEMA", value: "4623", uf: "MG" },
  { name: "GUARAI", value: "9627", uf: "TO" },
  { name: "LAGOA VERMELHA", value: "8727", uf: "RS" },
  { name: "TIMOTEO", value: "5373", uf: "MG" },
  { name: "BARIRI", value: "6203", uf: "SP" },
  { name: "CACERES", value: "9047", uf: "MT" },
  { name: "CAMACARI", value: "3413", uf: "BA" },
  { name: "AURIFLAMA", value: "6183", uf: "SP" },
  { name: "PIRANGA", value: "5015", uf: "MG" },
  { name: "CANELINHA", value: "8071", uf: "SC" },
  { name: "HERVAL D'OESTE", value: "8131", uf: "SC" },
  { name: "SAO FRANCISCO", value: "5221", uf: "MG" },
  { name: "CUITE DE MAMANGUAPE", value: "0474", uf: "PB" },
  { name: "SANTO ANTONIO DE GOIAS", value: "0053", uf: "GO" },
  { name: "NOVA LIMA", value: "4895", uf: "MG" },
  { name: "MARIANA", value: "4799", uf: "MG" },
  { name: "IBIRITE", value: "4595", uf: "MG" },
  { name: "FRONTEIRA", value: "4539", uf: "MG" },
  { name: "LAGO DA PEDRA", value: "0813", uf: "MA" },
  { name: "CANELA", value: "8585", uf: "RS" },
  { name: "TOME-ACU", value: "0559", uf: "PA" },
  { name: "COSMOPOLIS", value: "6357", uf: "SP" },
  { name: "ARVOREZINHA", value: "8527", uf: "RS" },
  { name: "SANTA MARIA DE ITABIRA", value: "5159", uf: "MG" },
  { name: "CACHOEIRA DO SUL", value: "8559", uf: "RS" },
  { name: "TORRINHA", value: "7195", uf: "SP" },
  { name: "SANTANA", value: "3865", uf: "BA" },
  { name: "UBATUBA", value: "7209", uf: "SP" },
  { name: "SAO DOMINGOS DO PRATA", value: "5219", uf: "MG" },
  { name: "SANTA ROSA DE VITERBO", value: "7053", uf: "SP" },
  { name: "ANTONIO CARLOS", value: "8023", uf: "SC" },
  { name: "IRAI DE MINAS", value: "4631", uf: "MG" },
  { name: "CUNHA PORA", value: "8091", uf: "SC" },
  { name: "LAGUNA", value: "8185", uf: "SC" },
  { name: "MORRO DA FUMACA", value: "8219", uf: "SC" },
  { name: "RIBEIRAO BRANCO", value: "6961", uf: "SP" },
  { name: "HOLAMBRA", value: "2953", uf: "SP" },
  { name: "SAO JOAO DO TRIUNFO", value: "7877", uf: "PR" },
  { name: "COLOMBIA", value: "6343", uf: "SP" },
  { name: "ERVAL GRANDE", value: "8641", uf: "RS" },
  { name: "MARMELOPOLIS", value: "4807", uf: "MG" },
  { name: "VIANA", value: "0949", uf: "MA" },
  { name: "JACUTINGA", value: "4697", uf: "MG" },
  { name: "SANTANA DA VARGEM", value: "5165", uf: "MG" },
  { name: "SUZANO", value: "7151", uf: "SP" },
  { name: "SANTA MARIA DO OESTE", value: "5505", uf: "PR" },
  { name: "MEDEIROS NETO", value: "3723", uf: "BA" },
  { name: "TIMON", value: "0937", uf: "MA" },
  { name: "PORECATU", value: "7779", uf: "PR" },
  { name: "SENGES", value: "7901", uf: "PR" },
  { name: "IBIRA", value: "6489", uf: "SP" },
  { name: "CORONEL VIVIDA", value: "7527", uf: "PR" },
  { name: "RIO DAS PEDRAS", value: "6981", uf: "SP" },
  { name: "NIPOA", value: "6755", uf: "SP" },
  { name: "SANTO ANTONIO DA PATRULHA", value: "8855", uf: "RS" },
  { name: "SAO SEBASTIAO DA GRAMA", value: "7117", uf: "SP" },
  { name: "CACIMBAS", value: "0460", uf: "PB" },
  { name: "MAZAGAO", value: "0607", uf: "AP" },
  { name: "AVAI", value: "6185", uf: "SP" },
  { name: "GUARAPUAVA", value: "7583", uf: "PR" },
  { name: "EUNAPOLIS", value: "3117", uf: "BA" },
  { name: "RIO DO SUL", value: "8291", uf: "SC" },
  { name: "MATRIZ DE CAMARAGIBE", value: "2801", uf: "AL" },
  { name: "PARELHAS", value: "1777", uf: "RN" },
  { name: "BARRA DO MENDES", value: "3359", uf: "BA" },
  { name: "TELEMACO BORBA", value: "7915", uf: "PR" },
  { name: "NOVA OLINDA", value: "1485", uf: "CE" },
  { name: "GUAPIARA", value: "6453", uf: "SP" },
  { name: "TROMBUDO CENTRAL", value: "8365", uf: "SC" },
  { name: "ROSARIO DO CATETE", value: "3221", uf: "SE" },
  { name: "RIO NEGRINHO", value: "8295", uf: "SC" },
  { name: "CACHOEIRINHA", value: "8561", uf: "RS" },
  { name: "ASSIS CHATEAUBRIAND", value: "7953", uf: "PR" },
  { name: "PARANAPANEMA", value: "6817", uf: "SP" },
  { name: "SANTO EXPEDITO DO SUL", value: "5977", uf: "RS" },
  { name: "ITABORAI", value: "5837", uf: "RJ" },
  { name: "CAICARA DO NORTE", value: "0414", uf: "RN" },
  { name: "LAJE DO MURIAE", value: "5845", uf: "RJ" },
  { name: "SANTA BARBARA D'OESTE", value: "7017", uf: "SP" },
  { name: "NOVA CRIXAS", value: "9653", uf: "GO" },
  { name: "PARAISO", value: "6815", uf: "SP" },
  { name: "SACRAMENTO", value: "5137", uf: "MG" },
  { name: "SABARA", value: "5133", uf: "MG" },
  { name: "BRASILIA DE MINAS", value: "4171", uf: "MG" },
  { name: "FORTALEZA DE MINAS", value: "4525", uf: "MG" },
  { name: "NOVA OLINDA", value: "9663", uf: "TO" },
  { name: "NOVO HORIZONTE", value: "6771", uf: "SP" },
  { name: "TRIZIDELA DO VALE", value: "0258", uf: "MA" },
  { name: "ALMENARA", value: "4033", uf: "MG" },
  { name: "GUANHAES", value: "4559", uf: "MG" },
  { name: "LENCOIS PAULISTA", value: "6637", uf: "SP" },
  { name: "VILHENA", value: "0013", uf: "RO" },
  { name: "CANAPOLIS", value: "4235", uf: "MG" },
  { name: "GRAVATA", value: "2427", uf: "PE" },
  { name: "ALENQUER", value: "0407", uf: "PA" },
  { name: "CORDEIRO", value: "5829", uf: "RJ" },
  { name: "SAO JOSE DA BELA VISTA", value: "7091", uf: "SP" },
  { name: "PEQUI", value: "4991", uf: "MG" },
  { name: "PORTO FERREIRA", value: "6915", uf: "SP" },
  { name: "VALINHOS", value: "7225", uf: "SP" },
  { name: "CASCA", value: "8595", uf: "RS" },
  { name: "VARZEA PAULISTA", value: "7233", uf: "SP" },
  { name: "BURITI DOS LOPES", value: "1039", uf: "PI" },
  { name: "ARAGUARI", value: "4069", uf: "MG" },
  { name: "FERRAZ DE VASCONCELOS", value: "6415", uf: "SP" },
  { name: "SAPUCAIA", value: "5907", uf: "RJ" },
  { name: "ARARENDA", value: "0989", uf: "CE" },
  { name: "FREDERICO WESTPHALEN", value: "8667", uf: "RS" },
  { name: "CAMPO LIMPO PAULISTA", value: "6293", uf: "SP" },
  { name: "OLINDA", value: "2491", uf: "PE" },
  { name: "ACAILANDIA", value: "0961", uf: "MA" },
  { name: "PORTO UNIAO", value: "8267", uf: "SC" },
  { name: "RESENDE", value: "5883", uf: "RJ" },
  { name: "CONCEICAO DO COITE", value: "3467", uf: "BA" },
  { name: "TUBARAO", value: "8367", uf: "SC" },
  { name: "NEPOMUCENO", value: "4891", uf: "MG" },
  { name: "CERRO BRANCO", value: "8439", uf: "RS" },
  { name: "BARRA MANSA", value: "5807", uf: "RJ" },
  { name: "IRAPURU", value: "6533", uf: "SP" },
  { name: "FLORES DA CUNHA", value: "8661", uf: "RS" },
  { name: "SANTA LUZIA", value: "5155", uf: "MG" },
  { name: "RIO BRANCO", value: "0139", uf: "AC" },
  { name: "BONITO", value: "9043", uf: "MS" },
  { name: "SANTO ANASTACIO", value: "7055", uf: "SP" },
  { name: "MATEUS LEME", value: "4813", uf: "MG" },
  { name: "NOVA ITARANA", value: "3757", uf: "BA" },
  { name: "VICOSA", value: "2887", uf: "AL" },
  { name: "GARCA", value: "6435", uf: "SP" },
  { name: "DONA EUZEBIA", value: "4457", uf: "MG" },
  { name: "ITANHANGA", value: "1186", uf: "MT" },
  { name: "VENDA NOVA DO IMIGRANTE", value: "5729", uf: "ES" },
  { name: "SERITINGA", value: "5327", uf: "MG" },
  { name: "EMBU-GUACU", value: "6403", uf: "SP" },
  { name: "TATUI", value: "7181", uf: "SP" },
  { name: "BARAO DO TRIUNFO", value: "5771", uf: "RS" },
  { name: "PORTO NACIONAL", value: "9559", uf: "TO" },
  { name: "ITAITINGA", value: "0991", uf: "CE" },
  { name: "GOIANAPOLIS", value: "9367", uf: "GO" },
  { name: "GUARACI", value: "6459", uf: "SP" },
  { name: "AGUAI", value: "6105", uf: "SP" },
  { name: "PIRAPOZINHO", value: "6885", uf: "SP" },
  { name: "MANTENOPOLIS", value: "5665", uf: "ES" },
  { name: "ITAPOLIS", value: "6555", uf: "SP" },
  { name: "CANINDE DE SAO FRANCISCO", value: "3123", uf: "SE" },
  { name: "CACAPAVA", value: "6271", uf: "SP" },
  { name: "SANTA GERTRUDES", value: "7035", uf: "SP" },
  { name: "SAO GONCALO DO AMARANTE", value: "1837", uf: "RN" },
  { name: "NOVA SANTA ROSA", value: "7979", uf: "PR" },
  { name: "LAGOA SECA", value: "2067", uf: "PB" },
  { name: "QUIXERAMOBIM", value: "1529", uf: "CE" },
  { name: "RESTINGA SECA", value: "8813", uf: "RS" },
  { name: "SAO MATEUS DO MARANHAO", value: "0923", uf: "MA" },
  { name: "MORRO AGUDO", value: "6739", uf: "SP" },
  { name: "CAMPO NOVO DO PARECIS", value: "9777", uf: "MT" },
  { name: "ANICUNS", value: "9225", uf: "GO" },
  { name: "GUAIMBE", value: "6447", uf: "SP" },
  { name: "GARUVA", value: "8115", uf: "SC" },
  { name: "MANHUMIRIM", value: "4789", uf: "MG" },
  { name: "IGUATU", value: "1411", uf: "CE" },
  { name: "RIO BONITO DO IGUACU", value: "5481", uf: "PR" },
  { name: "CANTANHEDE", value: "0751", uf: "MA" },
  { name: "MANGARATIBA", value: "5851", uf: "RJ" },
  { name: "ARUJA", value: "6177", uf: "SP" },
  { name: "SANTO ANTONIO DO PINHAL", value: "7065", uf: "SP" },
  { name: "SALTO DE PIRAPORA", value: "7007", uf: "SP" },
  { name: "VARZEA", value: "1891", uf: "RN" },
  { name: "BARRA D'ALCANTARA", value: "0272", uf: "PI" },
  { name: "GUAPORE", value: "8687", uf: "RS" },
  { name: "CHAVANTES", value: "6337", uf: "SP" },
  { name: "PIRANGI", value: "6881", uf: "SP" },
  { name: "NOVA ERA", value: "4893", uf: "MG" },
  { name: "VISTA ALEGRE DO ALTO", value: "7241", uf: "SP" },
  { name: "SALTO DO ITARARE", value: "7835", uf: "PR" },
  { name: "TEODORO SAMPAIO", value: "7187", uf: "SP" },
  { name: "ANANINDEUA", value: "0415", uf: "PA" },
  { name: "CAJURU", value: "6289", uf: "SP" },
  { name: "PRESIDENTE MEDICI", value: "0214", uf: "MA" },
  { name: "GOVERNADOR ARCHER", value: "0787", uf: "MA" },
  { name: "PORTO SEGURO", value: "3807", uf: "BA" },
  { name: "AGUA NOVA", value: "1607", uf: "RN" },
  { name: "TURIACU", value: "0941", uf: "MA" },
  { name: "MIRINZAL", value: "0835", uf: "MA" },
  { name: "CAMBUQUIRA", value: "4213", uf: "MG" },
  { name: "BOA ESPERANCA", value: "4141", uf: "MG" },
  { name: "PINHEIRO MACHADO", value: "8793", uf: "RS" },
  { name: "SANTO ANTONIO", value: "1829", uf: "RN" },
  { name: "BRUSQUE", value: "8055", uf: "SC" },
  { name: "EIRUNEPE", value: "0229", uf: "AM" },
  { name: "GAURAMA", value: "8671", uf: "RS" },
  { name: "CARMO DA CACHOEIRA", value: "4277", uf: "MG" },
  { name: "TANGARA", value: "1877", uf: "RN" },
  { name: "DELFIM MOREIRA", value: "4421", uf: "MG" },
  { name: "MUZAMBINHO", value: "4881", uf: "MG" },
  { name: "BERNARDO SAYAO", value: "9695", uf: "TO" },
  { name: "ITAPUI", value: "6559", uf: "SP" },
  { name: "JAPERI", value: "2913", uf: "RJ" },
  { name: "GOIANIRA", value: "9375", uf: "GO" },
  { name: "CUBATAO", value: "6371", uf: "SP" },
  { name: "DIVINO", value: "4439", uf: "MG" },
  { name: "ITAPERUNA", value: "5843", uf: "RJ" },
  { name: "ALTO TAQUARI", value: "9911", uf: "MT" },
  { name: "SAO SEPE", value: "8895", uf: "RS" },
  { name: "ROLANTE", value: "8823", uf: "RS" },
  { name: "CANDEIAS", value: "4239", uf: "MG" },
  { name: "ORLANDIA", value: "6787", uf: "SP" },
  { name: "LOGRADOURO", value: "0482", uf: "PB" },
  { name: "ARINOS", value: "4089", uf: "MG" },
  { name: "PEDREIRAS", value: "0861", uf: "MA" },
  { name: "MAURITI", value: "1463", uf: "CE" },
  { name: "XAPURI", value: "0149", uf: "AC" },
  { name: "CONSELHEIRO PENA", value: "4367", uf: "MG" },
  { name: "ANDRE DA ROCHA", value: "8491", uf: "RS" },
  { name: "SALES OLIVEIRA", value: "6999", uf: "SP" },
  { name: "PIMENTA BUENO", value: "0011", uf: "RO" },
  { name: "LOANDA", value: "7663", uf: "PR" },
  { name: "JANUARIA", value: "4703", uf: "MG" },
  { name: "ESPIRITO SANTO DO PINHAL", value: "6865", uf: "SP" },
  { name: "RIO ACIMA", value: "5095", uf: "MG" },
  { name: "CHARQUEADA", value: "6335", uf: "SP" },
  { name: "GUARATUBA", value: "7587", uf: "PR" },
  { name: "MEDIANEIRA", value: "7701", uf: "PR" },
  { name: "ITURAMA", value: "4687", uf: "MG" },
  { name: "GUARA", value: "6455", uf: "SP" },
  { name: "DATAS", value: "4419", uf: "MG" },
  { name: "SANTA RITA DO PASSA QUATRO", value: "7051", uf: "SP" },
  { name: "BAEPENDI", value: "4097", uf: "MG" },
  { name: "HUMBERTO DE CAMPOS", value: "0797", uf: "MA" },
  { name: "MARTINOPOLIS", value: "6685", uf: "SP" },
  { name: "HORIZONTE", value: "1253", uf: "CE" },
  { name: "INUBIA PAULISTA", value: "6517", uf: "SP" },
  { name: "JAGUAQUARA", value: "3653", uf: "BA" },
  { name: "IBIRUBA", value: "8701", uf: "RS" },
  { name: "SANTO ANTONIO DO AMPARO", value: "5197", uf: "MG" },
  { name: "SAO JOSE DO ALEGRE", value: "5263", uf: "MG" },
  { name: "BURITAMA", value: "6263", uf: "SP" },
  { name: "ALMEIRIM", value: "0409", uf: "PA" },
  { name: "LAGAMAR", value: "4741", uf: "MG" },
  { name: "PONTA PORA", value: "9131", uf: "MS" },
  { name: "APUCARANA", value: "7425", uf: "PR" },
  { name: "OLIMPIA", value: "6779", uf: "SP" },
  { name: "GUARANESIA", value: "4565", uf: "MG" },
  { name: "ARAPONGAS", value: "7427", uf: "PR" },
  { name: "SAO BENTO", value: "0907", uf: "MA" },
  { name: "AGUAS DE SAO PEDRO", value: "6111", uf: "SP" },
  { name: "SAO BENEDITO DO RIO PRETO", value: "0905", uf: "MA" },
  { name: "CONCORDIA", value: "8083", uf: "SC" },
  { name: "CARIRA", value: "3127", uf: "SE" },
  { name: "PRESIDENTE BERNARDES", value: "6925", uf: "SP" },
  { name: "ITAPURANGA", value: "9419", uf: "GO" },
  { name: "ILICINEA", value: "4609", uf: "MG" },
  { name: "TUCURUI", value: "0561", uf: "PA" },
  { name: "ALTO PARNAIBA", value: "0709", uf: "MA" },
  { name: "ITAU DE MINAS", value: "5731", uf: "MG" },
  { name: "CONGONHAS", value: "4359", uf: "MG" },
  { name: "SAO TOMAS DE AQUINO", value: "5301", uf: "MG" },
  { name: "TACURU", value: "9815", uf: "MS" },
  { name: "ITAPECURU MIRIM", value: "0807", uf: "MA" },
  { name: "PASSA QUATRO", value: "4951", uf: "MG" },
  { name: "DOURADOS", value: "9073", uf: "MS" },
  { name: "PALMEIRA DOS INDIOS", value: "2825", uf: "AL" },
  { name: "MARECHAL DEODORO", value: "2793", uf: "AL" },
  { name: "CAMAPUA", value: "9049", uf: "MS" },
  { name: "PERDIGAO", value: "4993", uf: "MG" },
  { name: "TUFILANDIA", value: "0260", uf: "MA" },
  { name: "GOIANA", value: "2423", uf: "PE" },
  { name: "CHUI", value: "0968", uf: "RS" },
  { name: "PALMEIRA", value: "7735", uf: "PR" },
  { name: "TRES LAGOAS", value: "9165", uf: "MS" },
  { name: "ITAPIRAPUA", value: "9415", uf: "GO" },
  { name: "SAO MIGUEL DAS MISSOES", value: "7341", uf: "RS" },
  { name: "ALVARES MACHADO", value: "6125", uf: "SP" },
  { name: "IGARAPE GRANDE", value: "0801", uf: "MA" },
  { name: "COSTA RICA", value: "9803", uf: "MS" },
  { name: "JACUNDA", value: "0475", uf: "PA" },
  { name: "VIEIRAS", value: "5429", uf: "MG" },
  { name: "LAGOA SANTA", value: "4751", uf: "MG" },
  { name: "SAO JOAQUIM", value: "8325", uf: "SC" },
  { name: "LAGOA GRANDE", value: "2905", uf: "MG" },
  { name: "BARAO DE COCAIS", value: "4107", uf: "MG" },
  { name: "CARPINA", value: "2379", uf: "PE" },
  { name: "SANTA ROSA", value: "8847", uf: "RS" },
  { name: "CARMOPOLIS", value: "3129", uf: "SE" },
  { name: "BALSAS", value: "0727", uf: "MA" },
  { name: "CONSELHEIRO LAFAIETE", value: "4365", uf: "MG" },
  { name: "JUNQUEIROPOLIS", value: "6621", uf: "SP" },
  { name: "PITANGUI", value: "5027", uf: "MG" },
  { name: "IGUATEMI", value: "9085", uf: "MS" },
  { name: "IBAITI", value: "7589", uf: "PR" },
  { name: "SAO JORGE DO PATROCINIO", value: "7999", uf: "PR" },
  { name: "RIBAS DO RIO PARDO", value: "9141", uf: "MS" },
  { name: "NOVO REPARTIMENTO", value: "0629", uf: "PA" },
  { name: "SAO BERNARDO", value: "0909", uf: "MA" },
  { name: "BURITIS", value: "4185", uf: "MG" },
  { name: "MINAS NOVAS", value: "4835", uf: "MG" },
  { name: "PIRATININGA", value: "6889", uf: "SP" },
  { name: "PENALVA", value: "0863", uf: "MA" },
  { name: "RIBEIRAO PIRES", value: "6967", uf: "SP" },
  { name: "GOIANESIA", value: "9371", uf: "GO" },
  { name: "CHAPADINHA", value: "0761", uf: "MA" },
  { name: "SAO GOTARDO", value: "5241", uf: "MG" },
  { name: "TRES RANCHOS", value: "9623", uf: "GO" },
  { name: "MAUA", value: "6689", uf: "SP" },
  { name: "PINHALZINHO", value: "6867", uf: "SP" },
  { name: "BELA VISTA", value: "9041", uf: "MS" },
  { name: "CASA BRANCA", value: "6317", uf: "SP" },
  { name: "BASTOS", value: "6215", uf: "SP" },
  { name: "PLANALTINA", value: "9595", uf: "GO" },
  { name: "GUARACIABA DO NORTE", value: "1401", uf: "CE" },
  { name: "MARAU", value: "8737", uf: "RS" },
  { name: "SANTA LUZIA", value: "0897", uf: "MA" },
  { name: "MAJOR GERCINO", value: "8201", uf: "SC" },
  { name: "CAMPOS VERDES", value: "9781", uf: "GO" },
  { name: "CACU", value: "9285", uf: "GO" },
  { name: "CAMBORIU", value: "8061", uf: "SC" },
  { name: "BARREIRINHA", value: "0211", uf: "AM" },
  { name: "ANASTACIO", value: "9013", uf: "MS" },
  { name: "MANHUACU", value: "4787", uf: "MG" },
  { name: "BOM JESUS DO GALHO", value: "4155", uf: "MG" },
  { name: "ARUANA", value: "9249", uf: "GO" },
  { name: "PIUM", value: "9547", uf: "TO" },
  { name: "SAO LUIS DE MONTES BELOS", value: "9599", uf: "GO" },
  { name: "CANTAGALO", value: "8451", uf: "PR" },
  { name: "PEDREGULHO", value: "6841", uf: "SP" },
  { name: "AGUAS VERMELHAS", value: "4019", uf: "MG" },
  { name: "FAXINAL DO SOTURNO", value: "8657", uf: "RS" },
  { name: "MINACU", value: "9647", uf: "GO" },
  { name: "CIDREIRA", value: "8433", uf: "RS" },
  { name: "SANTA ADELIA", value: "7013", uf: "SP" },
  { name: "ITINGA", value: "4679", uf: "MG" },
  { name: "LAGO DO JUNCO", value: "0815", uf: "MA" },
  { name: "ARAGARCAS", value: "9233", uf: "GO" },
  { name: "GETULINA", value: "6441", uf: "SP" },
  { name: "ARAGOIANIA", value: "9235", uf: "GO" },
  { name: "PINHAIS", value: "5453", uf: "PR" },
  { name: "RIBEIRAO DO SUL", value: "6965", uf: "SP" },
  { name: "GURUPI", value: "9385", uf: "TO" },
  { name: "SANTA TEREZINHA DE GOIAS", value: "9589", uf: "GO" },
  { name: "PRESIDENTE GETULIO", value: "8275", uf: "SC" },
  { name: "JI-PARANA", value: "0005", uf: "RO" },
  { name: "SANTA ROSA DE GOIAS", value: "9585", uf: "GO" },
  { name: "IPORA", value: "9399", uf: "GO" },
  { name: "DOM PEDRITO", value: "8629", uf: "RS" },
  { name: "CAMPO BOM", value: "8577", uf: "RS" },
  { name: "SANTA QUITERIA DO MARANHAO", value: "0899", uf: "MA" },
  { name: "LASTRO", value: "2069", uf: "PB" },
  { name: "ARAGUACEMA", value: "9237", uf: "TO" },
  { name: "ITANHAEM", value: "6543", uf: "SP" },
  { name: "MACAU", value: "1743", uf: "RN" },
  { name: "SARZEDO", value: "0730", uf: "MG" },
  { name: "SANTANA DO LIVRAMENTO", value: "8845", uf: "RS" },
  { name: "TIRADENTES", value: "5375", uf: "MG" },
  { name: "RIOLANDIA", value: "6985", uf: "SP" },
  { name: "JAGUARIUNA", value: "6595", uf: "SP" },
  { name: "PORANGATU", value: "9555", uf: "GO" },
  { name: "IPAMERI", value: "9397", uf: "GO" },
  { name: "VILA MARIA", value: "7309", uf: "RS" },
  { name: "PRATA", value: "5055", uf: "MG" },
  { name: "URBANO SANTOS", value: "0945", uf: "MA" },
  { name: "NOVA ANDRADINA", value: "9123", uf: "MS" },
  { name: "ARIQUEMES", value: "0007", uf: "RO" },
  { name: "JABORANDI", value: "6585", uf: "SP" },
  { name: "COROMANDEL", value: "4385", uf: "MG" },
  { name: "PALMARES", value: "2499", uf: "PE" },
  { name: "ALAGOINHAS", value: "3313", uf: "BA" },
  { name: "NOVA CRUZ", value: "1765", uf: "RN" },
  { name: "OSVALDO CRUZ", value: "6793", uf: "SP" },
  { name: "VASSOURAS", value: "5923", uf: "RJ" },
  { name: "ICO", value: "1409", uf: "CE" },
  { name: "RIBEIRA DO POMBAL", value: "3833", uf: "BA" },
  { name: "FLOREAL", value: "6419", uf: "SP" },
  { name: "TUMIRITINGA", value: "5389", uf: "MG" },
  { name: "MONTALVANIA", value: "4853", uf: "MG" },
  { name: "PECANHA", value: "4971", uf: "MG" },
  { name: "SINOP", value: "8985", uf: "MT" },
  { name: "SOLEDADE DE MINAS", value: "5355", uf: "MG" },
  { name: "CAMPO LARGO", value: "7481", uf: "PR" },
  { name: "BACURITUBA", value: "0118", uf: "MA" },
  { name: "BARRA DO BUGRES", value: "9033", uf: "MT" },
  { name: "SANTA INES", value: "0957", uf: "MA" },
  { name: "ALTO ARAGUAIA", value: "9005", uf: "MT" },
  { name: "MARACAI", value: "6677", uf: "SP" },
  { name: "PONTES E LACERDA", value: "8999", uf: "MT" },
  { name: "ALTA FLORESTA", value: "8987", uf: "MT" },
  { name: "MIRASSOL D'OESTE", value: "9177", uf: "MT" },
  { name: "CHAPADA DOS GUIMARAES", value: "9059", uf: "MT" },
  { name: "CAMPINA GRANDE", value: "1981", uf: "PB" },
  { name: "DIVINOLANDIA DE MINAS", value: "4443", uf: "MG" },
  { name: "ARAIOSES", value: "0717", uf: "MA" },
  { name: "TIMBAUBA", value: "2605", uf: "PE" },
  { name: "SANTA ISABEL", value: "7037", uf: "SP" },
  { name: "LUIS DOMINGUES", value: "0823", uf: "MA" },
  { name: "CABREUVA", value: "6269", uf: "SP" },
  { name: "MUNDO NOVO", value: "9179", uf: "MS" },
  { name: "PASSO FUNDO", value: "8785", uf: "RS" },
  { name: "JUARA", value: "9819", uf: "MT" },
  { name: "CRUZEIRO", value: "6369", uf: "SP" },
  { name: "PASSAGEM FRANCA", value: "0857", uf: "MA" },
  { name: "NOVA MONTE VERDE", value: "0119", uf: "MT" },
  { name: "CATINGUEIRA", value: "1985", uf: "PB" },
  { name: "PROGRESSO", value: "7361", uf: "RS" },
  { name: "SANCLERLANDIA", value: "9575", uf: "GO" },
  { name: "PRIMAVERA DO LESTE", value: "9871", uf: "MT" },
  { name: "JANGADA", value: "9861", uf: "MT" },
  { name: "LUCAS DO RIO VERDE", value: "9925", uf: "MT" },
  { name: "NAZARE DA MATA", value: "2489", uf: "PE" },
  { name: "IJUI", value: "8705", uf: "RS" },
  { name: "NAZAREZINHO", value: "2101", uf: "PB" },
  { name: "GUARANTA DO NORTE", value: "9887", uf: "MT" },
  { name: "CACAPAVA DO SUL", value: "8555", uf: "RS" },
  { name: "JEQUERI", value: "4709", uf: "MG" },
  { name: "RANCHARIA", value: "6945", uf: "SP" },
  { name: "CONCEICAO DAS ALAGOAS", value: "4345", uf: "MG" },
  { name: "JOAO NEIVA", value: "5721", uf: "ES" },
  { name: "COMODORO", value: "9883", uf: "MT" },
  { name: "SAO GERALDO DA PIEDADE", value: "5231", uf: "MG" },
  { name: "ITAREMA", value: "1595", uf: "CE" },
  { name: "SALTO GRANDE", value: "7009", uf: "SP" },
  { name: "VEREDA", value: "3051", uf: "BA" },
  { name: "CASSIA", value: "4301", uf: "MG" },
  { name: "CANDEIAS", value: "3429", uf: "BA" },
  { name: "CANAPI", value: "2731", uf: "AL" },
  { name: "MONTE SIAO", value: "4867", uf: "MG" },
  { name: "CRISTAIS", value: "4403", uf: "MG" },
  { name: "PRESIDENTE EPITACIO", value: "6927", uf: "SP" },
  { name: "PORTO ESPERIDIAO", value: "9875", uf: "MT" },
  { name: "BARRAS", value: "1023", uf: "PI" },
  { name: "BARCARENA", value: "0425", uf: "PA" },
  { name: "JUINA", value: "9831", uf: "MT" },
  { name: "ITABERAI", value: "9403", uf: "GO" },
  { name: "NOVO ARIPUANA", value: "0267", uf: "AM" },
  { name: "VERA", value: "9905", uf: "MT" },
  { name: "COLIDER", value: "8979", uf: "MT" },
  { name: "DORES DE GUANHAES", value: "4461", uf: "MG" },
  { name: "MORAUJO", value: "1477", uf: "CE" },
  { name: "SORRISO", value: "9907", uf: "MT" },
  { name: "MACEDONIA", value: "6665", uf: "SP" },
  { name: "ENCRUZILHADA", value: "3507", uf: "BA" },
  { name: "BELA CRUZ", value: "1345", uf: "CE" },
  { name: "OURO PRETO DO OESTE", value: "0017", uf: "RO" },
  { name: "CASSILANDIA", value: "9057", uf: "MS" },
  { name: "ANANAS", value: "9219", uf: "TO" },
  { name: "SOBRAL", value: "1559", uf: "CE" },
  { name: "PITANGA", value: "7771", uf: "PR" },
  { name: "POMBOS", value: "2525", uf: "PE" },
  { name: "JAPIRA", value: "7639", uf: "PR" },
  { name: "DESCALVADO", value: "6375", uf: "SP" },
  { name: "AREAL", value: "2925", uf: "RJ" },
  { name: "LIMEIRA DO OESTE", value: "2687", uf: "MG" },
  { name: "PAULO AFONSO", value: "3781", uf: "BA" },
  { name: "SANTA ALBERTINA", value: "7015", uf: "SP" },
  { name: "LOUVEIRA", value: "6647", uf: "SP" },
  { name: "ENTRE RIOS DE MINAS", value: "4477", uf: "MG" },
  { name: "SERRA NEGRA", value: "7133", uf: "SP" },
  { name: "NOVO ORIENTE DE MINAS", value: "0666", uf: "MG" },
  { name: "COCALINHO", value: "9865", uf: "MT" },
  { name: "JATOBA", value: "0176", uf: "MA" },
  { name: "ITAMARATI DE MINAS", value: "4651", uf: "MG" },
  { name: "FRANCISCO DUMONT", value: "4531", uf: "MG" },
  { name: "APARECIDA D'OESTE", value: "6151", uf: "SP" },
  { name: "GUIRATINGA", value: "9083", uf: "MT" },
  { name: "CANANEIA", value: "6299", uf: "SP" },
  { name: "ITAUBA", value: "9901", uf: "MT" },
  { name: "SANTO AMARO", value: "3871", uf: "BA" },
  { name: "ITAQUI", value: "8713", uf: "RS" },
  { name: "TORIXOREU", value: "9163", uf: "MT" },
  { name: "PARDINHO", value: "6823", uf: "SP" },
  { name: "SAO SEBASTIAO DO CAI", value: "8893", uf: "RS" },
  { name: "BOQUIM", value: "3115", uf: "SE" },
  { name: "CACHOEIRA PAULISTA", value: "6273", uf: "SP" },
  { name: "PILAR DO SUL", value: "6859", uf: "SP" },
  { name: "DIAMANTINO", value: "9069", uf: "MT" },
  { name: "PARANAPUA", value: "6819", uf: "SP" },
  { name: "COARACI", value: "3459", uf: "BA" },
  { name: "IVINHEMA", value: "9093", uf: "MS" },
  { name: "SALTO DA DIVISA", value: "5141", uf: "MG" },
  { name: "CONCEICAO DA APARECIDA", value: "4341", uf: "MG" },
  { name: "FIGUEIROPOLIS D'OESTE", value: "9881", uf: "MT" },
  { name: "VILA RICA", value: "9897", uf: "MT" },
  { name: "CAPANEMA", value: "0443", uf: "PA" },
  { name: "COROACI", value: "4383", uf: "MG" },
  { name: "TAQUARA", value: "8927", uf: "RS" },
  { name: "NOVA VENEZA", value: "9497", uf: "GO" },
  { name: "CAIUA", value: "6283", uf: "SP" },
  { name: "VIAMAO", value: "8963", uf: "RS" },
  { name: "ANTONIO CARLOS", value: "4057", uf: "MG" },
  { name: "ALCOBACA", value: "3315", uf: "BA" },
  { name: "TOCANTINIA", value: "9619", uf: "TO" },
  { name: "VINHEDO", value: "7237", uf: "SP" },
  { name: "MARITUBA", value: "0054", uf: "PA" },
  { name: "SANTA CRUZ", value: "1823", uf: "RN" },
  { name: "NATIVIDADE", value: "9481", uf: "TO" },
  { name: "ITIRAPUA", value: "6575", uf: "SP" },
  { name: "AUGUSTO PESTANA", value: "8529", uf: "RS" },
  { name: "GUARACAI", value: "6457", uf: "SP" },
  { name: "ALTO JEQUITIBA", value: "5069", uf: "MG" },
  { name: "BARRA BONITA", value: "6205", uf: "SP" },
  { name: "GODOFREDO VIANA", value: "0783", uf: "MA" },
  { name: "CAMPESTRE DE GOIAS", value: "9291", uf: "GO" },
  { name: "TASSO FRAGOSO", value: "0933", uf: "MA" },
  { name: "XINGUARA", value: "0571", uf: "PA" },
  { name: "SENADOR SA", value: "1557", uf: "CE" },
  { name: "AGUAS DA PRATA", value: "6107", uf: "SP" },
  { name: "SERRARIA", value: "2219", uf: "PB" },
  { name: "TEFE", value: "0281", uf: "AM" },
  { name: "PINDORETAMA", value: "1267", uf: "CE" },
  { name: "EUSEBIO", value: "1247", uf: "CE" },
  { name: "BARREIRINHAS", value: "0733", uf: "MA" },
  { name: "IBIUNA", value: "6495", uf: "SP" },
  { name: "SERRANOPOLIS", value: "9607", uf: "GO" },
  { name: "SAO RAIMUNDO DAS MANGABEIRAS", value: "0925", uf: "MA" },
  { name: "BELO JARDIM", value: "2333", uf: "PE" },
  { name: "PONTAL", value: "6905", uf: "SP" },
  { name: "SANTO ANTONIO DE POSSE", value: "7061", uf: "SP" },
  { name: "ITACARAMBI", value: "4641", uf: "MG" },
  { name: "ITATINGA", value: "6571", uf: "SP" },
  { name: "OEIRAS", value: "1139", uf: "PI" },
  { name: "SAO PEDRO DA ALDEIA", value: "5903", uf: "RJ" },
  { name: "CIDADE OCIDENTAL", value: "0077", uf: "GO" },
  { name: "RINCAO", value: "6975", uf: "SP" },
  { name: "VARJAO", value: "9639", uf: "GO" },
  { name: "CARACOL", value: "9053", uf: "MS" },
  { name: "MIRACATU", value: "6699", uf: "SP" },
  { name: "BRASILANDIA DE MINAS", value: "0574", uf: "MG" },
  { name: "SANTA CRUZ DAS PALMEIRAS", value: "7027", uf: "SP" },
  { name: "ITUETA", value: "4681", uf: "MG" },
  { name: "SAO DOMINGOS DO ARAGUAIA", value: "0381", uf: "PA" },
  { name: "MARIA DA FE", value: "4797", uf: "MG" },
  { name: "MONTE AZUL", value: "4857", uf: "MG" },
  { name: "NEROPOLIS", value: "9487", uf: "GO" },
  { name: "BERNARDINO DE CAMPOS", value: "6225", uf: "SP" },
  { name: "SAO JOSE DE MIPIBU", value: "1841", uf: "RN" },
  { name: "PALMEIRA D'OESTE", value: "6805", uf: "SP" },
  { name: "COCOS", value: "3461", uf: "BA" },
  { name: "NIQUELANDIA", value: "9489", uf: "GO" },
  { name: "SANTA CRUZ DO RIO PARDO", value: "7029", uf: "SP" },
  { name: "SAO JOAO BATISTA", value: "8321", uf: "SC" },
  { name: "TAMBAU", value: "7167", uf: "SP" },
  { name: "PARAIBA DO SUL", value: "5873", uf: "RJ" },
  { name: "MESQUITA", value: "4833", uf: "MG" },
  { name: "CUNHA", value: "6373", uf: "SP" },
  { name: "NOVO HORIZONTE DO OESTE", value: "0689", uf: "RO" },
  { name: "GOIATUBA", value: "9379", uf: "GO" },
  { name: "MINEIROS", value: "9459", uf: "GO" },
  { name: "UNIAO PAULISTA", value: "7215", uf: "SP" },
  { name: "CONCEICAO DA BARRA DE MINAS", value: "4303", uf: "MG" },
  { name: "PRESIDENTE SARNEY", value: "0216", uf: "MA" },
  { name: "BELA VISTA DE MINAS", value: "4119", uf: "MG" },
  { name: "SAO JOSE DO DIVINO", value: "5265", uf: "MG" },
  { name: "PIEDADE", value: "6857", uf: "SP" },
  { name: "BARRA DO TURVO", value: "6207", uf: "SP" },
  { name: "FRAIBURGO", value: "8107", uf: "SC" },
  { name: "DIONISIO", value: "4435", uf: "MG" },
  { name: "PANORAMA", value: "6809", uf: "SP" },
  { name: "TABATINGA", value: "7155", uf: "SP" },
  { name: "JUPIA", value: "0924", uf: "SC" },
  { name: "RUBIATABA", value: "9573", uf: "GO" },
  { name: "TRAJANO DE MORAES", value: "5917", uf: "RJ" },
  { name: "JARDINOPOLIS", value: "6603", uf: "SP" },
  { name: "CENTRAL DE MINAS", value: "4313", uf: "MG" },
  { name: "SANTA RITA", value: "2175", uf: "PB" },
  { name: "PENEDO", value: "2833", uf: "AL" },
  { name: "PASSA SETE", value: "1010", uf: "RS" },
  { name: "IBIMIRIM", value: "2431", uf: "PE" },
  { name: "PIRACURUCA", value: "1165", uf: "PI" },
  { name: "PATOS DO PIAUI", value: "2277", uf: "PI" },
  { name: "RUBIM", value: "5131", uf: "MG" },
  { name: "PERDOES", value: "4997", uf: "MG" },
  { name: "ARCOS", value: "4083", uf: "MG" },
  { name: "CATANDUVAS", value: "8077", uf: "SC" },
  { name: "SAO JOAO DO ITAPERIU", value: "5551", uf: "SC" },
  { name: "RIO PARDO", value: "8817", uf: "RS" },
  { name: "BARBOSA", value: "6199", uf: "SP" },
  { name: "SARAPUI", value: "7123", uf: "SP" },
  { name: "CORDISBURGO", value: "4377", uf: "MG" },
  { name: "ITAIBA", value: "2449", uf: "PE" },
  { name: "TERRA BOA", value: "7917", uf: "PR" },
  { name: "OROS", value: "1491", uf: "CE" },
  { name: "SANTA RITA DE IBITIPOCA", value: "5187", uf: "MG" },
  { name: "TOMBOS", value: "5383", uf: "MG" },
  { name: "UPANEMA", value: "1889", uf: "RN" },
  { name: "UNIAO DA VITORIA", value: "7937", uf: "PR" },
  { name: "ITAPORANGA", value: "6557", uf: "SP" },
  { name: "PONTE SERRADA", value: "8263", uf: "SC" },
  { name: "BADY BASSITT", value: "6191", uf: "SP" },
  { name: "CRISSIUMAL", value: "8617", uf: "RS" },
  { name: "PRESIDENTE CASTELO BRANCO", value: "7787", uf: "PR" },
  { name: "MIRADOURO", value: "4841", uf: "MG" },
  { name: "SANTO ANTONIO DO RETIRO", value: "0708", uf: "MG" },
  { name: "JABORANDI", value: "9859", uf: "BA" },
  { name: "TUCUMA", value: "0397", uf: "PA" },
  { name: "FONTOURA XAVIER", value: "8663", uf: "RS" },
  { name: "PRADOS", value: "5053", uf: "MG" },
  { name: "CABROBO", value: "2359", uf: "PE" },
  { name: "PACO DO LUMIAR", value: "0849", uf: "MA" },
  { name: "SAO TIAGO", value: "5299", uf: "MG" },
  { name: "IVATE", value: "9955", uf: "PR" },
  { name: "SILVANIA", value: "9609", uf: "GO" },
  { name: "GUARACIABA", value: "8125", uf: "SC" },
  { name: "CAMPO ALEGRE", value: "8063", uf: "SC" },
  { name: "ARIRANHA DO IVAI", value: "0832", uf: "PR" },
  { name: "SAO FELIX DO XINGU", value: "0545", uf: "PA" },
  { name: "SAO MIGUEL DO ANTA", value: "5275", uf: "MG" },
  { name: "SANTA CRUZ DE MONTE CASTELO", value: "7843", uf: "PR" },
  { name: "GROAIRAS", value: "1399", uf: "CE" },
  { name: "VARGEM GRANDE PAULISTA", value: "7273", uf: "SP" },
  { name: "GRANDES RIOS", value: "7959", uf: "PR" },
  { name: "NINHEIRA", value: "0660", uf: "MG" },
  { name: "URUOCA", value: "1579", uf: "CE" },
  { name: "IRATI", value: "7607", uf: "PR" },
  { name: "VARJAO DE MINAS", value: "0750", uf: "MG" },
  { name: "SAO BENEDITO", value: "1547", uf: "CE" },
  { name: "RIO DO PRADO", value: "5101", uf: "MG" },
  { name: "SALGADO", value: "3223", uf: "SE" },
  { name: "BARRACAO", value: "8535", uf: "RS" },
  { name: "CACHOEIRA", value: "3397", uf: "BA" },
  { name: "BARCELOS", value: "0209", uf: "AM" },
  { name: "NOSSA SENHORA DE LOURDES", value: "3193", uf: "SE" },
  { name: "SAO MIGUEL DO TAPUIO", value: "1207", uf: "PI" },
  { name: "CRUCILANDIA", value: "4411", uf: "MG" },
  { name: "BONFIM", value: "4161", uf: "MG" },
  { name: "ESTIVA", value: "4489", uf: "MG" },
  { name: "SAO SEBASTIAO DA BELA VISTA", value: "5287", uf: "MG" },
  { name: "ANDRADAS", value: "4051", uf: "MG" },
  { name: "MORRETES", value: "7709", uf: "PR" },
  { name: "JUQUITIBA", value: "6625", uf: "SP" },
  { name: "OURILANDIA DO NORTE", value: "0591", uf: "PA" },
  { name: "ITAGUAJE", value: "7611", uf: "PR" },
  { name: "PASSABEM", value: "4949", uf: "MG" },
  { name: "FLORESTA", value: "2413", uf: "PE" },
  { name: "SANTO ANTONIO DE PADUA", value: "5893", uf: "RJ" },
  { name: "ARENOPOLIS", value: "9671", uf: "GO" },
  { name: "SEVERINIA", value: "7139", uf: "SP" },
  { name: "AMAMBAI", value: "9011", uf: "MS" },
  { name: "LAMBARI", value: "4755", uf: "MG" },
  { name: "CONCHAL", value: "6345", uf: "SP" },
  { name: "ALVORADA", value: "8511", uf: "RS" },
  { name: "PIRITIBA", value: "3797", uf: "BA" },
  { name: "ENCRUZILHADA DO SUL", value: "8635", uf: "RS" },
  { name: "JANDAIA", value: "9429", uf: "GO" },
  { name: "CARIDADE", value: "1359", uf: "CE" },
  { name: "CAMPOS GERAIS", value: "4231", uf: "MG" },
  { name: "ESTRELA D'OESTE", value: "6405", uf: "SP" },
  { name: "PALMELO", value: "9513", uf: "GO" },
  { name: "MANOEL VITORINO", value: "3709", uf: "BA" },
  { name: "TRIUNFO", value: "8943", uf: "RS" },
  { name: "NOVA RESENDE", value: "4901", uf: "MG" },
  { name: "CORREGO DO BOM JESUS", value: "4397", uf: "MG" },
  { name: "PINDARE MIRIM", value: "0867", uf: "MA" },
  { name: "ALTEROSA", value: "4039", uf: "MG" },
  { name: "AREADO", value: "4085", uf: "MG" },
  { name: "PIEDADE DE PONTE NOVA", value: "5003", uf: "MG" },
  { name: "CAUCAIA", value: "1373", uf: "CE" },
  { name: "BIAS FORTES", value: "4135", uf: "MG" },
  { name: "IGARATA", value: "6505", uf: "SP" },
  { name: "SOUSA", value: "2225", uf: "PB" },
  { name: "PIRIPIRI", value: "1167", uf: "PI" },
  { name: "CAXIAS", value: "0757", uf: "MA" },
  { name: "ARAGUACU", value: "9239", uf: "TO" },
  { name: "BAIAO", value: "0423", uf: "PA" },
  { name: "BARRA FUNDA", value: "5943", uf: "RS" },
  { name: "TANABI", value: "7169", uf: "SP" },
  { name: "DIAMANTINA", value: "4431", uf: "MG" },
  { name: "JATAI", value: "9433", uf: "GO" },
  { name: "PAVAO", value: "4969", uf: "MG" },
  { name: "SAO ROQUE", value: "7113", uf: "SP" },
  { name: "CAROLINA", value: "0753", uf: "MA" },
  { name: "SANTO ANTONIO DA ALEGRIA", value: "7059", uf: "SP" },
  { name: "TRES PASSOS", value: "8941", uf: "RS" },
  { name: "SAO LUIS DO QUITUNDE", value: "2869", uf: "AL" },
  { name: "PEDRINOPOLIS", value: "4983", uf: "MG" },
  { name: "ALDEIAS ALTAS", value: "0705", uf: "MA" },
  { name: "SAO LUIZ DO PARAITINGA", value: "7101", uf: "SP" },
  { name: "LEANDRO FERREIRA", value: "4765", uf: "MG" },
  { name: "BALSAMO", value: "6195", uf: "SP" },
  { name: "NOVA PRATA", value: "8769", uf: "RS" },
  { name: "ALTO ALEGRE", value: "0305", uf: "RR" },
  { name: "PEDRA DO ANTA", value: "4975", uf: "MG" },
  { name: "GUAIRA", value: "6449", uf: "SP" },
  { name: "ALVORADA D'OESTE", value: "0035", uf: "RO" },
  { name: "SARANDI", value: "8903", uf: "RS" },
  { name: "SAO VICENTE DO SUL", value: "8675", uf: "RS" },
  { name: "SAO LUIZ", value: "0315", uf: "RR" },
  { name: "CRUZEIRO DO SUL", value: "8621", uf: "RS" },
  { name: "NOVA HARTZ", value: "7379", uf: "RS" },
  { name: "CORONEL BICACO", value: "8615", uf: "RS" },
  { name: "ASTOLFO DUTRA", value: "4091", uf: "MG" },
  { name: "COLMEIA", value: "9529", uf: "TO" },
  { name: "APUAREMA", value: "3257", uf: "BA" },
  { name: "FARTURA", value: "6409", uf: "SP" },
  { name: "SEARA", value: "8345", uf: "SC" },
  { name: "SANTA MARIA", value: "8841", uf: "RS" },
  { name: "IVOTI", value: "8717", uf: "RS" },
  { name: "NOVA OLIMPIA", value: "9893", uf: "MT" },
  { name: "TRABIJU", value: "0826", uf: "SP" },
  { name: "PAULINIA", value: "6831", uf: "SP" },
  { name: "ARROIO GRANDE", value: "8525", uf: "RS" },
  { name: "ARAME", value: "1281", uf: "MA" },
  { name: "CORACAO DE JESUS", value: "4375", uf: "MG" },
  { name: "OURO BRANCO", value: "4917", uf: "MG" },
  { name: "TAPES", value: "8925", uf: "RS" },
  { name: "RIO CASCA", value: "5097", uf: "MG" },
  { name: "CAPELA", value: "3125", uf: "SE" },
  { name: "GUARIBA", value: "6473", uf: "SP" },
  { name: "CAARAPO", value: "9055", uf: "MS" },
  { name: "NAVIRAI", value: "9113", uf: "MS" },
  { name: "TERENOS", value: "9159", uf: "MS" },
  { name: "CAVALCANTE", value: "9305", uf: "GO" },
  { name: "SAO JOAO DA MATA", value: "5245", uf: "MG" },
  { name: "IRACEMA", value: "0032", uf: "RR" },
  { name: "SAO VALERIO DO SUL", value: "6075", uf: "RS" },
  { name: "RODEIO BONITO", value: "8821", uf: "RS" },
  { name: "AURORA", value: "8037", uf: "SC" },
  { name: "VEREDINHA", value: "0754", uf: "MG" },
  { name: "SIMOES FILHO", value: "3913", uf: "BA" },
  { name: "CANDEIAS DO JAMARI", value: "0681", uf: "RO" },
  { name: "CAMARGO", value: "8447", uf: "RS" },
  { name: "VIRGINIA", value: "5435", uf: "MG" },
  { name: "POCO REDONDO", value: "3207", uf: "SE" },
  { name: "RIBAMAR FIQUENE", value: "0220", uf: "MA" },
  { name: "CURURUPU", value: "0771", uf: "MA" },
  { name: "BENTO GONCALVES", value: "8541", uf: "RS" },
  { name: "PLANURA", value: "5031", uf: "MG" },
  { name: "RIBEIRAO DOS INDIOS", value: "0818", uf: "SP" },
  { name: "CONFRESA", value: "0131", uf: "MT" },
  { name: "TORRES", value: "8933", uf: "RS" },
  { name: "BOM JESUS DA LAPA", value: "3377", uf: "BA" },
  { name: "PALMITAL", value: "6807", uf: "SP" },
  { name: "LASSANCE", value: "4761", uf: "MG" },
  { name: "RIACHAO", value: "0887", uf: "MA" },
  { name: "SERRINHA", value: "3909", uf: "BA" },
  { name: "BOM JESUS", value: "8545", uf: "RS" },
  { name: "TUCUNDUVA", value: "8945", uf: "RS" },
  { name: "BRAZABRANTES", value: "9271", uf: "GO" },
  { name: "CORINTO", value: "4381", uf: "MG" },
  { name: "FELIXLANDIA", value: "4513", uf: "MG" },
  { name: "MUCUM", value: "8753", uf: "RS" },
  { name: "GONZAGA", value: "4549", uf: "MG" },
  { name: "PEDRA BRANCA", value: "1511", uf: "CE" },
  { name: "IBIRACI", value: "4593", uf: "MG" },
  { name: "JARDIM", value: "9099", uf: "MS" },
  { name: "IMBAU", value: "0862", uf: "PR" },
  { name: "JOVIANIA", value: "9437", uf: "GO" },
  { name: "ANDRELANDIA", value: "4055", uf: "MG" },
  { name: "BARRA DA ESTIVA", value: "3355", uf: "BA" },
  { name: "AGUA DOCE DO MARANHAO", value: "0104", uf: "MA" },
  { name: "CACONDE", value: "6275", uf: "SP" },
  { name: "ALECRIM", value: "8505", uf: "RS" },
  { name: "POPULINA", value: "6909", uf: "SP" },
  { name: "TIMBO", value: "8357", uf: "SC" },
  { name: "PEDREIRA", value: "6843", uf: "SP" },
  { name: "NOVO GAMA", value: "1058", uf: "GO" },
  { name: "PAROBE", value: "9825", uf: "RS" },
  { name: "TAPIRAI", value: "7171", uf: "SP" },
  { name: "PRUDENTOPOLIS", value: "7791", uf: "PR" },
  { name: "CAMPO ERE", value: "8067", uf: "SC" },
  { name: "CONCEICAO", value: "1989", uf: "PB" },
  { name: "ALPINOPOLIS", value: "4037", uf: "MG" },
  { name: "ALEGRETE", value: "8507", uf: "RS" },
  { name: "JABOTICATUBAS", value: "4691", uf: "MG" },
  { name: "GETULIO VARGAS", value: "8677", uf: "RS" },
  { name: "VARGEAO", value: "8375", uf: "SC" },
  { name: "SENTINELA DO SUL", value: "5781", uf: "RS" },
  { name: "TAQUARITUBA", value: "7177", uf: "SP" },
  { name: "MOCOCA", value: "6711", uf: "SP" },
  { name: "SAO LOURENCO DA SERRA", value: "5447", uf: "SP" },
  { name: "PEQUIZEIRO", value: "9705", uf: "TO" },
  { name: "CACHOEIRA DE MINAS", value: "4193", uf: "MG" },
  { name: "ALIANCA DO TOCANTINS", value: "9441", uf: "TO" },
  { name: "BOCA DA MATA", value: "2719", uf: "AL" },
  { name: "MARCELINO RAMOS", value: "8739", uf: "RS" },
  { name: "SERRANOS", value: "5339", uf: "MG" },
  { name: "LAVRINHAS", value: "6633", uf: "SP" },
  { name: "CANARANA", value: "9193", uf: "MT" },
  { name: "FORQUETINHA", value: "1142", uf: "RS" },
  { name: "MARIANOPOLIS DO TOCANTINS", value: "9711", uf: "TO" },
  { name: "SAO JOSE DE RIBAMAR", value: "0889", uf: "MA" },
  { name: "SANTA MARIANA", value: "7853", uf: "PR" },
  { name: "ITAMONTE", value: "4659", uf: "MG" },
  { name: "BOM SUCESSO", value: "7461", uf: "PR" },
  { name: "AMPARO", value: "6137", uf: "SP" },
  { name: "PARAISOPOLIS", value: "4945", uf: "MG" },
  { name: "RIBEIRAO", value: "2535", uf: "PE" },
  { name: "BANANAL", value: "6197", uf: "SP" },
  { name: "APARECIDA", value: "6149", uf: "SP" },
  { name: "ITARIRI", value: "6567", uf: "SP" },
  { name: "AGUAS LINDAS DE GOIAS", value: "1052", uf: "GO" },
  { name: "QUERENCIA DO NORTE", value: "7797", uf: "PR" },
  { name: "CRIXAS", value: "9329", uf: "GO" },
  { name: "CAJAPIO", value: "0745", uf: "MA" },
  { name: "CAMPESTRE", value: "4219", uf: "MG" },
  { name: "BODOQUENA", value: "9801", uf: "MS" },
  { name: "PARAIBUNA", value: "6813", uf: "SP" },
  { name: "COTRIGUACU", value: "0089", uf: "MT" },
  { name: "TANQUINHO", value: "3921", uf: "BA" },
  { name: "MIGUEL PEREIRA", value: "5857", uf: "RJ" },
  { name: "POCAO", value: "2523", uf: "PE" },
  { name: "SAO DOMINGOS DO CAPIM", value: "0543", uf: "PA" },
  { name: "MARAVILHA", value: "8205", uf: "SC" },
  { name: "CAPIVARI DE BAIXO", value: "5545", uf: "SC" },
  { name: "ITAPORANGA", value: "2041", uf: "PB" },
  { name: "MAMBORE", value: "7673", uf: "PR" },
  { name: "FUNDAO", value: "5643", uf: "ES" },
  { name: "PATO BRANCO", value: "7751", uf: "PR" },
  { name: "DOM FELICIANO", value: "8627", uf: "RS" },
  { name: "ALTO ALEGRE DOS PARECIS", value: "0002", uf: "RO" },
  { name: "ITAGIMIRIM", value: "3607", uf: "BA" },
  { name: "ACREUNA", value: "9645", uf: "GO" },
  { name: "PRAINHA", value: "0519", uf: "PA" },
  { name: "TIBAU", value: "0428", uf: "RN" },
  { name: "CHALE", value: "4319", uf: "MG" },
  { name: "PALMEIRA DAS MISSOES", value: "8777", uf: "RS" },
  { name: "CENTRAL", value: "3451", uf: "BA" },
  { name: "SANTA RITA DO ARAGUAIA", value: "9583", uf: "GO" },
  { name: "JAGUARUANA", value: "1441", uf: "CE" },
  { name: "SALINOPOLIS", value: "0523", uf: "PA" },
  { name: "SAPUCAIA DO SUL", value: "8901", uf: "RS" },
  { name: "MONTEIROPOLIS", value: "2807", uf: "AL" },
  { name: "CURIONOPOLIS", value: "0581", uf: "PA" },
  { name: "MUCAJAI", value: "0309", uf: "RR" },
  { name: "ABATIA", value: "7401", uf: "PR" },
  { name: "ARCOVERDE", value: "2323", uf: "PE" },
  { name: "CAMPINAPOLIS", value: "9863", uf: "MT" },
  { name: "PEIXOTO DE AZEVEDO", value: "9891", uf: "MT" },
  { name: "CHAPADAO DO CEU", value: "0073", uf: "GO" },
  { name: "SANTO ANGELO", value: "8853", uf: "RS" },
  { name: "TRES CACHOEIRAS", value: "7329", uf: "RS" },
  { name: "PALMINOPOLIS", value: "9515", uf: "GO" },
  { name: "ITAIPE", value: "4645", uf: "MG" },
  { name: "PINHAL GRANDE", value: "5787", uf: "RS" },
  { name: "PAULICEIA", value: "6829", uf: "SP" },
  { name: "FERROS", value: "4517", uf: "MG" },
  { name: "SANTO ESTEVAO", value: "3875", uf: "BA" },
  { name: "SALMOURAO", value: "7003", uf: "SP" },
  { name: "ALAMBARI", value: "2995", uf: "SP" },
  { name: "MADEIRO", value: "0344", uf: "PI" },
  { name: "NOVA CANAA DO NORTE", value: "9889", uf: "MT" },
  { name: "GUAJERU", value: "3095", uf: "BA" },
  { name: "SALGUEIRO", value: "2543", uf: "PE" },
  { name: "RIBEIRA DO AMPARO", value: "3831", uf: "BA" },
  { name: "ARROIO TRINTA", value: "8031", uf: "SC" },
  { name: "ITAPACI", value: "9413", uf: "GO" },
  { name: "GUAPO", value: "9381", uf: "GO" },
  { name: "BARAO", value: "8485", uf: "RS" },
  { name: "MIRACEMA DO TOCANTINS", value: "9461", uf: "TO" },
  { name: "IGARACU DO TIETE", value: "6501", uf: "SP" },
  { name: "ALTO PIQUIRI", value: "7411", uf: "PR" },
  { name: "TARABAI", value: "7179", uf: "SP" },
  { name: "AGUA BOA", value: "4011", uf: "MG" },
  { name: "TENENTE LAURENTINO CRUZ", value: "0434", uf: "RN" },
  { name: "CORONEL DOMINGOS SOARES", value: "0846", uf: "PR" },
  { name: "ARROIO DO MEIO", value: "8519", uf: "RS" },
  { name: "JOCA MARQUES", value: "0328", uf: "PI" },
  { name: "CERQUEIRA CESAR", value: "6329", uf: "SP" },
  { name: "PONTALINDA", value: "2987", uf: "SP" },
  { name: "SIRIRI", value: "3243", uf: "SE" },
  { name: "BUTIA", value: "8553", uf: "RS" },
  { name: "BARRO ALTO", value: "9263", uf: "GO" },
  { name: "ENGENHEIRO COELHO", value: "2949", uf: "SP" },
  { name: "IGUATAMA", value: "4605", uf: "MG" },
  { name: "MISSAO VELHA", value: "1469", uf: "CE" },
  { name: "MURUTINGA DO SUL", value: "6743", uf: "SP" },
  { name: "ALCANTIL", value: "0440", uf: "PB" },
  { name: "GUIMARANIA", value: "4577", uf: "MG" },
  { name: "CONGONHINHAS", value: "7517", uf: "PR" },
  { name: "MONTE CARMELO", value: "4861", uf: "MG" },
  { name: "RIO BANANAL", value: "5711", uf: "ES" },
  { name: "CARMO DO PARANAIBA", value: "4285", uf: "MG" },
  { name: "SANTA JULIANA", value: "5153", uf: "MG" },
  { name: "SAO CAITANO", value: "2561", uf: "PE" },
  { name: "PAULA FREITAS", value: "7753", uf: "PR" },
  { name: "LEOPOLDINA", value: "4767", uf: "MG" },
  { name: "RIO NEGRO", value: "7823", uf: "PR" },
  { name: "CAIAPONIA", value: "9287", uf: "GO" },
  { name: "BITURUNA", value: "7455", uf: "PR" },
  { name: "VIANOPOLIS", value: "9641", uf: "GO" },
  { name: "ARAXA", value: "4079", uf: "MG" },
  { name: "ESTRELA DO NORTE", value: "6407", uf: "SP" },
  { name: "SAO MIGUEL DO ARAGUAIA", value: "9601", uf: "GO" },
  { name: "HONORIO SERPA", value: "9981", uf: "PR" },
  { name: "SERINGUEIRAS", value: "0699", uf: "RO" },
  { name: "SAO MIGUEL DO TOCANTINS", value: "0199", uf: "TO" },
  { name: "PERDIZES", value: "4995", uf: "MG" },
  { name: "POSSE", value: "9561", uf: "GO" },
  { name: "ESTREITO", value: "0963", uf: "MA" },
  { name: "NOVA XAVANTINA", value: "9195", uf: "MT" },
  { name: "COLORADO DO OESTE", value: "0023", uf: "RO" },
  { name: "ARACUAI", value: "4067", uf: "MG" },
  { name: "BORA", value: "6243", uf: "SP" },
  { name: "JARINU", value: "6605", uf: "SP" },
  { name: "LIMA DUARTE", value: "4771", uf: "MG" },
  { name: "CORBELIA", value: "7523", uf: "PR" },
  { name: "BRACO DO NORTE", value: "8053", uf: "SC" },
  { name: "CASCAVEL", value: "1369", uf: "CE" },
  { name: "LAPA", value: "7657", uf: "PR" },
  { name: "GALIA", value: "6433", uf: "SP" },
  { name: "RONDA ALTA", value: "8825", uf: "RS" },
  { name: "SANTO ANTONIO DO DESCOBERTO", value: "9677", uf: "GO" },
  { name: "IGUABA GRANDE", value: "0774", uf: "RJ" },
  { name: "ARMACAO DOS BUZIOS", value: "0770", uf: "RJ" },
  { name: "INDAIAL", value: "8147", uf: "SC" },
  { name: "RIBEIRO GONCALVES", value: "1177", uf: "PI" },
  { name: "MAIRIPORA", value: "6671", uf: "SP" },
  { name: "BOM JESUS DO ITABAPOANA", value: "5811", uf: "RJ" },
  { name: "CRUZEIRO DO OESTE", value: "7529", uf: "PR" },
  { name: "BRAUNAS", value: "4175", uf: "MG" },
  { name: "PIRANHAS", value: "9541", uf: "GO" },
  { name: "OURO PRETO", value: "4921", uf: "MG" },
  { name: "IRANDUBA", value: "9835", uf: "AM" },
  { name: "FORMOSA", value: "9361", uf: "GO" },
  { name: "COLINAS DO TOCANTINS", value: "9311", uf: "TO" },
  { name: "HERCULANDIA", value: "6481", uf: "SP" },
  { name: "BOM JESUS DOS PERDOES", value: "6241", uf: "SP" },
  { name: "JACUI", value: "4695", uf: "MG" },
  { name: "TAQUARI", value: "8929", uf: "RS" },
  { name: "MATELANDIA", value: "7699", uf: "PR" },
  { name: "POXOREU", value: "9139", uf: "MT" },
  { name: "SANTA FE", value: "7845", uf: "PR" },
  { name: "BARROLANDIA", value: "9693", uf: "TO" },
  { name: "TERRA ROXA", value: "7921", uf: "PR" },
  { name: "RITAPOLIS", value: "5121", uf: "MG" },
  { name: "POTIRENDABA", value: "6917", uf: "SP" },
  { name: "CASTRO", value: "7495", uf: "PR" },
  { name: "SANTA MERCEDES", value: "7043", uf: "SP" },
  { name: "MACHADINHO D'OESTE", value: "0039", uf: "RO" },
  { name: "CARANDAI", value: "4263", uf: "MG" },
  { name: "PARAGOMINAS", value: "0509", uf: "PA" },
  { name: "PORTEIRAO", value: "1060", uf: "GO" },
  { name: "CAPANEMA", value: "7487", uf: "PR" },
  { name: "LAGOA DA CANOA", value: "2781", uf: "AL" },
  { name: "SILVA JARDIM", value: "5911", uf: "RJ" },
  { name: "SANTA TEREZINHA", value: "9197", uf: "MT" },
  { name: "TOMAZINA", value: "7929", uf: "PR" },
  { name: "QUELUZ", value: "6939", uf: "SP" },
  { name: "SAO MATEUS DO SUL", value: "7887", uf: "PR" },
  { name: "MINISTRO ANDREAZZA", value: "0695", uf: "RO" },
  { name: "NOVA AURORA", value: "9493", uf: "GO" },
  { name: "IPOJUCA", value: "2443", uf: "PE" },
  { name: "PUXINANA", value: "2149", uf: "PB" },
  { name: "FAXINAL DOS GUEDES", value: "8103", uf: "SC" },
  { name: "PARANATINGA", value: "8983", uf: "MT" },
  { name: "QUATRO BARRAS", value: "7795", uf: "PR" },
  { name: "ANTA GORDA", value: "8513", uf: "RS" },
  { name: "SAO MIGUEL", value: "1847", uf: "RN" },
  { name: "ITAPAGIPE", value: "4667", uf: "MG" },
  { name: "SANHARO", value: "2547", uf: "PE" },
  { name: "BORBOREMA", value: "6247", uf: "SP" },
  { name: "CUJUBIM", value: "0008", uf: "RO" },
  { name: "MINDURI", value: "4837", uf: "MG" },
  { name: "UNIAO DO SUL", value: "1048", uf: "MT" },
  { name: "TIROS", value: "5377", uf: "MG" },
  { name: "SAPUCAIA", value: "0066", uf: "PA" },
  { name: "VERA CRUZ DO OESTE", value: "7989", uf: "PR" },
  { name: "MIGUEL ALVES", value: "1123", uf: "PI" },
  { name: "BARRETOS", value: "6209", uf: "SP" },
  { name: "CAMPO MAGRO", value: "0842", uf: "PR" },
  { name: "VERISSIMO", value: "5423", uf: "MG" },
  { name: "VERTENTES", value: "2623", uf: "PE" },
  { name: "QUIRINOPOLIS", value: "9563", uf: "GO" },
  { name: "QUEIMADOS", value: "2911", uf: "RJ" },
  { name: "IPEUNA", value: "6523", uf: "SP" },
  { name: "MATHIAS LOBATO", value: "5431", uf: "MG" },
  { name: "ANGATUBA", value: "6143", uf: "SP" },
  { name: "ANDIRA", value: "7419", uf: "PR" },
  { name: "FLORESTA DO ARAGUAIA", value: "0052", uf: "PA" },
  { name: "UNIAO DE MINAS", value: "0742", uf: "MG" },
  { name: "TERRA NOVA DO NORTE", value: "9909", uf: "MT" },
  { name: "POCONE", value: "9129", uf: "MT" },
  { name: "CIANORTE", value: "7505", uf: "PR" },
  { name: "ARENAPOLIS", value: "9025", uf: "MT" },
  { name: "ALMIRANTE TAMANDARE", value: "7407", uf: "PR" },
  { name: "MARAGOGI", value: "2789", uf: "AL" },
  { name: "JACAREZINHO", value: "7629", uf: "PR" },
  { name: "BELMONTE", value: "3367", uf: "BA" },
  { name: "BONFINOPOLIS DE MINAS", value: "4163", uf: "MG" },
  { name: "CRUZ DO ESPIRITO SANTO", value: "1999", uf: "PB" },
  { name: "CAMPO ALEGRE", value: "2727", uf: "AL" },
  { name: "ITAPETINGA", value: "3629", uf: "BA" },
  { name: "MATA GRANDE", value: "2799", uf: "AL" },
  { name: "SAO JOSE DA TAPERA", value: "2867", uf: "AL" },
  { name: "TAIO", value: "8351", uf: "SC" },
  { name: "SERRA DOURADA", value: "3905", uf: "BA" },
  { name: "DOIS IRMAOS", value: "8625", uf: "RS" },
  { name: "MONTES ALTOS", value: "0839", uf: "MA" },
  { name: "TAPIRA", value: "7973", uf: "PR" },
  { name: "CARMO DO RIO VERDE", value: "9299", uf: "GO" },
  { name: "DIAMANTE DO NORTE", value: "7539", uf: "PR" },
  { name: "PRIMEIRO DE MAIO", value: "7789", uf: "PR" },
  { name: "ERNESTINA", value: "8415", uf: "RS" },
  { name: "ANTONINA", value: "7421", uf: "PR" },
  { name: "ROSEIRA", value: "6987", uf: "SP" },
  { name: "FRONTEIRAS", value: "1085", uf: "PI" },
  { name: "JANDAIA DO SUL", value: "7635", uf: "PR" },
  { name: "IPE", value: "8399", uf: "RS" },
  { name: "SANTA RITA DE JACUTINGA", value: "5185", uf: "MG" },
  { name: "JACIARA", value: "9095", uf: "MT" },
  { name: "RIBEIRAO CLARO", value: "7813", uf: "PR" },
  { name: "SANTA BARBARA", value: "5143", uf: "MG" },
  { name: "CANAA DOS CARAJAS", value: "0048", uf: "PA" },
  { name: "ITIQUIRA", value: "9091", uf: "MT" },
  { name: "BOM JESUS", value: "1037", uf: "PI" },
  { name: "PALOTINA", value: "7739", uf: "PR" },
  { name: "MONTE ALEGRE DE MINAS", value: "4855", uf: "MG" },
  { name: "JARDIM ALEGRE", value: "7643", uf: "PR" },
  { name: "JUNDIA", value: "2777", uf: "AL" },
  { name: "SAO MIGUEL DO ALEIXO", value: "3239", uf: "SE" },
  { name: "CAMPOS BELOS", value: "9297", uf: "GO" },
  { name: "ALTO BOA VISTA", value: "0127", uf: "MT" },
  { name: "IBICARAI", value: "3541", uf: "BA" },
  { name: "SULINA", value: "8477", uf: "PR" },
  { name: "SAO JOAO", value: "7871", uf: "PR" },
  { name: "SAO MIGUEL DO IGUACU", value: "7889", uf: "PR" },
  { name: "CATANDUVAS", value: "7497", uf: "PR" },
  { name: "ARAUCARIA", value: "7435", uf: "PR" },
  { name: "IBICARE", value: "8133", uf: "SC" },
  { name: "MONSENHOR PAULO", value: "4851", uf: "MG" },
  { name: "BENJAMIN CONSTANT", value: "0213", uf: "AM" },
  { name: "SETE BARRAS", value: "7137", uf: "SP" },
  { name: "PIRAPETINGA", value: "5021", uf: "MG" },
  { name: "SAO JOAO D'ALIANCA", value: "9597", uf: "GO" },
  { name: "BANDEIRANTES", value: "9029", uf: "MS" },
  { name: "SANTA ERNESTINA", value: "7031", uf: "SP" },
  { name: "LAPAO", value: "3973", uf: "BA" },
  { name: "GOVERNADOR EDISON LOBAO", value: "0162", uf: "MA" },
  { name: "TAUA", value: "1567", uf: "CE" },
  { name: "RODEIO", value: "8297", uf: "SC" },
  { name: "ITAIOPOLIS", value: "8159", uf: "SC" },
  { name: "CRISTAL", value: "8431", uf: "RS" },
  { name: "IRACEMINHA", value: "9953", uf: "SC" },
  { name: "ORIXIMINA", value: "0505", uf: "PA" },
  { name: "BARRACAO", value: "7449", uf: "PR" },
  { name: "ITA", value: "8157", uf: "SC" },
  { name: "BURITIS", value: "0004", uf: "RO" },
  { name: "IBIRAREMA", value: "6491", uf: "SP" },
  { name: "NOVA BRASILANDIA D'OESTE", value: "0041", uf: "RO" },
  { name: "CAPIXABA", value: "0647", uf: "AC" },
  { name: "MARACAJU", value: "9107", uf: "MS" },
  { name: "ITAIPULANDIA", value: "5525", uf: "PR" },
  { name: "RIO BOM", value: "7819", uf: "PR" },
  { name: "MAUES", value: "0261", uf: "AM" },
  { name: "ANHUMAS", value: "6147", uf: "SP" },
  { name: "GOIANORTE", value: "9699", uf: "TO" },
  { name: "MONTE DAS GAMELEIRAS", value: "1757", uf: "RN" },
  { name: "CASTANHEIRAS", value: "0691", uf: "RO" },
  { name: "BOM JARDIM DE GOIAS", value: "9267", uf: "GO" },
  { name: "BARREIRAS", value: "3363", uf: "BA" },
  { name: "TERRA ROXA", value: "7189", uf: "SP" },
  { name: "SAO FRANCISCO DO PIAUI", value: "1193", uf: "PI" },
  { name: "AGUA PRETA", value: "2307", uf: "PE" },
  { name: "ARCO-IRIS", value: "0790", uf: "SP" },
  { name: "SAO CRISTOVAO", value: "3233", uf: "SE" },
  { name: "ITUPEVA", value: "6581", uf: "SP" },
  { name: "MIRANDIBA", value: "2485", uf: "PE" },
  { name: "ELIAS FAUSTO", value: "6399", uf: "SP" },
  { name: "ASSAI", value: "7437", uf: "PR" },
  { name: "GUAPE", value: "4561", uf: "MG" },
  { name: "SAO GONCALO DO ABAETE", value: "5233", uf: "MG" },
  { name: "JESUANIA", value: "4717", uf: "MG" },
  { name: "IGREJA NOVA", value: "2763", uf: "AL" },
  { name: "ARARUNA", value: "1919", uf: "PB" },
  { name: "JEQUIE", value: "3661", uf: "BA" },
  { name: "ROLIM DE MOURA", value: "0029", uf: "RO" },
  { name: "LAVINIA", value: "6631", uf: "SP" },
  { name: "PINHAO", value: "7765", uf: "PR" },
  { name: "AMONTADA", value: "1587", uf: "CE" },
  { name: "BARBOSA FERRAZ", value: "7447", uf: "PR" },
  { name: "LAGOA DO TOCANTINS", value: "0353", uf: "TO" },
  { name: "JORDANIA", value: "4729", uf: "MG" },
  { name: "BOA ESPERANCA", value: "7457", uf: "PR" },
  { name: "SANTA QUITERIA", value: "1545", uf: "CE" },
  { name: "NOVA PRATA DO IGUACU", value: "7995", uf: "PR" },
  { name: "ILHOTA", value: "8139", uf: "SC" },
  { name: "SIDEROPOLIS", value: "8347", uf: "SC" },
  { name: "SANTANA DO MANHUACU", value: "5177", uf: "MG" },
  { name: "AQUIRAZ", value: "1319", uf: "CE" },
  { name: "POCRANE", value: "5037", uf: "MG" },
  { name: "PADRE PARAISO", value: "4925", uf: "MG" },
  { name: "TAPEJARA", value: "7911", uf: "PR" },
  { name: "SANTA HELENA DE GOIAS", value: "9581", uf: "GO" },
  { name: "TRES BARRAS", value: "8359", uf: "SC" },
  { name: "BONITO DE SANTA FE", value: "1949", uf: "PB" },
  { name: "SAPE", value: "2207", uf: "PB" },
  { name: "BERILO", value: "4129", uf: "MG" },
  { name: "ACEGUA", value: "1118", uf: "RS" },
  { name: "BARROS CASSAL", value: "8539", uf: "RS" },
  { name: "SAO JOSE DOS QUATRO MARCOS", value: "8993", uf: "MT" },
  { name: "COREMAS", value: "1997", uf: "PB" },
  { name: "IMBE", value: "7297", uf: "RS" },
  { name: "CURITIBANOS", value: "8093", uf: "SC" },
  { name: "BORBA", value: "0217", uf: "AM" },
  { name: "PORTEIRAS", value: "1523", uf: "CE" },
  { name: "AGROLANDIA", value: "8003", uf: "SC" },
  { name: "CEZARINA", value: "9785", uf: "GO" },
  { name: "ILHA COMPRIDA", value: "2969", uf: "SP" },
  { name: "HERVAL", value: "8639", uf: "RS" },
  { name: "CORREGO NOVO", value: "4399", uf: "MG" },
  { name: "CURIUVA", value: "7537", uf: "PR" },
  { name: "IBIRAMA", value: "8135", uf: "SC" },
  { name: "GALVAO", value: "8109", uf: "SC" },
  { name: "SANTA LUZIA DO PARUA", value: "1285", uf: "MA" },
  { name: "SENADOR CANEDO", value: "9753", uf: "GO" },
  { name: "RUY BARBOSA", value: "3845", uf: "BA" },
  { name: "TENENTE PORTELA", value: "8931", uf: "RS" },
  { name: "NOVA SANTA RITA", value: "5757", uf: "RS" },
  { name: "COARI", value: "0225", uf: "AM" },
  { name: "RODEIRO", value: "5125", uf: "MG" },
  { name: "MARILUZ", value: "7689", uf: "PR" },
  { name: "ALPESTRE", value: "8509", uf: "RS" },
  { name: "ANGICOS", value: "1615", uf: "RN" },
  { name: "SANTA CLARA DO SUL", value: "6033", uf: "RS" },
  { name: "ENTRE IJUIS", value: "8419", uf: "RS" },
  { name: "PINHEIRO PRETO", value: "8255", uf: "SC" },
  { name: "CARLOPOLIS", value: "7491", uf: "PR" },
  { name: "ORIZONA", value: "9503", uf: "GO" },
  { name: "FLORES DO PIAUI", value: "1075", uf: "PI" },
  { name: "RIBEIRAO DO PINHAL", value: "7815", uf: "PR" },
  { name: "SAO JOAO DO OESTE", value: "5753", uf: "SC" },
  { name: "GUAIRA", value: "7571", uf: "PR" },
  { name: "SANTANA DO PARAISO", value: "2673", uf: "MG" },
  { name: "ALFREDO MARCONDES", value: "6115", uf: "SP" },
  { name: "TARTARUGALZINHO", value: "0617", uf: "AP" },
  { name: "ILHA SOLTEIRA", value: "2943", uf: "SP" },
  { name: "NOVA LONDRINA", value: "7725", uf: "PR" },
  { name: "NOVA UNIAO", value: "0010", uf: "RO" },
  { name: "CATUJI", value: "2653", uf: "MG" },
  { name: "CENTENARIO DO SUL", value: "7499", uf: "PR" },
  { name: "CAFEARA", value: "7465", uf: "PR" },
  { name: "PARNAGUA", value: "1151", uf: "PI" },
  { name: "CHAPADAO DO LAGEADO", value: "0908", uf: "SC" },
  { name: "CAMANDUCAIA", value: "4209", uf: "MG" },
  { name: "IPUEIRAS", value: "1419", uf: "CE" },
  { name: "SAO MIGUEL ARCANJO", value: "7105", uf: "SP" },
  { name: "BELO ORIENTE", value: "4125", uf: "MG" },
  { name: "CRUZ DAS ALMAS", value: "3495", uf: "BA" },
  { name: "QUITERIANOPOLIS", value: "9917", uf: "CE" },
  { name: "RIO PARANAIBA", value: "5109", uf: "MG" },
  { name: "CONSELHEIRO MAIRINCK", value: "7519", uf: "PR" },
  { name: "TAQUARAL DE GOIAS", value: "9617", uf: "GO" },
  { name: "AMAPORA", value: "7415", uf: "PR" },
  { name: "JULIO BORGES", value: "0332", uf: "PI" },
  { name: "ITAPITANGA", value: "3633", uf: "BA" },
  { name: "PROFESSOR JAMIL", value: "0051", uf: "GO" },
  { name: "CRAVINHOS", value: "6363", uf: "SP" },
  { name: "FAZENDA RIO GRANDE", value: "9983", uf: "PR" },
  { name: "URUCUI", value: "1223", uf: "PI" },
  { name: "POUSO REDONDO", value: "8269", uf: "SC" },
  { name: "PEDRINHAS PAULISTA", value: "2963", uf: "SP" },
  { name: "MARTINHO CAMPOS", value: "4809", uf: "MG" },
  { name: "CORUMBAIBA", value: "9319", uf: "GO" },
  { name: "MAQUINE", value: "5783", uf: "RS" },
  { name: "CRUZ ALTA", value: "8619", uf: "RS" },
  { name: "PALMEIRAIS", value: "1149", uf: "PI" },
  { name: "SAO BENTO DO SAPUCAI", value: "7073", uf: "SP" },
  { name: "MARACAS", value: "3711", uf: "BA" },
  { name: "XAXIM", value: "8387", uf: "SC" },
  { name: "LUZ", value: "4775", uf: "MG" },
  { name: "SANTA MARIA DA VITORIA", value: "3863", uf: "BA" },
  { name: "SAO JOAO BATISTA", value: "0917", uf: "MA" },
  { name: "SANTA CECILIA", value: "8305", uf: "SC" },
  { name: "TUNEIRAS DO OESTE", value: "7931", uf: "PR" },
  { name: "TUPASSI", value: "7993", uf: "PR" },
  { name: "XAMBRE", value: "7949", uf: "PR" },
  { name: "SANTA MARIA DA BOA VISTA", value: "2551", uf: "PE" },
  { name: "QUERENCIA", value: "0097", uf: "MT" },
  { name: "LAURENTINO", value: "8187", uf: "SC" },
  { name: "SAO CARLOS", value: "8315", uf: "SC" },
  { name: "SAO JOAQUIM DE BICAS", value: "0722", uf: "MG" },
  { name: "CENTRAL DO MARANHAO", value: "0144", uf: "MA" },
  { name: "PILAR", value: "2131", uf: "PB" },
  { name: "ARAGUAINHA", value: "9023", uf: "MT" },
  { name: "TAGUAI", value: "7161", uf: "SP" },
  { name: "ESPERA FELIZ", value: "4483", uf: "MG" },
  { name: "ALTO CAPARAO", value: "0564", uf: "MG" },
  { name: "ITABAIANA", value: "3157", uf: "SE" },
  { name: "ITUPORANGA", value: "8167", uf: "SC" },
  { name: "TERRA NOVA", value: "2603", uf: "PE" },
  { name: "BUENO BRANDAO", value: "4181", uf: "MG" },
  { name: "CONDEUBA", value: "3473", uf: "BA" },
  { name: "CARINHANHA", value: "3441", uf: "BA" },
  { name: "COCAL DE TELHA", value: "0304", uf: "PI" },
  { name: "OLIVEDOS", value: "2111", uf: "PB" },
  { name: "FEIRA NOVA", value: "2407", uf: "PE" },
  { name: "ITABAIANINHA", value: "3159", uf: "SE" },
  { name: "CAIRU", value: "3407", uf: "BA" },
  { name: "TRES DE MAIO", value: "8939", uf: "RS" },
  { name: "CHACARA", value: "4317", uf: "MG" },
  { name: "SAO JOAO DAS DUAS PONTES", value: "7085", uf: "SP" },
  { name: "SAO PEDRO DE ALCANTARA", value: "0944", uf: "SC" },
  { name: "LINHARES", value: "5663", uf: "ES" },
  { name: "INDIAROBA", value: "3155", uf: "SE" },
  { name: "FREI INOCENCIO", value: "4537", uf: "MG" },
  { name: "SANTA LUZIA DO ITANHY", value: "3225", uf: "SE" },
  { name: "VILA FLORES", value: "7311", uf: "RS" },
  { name: "ABADIANIA", value: "9201", uf: "GO" },
  { name: "ARAPONGA", value: "4073", uf: "MG" },
  { name: "AGUA BOA", value: "9191", uf: "MT" },
  { name: "MOJU", value: "0493", uf: "PA" },
  { name: "DIVINOPOLIS DO TOCANTINS", value: "9719", uf: "TO" },
  { name: "REALEZA", value: "7805", uf: "PR" },
  { name: "PORTO DA FOLHA", value: "3211", uf: "SE" },
  { name: "PIRAJUBA", value: "5013", uf: "MG" },
  { name: "RIO BRANCO DO IVAI", value: "0880", uf: "PR" },
  { name: "IRAQUARA", value: "3589", uf: "BA" },
  { name: "SANTANA DE MANGUEIRA", value: "2171", uf: "PB" },
  { name: "NOVA AMERICA", value: "9491", uf: "GO" },
  { name: "VALENCA", value: "3957", uf: "BA" },
  { name: "CENTRO DO GUILHERME", value: "0146", uf: "MA" },
  { name: "MARA ROSA", value: "9451", uf: "GO" },
  { name: "MIRASELVA", value: "7705", uf: "PR" },
  { name: "GUAJARA-MIRIM", value: "0001", uf: "RO" },
  { name: "SUSSUAPARA", value: "0400", uf: "PI" },
  { name: "CEU AZUL", value: "7957", uf: "PR" },
  { name: "SANTA LUCIA", value: "5469", uf: "PR" },
  { name: "ITARANA", value: "5657", uf: "ES" },
  { name: "BARRA DO RIBEIRO", value: "8537", uf: "RS" },
  { name: "ITAPEJARA D'OESTE", value: "7617", uf: "PR" },
  { name: "PAULISTANA", value: "1155", uf: "PI" },
  { name: "EUGENOPOLIS", value: "4497", uf: "MG" },
  { name: "BOA VISTA DA APARECIDA", value: "7981", uf: "PR" },
  { name: "CABECEIRAS", value: "9279", uf: "GO" },
  { name: "CAICO", value: "1639", uf: "RN" },
  { name: "URAI", value: "7941", uf: "PR" },
  { name: "GOVERNADOR NUNES FREIRE", value: "0168", uf: "MA" },
  { name: "GUARANIACU", value: "7581", uf: "PR" },
  { name: "ALTO DO RODRIGUES", value: "1613", uf: "RN" },
  { name: "BROCHIER", value: "8449", uf: "RS" },
  { name: "ITAJA", value: "9411", uf: "GO" },
  { name: "BETANIA DO PIAUI", value: "0278", uf: "PI" },
  { name: "IMBITUVA", value: "7597", uf: "PR" },
  { name: "CONCEICAO DO MATO DENTRO", value: "4349", uf: "MG" },
  { name: "ALAGOA NOVA", value: "1907", uf: "PB" },
  { name: "COMENDADOR LEVY GASPARIAN", value: "2927", uf: "RJ" },
  { name: "ITAMOGI", value: "4657", uf: "MG" },
  { name: "AGUA DOCE DO NORTE", value: "5717", uf: "ES" },
  { name: "MACURURE", value: "3699", uf: "BA" },
  { name: "PONTE ALTA", value: "8261", uf: "SC" },
  { name: "NOVA RUSSAS", value: "1487", uf: "CE" },
  { name: "NHAMUNDA", value: "0263", uf: "AM" },
  { name: "ARAPOTI", value: "7429", uf: "PR" },
  { name: "OLHO D'AGUA DAS CUNHAS", value: "0847", uf: "MA" },
  { name: "TIANGUA", value: "1569", uf: "CE" },
  { name: "FATIMA DO SUL", value: "9075", uf: "MS" },
  { name: "SENHOR DO BONFIM", value: "3901", uf: "BA" },
  { name: "PRESIDENTE DUTRA", value: "0879", uf: "MA" },
  { name: "ITUPIRANGA", value: "0473", uf: "PA" },
  { name: "IBATEGUARA", value: "2759", uf: "AL" },
  { name: "QUIXERE", value: "1531", uf: "CE" },
  { name: "IBATE", value: "6487", uf: "SP" },
  { name: "ASSARE", value: "1331", uf: "CE" },
  { name: "CANDIDO SALES", value: "3433", uf: "BA" },
  { name: "ICONHA", value: "5651", uf: "ES" },
  { name: "DOM JOAQUIM", value: "4451", uf: "MG" },
  { name: "NOVA CANTU", value: "7719", uf: "PR" },
  { name: "AVANHANDAVA", value: "6187", uf: "SP" },
  { name: "IPAUMIRIM", value: "1415", uf: "CE" },
  { name: "TURMALINA", value: "5393", uf: "MG" },
  { name: "ILHABELA", value: "6509", uf: "SP" },
  { name: "MURICILANDIA", value: "0187", uf: "TO" },
  { name: "PIRANHAS", value: "2841", uf: "AL" },
  { name: "MEDINA", value: "4827", uf: "MG" },
  { name: "SITIO DO MATO", value: "3041", uf: "BA" },
  { name: "ABADIA DE GOIAS", value: "1050", uf: "GO" },
  { name: "BROTAS DE MACAUBAS", value: "3389", uf: "BA" },
  { name: "MIRANTE DA SERRA", value: "0697", uf: "RO" },
  { name: "ITAPIRATINS", value: "0347", uf: "TO" },
  { name: "IPUIUNA", value: "4629", uf: "MG" },
  { name: "BANANEIRAS", value: "1931", uf: "PB" },
  { name: "SANTA ROSA DO TOCANTINS", value: "9729", uf: "TO" },
  { name: "TUPANDI", value: "7321", uf: "RS" },
  { name: "VITORIA BRASIL", value: "0828", uf: "SP" },
  { name: "CORDEIROPOLIS", value: "6349", uf: "SP" },
  { name: "DELTA", value: "0602", uf: "MG" },
  { name: "ESTRELA DO NORTE", value: "9351", uf: "GO" },
  { name: "WENCESLAU BRAZ", value: "7943", uf: "PR" },
  { name: "ITANHANDU", value: "4661", uf: "MG" },
  { name: "BOA VISTA DO BURICA", value: "8543", uf: "RS" },
  { name: "PIRAUBA", value: "5025", uf: "MG" },
  { name: "CARNEIROS", value: "2735", uf: "AL" },
  { name: "PINHALZINHO", value: "8253", uf: "SC" },
  { name: "MORRO DO PILAR", value: "4873", uf: "MG" },
  { name: "SAO JOAO DO POLESINE", value: "5791", uf: "RS" },
  { name: "MARANGUAPE", value: "1455", uf: "CE" },
  { name: "ANISIO DE ABREU", value: "1013", uf: "PI" },
  { name: "ITUMIRIM", value: "4685", uf: "MG" },
  { name: "TABAPUA", value: "7153", uf: "SP" },
  { name: "ITAOBIM", value: "4665", uf: "MG" },
  { name: "AMAPA DO MARANHAO", value: "0110", uf: "MA" },
  { name: "MACAIBA", value: "1741", uf: "RN" },
  { name: "BARAO DE GRAJAU", value: "0729", uf: "MA" },
  { name: "CAMALAU", value: "1979", uf: "PB" },
  { name: "JOAO CAMARA", value: "1713", uf: "RN" },
  { name: "CAJAZEIRINHAS", value: "0462", uf: "PB" },
  { name: "FIGUEIRA", value: "8457", uf: "PR" },
  { name: "MANICORE", value: "0257", uf: "AM" },
  { name: "CALCADO", value: "2365", uf: "PE" },
  { name: "AUGUSTINOPOLIS", value: "9685", uf: "TO" },
  { name: "PARANACITY", value: "7743", uf: "PR" },
  { name: "ESTRELA DALVA", value: "4491", uf: "MG" },
  { name: "LADAINHA", value: "4739", uf: "MG" },
  { name: "ARAPOEMA", value: "9245", uf: "TO" },
  { name: "SAO MAMEDE", value: "2199", uf: "PB" },
  { name: "TUIUTI", value: "2955", uf: "SP" },
  { name: "LARANJAL DO JARI", value: "0613", uf: "AP" },
  { name: "MARIALVA", value: "7687", uf: "PR" },
  { name: "CASEARA", value: "9717", uf: "TO" },
  { name: "CARRAPATEIRA", value: "1983", uf: "PB" },
  { name: "IGARACY", value: "1953", uf: "PB" },
  { name: "PONTAL DO PARANA", value: "0870", uf: "PR" },
  { name: "UMBURANAS", value: "3047", uf: "BA" },
  { name: "CAJAZEIRAS", value: "1975", uf: "PB" },
  { name: "SAO JOSE DA COROA GRANDE", value: "2567", uf: "PE" },
  { name: "HORTOLANDIA", value: "2951", uf: "SP" },
  { name: "SANTANA DOS GARROTES", value: "2173", uf: "PB" },
  { name: "PRATANIA", value: "0814", uf: "SP" },
  { name: "DOLCINOPOLIS", value: "6385", uf: "SP" },
  { name: "COROATA", value: "0769", uf: "MA" },
  { name: "IBITITA", value: "3563", uf: "BA" },
  { name: "GRAMADO DOS LOUREIROS", value: "5949", uf: "RS" },
  { name: "PIRAQUARA", value: "7769", uf: "PR" },
  { name: "PEIXE", value: "9527", uf: "TO" },
  { name: "FIGUEIROPOLIS", value: "9667", uf: "TO" },
  { name: "CENTRALINA", value: "4315", uf: "MG" },
  { name: "CANAVIEIRAS", value: "3425", uf: "BA" },
  { name: "ITANHOMI", value: "4663", uf: "MG" },
  { name: "ITUTINGA", value: "4689", uf: "MG" },
  { name: "IBITIURA DE MINAS", value: "4597", uf: "MG" },
  { name: "ITAJOBI", value: "6539", uf: "SP" },
  { name: "CANOINHAS", value: "8073", uf: "SC" },
  { name: "AQUIDABA", value: "3103", uf: "SE" },
  { name: "SAO JOSE DA LAPA", value: "2649", uf: "MG" },
  { name: "BORBOREMA", value: "1955", uf: "PB" },
  { name: "ALTO RIO DOCE", value: "4041", uf: "MG" },
  { name: "SAO TOME", value: "7897", uf: "PR" },
  { name: "MONTEIRO LOBATO", value: "6735", uf: "SP" },
  { name: "SAO JOAO DO SABUGI", value: "1839", uf: "RN" },
  { name: "ELDORADO", value: "9173", uf: "MS" },
  { name: "MALHADA", value: "3705", uf: "BA" },
  { name: "SANGAO", value: "5547", uf: "SC" },
  { name: "URANIA", value: "7217", uf: "SP" },
  { name: "BALSA NOVA", value: "7443", uf: "PR" },
  { name: "DIORAMA", value: "9343", uf: "GO" },
  { name: "RONDON DO PARA", value: "0573", uf: "PA" },
  { name: "SANTO ANTONIO DO ARACANGUA", value: "2939", uf: "SP" },
  { name: "IPIRA", value: "8149", uf: "SC" },
  { name: "LAJINHA", value: "4753", uf: "MG" },
  { name: "REDENCAO", value: "1533", uf: "CE" },
  { name: "IPUPIARA", value: "3583", uf: "BA" },
  { name: "AGUA BRANCA", value: "1901", uf: "PB" },
  { name: "CAMPO FORMOSO", value: "3419", uf: "BA" },
  { name: "ABRE CAMPO", value: "4005", uf: "MG" },
  { name: "PEDRA PRETA", value: "9181", uf: "MT" },
  { name: "ITABAIANA", value: "2039", uf: "PB" },
  { name: "TEOTONIO VILELA", value: "0971", uf: "AL" },
  { name: "ELISEU MARTINS", value: "1071", uf: "PI" },
  { name: "DOURADO", value: "6387", uf: "SP" },
  { name: "MIRADOR", value: "0833", uf: "MA" },
  { name: "BREJO GRANDE DO ARAGUAIA", value: "0577", uf: "PA" },
  { name: "MIRANDA DO NORTE", value: "1283", uf: "MA" },
  { name: "SILVEIRANIA", value: "5345", uf: "MG" },
  { name: "CAFARNAUM", value: "3405", uf: "BA" },
  { name: "SAO BENTO DO TOCANTINS", value: "0197", uf: "TO" },
  { name: "SANTO ANTONIO DO ICA", value: "0273", uf: "AM" },
  { name: "FORMOSO DO ARAGUAIA", value: "9365", uf: "TO" },
  { name: "BOCAIUVA", value: "4145", uf: "MG" },
  { name: "JACINTO", value: "4693", uf: "MG" },
  { name: "PETROLANDIA", value: "8249", uf: "SC" },
  { name: "SANTO ANTONIO DO PARAISO", value: "7863", uf: "PR" },
  { name: "INDEPENDENCIA", value: "1413", uf: "CE" },
  { name: "SANTALUZ", value: "3861", uf: "BA" },
  { name: "GOIOERE", value: "7569", uf: "PR" },
  { name: "PIEDADE DOS GERAIS", value: "5007", uf: "MG" },
  { name: "CARLOS BARBOSA", value: "8593", uf: "RS" },
  { name: "MASSAPE", value: "1461", uf: "CE" },
  { name: "PIEDADE DO RIO GRANDE", value: "5005", uf: "MG" },
  { name: "FRANCISCO SA", value: "4533", uf: "MG" },
  { name: "LAGOA DOS PATOS", value: "4745", uf: "MG" },
  { name: "DUMONT", value: "6393", uf: "SP" },
  { name: "MATOZINHOS", value: "4821", uf: "MG" },
  { name: "TREZE DE MAIO", value: "8361", uf: "SC" },
  { name: "PALMA SOLA", value: "8235", uf: "SC" },
  { name: "OUREM", value: "0507", uf: "PA" },
  { name: "LAGOA NOVA", value: "1729", uf: "RN" },
  { name: "RUBINEIA", value: "6991", uf: "SP" },
  { name: "MAFRA", value: "8199", uf: "SC" },
  { name: "DELFINOPOLIS", value: "4423", uf: "MG" },
  { name: "COLINA", value: "6341", uf: "SP" },
  { name: "FERNANDO PRESTES", value: "6413", uf: "SP" },
  { name: "TURVO", value: "8369", uf: "SC" },
  { name: "CONQUISTA", value: "4363", uf: "MG" },
  { name: "CAREACU", value: "4271", uf: "MG" },
  { name: "JACOBINA", value: "3651", uf: "BA" },
  { name: "PAULA CANDIDO", value: "4965", uf: "MG" },
  { name: "SAO FRANCISCO DE SALES", value: "5225", uf: "MG" },
  { name: "SETE QUEDAS", value: "9813", uf: "MS" },
  { name: "PETROLINA", value: "2521", uf: "PE" },
  { name: "FAINA", value: "9797", uf: "GO" },
  { name: "IPUMIRIM", value: "8151", uf: "SC" },
  { name: "JARDIM DO MULATO", value: "0997", uf: "PI" },
  { name: "PARANHOS", value: "9739", uf: "MS" },
  { name: "JARDIM DO SERIDO", value: "1711", uf: "RN" },
  { name: "PARAZINHO", value: "1775", uf: "RN" },
  { name: "MONTE ALEGRE", value: "1755", uf: "RN" },
  { name: "WAGNER", value: "3967", uf: "BA" },
  { name: "SAO JOSE DO NORTE", value: "8873", uf: "RS" },
  { name: "SALGADO FILHO", value: "7833", uf: "PR" },
  { name: "COLONIA DO PIAUI", value: "2253", uf: "PI" },
  { name: "GARIBALDI", value: "8669", uf: "RS" },
  { name: "CANUTAMA", value: "0219", uf: "AM" },
  { name: "TIMBURI", value: "7193", uf: "SP" },
  { name: "ROSARIO DO SUL", value: "8831", uf: "RS" },
  { name: "BAIXIO", value: "1335", uf: "CE" },
  { name: "SAO JOSE DO JACURI", value: "5269", uf: "MG" },
  { name: "MAR DE ESPANHA", value: "4795", uf: "MG" },
  { name: "MATINHOS", value: "7963", uf: "PR" },
  { name: "BOQUEIRAO DO LEAO", value: "8483", uf: "RS" },
  { name: "UMBAUBA", value: "3251", uf: "SE" },
  { name: "RIO MARIA", value: "0569", uf: "PA" },
  { name: "GUANAMBI", value: "3533", uf: "BA" },
  { name: "PENHA", value: "8245", uf: "SC" },
  { name: "ITAMBACURI", value: "4653", uf: "MG" },
  { name: "NOVA INDEPENDENCIA", value: "6765", uf: "SP" },
  { name: "NOVA VENEZA", value: "8227", uf: "SC" },
  { name: "PORTO FELIZ", value: "6913", uf: "SP" },
  { name: "DESCOBERTO", value: "4425", uf: "MG" },
  { name: "CARIDADE DO PIAUI", value: "0300", uf: "PI" },
  { name: "FORMOSA DO OESTE", value: "7561", uf: "PR" },
  { name: "PADRE MARCOS", value: "1143", uf: "PI" },
  { name: "MATIAS BARBOSA", value: "4815", uf: "MG" },
  { name: "FRONTEIRA DOS VALES", value: "4935", uf: "MG" },
  { name: "AVEIRO", value: "0419", uf: "PA" },
  { name: "CARMO DA MATA", value: "4279", uf: "MG" },
  { name: "NOSSA SENHORA DAS GRACAS", value: "7713", uf: "PR" },
  { name: "LINDOIA", value: "6641", uf: "SP" },
  { name: "MIRANDA", value: "9111", uf: "MS" },
  { name: "CAMOCIM", value: "1351", uf: "CE" },
  { name: "PARAOPEBA", value: "4947", uf: "MG" },
  { name: "ABAETETUBA", value: "0401", uf: "PA" },
  { name: "GUATAPARA", value: "7257", uf: "SP" },
  { name: "UARINI", value: "9849", uf: "AM" },
  { name: "CAMPO ALEGRE DE LOURDES", value: "3417", uf: "BA" },
  { name: "SANTA RITA", value: "0901", uf: "MA" },
  { name: "MIRASSOLANDIA", value: "6709", uf: "SP" },
  { name: "ANTONIO DIAS", value: "4059", uf: "MG" },
  { name: "SAO FRANCISCO DO PARA", value: "0547", uf: "PA" },
  { name: "UBAJARA", value: "1573", uf: "CE" },
  { name: "POUSO ALTO", value: "5051", uf: "MG" },
  { name: "URUPA", value: "0977", uf: "RO" },
  { name: "WANDERLANDIA", value: "9665", uf: "TO" },
  { name: "ANTONIO ALMEIDA", value: "1015", uf: "PI" },
  { name: "RIACHO DE SANTO ANTONIO", value: "0508", uf: "PB" },
  { name: "BOM JARDIM", value: "0955", uf: "MA" },
  { name: "COITE DO NOIA", value: "2739", uf: "AL" },
  { name: "CONGONHAS DO NORTE", value: "4361", uf: "MG" },
  { name: "ARAMARI", value: "3343", uf: "BA" },
  { name: "BOM JESUS DE GOIAS", value: "9269", uf: "GO" },
  { name: "CAMPO GRANDE", value: "2729", uf: "AL" },
  { name: "SAO JERONIMO DA SERRA", value: "7869", uf: "PR" },
  { name: "NOVA LARANJEIRAS", value: "5479", uf: "PR" },
  { name: "FLORIDA PAULISTA", value: "6421", uf: "SP" },
  { name: "BURITI BRAVO", value: "0743", uf: "MA" },
  { name: "TUPARENDI", value: "8949", uf: "RS" },
  { name: "BARRA DOS COQUEIROS", value: "3111", uf: "SE" },
  { name: "TRES FRONTEIRAS", value: "7199", uf: "SP" },
  { name: "AGUAS DE SANTA BARBARA", value: "7019", uf: "SP" },
  { name: "RIVERSUL", value: "6971", uf: "SP" },
  { name: "NOVA CANAA", value: "3755", uf: "BA" },
  { name: "ARARIPINA", value: "2321", uf: "PE" },
  { name: "ESTRELA DO INDAIA", value: "4493", uf: "MG" },
  { name: "ARARA", value: "1917", uf: "PB" },
  { name: "DESCANSO", value: "8095", uf: "SC" },
  { name: "CANDIDO MOTA", value: "6301", uf: "SP" },
  { name: "INHANGAPI", value: "0467", uf: "PA" },
  { name: "CRISTAIS PAULISTA", value: "6365", uf: "SP" },
  { name: "RIBEIROPOLIS", value: "3219", uf: "SE" },
  { name: "ITACURUBI", value: "7397", uf: "RS" },
  { name: "NATIVIDADE DA SERRA", value: "6747", uf: "SP" },
  { name: "MARAVILHAS", value: "4793", uf: "MG" },
  { name: "PRESIDENTE BERNARDES", value: "5061", uf: "MG" },
  { name: "FRANCISCO BADARO", value: "4529", uf: "MG" },
  { name: "NANUQUE", value: "4885", uf: "MG" },
  { name: "SANTA FILOMENA", value: "1183", uf: "PI" },
  { name: "CANINDE", value: "1355", uf: "CE" },
  { name: "IPIACU", value: "4627", uf: "MG" },
  { name: "COLUNA", value: "4335", uf: "MG" },
  { name: "SAO MARTINHO", value: "8885", uf: "RS" },
  { name: "INDAIABIRA", value: "0628", uf: "MG" },
  { name: "PIRAI DO SUL", value: "7767", uf: "PR" },
  { name: "GUARANI DE GOIAS", value: "9383", uf: "GO" },
  { name: "AGUA AZUL DO NORTE", value: "0383", uf: "PA" },
  { name: "JUARINA", value: "0349", uf: "TO" },
  { name: "PRACUUBA", value: "0673", uf: "AP" },
  { name: "BARROQUINHA", value: "1237", uf: "CE" },
  { name: "DIRCEU ARCOVERDE", value: "1229", uf: "PI" },
  { name: "BOM JESUS", value: "1945", uf: "PB" },
  { name: "CAPAO DO LEAO", value: "8973", uf: "RS" },
  { name: "RIO PRETO DA EVA", value: "9843", uf: "AM" },
  { name: "FREI MIGUELINHO", value: "2415", uf: "PE" },
  { name: "IJACI", value: "4607", uf: "MG" },
  { name: "GUARANI", value: "4567", uf: "MG" },
  { name: "PAULO DE FARIA", value: "6833", uf: "SP" },
  { name: "RONCADOR", value: "7827", uf: "PR" },
  { name: "KALORE", value: "7655", uf: "PR" },
  { name: "SAO GONCALO DO SAPUCAI", value: "5239", uf: "MG" },
  { name: "MUCUGE", value: "3739", uf: "BA" },
  { name: "ITAPEVA", value: "4671", uf: "MG" },
  { name: "TEODORO SAMPAIO", value: "3927", uf: "BA" },
  { name: "ALFREDO WAGNER", value: "8013", uf: "SC" },
  { name: "BOM CONSELHO", value: "2341", uf: "PE" },
  { name: "ASTORGA", value: "7439", uf: "PR" },
  { name: "COLINAS", value: "0767", uf: "MA" },
  { name: "ITAQUIRAI", value: "9807", uf: "MS" },
  { name: "RESPLENDOR", value: "5085", uf: "MG" },
  { name: "SENHORA DO PORTO", value: "5321", uf: "MG" },
  { name: "PAES LANDIM", value: "1145", uf: "PI" },
  { name: "IRACEMA DO OESTE", value: "5485", uf: "PR" },
  { name: "BURITI ALEGRE", value: "9277", uf: "GO" },
  { name: "CARDOSO", value: "6315", uf: "SP" },
  { name: "INACIO MARTINS", value: "7599", uf: "PR" },
  { name: "AGUDO", value: "8501", uf: "RS" },
  { name: "TOCANTINS", value: "5379", uf: "MG" },
  { name: "PAICANDU", value: "7731", uf: "PR" },
  { name: "CAPIM BRANCO", value: "4249", uf: "MG" },
  { name: "OBIDOS", value: "0501", uf: "PA" },
  { name: "CABRALIA PAULISTA", value: "6267", uf: "SP" },
  { name: "CLEVELANDIA", value: "7509", uf: "PR" },
  { name: "HULHA NEGRA", value: "6085", uf: "RS" },
  { name: "CORONEL SAPUCAIA", value: "9997", uf: "MS" },
  { name: "VERA CRUZ", value: "3963", uf: "BA" },
  { name: "ORTIGUEIRA", value: "7727", uf: "PR" },
  { name: "TIBAGI", value: "7923", uf: "PR" },
  { name: "ARARUNA", value: "7431", uf: "PR" },
  { name: "MONTE APRAZIVEL", value: "6729", uf: "SP" },
  { name: "BATALHA", value: "1029", uf: "PI" },
  { name: "CAICARA", value: "8567", uf: "RS" },
  { name: "VIGIA", value: "0563", uf: "PA" },
  { name: "DIVINOLANDIA", value: "6379", uf: "SP" },
  { name: "SIQUEIRA CAMPOS", value: "7907", uf: "PR" },
  { name: "CAMPANARIO", value: "4215", uf: "MG" },
  { name: "SABINOPOLIS", value: "5135", uf: "MG" },
  { name: "IVAI", value: "7621", uf: "PR" },
  { name: "MARIOPOLIS", value: "7693", uf: "PR" },
  { name: "VARGEM BONITA", value: "5565", uf: "SC" },
  { name: "CAPELINHA", value: "4245", uf: "MG" },
  { name: "OIAPOQUE", value: "0609", uf: "AP" },
  { name: "QUEDAS DO IGUACU", value: "7955", uf: "PR" },
  { name: "BOM SUCESSO DO SUL", value: "9979", uf: "PR" },
  { name: "PALMITOS", value: "8237", uf: "SC" },
  { name: "BELTERRA", value: "0044", uf: "PA" },
  { name: "CANTO DO BURITI", value: "1045", uf: "PI" },
  { name: "MARIANA PIMENTEL", value: "5759", uf: "RS" },
  { name: "ESPIRITO SANTO DO DOURADO", value: "4487", uf: "MG" },
  { name: "CAMPINA DA LAGOA", value: "7475", uf: "PR" },
  { name: "CONCEICAO DO ARAGUAIA", value: "0453", uf: "PA" },
  { name: "ZABELE", value: "0542", uf: "PB" },
  { name: "MOREILANDIA", value: "2585", uf: "PE" },
  { name: "CHAPADAO DO SUL", value: "9787", uf: "MS" },
  { name: "GUAIRACA", value: "7573", uf: "PR" },
  { name: "TERRA RICA", value: "7919", uf: "PR" },
  { name: "SAO JOSE DO GOIABAL", value: "5267", uf: "MG" },
  { name: "JOAIMA", value: "4719", uf: "MG" },
  { name: "MUNIZ FERREIRA", value: "3745", uf: "BA" },
  { name: "MONGAGUA", value: "6723", uf: "SP" },
  { name: "GUARARA", value: "4569", uf: "MG" },
  { name: "SEABRA", value: "3897", uf: "BA" },
  { name: "MARMELEIRO", value: "7695", uf: "PR" },
  { name: "PAULINO NEVES", value: "0206", uf: "MA" },
  { name: "MUTUM", value: "4879", uf: "MG" },
  { name: "BAIA DA TRAICAO", value: "1929", uf: "PB" },
  { name: "SERRA TALHADA", value: "2577", uf: "PE" },
  { name: "SAO BORJA", value: "8863", uf: "RS" },
  { name: "SERTANEJA", value: "7903", uf: "PR" },
  { name: "SAO MIGUEL DE TAIPU", value: "2201", uf: "PB" },
  { name: "BUERAREMA", value: "3393", uf: "BA" },
  { name: "OLARIA", value: "4907", uf: "MG" },
  { name: "VICENCIA", value: "2625", uf: "PE" },
  { name: "ABELARDO LUZ", value: "8001", uf: "SC" },
  { name: "IBIRAPITANGA", value: "3555", uf: "BA" },
  { name: "RIO NOVO", value: "5107", uf: "MG" },
  { name: "GUARAQUECABA", value: "7585", uf: "PR" },
  { name: "INDIANOPOLIS", value: "7961", uf: "PR" },
  { name: "GOIANINHA", value: "1681", uf: "RN" },
  { name: "JAGUARARI", value: "3655", uf: "BA" },
  { name: "MAE D'AGUA", value: "2075", uf: "PB" },
  { name: "IPORA", value: "7605", uf: "PR" },
  { name: "DELMIRO GOUVEIA", value: "2747", uf: "AL" },
  { name: "POMERODE", value: "8259", uf: "SC" },
  { name: "CODO", value: "0763", uf: "MA" },
  { name: "VARGEM ALTA", value: "5727", uf: "ES" },
  { name: "UAUA", value: "3939", uf: "BA" },
  { name: "SANTO ANTONIO DOS LOPES", value: "0903", uf: "MA" },
  { name: "MOEDA", value: "4845", uf: "MG" },
  { name: "CONCEICAO DA BARRA", value: "5631", uf: "ES" },
  { name: "MAE DO RIO", value: "0587", uf: "PA" },
  { name: "GUARARAPES", value: "6465", uf: "SP" },
  { name: "VERDELANDIA", value: "0752", uf: "MG" },
  { name: "TANQUE NOVO", value: "3991", uf: "BA" },
  { name: "CAPARAO", value: "4241", uf: "MG" },
  { name: "SERTANIA", value: "2581", uf: "PE" },
  { name: "RANCHO QUEIMADO", value: "8281", uf: "SC" },
  { name: "MANDURI", value: "6673", uf: "SP" },
  { name: "SELVIRIA", value: "9811", uf: "MS" },
  { name: "VITORIA DE SANTO ANTAO", value: "2627", uf: "PE" },
  { name: "COQUEIRAL", value: "4373", uf: "MG" },
  { name: "MARCELINO VIEIRA", value: "1745", uf: "RN" },
  { name: "CORIBE", value: "3481", uf: "BA" },
  { name: "TARAUACA", value: "0147", uf: "AC" },
  { name: "LEBON REGIS", value: "8191", uf: "SC" },
  { name: "ENGENHEIRO CALDAS", value: "4473", uf: "MG" },
  { name: "SAO LOURENCO DO OESTE", value: "8333", uf: "SC" },
  { name: "BARRA DO CORDA", value: "0731", uf: "MA" },
  { name: "RIOZINHO", value: "7353", uf: "RS" },
  { name: "PORTO BELO", value: "8265", uf: "SC" },
  { name: "ORLEANS", value: "8229", uf: "SC" },
  { name: "SIMOES", value: "1213", uf: "PI" },
  { name: "JAPARAIBA", value: "4705", uf: "MG" },
  { name: "ELESBAO VELOSO", value: "1069", uf: "PI" },
  { name: "MORADA NOVA DE MINAS", value: "4869", uf: "MG" },
  { name: "TABULEIRO", value: "5357", uf: "MG" },
  { name: "SANTA ROSA DE LIMA", value: "8307", uf: "SC" },
  { name: "CLAUDIA", value: "9789", uf: "MT" },
  { name: "EUCLIDES DA CUNHA PAULISTA", value: "7255", uf: "SP" },
  { name: "JERONIMO MONTEIRO", value: "5661", uf: "ES" },
  { name: "AMARAL FERRADOR", value: "8493", uf: "RS" },
  { name: "IBIQUERA", value: "3553", uf: "BA" },
  { name: "BREVES", value: "0435", uf: "PA" },
  { name: "UNIAO DOS PALMARES", value: "2885", uf: "AL" },
  { name: "UNIAO", value: "1221", uf: "PI" },
  { name: "ITATIAIUCU", value: "4673", uf: "MG" },
  { name: "LONTRA", value: "2695", uf: "MG" },
  { name: "VARZEA ALEGRE", value: "1581", uf: "CE" },
  { name: "GLAUCILANDIA", value: "0616", uf: "MG" },
  { name: "ACUCENA", value: "4009", uf: "MG" },
  { name: "MARIA HELENA", value: "7685", uf: "PR" },
  { name: "PEQUERI", value: "4989", uf: "MG" },
  { name: "CONCEICAO DO RIO VERDE", value: "4353", uf: "MG" },
  { name: "SANTO ANTONIO DO SUDOESTE", value: "7857", uf: "PR" },
  { name: "NAZARE PAULISTA", value: "6749", uf: "SP" },
  { name: "MACHACALIS", value: "4777", uf: "MG" },
  { name: "SANTA ISABEL DO PARA", value: "0529", uf: "PA" },
  { name: "CARDOSO MOREIRA", value: "2915", uf: "RJ" },
  { name: "REMANSO", value: "3821", uf: "BA" },
  { name: "IBITIARA", value: "3561", uf: "BA" },
  { name: "JOAO RAMALHO", value: "6613", uf: "SP" },
  { name: "PRESIDENTE KENNEDY", value: "5685", uf: "ES" },
  { name: "SAO PEDRO DO TURVO", value: "7111", uf: "SP" },
  { name: "PALMITINHO", value: "8779", uf: "RS" },
  { name: "QUITANDINHA", value: "7801", uf: "PR" },
  { name: "JAGUARAO", value: "8721", uf: "RS" },
  { name: "CONCEICAO DE MACABU", value: "5827", uf: "RJ" },
  { name: "IBIRATAIA", value: "3559", uf: "BA" },
  { name: "RONDON", value: "7829", uf: "PR" },
  { name: "BELA VISTA DO MARANHAO", value: "0122", uf: "MA" },
  { name: "IPUA", value: "6527", uf: "SP" },
  { name: "MARIO CAMPOS", value: "0648", uf: "MG" },
  { name: "MONTANHA", value: "5669", uf: "ES" },
  { name: "BURITIRAMA", value: "3079", uf: "BA" },
  { name: "LAGOA D'ANTA", value: "1723", uf: "RN" },
  { name: "URUARA", value: "0399", uf: "PA" },
  { name: "IPUBI", value: "2445", uf: "PE" },
  { name: "CACAULANDIA", value: "0677", uf: "RO" },
  { name: "SAO JOSE DO BARREIRO", value: "7093", uf: "SP" },
  { name: "XIQUE-XIQUE", value: "3971", uf: "BA" },
  { name: "CARLOS CHAGAS", value: "4273", uf: "MG" },
  { name: "LUIS EDUARDO MAGALHAES", value: "1112", uf: "BA" },
  { name: "JURUTI", value: "0477", uf: "PA" },
  { name: "SAO PAULO DO POTENGI", value: "1849", uf: "RN" },
  { name: "SANTA MARIA", value: "0424", uf: "RN" },
  { name: "SELBACH", value: "8907", uf: "RS" },
  { name: "TABATINGA", value: "9847", uf: "AM" },
  { name: "CAPITAO", value: "6025", uf: "RS" },
  { name: "IPIRA", value: "3581", uf: "BA" },
  { name: "SALESOPOLIS", value: "7001", uf: "SP" },
  { name: "RIO FORMOSO", value: "2537", uf: "PE" },
  { name: "EMILIANOPOLIS", value: "2961", uf: "SP" },
  { name: "RIO GRANDE DA SERRA", value: "6983", uf: "SP" },
  { name: "NOVA LUZITANIA", value: "6767", uf: "SP" },
  { name: "SAO PAULO DAS MISSOES", value: "8889", uf: "RS" },
  { name: "VIRGOLANDIA", value: "5439", uf: "MG" },
  { name: "SAO FRANCISCO DO GUAPORE", value: "0020", uf: "RO" },
  { name: "PACAJA", value: "0593", uf: "PA" },
  { name: "JARAGUARI", value: "9097", uf: "MS" },
  { name: "RIO DO CAMPO", value: "8285", uf: "SC" },
  { name: "DOM AQUINO", value: "9071", uf: "MT" },
  { name: "PALMA", value: "4933", uf: "MG" },
  { name: "SERRA DO RAMALHO", value: "3039", uf: "BA" },
  { name: "ARAGUAPAZ", value: "9669", uf: "GO" },
  { name: "SANTA RITA D'OESTE", value: "7049", uf: "SP" },
  { name: "URUSSANGA", value: "8373", uf: "SC" },
  { name: "AGUAS FRIAS", value: "5577", uf: "SC" },
  { name: "SAO BENTO DO UNA", value: "2559", uf: "PE" },
  { name: "BREJO GRANDE", value: "3113", uf: "SE" },
  { name: "PAINEIRAS", value: "4927", uf: "MG" },
  { name: "SANTANA", value: "0615", uf: "AP" },
  { name: "GUARACIABA", value: "4563", uf: "MG" },
  { name: "POCO FUNDO", value: "5033", uf: "MG" },
  { name: "TOUROS", value: "1885", uf: "RN" },
  { name: "ARIPUANA", value: "9027", uf: "MT" },
  { name: "SOMBRIO", value: "8349", uf: "SC" },
  { name: "LAGOINHA", value: "6627", uf: "SP" },
  { name: "RIO CRESPO", value: "0687", uf: "RO" },
  { name: "VALPARAISO", value: "7227", uf: "SP" },
  { name: "PONTAL DO ARAGUAIA", value: "0095", uf: "MT" },
  { name: "GOVERNADOR DIX-SEPT ROSADO", value: "1683", uf: "RN" },
  { name: "OTACILIO COSTA", value: "8397", uf: "SC" },
  { name: "TAPERA", value: "8923", uf: "RS" },
  { name: "POCO DANTAS", value: "0496", uf: "PB" },
  { name: "MANDAGUACU", value: "7675", uf: "PR" },
  { name: "CAPELA DO ALTO", value: "6307", uf: "SP" },
  { name: "ENGENHEIRO BELTRAO", value: "7547", uf: "PR" },
  { name: "ARACOIABA", value: "1323", uf: "CE" },
  { name: "ACOPIARA", value: "1305", uf: "CE" },
  { name: "MOZARLANDIA", value: "9477", uf: "GO" },
  { name: "ATALAIA", value: "7441", uf: "PR" },
  { name: "MONSENHOR HIPOLITO", value: "1129", uf: "PI" },
  { name: "ITIRUCU", value: "3639", uf: "BA" },
  { name: "TUTOIA", value: "0943", uf: "MA" },
  { name: "MAREMA", value: "9963", uf: "SC" },
  { name: "DOM SILVERIO", value: "4453", uf: "MG" },
  { name: "PARAGUACU PAULISTA", value: "6811", uf: "SP" },
  { name: "ITAUNA DO SUL", value: "7619", uf: "PR" },
  { name: "VALENTIM GENTIL", value: "7223", uf: "SP" },
  { name: "RAPOSOS", value: "5077", uf: "MG" },
  { name: "ASSU", value: "1603", uf: "RN" },
  { name: "JIJOCA DE JERICOACOARA", value: "0985", uf: "CE" },
  { name: "SAO JOSE DO VALE DO RIO PRETO", value: "6009", uf: "RJ" },
  { name: "ANAPU", value: "0040", uf: "PA" },
  { name: "CAJOBI", value: "6287", uf: "SP" },
  { name: "SAO PEDRO DO SUL", value: "8891", uf: "RS" },
  { name: "BURITICUPU", value: "0134", uf: "MA" },
  { name: "SALVADOR DAS MISSOES", value: "6061", uf: "RS" },
  { name: "NAVEGANTES", value: "8221", uf: "SC" },
  { name: "AGRESTINA", value: "2305", uf: "PE" },
  { name: "DOM BOSCO", value: "0606", uf: "MG" },
  { name: "PONTE BRANCA", value: "9133", uf: "MT" },
  { name: "ROQUE GONZALES", value: "8829", uf: "RS" },
  { name: "SILVIANOPOLIS", value: "5347", uf: "MG" },
  { name: "PESCADOR", value: "4999", uf: "MG" },
  { name: "CORDISLANDIA", value: "4379", uf: "MG" },
  { name: "CAMPO BELO DO SUL", value: "8065", uf: "SC" },
  { name: "SANTA ISABEL", value: "9689", uf: "GO" },
  { name: "ARGIRITA", value: "4087", uf: "MG" },
  { name: "NOVA ALVORADA DO SUL", value: "0143", uf: "MS" },
  { name: "URANDI", value: "3951", uf: "BA" },
  { name: "CACHOEIRA ALTA", value: "9281", uf: "GO" },
  { name: "PRESIDENTE MEDICI", value: "0019", uf: "RO" },
  { name: "QUARAI", value: "8809", uf: "RS" },
  { name: "ITABIRINHA", value: "4635", uf: "MG" },
  { name: "SAO JOSE DO POVO", value: "6087", uf: "MT" },
  { name: "MANDAGUARI", value: "7677", uf: "PR" },
  { name: "SURUBIM", value: "2589", uf: "PE" },
  { name: "RESERVA", value: "7811", uf: "PR" },
  { name: "CANDIDO DE ABREU", value: "7485", uf: "PR" },
  { name: "BOM PRINCIPIO", value: "9823", uf: "RS" },
  { name: "GANDU", value: "3523", uf: "BA" },
  { name: "SAO JOSE DAS PALMEIRAS", value: "8471", uf: "PR" },
  { name: "NOVA SOURE", value: "3759", uf: "BA" },
  { name: "NHANDEARA", value: "6753", uf: "SP" },
  { name: "SANTA INES", value: "0512", uf: "PB" },
  { name: "CABEDELO", value: "1965", uf: "PB" },
  { name: "JEQUITINHONHA", value: "4715", uf: "MG" },
  { name: "BUJARI", value: "0645", uf: "AC" },
  { name: "ALTO GARCAS", value: "9007", uf: "MT" },
  { name: "IPIAU", value: "3579", uf: "BA" },
  { name: "SAO FRANCISCO DE PAULA", value: "8867", uf: "RS" },
  { name: "ITUBERA", value: "3647", uf: "BA" },
  { name: "GUARATINGA", value: "3535", uf: "BA" },
  { name: "TIJUCAS", value: "8355", uf: "SC" },
  { name: "GAUCHA DO NORTE", value: "1038", uf: "MT" },
  { name: "PATY DO ALFERES", value: "6005", uf: "RJ" },
  { name: "INOCENCIA", value: "9087", uf: "MS" },
  { name: "COCAL", value: "1053", uf: "PI" },
  { name: "RIO LARGO", value: "2853", uf: "AL" },
  { name: "MUCAMBO", value: "1481", uf: "CE" },
  { name: "VIRGEM DA LAPA", value: "5433", uf: "MG" },
  { name: "CEDRO DE SAO JOAO", value: "3131", uf: "SE" },
  { name: "CATUIPE", value: "8597", uf: "RS" },
  { name: "NOVA ESPERANCA DO PIRIA", value: "0391", uf: "PA" },
  { name: "ALTAMIRA DO MARANHAO", value: "0707", uf: "MA" },
  { name: "ALTONIA", value: "7951", uf: "PR" },
  { name: "PACATUBA", value: "1495", uf: "CE" },
  { name: "RIO REAL", value: "3841", uf: "BA" },
  { name: "QUINZE DE NOVEMBRO", value: "7357", uf: "RS" },
  { name: "CORGUINHO", value: "9061", uf: "MS" },
  { name: "JUTAI", value: "0249", uf: "AM" },
  { name: "EREBANGO", value: "8417", uf: "RS" },
  { name: "CANGUCU", value: "8587", uf: "RS" },
  { name: "IBIRAJUBA", value: "2433", uf: "PE" },
  { name: "DOM CAVATI", value: "4449", uf: "MG" },
  { name: "MARIAPOLIS", value: "6679", uf: "SP" },
  { name: "NOVA BRESCIA", value: "8763", uf: "RS" },
  { name: "CAMETA", value: "0441", uf: "PA" },
  { name: "ITALVA", value: "5929", uf: "RJ" },
  { name: "PORTO FIRME", value: "5045", uf: "MG" },
  { name: "VARRE-SAI", value: "2917", uf: "RJ" },
  { name: "CACHOEIRA DOURADA", value: "4195", uf: "MG" },
  { name: "SABAUDIA", value: "7831", uf: "PR" },
  { name: "LIMOEIRO DE ANADIA", value: "2783", uf: "AL" },
  { name: "NEVES PAULISTA", value: "6751", uf: "SP" },
  { name: "SAO JOAO DA BARRA", value: "5899", uf: "RJ" },
  { name: "CUSTODIA", value: "2401", uf: "PE" },
  { name: "BOM REPOUSO", value: "4157", uf: "MG" },
  { name: "ARAPUTANGA", value: "8989", uf: "MT" },
  { name: "JANIOPOLIS", value: "7637", uf: "PR" },
  { name: "SANTA HELENA", value: "0895", uf: "MA" },
  { name: "MARACACUME", value: "0190", uf: "MA" },
  { name: "GUAPIACU", value: "6451", uf: "SP" },
  { name: "VALE REAL", value: "6049", uf: "RS" },
  { name: "CARMO DO CAJURU", value: "4283", uf: "MG" },
  { name: "LAJEADO", value: "0351", uf: "TO" },
  { name: "JAU DO TOCANTINS", value: "0329", uf: "TO" },
  { name: "SAO JOAO DO RIO DO PEIXE", value: "1913", uf: "PB" },
  { name: "CAPELA DE SANTANA", value: "8443", uf: "RS" },
  { name: "ESPIRITO SANTO DO TURVO", value: "7253", uf: "SP" },
  { name: "VILA BELA DA SANTISSIMA TRINDADE", value: "9109", uf: "MT" },
  { name: "GUIRICEMA", value: "4579", uf: "MG" },
  { name: "PIRACAIA", value: "6873", uf: "SP" },
  { name: "MARCO", value: "1457", uf: "CE" },
  { name: "CAATIBA", value: "3395", uf: "BA" },
  { name: "IGARAPE-MIRI", value: "0465", uf: "PA" },
  { name: "COCAL DO SUL", value: "5543", uf: "SC" },
  { name: "DOM MACEDO COSTA", value: "3503", uf: "BA" },
  { name: "PORTO GRANDE", value: "0671", uf: "AP" },
  { name: "BARRA DE SAO FRANCISCO", value: "5617", uf: "ES" },
  { name: "LAGOA GRANDE", value: "0552", uf: "PE" },
  { name: "CACOAL", value: "0009", uf: "RO" },
  { name: "SANTA LUZIA D'OESTE", value: "0043", uf: "RO" },
  { name: "IRARA", value: "3591", uf: "BA" },
  { name: "CALDAS BRANDAO", value: "1977", uf: "PB" },
  { name: "PARACURU", value: "1505", uf: "CE" },
  { name: "SAO SEBASTIAO DO MARANHAO", value: "5289", uf: "MG" },
  { name: "COQUEIRO SECO", value: "2743", uf: "AL" },
  { name: "SAO LUDGERO", value: "8335", uf: "SC" },
  { name: "ITAPETIM", value: "2453", uf: "PE" },
  { name: "INCONFIDENTES", value: "4611", uf: "MG" },
  { name: "FELIZ DESERTO", value: "2753", uf: "AL" },
  { name: "SAPUCAI-MIRIM", value: "5307", uf: "MG" },
  { name: "ARCEBURGO", value: "4081", uf: "MG" },
  { name: "AFOGADOS DA INGAZEIRA", value: "2301", uf: "PE" },
  { name: "IUNA", value: "5659", uf: "ES" },
  { name: "OEIRAS DO PARA", value: "0503", uf: "PA" },
  { name: "CALDAZINHA", value: "0031", uf: "GO" },
  { name: "CASTELO DO PIAUI", value: "1051", uf: "PI" },
  { name: "ALTO ALEGRE DO MARANHAO", value: "0106", uf: "MA" },
  { name: "ERVALIA", value: "4479", uf: "MG" },
  { name: "ALTO PARAISO DE GOIAS", value: "9211", uf: "GO" },
  { name: "SAO JOSE DO CERRITO", value: "8331", uf: "SC" },
  { name: "TRAIPU", value: "2883", uf: "AL" },
  { name: "COSTA MARQUES", value: "0021", uf: "RO" },
  { name: "SERTAO", value: "8911", uf: "RS" },
  { name: "DOUTOR CAMARGO", value: "7543", uf: "PR" },
  { name: "SAO JORGE DO IVAI", value: "7879", uf: "PR" },
  { name: "ITAMARANDIBA", value: "4649", uf: "MG" },
  { name: "CARAZINHO", value: "8591", uf: "RS" },
  { name: "MISSAL", value: "8469", uf: "PR" },
  { name: "NOVO ACORDO", value: "9499", uf: "TO" },
  { name: "FORMIGUEIRO", value: "8665", uf: "RS" },
  { name: "RAUL SOARES", value: "5079", uf: "MG" },
  { name: "VISTA ALEGRE", value: "7307", uf: "RS" },
  { name: "SANTANA DO CARIRI", value: "1543", uf: "CE" },
  { name: "TUPANCIRETA", value: "8947", uf: "RS" },
  { name: "MANOEL RIBAS", value: "7681", uf: "PR" },
  { name: "BOM JESUS", value: "0900", uf: "SC" },
  { name: "TRIUNFO", value: "2237", uf: "PB" },
  { name: "MARATAIZES", value: "0760", uf: "ES" },
  { name: "SANTA ISABEL DO IVAI", value: "7849", uf: "PR" },
  { name: "BONITO", value: "2345", uf: "PE" },
  { name: "SERIO", value: "6035", uf: "RS" },
  { name: "AMARANTE", value: "1009", uf: "PI" },
  { name: "URUTAI", value: "9637", uf: "GO" },
  { name: "PANCAS", value: "5679", uf: "ES" },
  { name: "PIRENOPOLIS", value: "9543", uf: "GO" },
  { name: "MATIPO", value: "4817", uf: "MG" },
  { name: "SALOA", value: "2545", uf: "PE" },
  { name: "DENISE", value: "9833", uf: "MT" },
  { name: "COROADOS", value: "6351", uf: "SP" },
  { name: "JOCA CLAUDINO", value: "0514", uf: "PB" },
  { name: "PINHEIROS", value: "5681", uf: "ES" },
  { name: "SAO DOMINGOS", value: "8317", uf: "SC" },
  { name: "GADO BRAVO", value: "0480", uf: "PB" },
  { name: "SAO FRANCISCO DO BREJAO", value: "0230", uf: "MA" },
  { name: "BARRA VELHA", value: "8041", uf: "SC" },
  { name: "SANTA TERESINHA", value: "2177", uf: "PB" },
  { name: "ANCHIETA", value: "5607", uf: "ES" },
  { name: "ATALEIA", value: "4093", uf: "MG" },
  { name: "MIMOSO DO SUL", value: "5667", uf: "ES" },
  { name: "NOBRES", value: "9117", uf: "MT" },
  { name: "MEDEIROS", value: "4825", uf: "MG" },
  { name: "VICTOR GRAEFF", value: "8969", uf: "RS" },
  { name: "TAMBORIL", value: "1565", uf: "CE" },
  { name: "TAPEROA", value: "2231", uf: "PB" },
  { name: "MAMANGUAPE", value: "2079", uf: "PB" },
  { name: "AGUA FRIA DE GOIAS", value: "9771", uf: "GO" },
  { name: "COMBINADO", value: "9697", uf: "TO" },
  { name: "SAO FRANCISCO DO GLORIA", value: "5227", uf: "MG" },
  { name: "CAETITE", value: "3403", uf: "BA" },
  { name: "DIVINA PASTORA", value: "3139", uf: "SE" },
  { name: "SIDROLANDIA", value: "9157", uf: "MS" },
  { name: "POJUCA", value: "3805", uf: "BA" },
  { name: "JERUMENHA", value: "1105", uf: "PI" },
  { name: "BIRITIBA-MIRIM", value: "6231", uf: "SP" },
  { name: "ENEAS MARQUES", value: "7545", uf: "PR" },
  { name: "PASSO DE TORRES", value: "5541", uf: "SC" },
  { name: "CAPITAO POCO", value: "0445", uf: "PA" },
  { name: "CAMBUCI", value: "5817", uf: "RJ" },
  { name: "MANGUEIRINHA", value: "7511", uf: "PR" },
  { name: "UNISTALDA", value: "1024", uf: "RS" },
  { name: "BRASILEIA", value: "0105", uf: "AC" },
  { name: "CONCEICAO DO TOCANTINS", value: "9313", uf: "TO" },
  { name: "PALMARES DO SUL", value: "8967", uf: "RS" },
  { name: "GAROPABA", value: "8113", uf: "SC" },
  { name: "SEROPEDICA", value: "0786", uf: "RJ" },
  { name: "BOA VISTA", value: "0458", uf: "PB" },
  { name: "INDIAPORA", value: "6515", uf: "SP" },
  { name: "PENAFORTE", value: "1513", uf: "CE" },
  { name: "TREMEDAL", value: "3935", uf: "BA" },
  { name: "DIVISA ALEGRE", value: "0604", uf: "MG" },
  { name: "SAO GABRIEL DO OESTE", value: "9809", uf: "MS" },
  { name: "POCOES", value: "3803", uf: "BA" },
  { name: "SALGADO DE SAO FELIX", value: "2163", uf: "PB" },
  { name: "ITACARE", value: "3599", uf: "BA" },
  { name: "CANTAGALO", value: "5821", uf: "RJ" },
  { name: "SUCUPIRA", value: "0335", uf: "TO" },
  { name: "CROATA", value: "1241", uf: "CE" },
  { name: "SALGADINHO", value: "2541", uf: "PE" },
  { name: "CIDADE GAUCHA", value: "7507", uf: "PR" },
  { name: "DOM ELISEU", value: "0583", uf: "PA" },
  { name: "ALTO PARANA", value: "7409", uf: "PR" },
  { name: "BELMIRO BRAGA", value: "4121", uf: "MG" },
  { name: "IEPE", value: "6499", uf: "SP" },
  { name: "ICARAIMA", value: "7593", uf: "PR" },
  { name: "CAPISTRANO", value: "1357", uf: "CE" },
  { name: "BATALHA", value: "2713", uf: "AL" },
  { name: "RIACHO DAS ALMAS", value: "2533", uf: "PE" },
  { name: "ABARE", value: "3303", uf: "BA" },
  { name: "NOVA UNIAO", value: "4731", uf: "MG" },
  { name: "UBIRETAMA", value: "1022", uf: "RS" },
  { name: "PIRAJUI", value: "6879", uf: "SP" },
  { name: "PEROLA", value: "7969", uf: "PR" },
  { name: "CANHOTINHO", value: "2373", uf: "PE" },
  { name: "ITAPIRANGA", value: "8165", uf: "SC" },
  { name: "IPUEIRAS", value: "0084", uf: "TO" },
  { name: "ARROIO DO SAL", value: "8489", uf: "RS" },
  { name: "ROCHEDO", value: "9149", uf: "MS" },
  { name: "IPERO", value: "6521", uf: "SP" },
  { name: "PIRIPA", value: "3795", uf: "BA" },
  { name: "JEQUITAI", value: "4711", uf: "MG" },
  { name: "PERI MIRIM", value: "0865", uf: "MA" },
  { name: "CORURIPE", value: "2745", uf: "AL" },
  { name: "BORRAZOPOLIS", value: "7463", uf: "PR" },
  { name: "ELOI MENDES", value: "4471", uf: "MG" },
  { name: "SANTA BARBARA DO MONTE VERDE", value: "0700", uf: "MG" },
  { name: "BODOCO", value: "2339", uf: "PE" },
  { name: "MARIPA DE MINAS", value: "4803", uf: "MG" },
  { name: "SAO MIGUEL DO GOSTOSO", value: "0430", uf: "RN" },
  { name: "PATO BRAGADO", value: "5533", uf: "PR" },
  { name: "URUCANIA", value: "5409", uf: "MG" },
  { name: "CRISTINO CASTRO", value: "1061", uf: "PI" },
  { name: "IBATIBA", value: "5709", uf: "ES" },
  { name: "SANTANA DO MATOS", value: "1827", uf: "RN" },
  { name: "GLORIA", value: "3527", uf: "BA" },
  { name: "CAAPORA", value: "1961", uf: "PB" },
  { name: "CARAUBAS", value: "1645", uf: "RN" },
  { name: "OURO BRANCO", value: "2821", uf: "AL" },
  { name: "ARAL MOREIRA", value: "9171", uf: "MS" },
  { name: "BIQUINHAS", value: "4139", uf: "MG" },
  { name: "RAPOSA", value: "0218", uf: "MA" },
  { name: "BRASOPOLIS", value: "4177", uf: "MG" },
  { name: "QUEIMADAS", value: "3817", uf: "BA" },
  { name: "CAMPOS NOVOS", value: "8069", uf: "SC" },
  { name: "CAMBIRA", value: "7473", uf: "PR" },
  { name: "SAO PEDRO DO IVAI", value: "7891", uf: "PR" },
  { name: "SALGADINHO", value: "2161", uf: "PB" },
  { name: "BARREIRA", value: "1235", uf: "CE" },
  { name: "JATOBA", value: "0550", uf: "PE" },
  { name: "DOUTOR RICARDO", value: "0978", uf: "RS" },
  { name: "VOLTA GRANDE", value: "5443", uf: "MG" },
  { name: "LUPERCIO", value: "6657", uf: "SP" },
  { name: "NATALANDIA", value: "0658", uf: "MG" },
  { name: "SANTA BARBARA DO PARA", value: "0369", uf: "PA" },
  { name: "SANTAREM NOVO", value: "0537", uf: "PA" },
  { name: "MATARACA", value: "2087", uf: "PB" },
  { name: "RIO DAS OSTRAS", value: "2921", uf: "RJ" },
  { name: "AREIA BRANCA", value: "3109", uf: "SE" },
  { name: "ACARAU", value: "1303", uf: "CE" },
  { name: "MALHADOR", value: "3177", uf: "SE" },
  { name: "MARINOPOLIS", value: "6683", uf: "SP" },
  { name: "SALTO DO LONTRA", value: "7837", uf: "PR" },
  { name: "QUEIROZ", value: "6937", uf: "SP" },
  { name: "NOVA PALMA", value: "8765", uf: "RS" },
  { name: "SAO FELIX DO PIAUI", value: "1191", uf: "PI" },
  { name: "PAULO RAMOS", value: "0959", uf: "MA" },
  { name: "TACAIMBO", value: "2593", uf: "PE" },
  { name: "BOA VISTA DO SUL", value: "0960", uf: "RS" },
  { name: "TRAIRI", value: "1571", uf: "CE" },
  { name: "FLORANIA", value: "1675", uf: "RN" },
  { name: "CAMBARA DO SUL", value: "8571", uf: "RS" },
  { name: "DORES DO INDAIA", value: "4463", uf: "MG" },
  { name: "SANTA LEOPOLDINA", value: "5689", uf: "ES" },
  { name: "GUARANI DAS MISSOES", value: "8689", uf: "RS" },
  { name: "VITORINO", value: "7947", uf: "PR" },
  { name: "CAMPINACU", value: "9687", uf: "GO" },
  { name: "JABORA", value: "8169", uf: "SC" },
  { name: "PEDRO CANARIO", value: "5715", uf: "ES" },
  { name: "PRIMAVERA DE RONDONIA", value: "0016", uf: "RO" },
  { name: "PRADO", value: "3811", uf: "BA" },
  { name: "MANOEL VIANA", value: "6079", uf: "RS" },
  { name: "SANTO ANTONIO DAS MISSOES", value: "8857", uf: "RS" },
  { name: "ITATUBA", value: "2045", uf: "PB" },
  { name: "NOVA FATIMA", value: "7723", uf: "PR" },
  { name: "PALMAS DE MONTE ALTO", value: "3769", uf: "BA" },
  { name: "NOVA OLIMPIA", value: "7967", uf: "PR" },
  { name: "CANDIOTA", value: "6083", uf: "RS" },
  { name: "NISIA FLORESTA", value: "1763", uf: "RN" },
  { name: "DORES DO RIO PRETO", value: "5639", uf: "ES" },
  { name: "PAVERAMA", value: "7371", uf: "RS" },
  { name: "MORADA NOVA", value: "1475", uf: "CE" },
  { name: "BELA VISTA DE GOIAS", value: "9265", uf: "GO" },
  { name: "NOVA REDENCAO", value: "3011", uf: "BA" },
  { name: "HELIOPOLIS", value: "3097", uf: "BA" },
  { name: "IACANGA", value: "6483", uf: "SP" },
  { name: "ENGENHO VELHO", value: "5947", uf: "RS" },
  { name: "GUARACI", value: "7579", uf: "PR" },
  { name: "JAGUAPITA", value: "7631", uf: "PR" },
  { name: "FAXINAL", value: "7549", uf: "PR" },
  { name: "BOCAIUVA DO SUL", value: "7459", uf: "PR" },
  { name: "SAO FELIX DO TOCANTINS", value: "0363", uf: "TO" },
  { name: "BETANIA", value: "2335", uf: "PE" },
  { name: "ARACARIGUAMA", value: "3067", uf: "SP" },
  { name: "JAGUARIAIVA", value: "7633", uf: "PR" },
  { name: "DESTERRO DE ENTRE RIOS", value: "4427", uf: "MG" },
  { name: "CONCORDIA DO PARA", value: "0579", uf: "PA" },
  { name: "ENCANTO", value: "1665", uf: "RN" },
  { name: "AGUDOS DO SUL", value: "7405", uf: "PR" },
  { name: "BRAS PIRES", value: "4173", uf: "MG" },
  { name: "MIRABELA", value: "4839", uf: "MG" },
  { name: "SAPEZAL", value: "1046", uf: "MT" },
  { name: "CANGUARETAMA", value: "1643", uf: "RN" },
  { name: "SANTA CRUZ CABRALIA", value: "3855", uf: "BA" },
  { name: "VISEU", value: "0565", uf: "PA" },
  { name: "ARACATU", value: "3339", uf: "BA" },
  { name: "ITATIM", value: "3283", uf: "BA" },
  { name: "ROSANA", value: "7265", uf: "SP" },
  { name: "BRAUNA", value: "6255", uf: "SP" },
  { name: "ARROIO DO TIGRE", value: "8523", uf: "RS" },
  { name: "JOAQUIM FELICIO", value: "4727", uf: "MG" },
  { name: "PAULO JACINTO", value: "2831", uf: "AL" },
  { name: "CAMPOS BORGES", value: "8445", uf: "RS" },
  { name: "JACAREACANGA", value: "0631", uf: "PA" },
  { name: "SANTO ANTONIO DO LEVERGER", value: "9155", uf: "MT" },
  { name: "ATILIO VIVACQUA", value: "5613", uf: "ES" },
  { name: "BOFETE", value: "6237", uf: "SP" },
  { name: "TAPIRAI", value: "5363", uf: "MG" },
  { name: "IBIPORA", value: "7591", uf: "PR" },
  { name: "MOREIRA SALES", value: "7707", uf: "PR" },
  { name: "CASA NOVA", value: "3443", uf: "BA" },
  { name: "QUADRA", value: "0816", uf: "SP" },
  { name: "FELISBURGO", value: "4511", uf: "MG" },
  { name: "DOURADINA", value: "9805", uf: "MS" },
  { name: "ESTRELA DO SUL", value: "4495", uf: "MG" },
  { name: "ITAPIUNA", value: "1431", uf: "CE" },
  { name: "ELDORADO DO SUL", value: "8423", uf: "RS" },
  { name: "ARMAZEM", value: "8029", uf: "SC" },
  { name: "ALUMINIO", value: "3065", uf: "SP" },
  { name: "BREJO ALEGRE", value: "0792", uf: "SP" },
  { name: "RAFARD", value: "6943", uf: "SP" },
  { name: "RENASCENCA", value: "7809", uf: "PR" },
  { name: "SERICITA", value: "5325", uf: "MG" },
  { name: "MENDES PIMENTEL", value: "4829", uf: "MG" },
  { name: "MARCELANDIA", value: "9899", uf: "MT" },
  { name: "BAMBUI", value: "4101", uf: "MG" },
  { name: "ANHEMBI", value: "6145", uf: "SP" },
  { name: "SAPEACU", value: "3891", uf: "BA" },
  { name: "CONTENDA", value: "7521", uf: "PR" },
  { name: "PLANALTO", value: "7775", uf: "PR" },
  { name: "TAMBOARA", value: "7909", uf: "PR" },
  { name: "BARRA DE SAO MIGUEL", value: "2711", uf: "AL" },
  { name: "MORRINHOS", value: "1479", uf: "CE" },
  { name: "MARIZOPOLIS", value: "0486", uf: "PB" },
  { name: "ITAMBE", value: "7615", uf: "PR" },
  { name: "MONTE ALEGRE DO SUL", value: "6725", uf: "SP" },
  { name: "SAO JOAO DO IVAI", value: "7875", uf: "PR" },
  { name: "PEDRO ALEXANDRE", value: "3785", uf: "BA" },
  { name: "MARACANA", value: "0485", uf: "PA" },
  { name: "ROCA SALES", value: "8819", uf: "RS" },
  { name: "CAMPO ALEGRE DE GOIAS", value: "9295", uf: "GO" },
  { name: "ENCANTADO", value: "8633", uf: "RS" },
  { name: "SERTANOPOLIS", value: "7905", uf: "PR" },
  { name: "PIRATUBA", value: "8257", uf: "SC" },
  { name: "TAPAUA", value: "0279", uf: "AM" },
  { name: "VICOSA DO CEARA", value: "1583", uf: "CE" },
  { name: "GUAPIRAMA", value: "7575", uf: "PR" },
  { name: "SAPOPEMA", value: "7899", uf: "PR" },
  { name: "ALVORADA DO SUL", value: "7413", uf: "PR" },
  { name: "LIDIANOPOLIS", value: "5507", uf: "PR" },
  { name: "SANTA BRANCA", value: "7021", uf: "SP" },
  { name: "SAO FRANCISCO DO CONDE", value: "3883", uf: "BA" },
  { name: "BOM JESUS DO TOCANTINS", value: "0575", uf: "PA" },
  { name: "PEROLA D'OESTE", value: "7759", uf: "PR" },
  { name: "LAVRAS DA MANGABEIRA", value: "1451", uf: "CE" },
  { name: "EUCLIDES DA CUNHA", value: "3513", uf: "BA" },
  { name: "SERRA DO SALITRE", value: "5335", uf: "MG" },
  { name: "ACAJUTIBA", value: "3305", uf: "BA" },
  { name: "ANGELINA", value: "8017", uf: "SC" },
  { name: "SERRANA", value: "7131", uf: "SP" },
  { name: "APODI", value: "1619", uf: "RN" },
  { name: "MATA DE SAO JOAO", value: "3721", uf: "BA" },
  { name: "BREU BRANCO", value: "0625", uf: "PA" },
  { name: "SAO PEDRO DO IGUACU", value: "5489", uf: "PR" },
  { name: "JATAIZINHO", value: "7647", uf: "PR" },
  { name: "NOVA TIMBOTEUA", value: "0499", uf: "PA" },
  { name: "HORIZONTINA", value: "8691", uf: "RS" },
  { name: "RIANAPOLIS", value: "9567", uf: "GO" },
  { name: "ITAQUITINGA", value: "2455", uf: "PE" },
  { name: "PALMITAL", value: "7737", uf: "PR" },
  { name: "SALTINHO", value: "5445", uf: "SP" },
  { name: "MERIDIANO", value: "6693", uf: "SP" },
  { name: "APIACAS", value: "9773", uf: "MT" },
  { name: "NOVA TEBAS", value: "9913", uf: "PR" },
  { name: "CARMOPOLIS DE MINAS", value: "4289", uf: "MG" },
  { name: "JOAQUIM TAVORA", value: "7649", uf: "PR" },
  { name: "QUATIS", value: "2923", uf: "RJ" },
  { name: "CONCEICAO DO PARA", value: "4351", uf: "MG" },
  { name: "SAO FELIX", value: "3879", uf: "BA" },
  { name: "MANSIDAO", value: "3977", uf: "BA" },
  { name: "SAO FRANCISCO DE ITABAPOANA", value: "0782", uf: "RJ" },
  { name: "TUCANO", value: "3937", uf: "BA" },
  { name: "IPIXUNA DO PARA", value: "0621", uf: "PA" },
  { name: "SAO FRANCISCO DO SUL", value: "8319", uf: "SC" },
  { name: "EPITACIOLANDIA", value: "0651", uf: "AC" },
  { name: "SANTO HIPOLITO", value: "5211", uf: "MG" },
  { name: "MURITIBA", value: "3747", uf: "BA" },
  { name: "DONA EMMA", value: "8099", uf: "SC" },
  { name: "GILBUES", value: "1087", uf: "PI" },
  { name: "COLORADO", value: "7515", uf: "PR" },
  { name: "MOSSAMEDES", value: "9475", uf: "GO" },
  { name: "JUNDIAI DO SUL", value: "7651", uf: "PR" },
  { name: "SAO SEBASTIAO DO UATUMA", value: "9845", uf: "AM" },
  { name: "MARAGOGIPE", value: "3713", uf: "BA" },
  { name: "CAMPO VERDE", value: "9779", uf: "MT" },
  { name: "LAGOA DO CARRO", value: "2289", uf: "PE" },
  { name: "NOVA FATIMA", value: "3007", uf: "BA" },
  { name: "AURORA", value: "1333", uf: "CE" },
  { name: "NAZARENO", value: "4889", uf: "MG" },
  { name: "SEM-PEIXE", value: "0734", uf: "MG" },
  { name: "LUNARDELLI", value: "8459", uf: "PR" },
  { name: "PORANGA", value: "1521", uf: "CE" },
  { name: "BAIANOPOLIS", value: "3349", uf: "BA" },
  { name: "CACEQUI", value: "8557", uf: "RS" },
  { name: "IPIRANGA", value: "7603", uf: "PR" },
  { name: "MERUOCA", value: "1465", uf: "CE" },
  { name: "BATURITE", value: "1341", uf: "CE" },
  { name: "REBOUCAS", value: "7807", uf: "PR" },
  { name: "LAGOA FORMOSA", value: "4749", uf: "MG" },
  { name: "TEIXEIRA SOARES", value: "7913", uf: "PR" },
  { name: "APUIARES", value: "1317", uf: "CE" },
  { name: "TRACUATEUA", value: "0068", uf: "PA" },
  { name: "MUNDO NOVO", value: "9651", uf: "GO" },
  { name: "CARNEIRINHO", value: "2685", uf: "MG" },
  { name: "AMERICA DOURADA", value: "3071", uf: "BA" },
  { name: "SAO SEBASTIAO DO OESTE", value: "5291", uf: "MG" },
  { name: "QUIPAPA", value: "2529", uf: "PE" },
  { name: "MERCEDES", value: "5531", uf: "PR" },
  { name: "LUCIANOPOLIS", value: "6651", uf: "SP" },
  { name: "ITAMARAJU", value: "3613", uf: "BA" },
  { name: "JAPORA", value: "0161", uf: "MS" },
  { name: "VARGEM", value: "5563", uf: "SC" },
  { name: "BELMONTE", value: "5745", uf: "SC" },
  { name: "RIACHO DE SANTANA", value: "3829", uf: "BA" },
  { name: "NOVO HORIZONTE DO SUL", value: "0159", uf: "MS" },
  { name: "SANTA LUZIA", value: "3987", uf: "BA" },
  { name: "APERIBE", value: "2919", uf: "RJ" },
  { name: "FORMOSA DO RIO PRETO", value: "3521", uf: "BA" },
  { name: "GOVERNADOR MANGABEIRA", value: "3531", uf: "BA" },
  { name: "PORTO LUCENA", value: "8803", uf: "RS" },
  { name: "AMORINOPOLIS", value: "9217", uf: "GO" },
  { name: "CERRO AZUL", value: "7501", uf: "PR" },
  { name: "UIRAUNA", value: "2239", uf: "PB" },
  { name: "MARZAGAO", value: "9453", uf: "GO" },
  { name: "SANTANA DO ARAGUAIA", value: "0533", uf: "PA" },
  { name: "OSORIO", value: "8773", uf: "RS" },
  { name: "IPORA DO OESTE", value: "9951", uf: "SC" },
  { name: "APUI", value: "0969", uf: "AM" },
  { name: "MACUCO", value: "0776", uf: "RJ" },
  { name: "CAMACAN", value: "3411", uf: "BA" },
  { name: "COMERCINHO", value: "4339", uf: "MG" },
  { name: "MARACAJA", value: "8391", uf: "SC" },
  { name: "SAO MIGUEL DO GUAPORE", value: "0045", uf: "RO" },
  { name: "SAO JORGE", value: "7347", uf: "RS" },
  { name: "CRISTIANO OTONI", value: "4407", uf: "MG" },
  { name: "CORONEL MACEDO", value: "6353", uf: "SP" },
  { name: "MESSIAS", value: "2803", uf: "AL" },
  { name: "MAIRI", value: "3703", uf: "BA" },
  { name: "JESUITAS", value: "7997", uf: "PR" },
  { name: "DESTERRO", value: "2009", uf: "PB" },
  { name: "AIURUOCA", value: "4023", uf: "MG" },
  { name: "SONORA", value: "9757", uf: "MS" },
  { name: "PALMEIROPOLIS", value: "9649", uf: "TO" },
  { name: "SAO MIGUEL DO GUAMA", value: "0551", uf: "PA" },
  { name: "SAO JOAO DO SUL", value: "8323", uf: "SC" },
  { name: "PINHAL DE SAO BENTO", value: "5495", uf: "PR" },
  { name: "COTEGIPE", value: "3487", uf: "BA" },
  { name: "MADRE DE DEUS DE MINAS", value: "4781", uf: "MG" },
  { name: "FORQUILHINHA", value: "0973", uf: "SC" },
  { name: "PORTO AMAZONAS", value: "7781", uf: "PR" },
  { name: "LUMINARIAS", value: "4773", uf: "MG" },
  { name: "SAO JOSE DO RIO CLARO", value: "9199", uf: "MT" },
  { name: "SEGREDO", value: "7317", uf: "RS" },
  { name: "CANDOI", value: "5499", uf: "PR" },
  { name: "IPIRANGA DO NORTE", value: "1184", uf: "MT" },
  { name: "OLHO D'AGUA DAS FLORES", value: "2813", uf: "AL" },
  { name: "SOUTO SOARES", value: "3915", uf: "BA" },
  { name: "PICUI", value: "2129", uf: "PB" },
  { name: "MONTEIRO", value: "2095", uf: "PB" },
  { name: "GURINHATA", value: "4581", uf: "MG" },
  { name: "NOVA PONTE", value: "4899", uf: "MG" },
  { name: "CUITE", value: "2003", uf: "PB" },
  { name: "MARUMBI", value: "7697", uf: "PR" },
  { name: "SANTO CRISTO", value: "8861", uf: "RS" },
  { name: "PIRATINI", value: "8795", uf: "RS" },
  { name: "QUINTA DO SOL", value: "7799", uf: "PR" },
  { name: "SIMOLANDIA", value: "9755", uf: "GO" },
  { name: "IPU", value: "1417", uf: "CE" },
  { name: "RUROPOLIS", value: "0597", uf: "PA" },
  { name: "PINHALAO", value: "7763", uf: "PR" },
  { name: "LAMBARI D'OESTE", value: "0137", uf: "MT" },
  { name: "UNA", value: "3949", uf: "BA" },
  { name: "HIDROLANDIA", value: "9389", uf: "GO" },
  { name: "SAO JOSE DO CALCADO", value: "5695", uf: "ES" },
  { name: "BOQUIRA", value: "3381", uf: "BA" },
  { name: "QUIXADA", value: "1527", uf: "CE" },
  { name: "CRAVOLANDIA", value: "3489", uf: "BA" },
  { name: "NONOAI", value: "8757", uf: "RS" },
  { name: "ITAPICURU", value: "3631", uf: "BA" },
  { name: "SAO JOSE DO EGITO", value: "2571", uf: "PE" },
  { name: "ITAPIPOCA", value: "1429", uf: "CE" },
  { name: "ALAGOINHA", value: "1909", uf: "PB" },
  { name: "JUTI", value: "9923", uf: "MS" },
  { name: "BELEM DO SAO FRANCISCO", value: "2331", uf: "PE" },
  { name: "ROMARIA", value: "5127", uf: "MG" },
  { name: "NOVA BRASILANDIA", value: "8981", uf: "MT" },
  { name: "ROSARIO DA LIMEIRA", value: "0698", uf: "MG" },
  { name: "RESENDE COSTA", value: "5083", uf: "MG" },
  { name: "PASSA TEMPO", value: "4953", uf: "MG" },
  { name: "TRES COROAS", value: "8937", uf: "RS" },
  { name: "SAUDADE DO IGUACU", value: "5493", uf: "PR" },
  { name: "RONDINHA", value: "8827", uf: "RS" },
  { name: "FOZ DO JORDAO", value: "0856", uf: "PR" },
  { name: "CRISTINA", value: "4409", uf: "MG" },
  { name: "ABAIRA", value: "3301", uf: "BA" },
  { name: "CHAPADA", value: "8603", uf: "RS" },
  { name: "SENADOR FIRMINO", value: "5313", uf: "MG" },
  { name: "SANTA CRUZ DO ARARI", value: "0527", uf: "PA" },
  { name: "SANTIAGO", value: "8851", uf: "RS" },
  { name: "RIACHUELO", value: "1815", uf: "RN" },
  { name: "TUNTUM", value: "0939", uf: "MA" },
  { name: "NATUBA", value: "2099", uf: "PB" },
  { name: "CAMPO DO BRITO", value: "3119", uf: "SE" },
  { name: "VICENTINA", value: "9187", uf: "MS" },
  { name: "LAJES", value: "1733", uf: "RN" },
  { name: "COELHO NETO", value: "0765", uf: "MA" },
  { name: "CRISTIANOPOLIS", value: "9327", uf: "GO" },
  { name: "ITAPERUCU", value: "5451", uf: "PR" },
  { name: "ANAGE", value: "3323", uf: "BA" },
  { name: "RIACHO DOS MACHADOS", value: "5089", uf: "MG" },
  { name: "ALTO HORIZONTE", value: "0085", uf: "GO" },
  { name: "IACRI", value: "6485", uf: "SP" },
  { name: "CRATEUS", value: "1383", uf: "CE" },
  { name: "SAO JOSE DA BOA VISTA", value: "7883", uf: "PR" },
  { name: "PLANALTO", value: "6893", uf: "SP" },
  { name: "BUIQUE", value: "2355", uf: "PE" },
  { name: "SALES", value: "6997", uf: "SP" },
  { name: "INAJA", value: "2439", uf: "PE" },
  { name: "CAMPOS SALES", value: "1353", uf: "CE" },
  { name: "ILHA DE ITAMARACA", value: "2451", uf: "PE" },
  { name: "PEDRA AZUL", value: "4973", uf: "MG" },
  { name: "PAUDALHO", value: "2511", uf: "PE" },
  { name: "SAO JORGE D'OESTE", value: "7881", uf: "PR" },
  { name: "SAO DOMINGOS", value: "9591", uf: "GO" },
  { name: "SANTA MARIA DO HERVAL", value: "7337", uf: "RS" },
  { name: "VICENTINOPOLIS", value: "9657", uf: "GO" },
  { name: "URUCUCA", value: "3953", uf: "BA" },
  { name: "SAO BENEDITO DO SUL", value: "2557", uf: "PE" },
  { name: "LUPIONOPOLIS", value: "7669", uf: "PR" },
  { name: "PAPAGAIOS", value: "4937", uf: "MG" },
  { name: "JACINTO MACHADO", value: "8171", uf: "SC" },
  { name: "LIBERDADE", value: "4769", uf: "MG" },
  { name: "LAGOA DE ITAENGA", value: "2469", uf: "PE" },
  { name: "RIBEIRAO BONITO", value: "6959", uf: "SP" },
  { name: "NOVO PROGRESSO", value: "0633", uf: "PA" },
  { name: "RIBEIRAO CORRENTE", value: "6963", uf: "SP" },
  { name: "FLORESTA", value: "7555", uf: "PR" },
  { name: "SAO PAULO DE OLIVENCA", value: "0275", uf: "AM" },
  { name: "CARAUARI", value: "0221", uf: "AM" },
  { name: "CHOPINZINHO", value: "7503", uf: "PR" },
  { name: "TAQUARANA", value: "2881", uf: "AL" },
  { name: "JUSSARA", value: "7653", uf: "PR" },
  { name: "PANTANO GRANDE", value: "7375", uf: "RS" },
  { name: "TRINDADE", value: "2611", uf: "PE" },
  { name: "LAGOA DE DENTRO", value: "2065", uf: "PB" },
  { name: "FLORESTOPOLIS", value: "7557", uf: "PR" },
  { name: "ESPIGAO D'OESTE", value: "0025", uf: "RO" },
  { name: "PARINTINS", value: "0269", uf: "AM" },
  { name: "AROEIRAS", value: "1925", uf: "PB" },
  { name: "CORUMBATAI DO SUL", value: "8479", uf: "PR" },
  { name: "SUME", value: "2227", uf: "PB" },
  { name: "ARACOIABA", value: "0544", uf: "PE" },
  { name: "TAVARES", value: "2233", uf: "PB" },
  { name: "TAILANDIA", value: "0395", uf: "PA" },
  { name: "CONCEICAO DO CANINDE", value: "1055", uf: "PI" },
  { name: "CORRENTINA", value: "3485", uf: "BA" },
  { name: "GUARABIRA", value: "2027", uf: "PB" },
  { name: "SOLEDADE", value: "8919", uf: "RS" },
  { name: "FREI ROGERIO", value: "0918", uf: "SC" },
  { name: "IBIARA", value: "2033", uf: "PB" },
  { name: "FUNILANDIA", value: "4543", uf: "MG" },
  { name: "RIO TINTO", value: "2159", uf: "PB" },
  { name: "RIO BRANCO DO SUL", value: "7821", uf: "PR" },
  { name: "PRINCESA ISABEL", value: "2147", uf: "PB" },
  { name: "JECEABA", value: "4707", uf: "MG" },
  { name: "ITAPOA", value: "9985", uf: "SC" },
  { name: "EXTREMOZ", value: "1671", uf: "RN" },
  { name: "ATALANTA", value: "8035", uf: "SC" },
  { name: "RIO DE CONTAS", value: "3835", uf: "BA" },
  { name: "PEROBAL", value: "0868", uf: "PR" },
  { name: "QUATIGUA", value: "7793", uf: "PR" },
  { name: "BOM JARDIM DA SERRA", value: "8389", uf: "SC" },
  { name: "CAJATI", value: "2967", uf: "SP" },
  { name: "QUEIMADAS", value: "2151", uf: "PB" },
  { name: "CATENDE", value: "2383", uf: "PE" },
  { name: "INHAUMA", value: "4619", uf: "MG" },
  { name: "JARDIM DE PIRANHAS", value: "1709", uf: "RN" },
  { name: "TEUTONIA", value: "9821", uf: "RS" },
  { name: "ALIANCA", value: "2313", uf: "PE" },
  { name: "ARRAIAL DO CABO", value: "5927", uf: "RJ" },
  { name: "IBIPITANGA", value: "3551", uf: "BA" },
  { name: "CAFEZAL DO SUL", value: "5491", uf: "PR" },
  { name: "MATOS COSTA", value: "8209", uf: "SC" },
  { name: "NOVO CRUZEIRO", value: "4905", uf: "MG" },
  { name: "PARAISO DO NORTE", value: "7741", uf: "PR" },
  { name: "IBIRACU", value: "5649", uf: "ES" },
  { name: "JARU", value: "0015", uf: "RO" },
  { name: "MARAPOAMA", value: "2977", uf: "SP" },
  { name: "CATOLANDIA", value: "3447", uf: "BA" },
  { name: "GENERAL CAMARA", value: "8673", uf: "RS" },
  { name: "INDIANOPOLIS", value: "4613", uf: "MG" },
  { name: "EXU", value: "2405", uf: "PE" },
  { name: "LEOPOLIS", value: "7661", uf: "PR" },
  { name: "MARAIAL", value: "2483", uf: "PE" },
  { name: "ITAMBE", value: "2597", uf: "PE" },
  { name: "SAO SEBASTIAO", value: "2875", uf: "AL" },
  { name: "MONTAURI", value: "7387", uf: "RS" },
  { name: "CAMPINAS DO PIAUI", value: "1041", uf: "PI" },
  { name: "XAMBIOA", value: "9643", uf: "TO" },
  { name: "JABOTI", value: "7627", uf: "PR" },
  { name: "AGUANIL", value: "4015", uf: "MG" },
  { name: "SANANDUVA", value: "8835", uf: "RS" },
  { name: "LUZILANDIA", value: "1115", uf: "PI" },
  { name: "URUBICI", value: "8371", uf: "SC" },
  { name: "LUIS ANTONIO", value: "6653", uf: "SP" },
  { name: "JURAMENTO", value: "4735", uf: "MG" },
  { name: "MODELO", value: "8213", uf: "SC" },
  { name: "PONTALINA", value: "9549", uf: "GO" },
  { name: "IPABA", value: "2665", uf: "MG" },
  { name: "LOURDES", value: "2937", uf: "SP" },
  { name: "TOCANTINOPOLIS", value: "9621", uf: "TO" },
  { name: "ARROIO DOS RATOS", value: "8521", uf: "RS" },
  { name: "PARAUAPEBAS", value: "0595", uf: "PA" },
  { name: "JAGUARACU", value: "4699", uf: "MG" },
  { name: "BOCAINA", value: "6235", uf: "SP" },
  { name: "BOM JESUS DA PENHA", value: "4151", uf: "MG" },
  { name: "GLORIA DE DOURADOS", value: "9079", uf: "MS" },
  { name: "PRAIA GRANDE", value: "8271", uf: "SC" },
  { name: "SOORETAMA", value: "0766", uf: "ES" },
  { name: "JAPURA", value: "7641", uf: "PR" },
  { name: "IBIASSUCE", value: "3539", uf: "BA" },
  { name: "ERVAL VELHO", value: "8101", uf: "SC" },
  { name: "CHORO", value: "0993", uf: "CE" },
  { name: "VALENTE", value: "3959", uf: "BA" },
  { name: "QUILOMBO", value: "8279", uf: "SC" },
  { name: "NOVA ITABERABA", value: "5589", uf: "SC" },
  { name: "SALTINHO", value: "0936", uf: "SC" },
  { name: "MURICI", value: "2809", uf: "AL" },
  { name: "QUINTANA", value: "6941", uf: "SP" },
  { name: "SANTANA DO IPANEMA", value: "2859", uf: "AL" },
  { name: "CAIBI", value: "8059", uf: "SC" },
  { name: "TOBIAS BARRETO", value: "3247", uf: "SE" },
  { name: "HUMAITA", value: "0235", uf: "AM" },
  { name: "SANTA MARIA DAS BARREIRAS", value: "0599", uf: "PA" },
  { name: "AREIA", value: "1921", uf: "PB" },
  { name: "AUTAZES", value: "0207", uf: "AM" },
  { name: "MANACAPURU", value: "0253", uf: "AM" },
  { name: "PARAMOTI", value: "1509", uf: "CE" },
  { name: "DOBRADA", value: "6381", uf: "SP" },
  { name: "SAO JOSE DE ESPINHARAS", value: "2189", uf: "PB" },
  { name: "UMARI", value: "1575", uf: "CE" },
  { name: "GOIANDIRA", value: "9369", uf: "GO" },
  { name: "TEJUCUOCA", value: "1277", uf: "CE" },
  { name: "GRAVATAL", value: "8121", uf: "SC" },
  { name: "AGUA DOCE", value: "8007", uf: "SC" },
  { name: "ITAPORA", value: "9089", uf: "MS" },
  { name: "JOSENOPOLIS", value: "0638", uf: "MG" },
  { name: "PERITIBA", value: "8247", uf: "SC" },
  { name: "ARAQUARI", value: "8025", uf: "SC" },
  { name: "ALEGRIA", value: "8497", uf: "RS" },
  { name: "ANCHIETA", value: "8015", uf: "SC" },
  { name: "SALETE", value: "8301", uf: "SC" },
  { name: "MONDAI", value: "8215", uf: "SC" },
  { name: "CAETANOPOLIS", value: "4197", uf: "MG" },
  { name: "LIMOEIRO", value: "2477", uf: "PE" },
  { name: "CURRALINHOS", value: "0310", uf: "PI" },
  { name: "JAQUIRANA", value: "7391", uf: "RS" },
  { name: "LONTRAS", value: "8195", uf: "SC" },
  { name: "TUNAPOLIS", value: "9991", uf: "SC" },
  { name: "ESPERANTINA", value: "1073", uf: "PI" },
  { name: "SAO PEDRO DO PIAUI", value: "1209", uf: "PI" },
  { name: "POTIRETAMA", value: "1271", uf: "CE" },
  { name: "CEDRAL", value: "0759", uf: "MA" },
  { name: "PIQUET CARNEIRO", value: "1519", uf: "CE" },
  { name: "CORUPA", value: "8087", uf: "SC" },
  { name: "CAPIM GROSSO", value: "3083", uf: "BA" },
  { name: "SANTA BRIGIDA", value: "3853", uf: "BA" },
  { name: "FAZENDA NOVA", value: "9353", uf: "GO" },
  { name: "PEABIRU", value: "7757", uf: "PR" },
  { name: "JAGUARIBE", value: "1439", uf: "CE" },
  { name: "ANAURILANDIA", value: "9015", uf: "MS" },
  { name: "PARAMBU", value: "1507", uf: "CE" },
  { name: "BARRO", value: "1339", uf: "CE" },
  { name: "MARTINS", value: "1747", uf: "RN" },
  { name: "SAO VICENTE FERRER", value: "2575", uf: "PE" },
  { name: "GRAO PARA", value: "8119", uf: "SC" },
  { name: "ACARA", value: "0403", uf: "PA" },
  { name: "BRUNOPOLIS", value: "0904", uf: "SC" },
  { name: "GUARUJA DO SUL", value: "8129", uf: "SC" },
  { name: "PORTEL", value: "0515", uf: "PA" },
  { name: "MIGUEL CALMON", value: "3725", uf: "BA" },
  { name: "ALTO ALEGRE DO PINDARE", value: "0108", uf: "MA" },
  { name: "PARAIPABA", value: "1599", uf: "CE" },
  { name: "SAO GONCALO DO AMARANTE", value: "1549", uf: "CE" },
  { name: "BREJO SANTO", value: "1349", uf: "CE" },
  { name: "INDIARA", value: "9681", uf: "GO" },
  { name: "NUPORANGA", value: "6773", uf: "SP" },
  { name: "TABULEIRO DO NORTE", value: "1563", uf: "CE" },
  { name: "CRUZ", value: "1589", uf: "CE" },
  { name: "FRUTUOSO GOMES", value: "1751", uf: "RN" },
  { name: "SANTA VITORIA DO PALMAR", value: "8849", uf: "RS" },
  { name: "PINHEIRO", value: "0869", uf: "MA" },
  { name: "LUTECIA", value: "6659", uf: "SP" },
  { name: "PARICONHA", value: "2645", uf: "AL" },
  { name: "ILOPOLIS", value: "8707", uf: "RS" },
  { name: "BOM JESUS DAS SELVAS", value: "0128", uf: "MA" },
  { name: "SATIRO DIAS", value: "3893", uf: "BA" },
  { name: "ALTOS", value: "1007", uf: "PI" },
  { name: "VENTANIA", value: "5497", uf: "PR" },
  { name: "JAICOS", value: "1103", uf: "PI" },
  { name: "PRIMAVERA", value: "2527", uf: "PE" },
  { name: "PIEN", value: "7761", uf: "PR" },
  { name: "SAO JOAO DO ORIENTE", value: "5251", uf: "MG" },
  { name: "COLONIA DO GURGUEIA", value: "2249", uf: "PI" },
  { name: "SAO JOAO", value: "2563", uf: "PE" },
  { name: "SAO JOAO DO PIAUI", value: "1199", uf: "PI" },
  { name: "BRASILEIRA", value: "2283", uf: "PI" },
  { name: "NOVA TRENTO", value: "8225", uf: "SC" },
  { name: "MOMBACA", value: "1471", uf: "CE" },
  { name: "FORTALEZA DOS VALOS", value: "9827", uf: "RS" },
  { name: "MACAMBARA", value: "0994", uf: "RS" },
  { name: "SAO SIMAO", value: "9605", uf: "GO" },
  { name: "POLONI", value: "6899", uf: "SP" },
  { name: "CEDRO", value: "1375", uf: "CE" },
  { name: "CANDELARIA", value: "8581", uf: "RS" },
  { name: "ARAPUA", value: "4075", uf: "MG" },
  { name: "CRUZEIRO DO IGUACU", value: "5473", uf: "PR" },
  { name: "ZE DOCA", value: "1287", uf: "MA" },
  { name: "NOVO ITACOLOMI", value: "5517", uf: "PR" },
  { name: "SANTO AUGUSTO", value: "8859", uf: "RS" },
  { name: "HUMAITA", value: "8695", uf: "RS" },
  { name: "SENADOR POMPEU", value: "1555", uf: "CE" },
  { name: "ATALAIA", value: "2707", uf: "AL" },
  { name: "POCAO DE PEDRAS", value: "0875", uf: "MA" },
  { name: "GONCALVES DIAS", value: "0785", uf: "MA" },
  { name: "SANTO INACIO", value: "7865", uf: "PR" },
  { name: "AXIXA", value: "0721", uf: "MA" },
  { name: "PILAR DE GOIAS", value: "9535", uf: "GO" },
  { name: "AVELINO LOPES", value: "1021", uf: "PI" },
  { name: "MACAPARANA", value: "2479", uf: "PE" },
  { name: "CURRAIS NOVOS", value: "1661", uf: "RN" },
  { name: "MAGDA", value: "6667", uf: "SP" },
  { name: "MONTE BELO", value: "4859", uf: "MG" },
  { name: "SOBRADINHO", value: "8917", uf: "RS" },
  { name: "PAULO LOPES", value: "8241", uf: "SC" },
  { name: "LAGOA DOURADA", value: "4747", uf: "MG" },
  { name: "JURANDA", value: "8463", uf: "PR" },
  { name: "SANTA HELENA DE MINAS", value: "0706", uf: "MG" },
  { name: "MACATUBA", value: "6661", uf: "SP" },
  { name: "CONSTANTINA", value: "8613", uf: "RS" },
  { name: "CORREIA PINTO", value: "8395", uf: "SC" },
  { name: "BREJO DA MADRE DE DEUS", value: "2351", uf: "PE" },
  { name: "SIMONESIA", value: "5351", uf: "MG" },
  { name: "SAO FRANCISCO", value: "3237", uf: "SE" },
  { name: "VILA PROPICIO", value: "1068", uf: "GO" },
  { name: "TERRA DE AREIA", value: "7333", uf: "RS" },
  { name: "GUIMARAES", value: "0795", uf: "MA" },
  { name: "DIONISIO CERQUEIRA", value: "8097", uf: "SC" },
  { name: "ITATI", value: "1144", uf: "RS" },
  { name: "NOVO XINGU", value: "1152", uf: "RS" },
  { name: "SAO FRANCISCO DE PAULA", value: "5223", uf: "MG" },
  { name: "ERVAL SECO", value: "8643", uf: "RS" },
  { name: "BARREIROS", value: "2327", uf: "PE" },
  { name: "BERTOPOLIS", value: "4131", uf: "MG" },
  { name: "NACIP RAYDAN", value: "4883", uf: "MG" },
  { name: "VANINI", value: "7319", uf: "RS" },
  { name: "GIRUA", value: "8679", uf: "RS" },
  { name: "JUCATI", value: "2295", uf: "PE" },
  { name: "CORONEL FREITAS", value: "8085", uf: "SC" },
  { name: "NOVA VICOSA", value: "3761", uf: "BA" },
  { name: "CASTRO ALVES", value: "3445", uf: "BA" },
  { name: "MATIAS OLIMPIO", value: "1121", uf: "PI" },
  { name: "TUPI PAULISTA", value: "7203", uf: "SP" },
  { name: "BOQUEIRAO", value: "1951", uf: "PB" },
  { name: "SAO TOME DAS LETRAS", value: "5303", uf: "MG" },
  { name: "IBIAI", value: "4591", uf: "MG" },
  { name: "INIMUTABA", value: "4621", uf: "MG" },
  { name: "SANTANA DO ACARAU", value: "1541", uf: "CE" },
  { name: "LIVRAMENTO DE NOSSA SENHORA", value: "3691", uf: "BA" },
  { name: "BOA VIAGEM", value: "1347", uf: "CE" },
  { name: "TIO HUGO", value: "1174", uf: "RS" },
  { name: "IGACI", value: "2761", uf: "AL" },
  { name: "ARANDU", value: "6161", uf: "SP" },
  { name: "PIRES FERREIRA", value: "1269", uf: "CE" },
  { name: "NAZARE DO PIAUI", value: "1133", uf: "PI" },
  { name: "IGUARACU", value: "7595", uf: "PR" },
  { name: "VALE VERDE", value: "1026", uf: "RS" },
  { name: "MARIANO MORO", value: "8741", uf: "RS" },
  { name: "GARRUCHOS", value: "6081", uf: "RS" },
  { name: "CARAI", value: "4259", uf: "MG" },
  { name: "SCHROEDER", value: "8343", uf: "SC" },
  { name: "SAO GERALDO DO ARAGUAIA", value: "0619", uf: "PA" },
  { name: "PENTECOSTE", value: "1515", uf: "CE" },
  { name: "SAO JOSE DA LAJE", value: "2865", uf: "AL" },
  { name: "HELIODORA", value: "4583", uf: "MG" },
  { name: "SOLONOPOLE", value: "1561", uf: "CE" },
  { name: "IGUAI", value: "3571", uf: "BA" },
  { name: "MIRANGABA", value: "3729", uf: "BA" },
  { name: "BOM RETIRO", value: "8049", uf: "SC" },
  { name: "JACI", value: "6591", uf: "SP" },
  { name: "JUNQUEIRO", value: "2779", uf: "AL" },
  { name: "SANTO ANTONIO DE JESUS", value: "3873", uf: "BA" },
  { name: "CANDIDO MENDES", value: "0749", uf: "MA" },
  { name: "ITABELA", value: "3279", uf: "BA" },
  { name: "PARECI NOVO", value: "6041", uf: "RS" },
  { name: "PAPANDUVA", value: "8239", uf: "SC" },
  { name: "BAIXA GRANDE DO RIBEIRO", value: "2245", uf: "PI" },
  { name: "CARAVELAS", value: "3437", uf: "BA" },
  { name: "ELDORADO DOS CARAJAS", value: "0377", uf: "PA" },
  { name: "BATAYPORA", value: "9039", uf: "MS" },
  { name: "TURUCU", value: "1020", uf: "RS" },
  { name: "TAQUARUCU DO SUL", value: "7313", uf: "RS" },
  { name: "ALCANTARA", value: "0703", uf: "MA" },
  { name: "NAO-ME-TOQUE", value: "8755", uf: "RS" },
  { name: "ASCURRA", value: "8033", uf: "SC" },
  { name: "BENEVIDES", value: "0429", uf: "PA" },
  { name: "JUREMA", value: "0334", uf: "PI" },
  { name: "SERROLANDIA", value: "3911", uf: "BA" },
  { name: "ARARI", value: "0719", uf: "MA" },
  { name: "SAO DOMINGOS", value: "3029", uf: "BA" },
  { name: "ADRIANOPOLIS", value: "7403", uf: "PR" },
  { name: "MACAUBAS", value: "3697", uf: "BA" },
  { name: "AREIA BRANCA", value: "1621", uf: "RN" },
  { name: "SAO JOAO DO CAIUA", value: "7873", uf: "PR" },
  { name: "POTENGI", value: "1525", uf: "CE" },
  { name: "RIBEIRA", value: "6957", uf: "SP" },
  { name: "OLINDINA", value: "3763", uf: "BA" },
  { name: "VERE", value: "7945", uf: "PR" },
  { name: "LUIZ ALVES", value: "8197", uf: "SC" },
  { name: "SERRA DA SAUDADE", value: "5331", uf: "MG" },
  { name: "GUARAMIRANGA", value: "1403", uf: "CE" },
  { name: "ITAGI", value: "3603", uf: "BA" },
  { name: "SAO MARCOS", value: "8883", uf: "RS" },
  { name: "PIRACEMA", value: "5011", uf: "MG" },
  { name: "CAJAZEIRAS DO PIAUI", value: "0286", uf: "PI" },
  { name: "CAMPO GRANDE DO PIAUI", value: "0292", uf: "PI" },
  { name: "JAURU", value: "8991", uf: "MT" },
  { name: "OROBO", value: "2493", uf: "PE" },
  { name: "TIMBO GRANDE", value: "9971", uf: "SC" },
  { name: "CORDILHEIRA ALTA", value: "5579", uf: "SC" },
  { name: "BENEDITO NOVO", value: "8043", uf: "SC" },
  { name: "POMPEIA", value: "6901", uf: "SP" },
  { name: "FLORES", value: "2411", uf: "PE" },
  { name: "NOVO MACHADO", value: "6057", uf: "RS" },
  { name: "MATUPA", value: "9929", uf: "MT" },
  { name: "CATURAI", value: "9303", uf: "GO" },
  { name: "PORTO", value: "1169", uf: "PI" },
  { name: "BOREBI", value: "7247", uf: "SP" },
  { name: "VERA CRUZ", value: "8957", uf: "RS" },
  { name: "SAO LUIS DO CURU", value: "1553", uf: "CE" },
  { name: "ITORORO", value: "3643", uf: "BA" },
  { name: "MONTE AZUL PAULISTA", value: "6731", uf: "SP" },
  { name: "PORTO XAVIER", value: "8805", uf: "RS" },
  { name: "QUEIMADA NOVA", value: "2279", uf: "PI" },
  { name: "CAFELANDIA", value: "7985", uf: "PR" },
  { name: "PEDRA DO INDAIA", value: "4977", uf: "MG" },
  { name: "MOEMA", value: "4847", uf: "MG" },
  { name: "ITATIRA", value: "1433", uf: "CE" },
  { name: "SEBERI", value: "8905", uf: "RS" },
  { name: "FEIRA NOVA", value: "3143", uf: "SE" },
  { name: "ITAVERAVA", value: "4677", uf: "MG" },
  { name: "FENIX", value: "7551", uf: "PR" },
  { name: "ALTO LONGA", value: "1005", uf: "PI" },
  { name: "RIO DO OESTE", value: "8287", uf: "SC" },
  { name: "XAVANTINA", value: "8385", uf: "SC" },
  { name: "SAO JOAO EVANGELISTA", value: "5255", uf: "MG" },
  { name: "ROMELANDIA", value: "8299", uf: "SC" },
  { name: "DOUTOR PEDRINHO", value: "9945", uf: "SC" },
  { name: "IMARUI", value: "8141", uf: "SC" },
  { name: "FRANCISCO ALVES", value: "7977", uf: "PR" },
  { name: "POMBAL", value: "2143", uf: "PB" },
  { name: "MANOEL EMIDIO", value: "1117", uf: "PI" },
  { name: "CACHOEIRA DOS INDIOS", value: "1967", uf: "PB" },
  { name: "MELGACO", value: "0489", uf: "PA" },
  { name: "JAIBA", value: "2893", uf: "MG" },
  { name: "TEREZINHA", value: "2601", uf: "PE" },
  { name: "SAO JOAO DA PARAUNA", value: "9747", uf: "GO" },
  { name: "TURVO", value: "8453", uf: "PR" },
  { name: "SAO PEDRO DO PARANA", value: "7893", uf: "PR" },
  { name: "CRAIBAS", value: "2889", uf: "AL" },
  { name: "CAMPO BONITO", value: "8475", uf: "PR" },
  { name: "SAO JOAO DA URTIGA", value: "7349", uf: "RS" },
  { name: "MACAJUBA", value: "3693", uf: "BA" },
  { name: "CAMPO LIMPO DE GOIAS", value: "1070", uf: "GO" },
  { name: "CRISTALIA", value: "4405", uf: "MG" },
  { name: "TANGARA", value: "8353", uf: "SC" },
  { name: "SANTA LUZIA", value: "2169", uf: "PB" },
  { name: "NOVA AMERICA DA COLINA", value: "7717", uf: "PR" },
  { name: "BARRA DO CHOCA", value: "3357", uf: "BA" },
  { name: "SINIMBU", value: "5767", uf: "RS" },
  { name: "TRES BARRAS DO PARANA", value: "7987", uf: "PR" },
  { name: "PEDRAS DE FOGO", value: "2125", uf: "PB" },
  { name: "ALMADINA", value: "3317", uf: "BA" },
  { name: "SAO MIGUEL DOS CAMPOS", value: "2871", uf: "AL" },
  { name: "PAO DE ACUCAR", value: "2827", uf: "AL" },
  { name: "SERRITA", value: "2579", uf: "PE" },
  { name: "FERREIROS", value: "2409", uf: "PE" },
  { name: "JATAUBA", value: "2459", uf: "PE" },
  { name: "TUPARETAMA", value: "2617", uf: "PE" },
  { name: "IBIAM", value: "0920", uf: "SC" },
  { name: "PINDORAMA DO TOCANTINS", value: "9537", uf: "TO" },
  { name: "ALHANDRA", value: "1911", uf: "PB" },
  { name: "INAJA", value: "7601", uf: "PR" },
  { name: "CACHOEIRA DOURADA", value: "9673", uf: "GO" },
  { name: "QUISSAMA", value: "6007", uf: "RJ" },
  { name: "ALTO PARAISO", value: "0675", uf: "RO" },
  { name: "ARABUTA", value: "5597", uf: "SC" },
  { name: "PASSIRA", value: "2509", uf: "PE" },
  { name: "CATAS ALTAS", value: "0584", uf: "MG" },
  { name: "LUIS GOMES", value: "1739", uf: "RN" },
  { name: "VERA CRUZ", value: "1895", uf: "RN" },
  { name: "AMARAJI", value: "2317", uf: "PE" },
  { name: "ITAJU", value: "6541", uf: "SP" },
  { name: "BARRA", value: "3353", uf: "BA" },
  { name: "RIO MANSO", value: "5105", uf: "MG" },
  { name: "LANDRI SALES", value: "1111", uf: "PI" },
  { name: "ALTINHO", value: "2315", uf: "PE" },
  { name: "CALIFORNIA", value: "7467", uf: "PR" },
  { name: "UNIFLOR", value: "7939", uf: "PR" },
  { name: "ARARIPE", value: "1325", uf: "CE" },
  { name: "MALLET", value: "7671", uf: "PR" },
  { name: "AGUAS MORNAS", value: "8011", uf: "SC" },
  { name: "LAURO MULLER", value: "8189", uf: "SC" },
  { name: "NOVO SAO JOAQUIM", value: "9867", uf: "MT" },
  { name: "BELEM DO BREJO DO CRUZ", value: "1941", uf: "PB" },
  { name: "PORTO REAL", value: "0780", uf: "RJ" },
  { name: "PEDRA", value: "2515", uf: "PE" },
  { name: "AURELINO LEAL", value: "3347", uf: "BA" },
  { name: "TAPEROA", value: "3923", uf: "BA" },
  { name: "GROSSOS", value: "1685", uf: "RN" },
  { name: "PORTO MURTINHO", value: "9137", uf: "MS" },
  { name: "LUIS CORREIA", value: "1113", uf: "PI" },
  { name: "SAO DESIDERIO", value: "3877", uf: "BA" },
  { name: "RIO NEGRO", value: "9145", uf: "MS" },
  { name: "CRISTALINA", value: "9325", uf: "GO" },
  { name: "JUSCIMEIRA", value: "9189", uf: "MT" },
  { name: "SANTA BARBARA DE GOIAS", value: "9577", uf: "GO" },
  { name: "ARRAIAS", value: "9247", uf: "TO" },
  { name: "PATROCINIO PAULISTA", value: "6827", uf: "SP" },
  { name: "SAO CARLOS DO IVAI", value: "7867", uf: "PR" },
  { name: "NOVO HORIZONTE DO NORTE", value: "9903", uf: "MT" },
  { name: "SANTO ANTONIO DE LISBOA", value: "1187", uf: "PI" },
  { name: "SALTO DO CEU", value: "8997", uf: "MT" },
  { name: "NOVA GLORIA", value: "9655", uf: "GO" },
  { name: "DAVINOPOLIS", value: "9339", uf: "GO" },
  { name: "TUNAS DO PARANA", value: "5455", uf: "PR" },
  { name: "ALTO ALEGRE", value: "6121", uf: "SP" },
  { name: "ALVORADA", value: "9213", uf: "TO" },
  { name: "RIO QUENTE", value: "9995", uf: "GO" },
  { name: "BONFINOPOLIS", value: "9775", uf: "GO" },
  { name: "MONTES CLAROS DE GOIAS", value: "9471", uf: "GO" },
  { name: "BREJO", value: "0739", uf: "MA" },
  { name: "SANTA LUZ", value: "1185", uf: "PI" },
  { name: "SARUTAIA", value: "7125", uf: "SP" },
  { name: "JAGUARIBARA", value: "1437", uf: "CE" },
  { name: "MUITOS CAPOES", value: "1002", uf: "RS" },
  { name: "SANTA TEREZA DO OESTE", value: "9969", uf: "PR" },
  { name: "GOUVELANDIA", value: "9799", uf: "GO" },
  { name: "SANTA RITA DE CASSIA", value: "3549", uf: "BA" },
  { name: "CURACA", value: "3497", uf: "BA" },
  { name: "PETROLINA DE GOIAS", value: "9531", uf: "GO" },
  { name: "ANADIA", value: "2703", uf: "AL" },
  { name: "MONTIVIDIU", value: "9933", uf: "GO" },
  { name: "BUENOS AIRES", value: "2353", uf: "PE" },
  { name: "SANTANA DO ITARARE", value: "7855", uf: "PR" },
  { name: "SAUDADES", value: "8341", uf: "SC" },
  { name: "ITAGUATINS", value: "9409", uf: "TO" },
  { name: "ESPERANTINA", value: "0181", uf: "TO" },
  { name: "SAO BERNARDINO", value: "0942", uf: "SC" },
  { name: "LINDOIA DO SUL", value: "9961", uf: "SC" },
  { name: "ALMAS", value: "9207", uf: "TO" },
  { name: "MIRANORTE", value: "9463", uf: "TO" },
  { name: "IVOLANDIA", value: "9427", uf: "GO" },
  { name: "SILVEIRAS", value: "7141", uf: "SP" },
  { name: "JURUA", value: "0247", uf: "AM" },
  { name: "PARANAIGUARA", value: "9455", uf: "GO" },
  { name: "MONTE CASTELO", value: "8217", uf: "SC" },
  { name: "BERTOLINIA", value: "1033", uf: "PI" },
  { name: "NOVA FLORESTA", value: "2103", uf: "PB" },
  { name: "WITMARSUM", value: "8381", uf: "SC" },
  { name: "GUABIRUBA", value: "8123", uf: "SC" },
  { name: "ARIRANHA", value: "6173", uf: "SP" },
  { name: "ARICANDUVA", value: "0568", uf: "MG" },
  { name: "CONEGO MARINHO", value: "0590", uf: "MG" },
  { name: "NOVA PORTEIRINHA", value: "0664", uf: "MG" },
  { name: "VARZELANDIA", value: "5417", uf: "MG" },
  { name: "SAO LUIZ DO NORTE", value: "9749", uf: "GO" },
  { name: "BRAGANEY", value: "7983", uf: "PR" },
  { name: "PIQUETE", value: "6871", uf: "SP" },
  { name: "CAPETINGA", value: "4247", uf: "MG" },
  { name: "MULUNGU", value: "1483", uf: "CE" },
  { name: "GUADALUPE", value: "1089", uf: "PI" },
  { name: "ITOBI", value: "6577", uf: "SP" },
  { name: "ERERE", value: "1245", uf: "CE" },
  { name: "ANALANDIA", value: "6139", uf: "SP" },
  { name: "CONGONHAL", value: "4357", uf: "MG" },
  { name: "DORES DE CAMPOS", value: "4459", uf: "MG" },
  { name: "NOVA IORQUE", value: "0845", uf: "MA" },
  { name: "IGARATINGA", value: "4603", uf: "MG" },
  { name: "QUARTEL GERAL", value: "5073", uf: "MG" },
  { name: "CONFINS", value: "0592", uf: "MG" },
  { name: "CAMPOS NOVOS PAULISTA", value: "6297", uf: "SP" },
  { name: "MASSARANDUBA", value: "8207", uf: "SC" },
  { name: "SAO PEDRO DA AGUA BRANCA", value: "0240", uf: "MA" },
  { name: "SANTO ANTONIO DO JARDIM", value: "7063", uf: "SP" },
  { name: "ABADIA DOS DOURADOS", value: "4001", uf: "MG" },
  { name: "MARILANDIA DO SUL", value: "7433", uf: "PR" },
  { name: "PEDRA BELA", value: "6837", uf: "SP" },
  { name: "SANTA AMELIA", value: "7839", uf: "PR" },
  { name: "LAGOA GRANDE DO MARANHAO", value: "0186", uf: "MA" },
  { name: "DIAMANTE D'OESTE", value: "9915", uf: "PR" },
  { name: "PRESIDENTE JUSCELINO", value: "5063", uf: "MG" },
  { name: "PORTO DOS GAUCHOS", value: "9135", uf: "MT" },
  { name: "PETROLANDIA", value: "2519", uf: "PE" },
  { name: "RINOPOLIS", value: "6977", uf: "SP" },
  { name: "BOTUVERA", value: "8051", uf: "SC" },
  { name: "TAQUARACU DE MINAS", value: "5365", uf: "MG" },
  { name: "BOA HORA", value: "0280", uf: "PI" },
  { name: "RIACHO DA CRUZ", value: "1811", uf: "RN" },
  { name: "IPAUSSU", value: "6519", uf: "SP" },
  { name: "QUEBRANGULO", value: "2851", uf: "AL" },
  { name: "REGINOPOLIS", value: "6951", uf: "SP" },
  { name: "PONTES GESTAL", value: "6907", uf: "SP" },
  { name: "SEBASTIANOPOLIS DO SUL", value: "7127", uf: "SP" },
  { name: "JEQUITIBA", value: "4713", uf: "MG" },
  { name: "LINDOLFO COLLOR", value: "6017", uf: "RS" },
  { name: "NOVA CAMPINA", value: "3061", uf: "SP" },
  { name: "SAO ROQUE DE MINAS", value: "5285", uf: "MG" },
  { name: "MUNHOZ DE MELO", value: "7711", uf: "PR" },
  { name: "PIQUEROBI", value: "6869", uf: "SP" },
  { name: "SANTA CRUZ DO PIAUI", value: "1181", uf: "PI" },
  { name: "JAGUARETAMA", value: "1435", uf: "CE" },
  { name: "CARACOL", value: "1049", uf: "PI" },
  { name: "BENEDITINOS", value: "1031", uf: "PI" },
  { name: "BOA VISTA DO TUPIM", value: "3375", uf: "BA" },
  { name: "GOVERNADOR EUGENIO BARROS", value: "0789", uf: "MA" },
  { name: "CATUNDA", value: "0983", uf: "CE" },
  { name: "MIGUELOPOLIS", value: "6695", uf: "SP" },
  { name: "TEOFILANDIA", value: "3929", uf: "BA" },
  { name: "RIO VERMELHO", value: "5119", uf: "MG" },
  { name: "IBEMA", value: "9949", uf: "PR" },
  { name: "IBIAPINA", value: "1407", uf: "CE" },
  { name: "ITAMBE", value: "3617", uf: "BA" },
  { name: "TURIUBA", value: "7205", uf: "SP" },
  { name: "CAMAMU", value: "3415", uf: "BA" },
  { name: "FRECHEIRINHA", value: "1391", uf: "CE" },
  { name: "SANTANOPOLIS", value: "3867", uf: "BA" },
  { name: "BOA VISTA DAS MISSOES", value: "5981", uf: "RS" },
  { name: "ALTO SANTO", value: "1313", uf: "CE" },
  { name: "ESPLANADA", value: "3511", uf: "BA" },
  { name: "CARUTAPERA", value: "0755", uf: "MA" },
  { name: "SAO GERALDO", value: "5229", uf: "MG" },
  { name: "IRAUCUBA", value: "1423", uf: "CE" },
  { name: "HIDROLANDIA", value: "1405", uf: "CE" },
  { name: "ROCHEDO DE MINAS", value: "5123", uf: "MG" },
  { name: "PORTO VITORIA", value: "7785", uf: "PR" },
  { name: "DEMERVAL LOBAO", value: "1065", uf: "PI" },
  { name: "ACARAPE", value: "1231", uf: "CE" },
  { name: "RESSAQUINHA", value: "5087", uf: "MG" },
  { name: "BARAO DO MONTE ALTO", value: "4109", uf: "MG" },
  { name: "CARNAUBAL", value: "1367", uf: "CE" },
  { name: "SENADOR CORTES", value: "5311", uf: "MG" },
  { name: "BELA VISTA DO PARAISO", value: "7453", uf: "PR" },
  { name: "LARANJAL", value: "4759", uf: "MG" },
  { name: "ARATUBA", value: "1327", uf: "CE" },
  { name: "GRANJA", value: "1395", uf: "CE" },
  { name: "ITAICABA", value: "1425", uf: "CE" },
  { name: "NOVA MODICA", value: "4897", uf: "MG" },
  { name: "SANTA MARIA DO PARA", value: "0531", uf: "PA" },
  { name: "BARBALHA", value: "1337", uf: "CE" },
  { name: "IBARETAMA", value: "1255", uf: "CE" },
  { name: "OURICURI", value: "2497", uf: "PE" },
  { name: "MORENO", value: "2487", uf: "PE" },
  { name: "VALENCA DO PIAUI", value: "1225", uf: "PI" },
  { name: "ALAGOINHA DO PIAUI", value: "9767", uf: "PI" },
  { name: "LAJEDO", value: "2475", uf: "PE" },
  { name: "BEBERIBE", value: "1343", uf: "CE" },
  { name: "IBICUITINGA", value: "1257", uf: "CE" },
  { name: "JUCURUTU", value: "1719", uf: "RN" },
  { name: "JOAQUIM NABUCO", value: "2463", uf: "PE" },
  { name: "PALMEIRINA", value: "2501", uf: "PE" },
  { name: "SAO JOAO DOS PATOS", value: "0919", uf: "MA" },
  { name: "CEARA-MIRIM", value: "1651", uf: "RN" },
  { name: "CACHOEIRA DE PAJEU", value: "4053", uf: "MG" },
  { name: "MERCES", value: "4831", uf: "MG" },
  { name: "PAU DOS FERROS", value: "1787", uf: "RN" },
  { name: "ALEXANDRIA", value: "1609", uf: "RN" },
  { name: "SAO FRANCISCO DO OESTE", value: "1821", uf: "RN" },
  { name: "ITAMBARACA", value: "7613", uf: "PR" },
  { name: "SAO GABRIEL DA CACHOEIRA", value: "0283", uf: "AM" },
  { name: "SAO JOSE DO CAMPESTRE", value: "1843", uf: "RN" },
  { name: "SAO JOSE DO SERIDO", value: "1845", uf: "RN" },
  { name: "IPIXUNA", value: "0239", uf: "AM" },
  { name: "DIAMANTE", value: "2013", uf: "PB" },
  { name: "JUNCO DO SERIDO", value: "2057", uf: "PB" },
  { name: "SAO TOME", value: "1855", uf: "RN" },
  { name: "AREIAL", value: "1923", uf: "PB" },
  { name: "CATOLE DO ROCHA", value: "1987", uf: "PB" },
  { name: "RORAINOPOLIS", value: "0036", uf: "RR" },
  { name: "EQUADOR", value: "1667", uf: "RN" },
  { name: "CASTILHO", value: "6321", uf: "SP" },
  { name: "ACARI", value: "1601", uf: "RN" },
  { name: "CEREJEIRAS", value: "0027", uf: "RO" },
  { name: "SANTA ROSA DO SUL", value: "9967", uf: "SC" },
  { name: "MONTANHAS", value: "1753", uf: "RN" },
  { name: "SAO RAFAEL", value: "1853", uf: "RN" },
  { name: "SALVATERRA", value: "0525", uf: "PA" },
  { name: "SANTA INES", value: "7847", uf: "PR" },
  { name: "SERRA CAIADA", value: "1805", uf: "RN" },
  { name: "PATU", value: "1785", uf: "RN" },
  { name: "JAPARATINGA", value: "2771", uf: "AL" },
  { name: "LAGOA DE PEDRAS", value: "1725", uf: "RN" },
  { name: "PEDRO VELHO", value: "1795", uf: "RN" },
  { name: "SILVES", value: "0277", uf: "AM" },
  { name: "INGA", value: "2037", uf: "PB" },
  { name: "MAJOR ISIDORO", value: "2787", uf: "AL" },
  { name: "NAZARIO", value: "9485", uf: "GO" },
  { name: "CAJUEIRO", value: "2725", uf: "AL" },
  { name: "PARANAPOEMA", value: "7747", uf: "PR" },
  { name: "JOAQUIM GOMES", value: "2775", uf: "AL" },
  { name: "MACHADOS", value: "2481", uf: "PE" },
  { name: "CACIMBA DE DENTRO", value: "1971", uf: "PB" },
  { name: "OCAUCU", value: "6775", uf: "SP" },
  { name: "TAIPU", value: "1875", uf: "RN" },
  { name: "ARES", value: "1623", uf: "RN" },
  { name: "ESCADA", value: "2403", uf: "PE" },
  { name: "SAO BENTO", value: "2179", uf: "PB" },
  { name: "SOLEDADE", value: "2223", uf: "PB" },
  { name: "ALAGOA GRANDE", value: "1905", uf: "PB" },
  { name: "CAMOCIM DE SAO FELIX", value: "2369", uf: "PE" },
  { name: "DUAS ESTRADAS", value: "2017", uf: "PB" },
  { name: "PAULO FRONTIN", value: "7755", uf: "PR" },
  { name: "GURINHEM", value: "2029", uf: "PB" },
  { name: "PEDRAS GRANDES", value: "8243", uf: "SC" },
  { name: "CAMPINORTE", value: "9293", uf: "GO" },
  { name: "SAO JOSE DO SABUGI", value: "2195", uf: "PB" },
  { name: "MARI", value: "2083", uf: "PB" },
  { name: "CAETES", value: "2363", uf: "PE" },
  { name: "ESPERANCA", value: "2021", uf: "PB" },
  { name: "EWBANK DA CAMARA", value: "4499", uf: "MG" },
  { name: "SERRANIA", value: "5337", uf: "MG" },
  { name: "VITOR MEIRELES", value: "9977", uf: "SC" },
  { name: "RIO NOVO DO SUL", value: "5687", uf: "ES" },
  { name: "SANTA MARIA DO SUACUI", value: "5163", uf: "MG" },
  { name: "REMIGIO", value: "2155", uf: "PB" },
  { name: "MUQUI", value: "5675", uf: "ES" },
  { name: "MARLIERIA", value: "4805", uf: "MG" },
  { name: "TURMALINA", value: "7207", uf: "SP" },
  { name: "SAO BENTO ABADE", value: "5215", uf: "MG" },
  { name: "SAO JOSE DA LAGOA TAPADA", value: "2185", uf: "PB" },
  { name: "BELEM", value: "1939", uf: "PB" },
  { name: "VARZEA", value: "2243", uf: "PB" },
  { name: "CONDE", value: "1993", uf: "PB" },
  { name: "DORES DO TURVO", value: "4465", uf: "MG" },
  { name: "SAO JOSE DE PIRANHAS", value: "2191", uf: "PB" },
  { name: "MULUNGU", value: "2097", uf: "PB" },
  { name: "PONTO BELO", value: "0762", uf: "ES" },
  { name: "PILOES", value: "2133", uf: "PB" },
  { name: "ECOPORANGA", value: "5641", uf: "ES" },
  { name: "JOANOPOLIS", value: "6611", uf: "SP" },
  { name: "PIANCO", value: "2127", uf: "PB" },
  { name: "MUNIZ FREIRE", value: "5673", uf: "ES" },
  { name: "MAJOR VIEIRA", value: "8203", uf: "SC" },
  { name: "QUATA", value: "6935", uf: "SP" },
  { name: "OURO VERDE", value: "6797", uf: "SP" },
  { name: "IPANGUACU", value: "1691", uf: "RN" },
  { name: "MONTE CARLO", value: "5561", uf: "SC" },
  { name: "SAO JOAO DO CARIRI", value: "2181", uf: "PB" },
  { name: "JAGUARE", value: "5713", uf: "ES" },
  { name: "BALBINOS", value: "6193", uf: "SP" },
  { name: "CONCEICAO DO CASTELO", value: "5633", uf: "ES" },
  { name: "ENGENHEIRO PAULO DE FRONTIN", value: "5835", uf: "RJ" },
  { name: "SAO JOAO DA SERRA", value: "1197", uf: "PI" },
  { name: "PAULISTA", value: "2119", uf: "PB" },
  { name: "TACARATU", value: "2595", uf: "PE" },
  { name: "CACHOEIRA DO ARARI", value: "0439", uf: "PA" },
  { name: "CAMPESTRE DA SERRA", value: "6013", uf: "RS" },
  { name: "SAO JOSE DO CEDRO", value: "8329", uf: "SC" },
  { name: "AGUAS DE CHAPECO", value: "8009", uf: "SC" },
  { name: "MELEIRO", value: "8211", uf: "SC" },
  { name: "CLEMENTINA", value: "6339", uf: "SP" },
  { name: "DIVINO DE SAO LOURENCO", value: "5635", uf: "ES" },
  { name: "LACERDOPOLIS", value: "8181", uf: "SC" },
  { name: "PIRAI", value: "5879", uf: "RJ" },
  { name: "ALPERCATA", value: "4035", uf: "MG" },
  { name: "SANTANA DO JACARE", value: "5175", uf: "MG" },
  { name: "PACARAIMA", value: "0034", uf: "RR" },
  { name: "IBERTIOGA", value: "4587", uf: "MG" },
  { name: "SANTA LUZIA DO PARA", value: "0371", uf: "PA" },
  { name: "PLACIDO DE CASTRO", value: "0151", uf: "AC" },
  { name: "CALCOENE", value: "0603", uf: "AP" },
  { name: "CAAPIRANGA", value: "0299", uf: "AM" },
  { name: "SABINO", value: "6993", uf: "SP" },
  { name: "IGUARACY", value: "2437", uf: "PE" },
  { name: "NOVO AIRAO", value: "0201", uf: "AM" },
  { name: "CUPIRA", value: "2399", uf: "PE" },
  { name: "MONTE CASTELO", value: "6733", uf: "SP" },
  { name: "URUCURITUBA", value: "0287", uf: "AM" },
  { name: "PANELAS", value: "2503", uf: "PE" },
  { name: "CAMPO NOVO DE RONDONIA", value: "0679", uf: "RO" },
  { name: "MOCAJUBA", value: "0491", uf: "PA" },
  { name: "ALTA FLORESTA D'OESTE", value: "0033", uf: "RO" },
  { name: "PEDRO II", value: "1157", uf: "PI" },
  { name: "CARNAIBA", value: "2377", uf: "PE" },
  { name: "TABIRA", value: "2591", uf: "PE" },
  { name: "DOM PEDRO", value: "0773", uf: "MA" },
  { name: "SANTA CLARA D'OESTE", value: "7023", uf: "SP" },
  { name: "ITAPUA DO OESTE", value: "0683", uf: "RO" },
  { name: "MARILENA", value: "7975", uf: "PR" },
  { name: "ORINDIUVA", value: "6785", uf: "SP" },
  { name: "CANITAR", value: "2947", uf: "SP" },
  { name: "UMIRIM", value: "9855", uf: "CE" },
  { name: "PALHANO", value: "1501", uf: "CE" },
  { name: "URUBURETAMA", value: "1577", uf: "CE" },
  { name: "CALUMBI", value: "2367", uf: "PE" },
  { name: "JUPI", value: "2465", uf: "PE" },
  { name: "ALVINLANDIA", value: "6129", uf: "SP" },
  { name: "GAMELEIRA", value: "2417", uf: "PE" },
  { name: "CACHOEIRINHA", value: "2361", uf: "PE" },
  { name: "ANTONIO OLINTO", value: "7423", uf: "PR" },
  { name: "OURO BRANCO", value: "1769", uf: "RN" },
  { name: "RIACHO DE SANTANA", value: "1813", uf: "RN" },
  { name: "ARAMINA", value: "6159", uf: "SP" },
  { name: "BOM SUCESSO", value: "1947", uf: "PB" },
  { name: "JURU", value: "2061", uf: "PB" },
  { name: "SERRA ALTA", value: "9989", uf: "SC" },
  { name: "MARIBONDO", value: "2795", uf: "AL" },
  { name: "PORTO DE PEDRAS", value: "2847", uf: "AL" },
  { name: "NATIVIDADE", value: "5861", uf: "RJ" },
  { name: "NOVO LINO", value: "2811", uf: "AL" },
  { name: "JUAZEIRINHO", value: "2055", uf: "PB" },
  { name: "AFONSO BEZERRA", value: "1605", uf: "RN" },
  { name: "BOA SAUDE", value: "1703", uf: "RN" },
  { name: "CAPELA", value: "2733", uf: "AL" },
  { name: "RIO PRETO", value: "5117", uf: "MG" },
  { name: "CONDADO", value: "2391", uf: "PE" },
  { name: "SIMPLICIO MENDES", value: "1215", uf: "PI" },
  { name: "BALNEARIO ARROIO DO SILVA", value: "0888", uf: "SC" },
  { name: "INHUMA", value: "1093", uf: "PI" },
  { name: "ALVARENGA", value: "4043", uf: "MG" },
  { name: "CARMESIA", value: "4275", uf: "MG" },
  { name: "FRANCINOPOLIS", value: "1079", uf: "PI" },
  { name: "AROEIRAS DO ITAIM", value: "1188", uf: "PI" },
  { name: "PORCIUNCULA", value: "5881", uf: "RJ" },
  { name: "BENEDITO LEITE", value: "0735", uf: "MA" },
  { name: "TIMBE DO SUL", value: "8393", uf: "SC" },
  { name: "JOSELANDIA", value: "0811", uf: "MA" },
  { name: "PASTOS BONS", value: "0859", uf: "MA" },
  { name: "INDIANA", value: "6513", uf: "SP" },
  { name: "CARIUS", value: "1365", uf: "CE" },
  { name: "OURO", value: "8231", uf: "SC" },
  { name: "TIMBIRAS", value: "0935", uf: "MA" },
  { name: "FORTUNA", value: "0781", uf: "MA" },
  { name: "MENDES", value: "5855", uf: "RJ" },
  { name: "GUARDA-MOR", value: "4571", uf: "MG" },
  { name: "PARNAMIRIM", value: "2507", uf: "PE" },
  { name: "CORONEL MURTA", value: "4389", uf: "MG" },
  { name: "SAO JOAO DA PONTE", value: "5247", uf: "MG" },
  { name: "TRIUNFO", value: "2613", uf: "PE" },
  { name: "PIRANGUINHO", value: "5019", uf: "MG" },
  { name: "CAMUTANGA", value: "2371", uf: "PE" },
  { name: "GOVERNADOR CELSO RAMOS", value: "8111", uf: "SC" },
  { name: "GLORIA DO GOITA", value: "2421", uf: "PE" },
  { name: "SANTA FILOMENA", value: "0556", uf: "PE" },
  { name: "BREJAO", value: "2347", uf: "PE" },
  { name: "SANTA MARIA DO CAMBUCA", value: "2553", uf: "PE" },
  { name: "CRUZEIRO DA FORTALEZA", value: "4413", uf: "MG" },
  { name: "SANTA BARBARA DO SUL", value: "8837", uf: "RS" },
  { name: "CAPITOLIO", value: "4255", uf: "MG" },
  { name: "ESPUMOSO", value: "8647", uf: "RS" },
  { name: "COMENDADOR GOMES", value: "4337", uf: "MG" },
  { name: "PEDRA BONITA", value: "0682", uf: "MG" },
  { name: "SAO VICENTE DE MINAS", value: "5305", uf: "MG" },
  { name: "SAO PEDRO DA UNIAO", value: "5277", uf: "MG" },
  { name: "CHA DE ALEGRIA", value: "2387", uf: "PE" },
  { name: "MARTINS SOARES", value: "0650", uf: "MG" },
  { name: "SAO JOSE DA BARRA", value: "0724", uf: "MG" },
  { name: "SAMBAIBA", value: "0893", uf: "MA" },
  { name: "CANA VERDE", value: "4237", uf: "MG" },
  { name: "PIO IX", value: "1163", uf: "PI" },
  { name: "COUTO DE MAGALHAES DE MINAS", value: "4401", uf: "MG" },
  { name: "COIMBRA", value: "4333", uf: "MG" },
  { name: "CORTES", value: "2395", uf: "PE" },
  { name: "AGUA BRANCA", value: "1003", uf: "PI" },
  { name: "BARRO DURO", value: "1027", uf: "PI" },
  { name: "PACUJA", value: "1499", uf: "CE" },
  { name: "ENGENHEIRO NAVARRO", value: "4475", uf: "MG" },
  { name: "GLORIA D'OESTE", value: "0135", uf: "MT" },
  { name: "ROLADOR", value: "1164", uf: "RS" },
  { name: "APIACA", value: "5609", uf: "ES" },
  { name: "CASINHAS", value: "0546", uf: "PE" },
  { name: "SAO VICENTE DO SERIDO", value: "2209", uf: "PB" },
  { name: "MASSARANDUBA", value: "2085", uf: "PB" },
  { name: "OLIVENCA", value: "2819", uf: "AL" },
  { name: "BRANQUINHA", value: "2721", uf: "AL" },
  { name: "JOSE DA PENHA", value: "1717", uf: "RN" },
  { name: "UMARIZAL", value: "1887", uf: "RN" },
  { name: "LAGOA SALGADA", value: "1731", uf: "RN" },
  { name: "OLIMPIO NORONHA", value: "4909", uf: "MG" },
  { name: "BARCELONA", value: "1629", uf: "RN" },
  { name: "MANAIRA", value: "2081", uf: "PB" },
  { name: "OLIVEIRA DOS BREJINHOS", value: "3765", uf: "BA" },
  { name: "AFRANIO", value: "2303", uf: "PE" },
  { name: "BOM JARDIM", value: "2343", uf: "PE" },
  { name: "SAO JOSE DO OURO", value: "8875", uf: "RS" },
  { name: "PORTO RICO DO MARANHAO", value: "0212", uf: "MA" },
  { name: "IBIACA", value: "8697", uf: "RS" },
  { name: "CACIMBINHAS", value: "2723", uf: "AL" },
  { name: "AGUA BRANCA", value: "2701", uf: "AL" },
  { name: "COREAU", value: "1381", uf: "CE" },
  { name: "FLEXEIRAS", value: "2755", uf: "AL" },
  { name: "IGREJINHA", value: "8703", uf: "RS" },
  { name: "ITAOCA", value: "3053", uf: "SP" },
  { name: "POUSO NOVO", value: "7363", uf: "RS" },
  { name: "FRANCISCO SANTOS", value: "1083", uf: "PI" },
  { name: "PEDRO OSORIO", value: "8787", uf: "RS" },
  { name: "TACIBA", value: "7159", uf: "SP" },
  { name: "BARRA DE SANTO ANTONIO", value: "2709", uf: "AL" },
  { name: "PIO XII", value: "0871", uf: "MA" },
  { name: "ARACOIABA DA SERRA", value: "6157", uf: "SP" },
  { name: "PORANGABA", value: "6911", uf: "SP" },
  { name: "CATARINA", value: "1371", uf: "CE" },
  { name: "MILAGRES", value: "1467", uf: "CE" },
  { name: "UBIRAJARA", value: "7211", uf: "SP" },
  { name: "VARGEM GRANDE", value: "0947", uf: "MA" },
  { name: "SATUBA", value: "2877", uf: "AL" },
  { name: "IBIRAIARAS", value: "8699", uf: "RS" },
  { name: "JULIO DE CASTILHOS", value: "8725", uf: "RS" },
  { name: "JOIA", value: "9829", uf: "RS" },
  { name: "SAO FRANCISCO DO MARANHAO", value: "0915", uf: "MA" },
  { name: "CAMPINAS DO SUL", value: "8575", uf: "RS" },
  { name: "SANTA RITA DE CALDAS", value: "5183", uf: "MG" },
  { name: "PIACABUCU", value: "2835", uf: "AL" },
  { name: "SAO PEDRO DO BUTIA", value: "6063", uf: "RS" },
  { name: "SILVANOPOLIS", value: "9659", uf: "TO" },
  { name: "CAMPO NOVO", value: "8579", uf: "RS" },
  { name: "PEDRO AVELINO", value: "1793", uf: "RN" },
  { name: "BENTO FERNANDES", value: "1631", uf: "RN" },
  { name: "BARRA DE SAO MIGUEL", value: "1935", uf: "PB" },
  { name: "ARACAGI", value: "1915", uf: "PB" },
  { name: "JUAREZ TAVORA", value: "2053", uf: "PB" },
  { name: "SAO VICENTE", value: "1857", uf: "RN" },
  { name: "BARAUNA", value: "3003", uf: "RN" },
  { name: "JACANA", value: "1697", uf: "RN" },
  { name: "VARGEM", value: "2957", uf: "SP" },
  { name: "SIRINHAEM", value: "2583", uf: "PE" },
  { name: "GIRAU DO PONCIANO", value: "2757", uf: "AL" },
  { name: "CERRO GRANDE DO SUL", value: "8435", uf: "RS" },
  { name: "JUREMA", value: "2467", uf: "PE" },
  { name: "CONGO", value: "1995", uf: "PB" },
  { name: "DOIS RIACHOS", value: "2749", uf: "AL" },
  { name: "COLONIA LEOPOLDINA", value: "2741", uf: "AL" },
  { name: "CARNAUBAIS", value: "1649", uf: "RN" },
  { name: "CATIGUA", value: "6325", uf: "SP" },
  { name: "CANDIDO GODOI", value: "8583", uf: "RS" },
  { name: "SAO FRANCISCO", value: "7081", uf: "SP" },
  { name: "RETIROLANDIA", value: "3823", uf: "BA" },
  { name: "PIRAI DO NORTE", value: "3019", uf: "BA" },
  { name: "LARANJEIRAS", value: "3171", uf: "SE" },
  { name: "ITAPORANGA D'AJUDA", value: "3163", uf: "SE" },
  { name: "IBICUI", value: "3545", uf: "BA" },
  { name: "GENERAL MAYNARD", value: "3147", uf: "SE" },
  { name: "PACATUBA", value: "3197", uf: "SE" },
  { name: "MONTE ALEGRE DE SERGIPE", value: "3183", uf: "SE" },
  { name: "SALTO DO JACUI", value: "8975", uf: "RS" },
  { name: "PEDRINHAS", value: "3201", uf: "SE" },
  { name: "SAO DOMINGOS", value: "3235", uf: "SE" },
  { name: "NOSSA SENHORA DA GLORIA", value: "3189", uf: "SE" },
  { name: "VIADUTOS", value: "8961", uf: "RS" },
  { name: "CANSANCAO", value: "3435", uf: "BA" },
  { name: "BOTUPORA", value: "3383", uf: "BA" },
  { name: "WANDERLEY", value: "3999", uf: "BA" },
  { name: "CORDEIROS", value: "3479", uf: "BA" },
  { name: "UBATA", value: "3945", uf: "BA" },
  { name: "GARARU", value: "3149", uf: "SE" },
  { name: "VARZEA NOVA", value: "3995", uf: "BA" },
  { name: "CORONEL JOAO SA", value: "3483", uf: "BA" },
  { name: "CAIBATE", value: "8565", uf: "RS" },
  { name: "FLORINEA", value: "6423", uf: "SP" },
  { name: "MARUIM", value: "3179", uf: "SE" },
  { name: "MURIBECA", value: "3185", uf: "SE" },
  { name: "TORITAMA", value: "2607", uf: "PE" },
  { name: "CICERO DANTAS", value: "3455", uf: "BA" },
  { name: "IUIU", value: "3285", uf: "BA" },
  { name: "MACARANI", value: "3695", uf: "BA" },
  { name: "SAO JOSE DAS MISSOES", value: "5989", uf: "RS" },
  { name: "SIMAO DIAS", value: "3241", uf: "SE" },
  { name: "FAXINALZINHO", value: "8409", uf: "RS" },
  { name: "BRAGA", value: "8551", uf: "RS" },
  { name: "JEREMOABO", value: "3663", uf: "BA" },
  { name: "ALTO RIO NOVO", value: "5719", uf: "ES" },
  { name: "ARATUIPE", value: "3345", uf: "BA" },
  { name: "SAO FELIPE", value: "3881", uf: "BA" },
  { name: "NOVO HORIZONTE", value: "3013", uf: "BA" },
  { name: "RIO DO PIRES", value: "3839", uf: "BA" },
  { name: "REDENCAO DA SERRA", value: "6947", uf: "SP" },
  { name: "RIACHAO DO JACUIPE", value: "3827", uf: "BA" },
  { name: "POTIRAGUA", value: "3809", uf: "BA" },
  { name: "CRISTOPOLIS", value: "3493", uf: "BA" },
  { name: "CERRO LARGO", value: "8601", uf: "RS" },
  { name: "ANTAS", value: "3331", uf: "BA" },
  { name: "VILA NOVA DO SUL", value: "5795", uf: "RS" },
  { name: "PRESIDENTE KUBITSCHEK", value: "5065", uf: "MG" },
  { name: "MUTUNOPOLIS", value: "9479", uf: "GO" },
  { name: "TANHACU", value: "3919", uf: "BA" },
  { name: "BAIXA GRANDE", value: "3351", uf: "BA" },
  { name: "CLARAVAL", value: "4327", uf: "MG" },
  { name: "PONTO NOVO", value: "3021", uf: "BA" },
  { name: "IRAI", value: "8711", uf: "RS" },
  { name: "CANARANA", value: "3423", uf: "BA" },
  { name: "IRUPI", value: "2931", uf: "ES" },
  { name: "ITARANTIM", value: "3637", uf: "BA" },
  { name: "FIRMINO ALVES", value: "3517", uf: "BA" },
  { name: "SAO ROQUE DO CANAA", value: "0764", uf: "ES" },
  { name: "BOA NOVA", value: "3373", uf: "BA" },
  { name: "VILA VALERIO", value: "0768", uf: "ES" },
  { name: "ITANHEM", value: "3621", uf: "BA" },
  { name: "LAJEDINHO", value: "3681", uf: "BA" },
  { name: "NAZARE", value: "3751", uf: "BA" },
  { name: "ITIUBA", value: "3641", uf: "BA" },
  { name: "CIPO", value: "3457", uf: "BA" },
  { name: "OUROLANDIA", value: "3017", uf: "BA" },
  { name: "REDENTORA", value: "8811", uf: "RS" },
  { name: "AJURICABA", value: "8503", uf: "RS" },
  { name: "DONA FRANCISCA", value: "8631", uf: "RS" },
  { name: "ESPERANTINOPOLIS", value: "0777", uf: "MA" },
  { name: "LICINIO DE ALMEIDA", value: "3689", uf: "BA" },
  { name: "SAO JOAO DO ARAGUAIA", value: "0549", uf: "PA" },
  { name: "SAO VENDELINO", value: "7293", uf: "RS" },
  { name: "PIACATU", value: "6855", uf: "SP" },
  { name: "LIMOEIRO DO AJURU", value: "0479", uf: "PA" },
  { name: "GUARANTA", value: "6463", uf: "SP" },
  { name: "DOM BASILIO", value: "3501", uf: "BA" },
  { name: "MAIQUINIQUE", value: "3701", uf: "BA" },
  { name: "CAREIRO", value: "0223", uf: "AM" },
  { name: "ARATIBA", value: "8517", uf: "RS" },
  { name: "ITAPEBI", value: "3627", uf: "BA" },
  { name: "SANTA BARBARA", value: "3851", uf: "BA" },
  { name: "PINDOBACU", value: "3793", uf: "BA" },
  { name: "ALVARES FLORENCE", value: "6123", uf: "SP" },
  { name: "ITAMARI", value: "3615", uf: "BA" },
  { name: "CARACARAI", value: "0303", uf: "RR" },
  { name: "MUNDO NOVO", value: "3743", uf: "BA" },
  { name: "LIBERATO SALZANO", value: "8733", uf: "RS" },
  { name: "BREJOLANDIA", value: "3387", uf: "BA" },
  { name: "IPECAETA", value: "3577", uf: "BA" },
  { name: "SAO GONCALO DOS CAMPOS", value: "3885", uf: "BA" },
  { name: "COLORADO", value: "8609", uf: "RS" },
  { name: "IGAPORA", value: "3569", uf: "BA" },
  { name: "PARATINGA", value: "3775", uf: "BA" },
  { name: "BELO CAMPO", value: "3369", uf: "BA" },
  { name: "VERA MENDES", value: "0406", uf: "PI" },
  { name: "UIBAI", value: "3947", uf: "BA" },
  { name: "JACARACI", value: "3649", uf: "BA" },
  { name: "AMELIA RODRIGUES", value: "3321", uf: "BA" },
  { name: "LAJE", value: "3677", uf: "BA" },
  { name: "PAU D'ARCO DO PIAUI", value: "1104", uf: "PI" },
  { name: "LAVRAS DO SUL", value: "8731", uf: "RS" },
  { name: "BOSSOROCA", value: "8549", uf: "RS" },
  { name: "TEOLANDIA", value: "3931", uf: "BA" },
  { name: "NOSSA SENHORA DO LIVRAMENTO", value: "9121", uf: "MT" },
  { name: "BARAO DE MELGACO", value: "9031", uf: "MT" },
  { name: "SAO SEBASTIAO DO PASSE", value: "3889", uf: "BA" },
  { name: "NOVA BASSANO", value: "8761", uf: "RS" },
  { name: "DOUTOR MAURICIO CARDOSO", value: "8425", uf: "RS" },
  { name: "CAMPINA DAS MISSOES", value: "8573", uf: "RS" },
  { name: "TAQUARITINGA DO NORTE", value: "2599", uf: "PE" },
  { name: "MUTUIPE", value: "3749", uf: "BA" },
  { name: "CAMPESTRE", value: "0560", uf: "AL" },
  { name: "NEOPOLIS", value: "3187", uf: "SE" },
  { name: "SANTO AMARO DAS BROTAS", value: "3231", uf: "SE" },
  { name: "SAO PEDRO", value: "1851", uf: "RN" },
  { name: "NORTELANDIA", value: "9119", uf: "MT" },
  { name: "PARANAITA", value: "9885", uf: "MT" },
  { name: "INDIAVAI", value: "9877", uf: "MT" },
  { name: "CRUZETA", value: "1659", uf: "RN" },
  { name: "SILVEIRA MARTINS", value: "7315", uf: "RS" },
  { name: "TABOCAS DO BREJO VELHO", value: "3917", uf: "BA" },
  { name: "AIQUARA", value: "3311", uf: "BA" },
  { name: "SANTA TERESINHA", value: "3869", uf: "BA" },
  { name: "CHA GRANDE", value: "2389", uf: "PE" },
  { name: "CHAVES", value: "0449", uf: "PA" },
  { name: "ANGELICA", value: "9169", uf: "MS" },
  { name: "PORTO DE MOZ", value: "0517", uf: "PA" },
  { name: "DIVISA NOVA", value: "4447", uf: "MG" },
  { name: "DOIS IRMAOS DO BURITI", value: "9793", uf: "MS" },
  { name: "NIOAQUE", value: "9115", uf: "MS" },
  { name: "PEDRO GOMES", value: "9127", uf: "MS" },
  { name: "TURVOLANDIA", value: "5395", uf: "MG" },
  { name: "PARAUNA", value: "9523", uf: "GO" },
  { name: "HIDROLINA", value: "9391", uf: "GO" },
  { name: "AMETISTA DO SUL", value: "5969", uf: "RS" },
  { name: "TAQUARUSSU", value: "9817", uf: "MS" },
  { name: "JATEI", value: "9101", uf: "MS" },
  { name: "CORUMBA DE GOIAS", value: "9317", uf: "GO" },
  { name: "PALESTINA", value: "6801", uf: "SP" },
  { name: "LADARIO", value: "9103", uf: "MS" },
  { name: "MORRO REUTER", value: "6019", uf: "RS" },
  { name: "CERRITO", value: "0966", uf: "RS" },
  { name: "DEODAPOLIS", value: "9175", uf: "MS" },
  { name: "DIVISOPOLIS", value: "2657", uf: "MG" },
  { name: "PASSA VINTE", value: "4955", uf: "MG" },
  { name: "RIACHAO DO DANTAS", value: "3215", uf: "SE" },
  { name: "CAPUTIRA", value: "4257", uf: "MG" },
  { name: "IBIPEBA", value: "3547", uf: "BA" },
  { name: "VARZEA DO POCO", value: "3961", uf: "BA" },
  { name: "SAO DOMINGOS DO SUL", value: "7351", uf: "RS" },
  { name: "CANDIBA", value: "3431", uf: "BA" },
  { name: "UBARANA", value: "2971", uf: "SP" },
  { name: "UBAIRA", value: "3941", uf: "BA" },
  { name: "FERVEDOURO", value: "2683", uf: "MG" },
  { name: "RIO ESPERA", value: "5103", uf: "MG" },
  { name: "SAO JOAO BATISTA DO GLORIA", value: "5243", uf: "MG" },
  { name: "URUCARA", value: "0285", uf: "AM" },
  { name: "MATA VERDE", value: "2659", uf: "MG" },
  { name: "VICENTE DUTRA", value: "8965", uf: "RS" },
  { name: "JAGUARI", value: "8723", uf: "RS" },
  { name: "BRASNORTE", value: "9873", uf: "MT" },
  { name: "TARUMA", value: "7267", uf: "SP" },
  { name: "IBARAMA", value: "8401", uf: "RS" },
  { name: "ITANAGRA", value: "3619", uf: "BA" },
  { name: "MATO QUEIMADO", value: "1150", uf: "RS" },
  { name: "ICAPUI", value: "1593", uf: "CE" },
  { name: "RIO DO ANTONIO", value: "3837", uf: "BA" },
  { name: "IBIRAPUITA", value: "7299", uf: "RS" },
  { name: "SERRA PRETA", value: "3907", uf: "BA" },
  { name: "JERIQUARA", value: "6609", uf: "SP" },
  { name: "CANUDOS", value: "3085", uf: "BA" },
  { name: "CONTENDAS DO SINCORA", value: "3475", uf: "BA" },
  { name: "MAETINGA", value: "3975", uf: "BA" },
  { name: "MAXIMILIANO DE ALMEIDA", value: "8745", uf: "RS" },
  { name: "CAETANOS", value: "3269", uf: "BA" },
  { name: "CALDEIRAO GRANDE", value: "3409", uf: "BA" },
  { name: "CRISOPOLIS", value: "3491", uf: "BA" },
  { name: "PINDAI", value: "3791", uf: "BA" },
  { name: "MARAPANIM", value: "0487", uf: "PA" },
  { name: "CRISTINAPOLIS", value: "3133", uf: "SE" },
  { name: "MARATA", value: "6039", uf: "RS" },
  { name: "JOAO DOURADO", value: "3099", uf: "BA" },
  { name: "PIATA", value: "3787", uf: "BA" },
  { name: "ANDARAI", value: "3325", uf: "BA" },
  { name: "RIBEIRAO CASCALHEIRA", value: "9741", uf: "MT" },
  { name: "ERICO CARDOSO", value: "3309", uf: "BA" },
  { name: "PEJUCARA", value: "8789", uf: "RS" },
  { name: "NOVA ESPERANCA DO SUL", value: "7381", uf: "RS" },
  { name: "PLANALTINO", value: "3799", uf: "BA" },
  { name: "SAO GABRIEL", value: "3989", uf: "BA" },
  { name: "CHORROCHO", value: "3453", uf: "BA" },
  { name: "MARCIONILIO SOUZA", value: "3717", uf: "BA" },
  { name: "ALTANEIRA", value: "1311", uf: "CE" },
  { name: "SANTA CRUZ DA VITORIA", value: "3857", uf: "BA" },
  { name: "SAO FELIX DO CORIBE", value: "3031", uf: "BA" },
  { name: "SAUDE", value: "3895", uf: "BA" },
  { name: "INHAMBUPE", value: "3575", uf: "BA" },
  { name: "NOSSA SENHORA APARECIDA", value: "3135", uf: "SE" },
  { name: "OURIZONA", value: "7729", uf: "PR" },
  { name: "TOLEDO", value: "5381", uf: "MG" },
  { name: "COTIPORA", value: "8977", uf: "RS" },
  { name: "ENVIRA", value: "0231", uf: "AM" },
  { name: "ALVINOPOLIS", value: "4045", uf: "MG" },
  { name: "CURUCA", value: "0457", uf: "PA" },
  { name: "SOBRALIA", value: "5353", uf: "MG" },
  { name: "IRINEOPOLIS", value: "8155", uf: "SC" },
  { name: "NOVA ESPERANCA DO SUDOESTE", value: "5477", uf: "PR" },
  { name: "FORTIM", value: "0987", uf: "CE" },
  { name: "FEIJO", value: "0113", uf: "AC" },
  { name: "ARACAI", value: "4063", uf: "MG" },
  { name: "MAUA DA SERRA", value: "5459", uf: "PR" },
  { name: "ESTRELA VELHA", value: "0982", uf: "RS" },
  { name: "BALDIM", value: "4099", uf: "MG" },
  { name: "SANTA CRUZ DA CONCEICAO", value: "7025", uf: "SP" },
  { name: "SANTO ANTONIO DO TAUA", value: "0539", uf: "PA" },
  { name: "MAGALHAES BARATA", value: "0481", uf: "PA" },
  { name: "MENDONCA", value: "6691", uf: "SP" },
  { name: "SERRA DOS AIMORES", value: "5333", uf: "MG" },
  { name: "CHAVAL", value: "1377", uf: "CE" },
  { name: "JACUTINGA", value: "8719", uf: "RS" },
  { name: "JARDIM", value: "1443", uf: "CE" },
  { name: "PARAISO DO SUL", value: "7373", uf: "RS" },
  { name: "VITORINO FREIRE", value: "0953", uf: "MA" },
  { name: "CIPOTANEA", value: "4325", uf: "MG" },
  { name: "DAVID CANABARRO", value: "8623", uf: "RS" },
  { name: "CEDRO", value: "2385", uf: "PE" },
  { name: "ARACAS", value: "3259", uf: "BA" },
  { name: "QUARTO CENTENARIO", value: "0876", uf: "PR" },
  { name: "BALNEARIO BARRA DO SUL", value: "5549", uf: "SC" },
  { name: "FORQUILHA", value: "1591", uf: "CE" },
  { name: "CHUPINGUAIA", value: "0006", uf: "RO" },
  { name: "RIBEIRAO DO LARGO", value: "3027", uf: "BA" },
  { name: "NOVA ROSALANDIA", value: "9721", uf: "TO" },
  { name: "NORDESTINA", value: "3979", uf: "BA" },
  { name: "PARAI", value: "8783", uf: "RS" },
  { name: "SAO SEBASTIAO DA AMOREIRA", value: "7895", uf: "PR" },
  { name: "NOVA MAMORE", value: "0047", uf: "RO" },
  { name: "TAPIRA", value: "5361", uf: "MG" },
  { name: "NOVA IPIXUNA", value: "0056", uf: "PA" },
  { name: "GURUPA", value: "0461", uf: "PA" },
  { name: "ANAJAS", value: "0413", uf: "PA" },
  { name: "BURITIRANA", value: "0136", uf: "MA" },
  { name: "ALGODAO DE JANDAIRA", value: "0442", uf: "PB" },
  { name: "CURIMATA", value: "1063", uf: "PI" },
  { name: "PEDRO DO ROSARIO", value: "0208", uf: "MA" },
  { name: "SAO GONCALO DO RIO PRETO", value: "4509", uf: "MG" },
  { name: "SAUBARA", value: "3037", uf: "BA" },
  { name: "VERDEJANTE", value: "2621", uf: "PE" },
  { name: "ITAJA", value: "0418", uf: "RN" },
  { name: "BANZAE", value: "3261", uf: "BA" },
  { name: "AGUA CLARA", value: "9003", uf: "MS" },
  { name: "ARARICA", value: "0952", uf: "RS" },
  { name: "MACIEIRA", value: "5575", uf: "SC" },
  { name: "DURANDE", value: "2675", uf: "MG" },
  { name: "PONTE ALTA DO TOCANTINS", value: "9553", uf: "TO" },
  { name: "ESTIVA GERBI", value: "2959", uf: "SP" },
  { name: "NOVA ROMA DO SUL", value: "7377", uf: "RS" },
  { name: "ANGICAL DO PIAUI", value: "1011", uf: "PI" },
  { name: "MONTE BELO DO SUL", value: "5993", uf: "RS" },
  { name: "BARROCAS", value: "1110", uf: "BA" },
  { name: "BANDEIRANTES DO TOCANTINS", value: "0074", uf: "TO" },
  { name: "JOAO ALFREDO", value: "2461", uf: "PE" },
  { name: "TROMBAS", value: "9761", uf: "GO" },
  { name: "SAO JOAO DA CANABRAVA", value: "1291", uf: "PI" },
  { name: "PASSO DO SOBRADO", value: "5765", uf: "RS" },
  { name: "MOGEIRO", value: "2089", uf: "PB" },
  { name: "NOVA LACERDA", value: "1040", uf: "MT" },
  { name: "GUIA LOPES DA LAGUNA", value: "9081", uf: "MS" },
  { name: "UTINGA", value: "3955", uf: "BA" },
  { name: "SAO DOMINGOS DO CARIRI", value: "0520", uf: "PB" },
  { name: "PRESIDENTE TANCREDO NEVES", value: "3023", uf: "BA" },
  { name: "GUARAITA", value: "0065", uf: "GO" },
  { name: "TIBAU DO SUL", value: "1881", uf: "RN" },
  { name: "JAMBEIRO", value: "6599", uf: "SP" },
  { name: "NOVA OLINDA DO MARANHAO", value: "0202", uf: "MA" },
  { name: "DAVINOPOLIS", value: "0154", uf: "MA" },
  { name: "BALNEARIO GAIVOTA", value: "0890", uf: "SC" },
  { name: "DOIS LAJEADOS", value: "8427", uf: "RS" },
  { name: "LAGOA SANTA", value: "1076", uf: "GO" },
  { name: "MARCOLANDIA", value: "2275", uf: "PI" },
  { name: "CAPAO ALTO", value: "0906", uf: "SC" },
  { name: "SITIO NOVO", value: "0929", uf: "MA" },
  { name: "GUARIBAS", value: "0320", uf: "PI" },
  { name: "MORTUGABA", value: "3737", uf: "BA" },
  { name: "CARBONITA", value: "4269", uf: "MG" },
  { name: "CRISOLITA", value: "0596", uf: "MG" },
  { name: "BUJARU", value: "0437", uf: "PA" },
  { name: "SENTO SE", value: "3903", uf: "BA" },
  { name: "VARGEM ALEGRE", value: "0746", uf: "MG" },
  { name: "ITAGIBA", value: "3605", uf: "BA" },
  { name: "PORTO BARREIRO", value: "0872", uf: "PR" },
  { name: "RIO AZUL", value: "7817", uf: "PR" },
  { name: "JUSSIAPE", value: "3673", uf: "BA" },
  { name: "ANAJATUBA", value: "0713", uf: "MA" },
  { name: "BOTUMIRIM", value: "4169", uf: "MG" },
  { name: "ANDORINHA", value: "3255", uf: "BA" },
  { name: "ESPERANCA DO SUL", value: "0980", uf: "RS" },
  { name: "PENDENCIAS", value: "1797", uf: "RN" },
  { name: "SAO PEDRO DA SERRA", value: "6043", uf: "RS" },
  { name: "PEREIRAS", value: "6851", uf: "SP" },
  { name: "GASTAO VIDIGAL", value: "6437", uf: "SP" },
  { name: "SEDE NOVA", value: "7335", uf: "RS" },
  { name: "OSCAR BRESSANE", value: "6791", uf: "SP" },
  { name: "PONTAO", value: "5939", uf: "RS" },
  { name: "CARAMBEI", value: "0844", uf: "PR" },
  { name: "NOVA CASTILHO", value: "0806", uf: "SP" },
  { name: "ASPASIA", value: "2981", uf: "SP" },
  { name: "BENTO DE ABREU", value: "6223", uf: "SP" },
  { name: "ITINGA DO MARANHAO", value: "0174", uf: "MA" },
  { name: "RERIUTABA", value: "1535", uf: "CE" },
  { name: "MARCACAO", value: "0484", uf: "PB" },
  { name: "ZACARIAS", value: "2973", uf: "SP" },
  { name: "TREZE TILIAS", value: "8363", uf: "SC" },
  { name: "CORUMBIARA", value: "0981", uf: "RO" },
  { name: "ITUACU", value: "3645", uf: "BA" },
  { name: "ALTAIR", value: "6117", uf: "SP" },
  { name: "FLORA RICA", value: "6417", uf: "SP" },
  { name: "PONTO CHIQUE", value: "0692", uf: "MG" },
  { name: "PEDRO DE TOLEDO", value: "6845", uf: "SP" },
  { name: "DIRCE REIS", value: "7249", uf: "SP" },
  { name: "PEDRANOPOLIS", value: "6839", uf: "SP" },
  { name: "MATEIROS", value: "0317", uf: "TO" },
  { name: "APIUNA", value: "9941", uf: "SC" },
  { name: "AREALVA", value: "6167", uf: "SP" },
  { name: "AMERICO DE CAMPOS", value: "6135", uf: "SP" },
  { name: "PAULISTANIA", value: "0810", uf: "SP" },
  { name: "MORRINHOS DO SUL", value: "5775", uf: "RS" },
  { name: "CAJUEIRO DA PRAIA", value: "0288", uf: "PI" },
  { name: "MORROS", value: "0841", uf: "MA" },
  { name: "OLEO", value: "6777", uf: "SP" },
  { name: "NOVA EUROPA", value: "6759", uf: "SP" },
  { name: "CAIANA", value: "4201", uf: "MG" },
  { name: "SAO BRAS", value: "2863", uf: "AL" },
  { name: "SANDOVALINA", value: "7011", uf: "SP" },
  { name: "AREIOPOLIS", value: "6171", uf: "SP" },
  { name: "RIBEIRAO VERMELHO", value: "5093", uf: "MG" },
  { name: "ITAGUARA", value: "4643", uf: "MG" },
  { name: "BOA VISTA DO RAMOS", value: "0297", uf: "AM" },
  { name: "TAMANDARE", value: "0558", uf: "PE" },
  { name: "CONDOR", value: "8611", uf: "RS" },
  { name: "RODELAS", value: "3843", uf: "BA" },
  { name: "AUREA", value: "8487", uf: "RS" },
  { name: "OLHOS-D'AGUA", value: "0670", uf: "MG" },
  { name: "BALNEARIO RINCAO", value: "1192", uf: "SC" },
  { name: "LAGOA BONITA DO SUL", value: "1148", uf: "RS" },
  { name: "SUD MENNUCCI", value: "7147", uf: "SP" },
  { name: "BARRO ALTO", value: "3075", uf: "BA" },
  { name: "SANTA RITA DO TRIVELATO", value: "1096", uf: "MT" },
  { name: "PARANATAMA", value: "2505", uf: "PE" },
  { name: "PRANCHITA", value: "7991", uf: "PR" },
  { name: "ULIANOPOLIS", value: "0623", uf: "PA" },
  { name: "SAO JOAO DA BALIZA", value: "0313", uf: "RR" },
  { name: "ARAMBARE", value: "5779", uf: "RS" },
  { name: "SANTA TEREZINHA", value: "2555", uf: "PE" },
  { name: "SERRA GRANDE", value: "2215", uf: "PB" },
  { name: "JACARAU", value: "2047", uf: "PB" },
  { name: "TAPIRAMUTA", value: "3925", uf: "BA" },
  { name: "CROMINIA", value: "9331", uf: "GO" },
  { name: "PASSOS MAIA", value: "5743", uf: "SC" },
  { name: "GAVIAO PEIXOTO", value: "0798", uf: "SP" },
  { name: "SANTA CARMEM", value: "0123", uf: "MT" },
  { name: "DIVINESIA", value: "4437", uf: "MG" },
  { name: "IATI", value: "2429", uf: "PE" },
  { name: "SABOEIRO", value: "1539", uf: "CE" },
  { name: "SUMIDOURO", value: "5913", uf: "RJ" },
  { name: "INDEPENDENCIA", value: "8709", uf: "RS" },
  { name: "PROTASIO ALVES", value: "7359", uf: "RS" },
  { name: "ALVARO DE CARVALHO", value: "6127", uf: "SP" },
  { name: "CAXAMBU DO SUL", value: "8079", uf: "SC" },
  { name: "TURVANIA", value: "9631", uf: "GO" },
  { name: "AUGUSTO CORREA", value: "0417", uf: "PA" },
  { name: "GUARACIAMA", value: "0622", uf: "MG" },
  { name: "PERITORO", value: "0210", uf: "MA" },
  { name: "INACIOLANDIA", value: "0069", uf: "GO" },
  { name: "SAO SEBASTIAO DO TOCANTINS", value: "9603", uf: "TO" },
  { name: "SEVERIANO MELO", value: "1869", uf: "RN" },
  { name: "ANGICAL", value: "3327", uf: "BA" },
  { name: "MONSENHOR TABOSA", value: "1473", uf: "CE" },
  { name: "DOUTOR ULYSSES", value: "5449", uf: "PR" },
  { name: "SAO JOSE DO MANTIMENTO", value: "5271", uf: "MG" },
  { name: "SAO JOSE DO HORTENCIO", value: "7343", uf: "RS" },
  { name: "GUABIJU", value: "8405", uf: "RS" },
  { name: "CASEIROS", value: "8441", uf: "RS" },
  { name: "CENTRO NOVO DO MARANHAO", value: "0148", uf: "MA" },
  { name: "BALNEARIO PINHAL", value: "0954", uf: "RS" },
  { name: "LAJEDO DO TABOCAL", value: "3291", uf: "BA" },
  { name: "SAO VALENTIM DO SUL", value: "5997", uf: "RS" },
  { name: "CARNAUBEIRA DA PENHA", value: "2635", uf: "PE" },
  { name: "SENADOR MODESTINO GONCALVES", value: "5317", uf: "MG" },
  { name: "SAO JOSE DA VARGINHA", value: "5261", uf: "MG" },
  { name: "BONFIM", value: "0307", uf: "RR" },
  { name: "ANGELANDIA", value: "0566", uf: "MG" },
  { name: "ALTAMIRA DO PARANA", value: "8455", uf: "PR" },
  { name: "BANNACH", value: "0042", uf: "PA" },
  { name: "JUSSARI", value: "3069", uf: "BA" },
  { name: "LAGOA DO OURO", value: "2471", uf: "PE" },
  { name: "VILA PAVAO", value: "2935", uf: "ES" },
  { name: "FORMOSA DA SERRA NEGRA", value: "0160", uf: "MA" },
  { name: "DAMOLANDIA", value: "9337", uf: "GO" },
  { name: "ALCINOPOLIS", value: "0141", uf: "MS" },
  { name: "LAJEDAO", value: "3679", uf: "BA" },
  { name: "TORRE DE PEDRA", value: "3227", uf: "SP" },
  { name: "SAO GONCALO DO PIAUI", value: "1195", uf: "PI" },
  { name: "SANTANA DE CATAGUASES", value: "5167", uf: "MG" },
  { name: "LUCRECIA", value: "1737", uf: "RN" },
  { name: "SAO BONIFACIO", value: "8313", uf: "SC" },
  { name: "LAGOAO", value: "7389", uf: "RS" },
  { name: "SALITRE", value: "1273", uf: "CE" },
  { name: "CUTIAS", value: "0667", uf: "AP" },
  { name: "BREJINHO", value: "2349", uf: "PE" },
  { name: "JUATUBA", value: "2691", uf: "MG" },
  { name: "GUAMARE", value: "1687", uf: "RN" },
  { name: "FLORESTA AZUL", value: "3519", uf: "BA" },
  { name: "SAO DOMINGOS DO NORTE", value: "2933", uf: "ES" },
  { name: "QUATIPURU", value: "0062", uf: "PA" },
  { name: "PRESIDENTE KENNEDY", value: "9629", uf: "TO" },
  { name: "PEDRA GRANDE", value: "1789", uf: "RN" },
  { name: "FLORAI", value: "7553", uf: "PR" },
  { name: "MASSAPE DO PIAUI", value: "0346", uf: "PI" },
  { name: "CAMPINA DO SIMAO", value: "0840", uf: "PR" },
  { name: "MURICI DOS PORTELAS", value: "0354", uf: "PI" },
  { name: "NOVO PLANALTO", value: "9735", uf: "GO" },
  { name: "SERTAO SANTANA", value: "5761", uf: "RS" },
  { name: "SANTANA DA PONTE PENSA", value: "7045", uf: "SP" },
  { name: "ARANTINA", value: "4071", uf: "MG" },
  { name: "NOVA ERECHIM", value: "8223", uf: "SC" },
  { name: "BARRA DE SANTA ROSA", value: "1933", uf: "PB" },
  { name: "PRESIDENTE FIGUEIREDO", value: "9841", uf: "AM" },
  { name: "MAGALHAES DE ALMEIDA", value: "0825", uf: "MA" },
  { name: "IPORANGA", value: "6525", uf: "SP" },
  { name: "LAGOA REAL", value: "3289", uf: "BA" },
  { name: "GUZOLANDIA", value: "6479", uf: "SP" },
  { name: "ORIENTE", value: "6783", uf: "SP" },
  { name: "BELEM DO PIAUI", value: "0276", uf: "PI" },
  { name: "PALMEIRAS", value: "3771", uf: "BA" },
  { name: "SANDOLANDIA", value: "0331", uf: "TO" },
  { name: "JENIPAPO DOS VIEIRAS", value: "0178", uf: "MA" },
  { name: "FARIA LEMOS", value: "4505", uf: "MG" },
  { name: "NOVO BRASIL", value: "9501", uf: "GO" },
  { name: "RODRIGUES ALVES", value: "0659", uf: "AC" },
  { name: "SANTA ROSA DO PURUS", value: "0661", uf: "AC" },
  { name: "DIAMANTE DO SUL", value: "5465", uf: "PR" },
  { name: "PRESIDENTE DUTRA", value: "3813", uf: "BA" },
  { name: "FILADELFIA", value: "3091", uf: "BA" },
  { name: "TABOLEIRO GRANDE", value: "1873", uf: "RN" },
  { name: "MIRIM DOCE", value: "5559", uf: "SC" },
  { name: "SAO NICOLAU", value: "8887", uf: "RS" },
  { name: "RUBIACEA", value: "6989", uf: "SP" },
  { name: "ARACITABA", value: "4065", uf: "MG" },
  { name: "PORTO WALTER", value: "0657", uf: "AC" },
  { name: "SANTA INES", value: "3859", uf: "BA" },
  { name: "NOVA UBIRATA", value: "1042", uf: "MT" },
  { name: "MONTE ALEGRE DO PIAUI", value: "1131", uf: "PI" },
  { name: "MORRO REDONDO", value: "7385", uf: "RS" },
  { name: "VIDAL RAMOS", value: "8377", uf: "SC" },
  { name: "INGAZEIRA", value: "2441", uf: "PE" },
  { name: "SAO JOAO DO SOTER", value: "0236", uf: "MA" },
  { name: "FELIZ NATAL", value: "1036", uf: "MT" },
  { name: "PIRAPO", value: "7367", uf: "RS" },
  { name: "BACURI", value: "0725", uf: "MA" },
  { name: "SAO JOAO DO PARAISO", value: "0234", uf: "MA" },
  { name: "HARMONIA", value: "8403", uf: "RS" },
  { name: "CALMON", value: "5553", uf: "SC" },
  { name: "SANTA RITA DO ITUETO", value: "5189", uf: "MG" },
  { name: "AGUA FRIA", value: "3307", uf: "BA" },
  { name: "URU", value: "7219", uf: "SP" },
  { name: "PASSAGEM", value: "2115", uf: "PB" },
  { name: "CORREGO DO OURO", value: "9315", uf: "GO" },
  { name: "FATIMA", value: "3089", uf: "BA" },
  { name: "BOM LUGAR", value: "0130", uf: "MA" },
  { name: "NOVAIS", value: "2979", uf: "SP" },
  { name: "TRES FORQUILHAS", value: "5777", uf: "RS" },
  { name: "AREIAS", value: "6169", uf: "SP" },
  { name: "SANTA ROSA DA SERRA", value: "5193", uf: "MG" },
  { name: "SANTA CECILIA DO PAVAO", value: "7841", uf: "PR" },
  { name: "LIMA CAMPOS", value: "0819", uf: "MA" },
  { name: "AMARANTE DO MARANHAO", value: "0711", uf: "MA" },
  { name: "JUCURUCU", value: "3287", uf: "BA" },
  { name: "PICARRA", value: "0058", uf: "PA" },
  { name: "NORMANDIA", value: "0311", uf: "RR" },
  { name: "CHAPADA GAUCHA", value: "0588", uf: "MG" },
  { name: "SANTA IZABEL DO OESTE", value: "7851", uf: "PR" },
  { name: "FATIMA", value: "9683", uf: "TO" },
  { name: "APARECIDA", value: "0446", uf: "PB" },
  { name: "IPAPORANGA", value: "1259", uf: "CE" },
  { name: "SAO MANOEL DO PARANA", value: "5515", uf: "PR" },
  { name: "DOM PEDRO DE ALCANTARA", value: "0976", uf: "RS" },
  { name: "IBIRAPUA", value: "3557", uf: "BA" },
  { name: "BOM JESUS DO ARAGUAIA", value: "1078", uf: "MT" },
  { name: "FREI GASPAR", value: "4535", uf: "MG" },
  { name: "COLARES", value: "0451", uf: "PA" },
  { name: "SAO JOSE DO XINGU", value: "0133", uf: "MT" },
  { name: "GENTIO DO OURO", value: "3525", uf: "BA" },
  { name: "MUNHOZ", value: "4875", uf: "MG" },
  { name: "BARRA DO ROCHA", value: "3361", uf: "BA" },
  { name: "PIRAPEMAS", value: "0873", uf: "MA" },
  { name: "JURUENA", value: "9921", uf: "MT" },
  { name: "CRUZALIA", value: "6367", uf: "SP" },
  { name: "GURJAO", value: "2031", uf: "PB" },
  { name: "SANTO ANDRE", value: "0516", uf: "PB" },
  { name: "ITAPIRANGA", value: "0243", uf: "AM" },
  { name: "OURO VERDE DE GOIAS", value: "9505", uf: "GO" },
  { name: "ITACAJA", value: "9405", uf: "TO" },
  { name: "SERRA BRANCA", value: "2211", uf: "PB" },
  { name: "SAO JOAO DO MANTENINHA", value: "2679", uf: "MG" },
  { name: "SANTA FE DE GOIAS", value: "9743", uf: "GO" },
  { name: "PEDRO REGIS", value: "0500", uf: "PB" },
  { name: "SANTIAGO DO SUL", value: "0940", uf: "SC" },
  { name: "SANTA TEREZA DO TOCANTINS", value: "9731", uf: "TO" },
  { name: "REDENCAO DO GURGUEIA", value: "1173", uf: "PI" },
  { name: "RIALMA", value: "9565", uf: "GO" },
  { name: "MARIPA", value: "5487", uf: "PR" },
  { name: "CARAA", value: "0964", uf: "RS" },
  { name: "SALVADOR DO SUL", value: "8833", uf: "RS" },
  { name: "SAO JOAO DAS MISSOES", value: "0718", uf: "MG" },
  { name: "BACABEIRA", value: "0116", uf: "MA" },
  { name: "SANTA CRUZ DE GOIAS", value: "9579", uf: "GO" },
  { name: "SOCORRO DO PIAUI", value: "1217", uf: "PI" },
  { name: "BELO MONTE", value: "2717", uf: "AL" },
  { name: "ROSARIO OESTE", value: "9153", uf: "MT" },
  { name: "VARJOTA", value: "9857", uf: "CE" },
  { name: "UIRAPURU", value: "0059", uf: "GO" },
  { name: "ENTRE FOLHAS", value: "2663", uf: "MG" },
  { name: "SERRA DO NAVIO", value: "0665", uf: "AP" },
  { name: "DEPUTADO IRAPUAN PINHEIRO", value: "1243", uf: "CE" },
  { name: "PIRAQUE", value: "0355", uf: "TO" },
  { name: "NOVA COLINAS", value: "0200", uf: "MA" },
  { name: "UBAPORANGA", value: "2671", uf: "MG" },
  { name: "PERIQUITO", value: "0684", uf: "MG" },
  { name: "NOVA OLINDA DO NORTE", value: "0265", uf: "AM" },
  { name: "BREJO DOS SANTOS", value: "1959", uf: "PB" },
  { name: "RIQUEZA", value: "5749", uf: "SC" },
  { name: "LAGOA DA CONFUSAO", value: "0367", uf: "TO" },
  { name: "ITARUMA", value: "9421", uf: "GO" },
  { name: "SAO SEBASTIAO DA VARGEM ALEGRE", value: "0726", uf: "MG" },
  { name: "ANAPURUS", value: "0715", uf: "MA" },
  { name: "MATIAS CARDOSO", value: "2897", uf: "MG" },
  { name: "PRINCESA", value: "0934", uf: "SC" },
  { name: "CRUZALTENSE", value: "1140", uf: "RS" },
  { name: "TURURU", value: "1279", uf: "CE" },
  { name: "CAMPO DO TENENTE", value: "7479", uf: "PR" },
  { name: "TRES ARROIOS", value: "7331", uf: "RS" },
  { name: "ITAPISSUMA", value: "2633", uf: "PE" },
  { name: "SERRANOPOLIS DO IGUACU", value: "0882", uf: "PR" },
  { name: "COQUEIROS DO SUL", value: "5945", uf: "RS" },
  { name: "LARANJA DA TERRA", value: "5723", uf: "ES" },
  { name: "EDEIA", value: "9349", uf: "GO" },
  { name: "SANTO ANTONIO DO LESTE", value: "1098", uf: "MT" },
  { name: "PALMEIRA DO PIAUI", value: "1147", uf: "PI" },
  { name: "RIO SONO", value: "9679", uf: "TO" },
  { name: "MARILANDIA", value: "5707", uf: "ES" },
  { name: "PEDRA LAVRADA", value: "2123", uf: "PB" },
  { name: "ASSIS BRASIL", value: "0157", uf: "AC" },
  { name: "RONDOLANDIA", value: "1092", uf: "MT" },
  { name: "ITAARA", value: "0990", uf: "RS" },
  { name: "PARIPUEIRA", value: "2641", uf: "AL" },
  { name: "SENA MADUREIRA", value: "0145", uf: "AC" },
  { name: "CHUVISCA", value: "0970", uf: "RS" },
  { name: "COCALZINHO DE GOIAS", value: "0055", uf: "GO" },
  { name: "PORTO ACRE", value: "0649", uf: "AC" },
  { name: "BARAO DE COTEGIPE", value: "8533", uf: "RS" },
  { name: "RANCHO ALEGRE", value: "7803", uf: "PR" },
  { name: "PITIMBU", value: "2139", uf: "PB" },
  { name: "URUCUIA", value: "2699", uf: "MG" },
  { name: "FERNANDO DE NORONHA", value: "3001", uf: "PE" },
  { name: "FERNANDES PINHEIRO", value: "0854", uf: "PR" },
  { name: "MOJUI DOS CAMPOS", value: "1190", uf: "PA" },
  { name: "RIO CLARO", value: "5887", uf: "RJ" },
  { name: "TRINDADE DO SUL", value: "7325", uf: "RS" },
  { name: "CHAPADA DA NATIVIDADE", value: "0080", uf: "TO" },
  { name: "ESPERANCA NOVA", value: "0850", uf: "PR" },
  { name: "MAMBAI", value: "9449", uf: "GO" },
  { name: "GUAIUBA", value: "1251", uf: "CE" },
  { name: "JUVENILIA", value: "0640", uf: "MG" },
  { name: "SAO DOMINGOS DO MARANHAO", value: "0911", uf: "MA" },
  { name: "CAPITAO GERVASIO OLIVEIRA", value: "0296", uf: "PI" },
  { name: "TENENTE ANANIAS", value: "1879", uf: "RN" },
  { name: "BOCAINA DO SUL", value: "0898", uf: "SC" },
  { name: "CANDIDO RODRIGUES", value: "6303", uf: "SP" },
  { name: "ALVORADA DO GURGUEIA", value: "0268", uf: "PI" },
  { name: "TURILANDIA", value: "0262", uf: "MA" },
  { name: "PARNARAMA", value: "0855", uf: "MA" },
  { name: "MONTE FORMOSO", value: "0654", uf: "MG" },
  { name: "MARECHAL FLORIANO", value: "2929", uf: "ES" },
  { name: "JACUIZINHO", value: "1146", uf: "RS" },
  { name: "MATINA", value: "3295", uf: "BA" },
  { name: "RIO BRANCO", value: "8995", uf: "MT" },
  { name: "SANTA ISABEL DO RIO NEGRO", value: "0237", uf: "AM" },
  { name: "RIACHAO", value: "0502", uf: "PB" },
  { name: "ITACURUBA", value: "2447", uf: "PE" },
  { name: "NOVA IBIA", value: "3009", uf: "BA" },
  { name: "PARAMIRIM", value: "3773", uf: "BA" },
  { name: "BREJINHO DE NAZARE", value: "9273", uf: "TO" },
  { name: "FORTALEZA DOS NOGUEIRAS", value: "0779", uf: "MA" },
  { name: "SAO DOMINGOS DAS DORES", value: "0710", uf: "MG" },
  { name: "BARRA DO QUARAI", value: "0956", uf: "RS" },
  { name: "SOBRADINHO", value: "3045", uf: "BA" },
  { name: "ABAIARA", value: "1301", uf: "CE" },
  { name: "DUAS BARRAS", value: "5831", uf: "RJ" },
  { name: "PRADO FERREIRA", value: "0874", uf: "PR" },
  { name: "CARIRIACU", value: "1363", uf: "CE" },
  { name: "MANCIO LIMA", value: "0109", uf: "AC" },
  { name: "ELISIO MEDRADO", value: "3505", uf: "BA" },
  { name: "ALVARAES", value: "0289", uf: "AM" },
  { name: "BRASIL NOVO", value: "0639", uf: "PA" },
  { name: "IBICOARA", value: "3543", uf: "BA" },
  { name: "PIRANGUCU", value: "5017", uf: "MG" },
  { name: "MINAS DO LEAO", value: "5773", uf: "RS" },
  { name: "MARQUES DE SOUZA", value: "0998", uf: "RS" },
  { name: "NINA RODRIGUES", value: "0843", uf: "MA" },
  { name: "SAO JULIAO", value: "1205", uf: "PI" },
  { name: "NOVO CABRAIS", value: "1008", uf: "RS" },
  { name: "REGENERACAO", value: "1175", uf: "PI" },
  { name: "CAPOEIRAS", value: "2375", uf: "PE" },
  { name: "TIRADENTES DO SUL", value: "6077", uf: "RS" },
  { name: "AUGUSTO DE LIMA", value: "4095", uf: "MG" },
  { name: "SANTA MARIA DO TOCANTINS", value: "0361", uf: "TO" },
  { name: "COUTO DE MAGALHAES", value: "9321", uf: "TO" },
  { name: "ESPIGAO ALTO DO IGUACU", value: "0852", uf: "PR" },
  { name: "SANTANA DO RIACHO", value: "5179", uf: "MG" },
  { name: "PEDRAS DE MARIA DA CRUZ", value: "2899", uf: "MG" },
  { name: "MATRINCHA", value: "9927", uf: "GO" },
  { name: "LEOBERTO LEAL", value: "8193", uf: "SC" },
  { name: "FLORESTA DO PIAUI", value: "0314", uf: "PI" },
  { name: "CARAPEBUS", value: "0772", uf: "RJ" },
  { name: "IMBE DE MINAS", value: "0626", uf: "MG" },
  { name: "IACIARA", value: "9393", uf: "GO" },
  { name: "FORMOSA DO SUL", value: "5581", uf: "SC" },
  { name: "VALE DO SOL", value: "5769", uf: "RS" },
  { name: "CANAPOLIS", value: "3421", uf: "BA" },
  { name: "SAO PEDRO DOS FERROS", value: "5279", uf: "MG" },
  { name: "MACHADINHO", value: "8735", uf: "RS" },
  { name: "ANTONIO PRADO DE MINAS", value: "4061", uf: "MG" },
  { name: "BERIZAL", value: "0570", uf: "MG" },
  { name: "FARO", value: "0459", uf: "PA" },
  { name: "SALDANHA MARINHO", value: "7339", uf: "RS" },
  { name: "LUZERNA", value: "0926", uf: "SC" },
  { name: "MARAU", value: "3715", uf: "BA" },
  { name: "LINDOESTE", value: "9959", uf: "PR" },
  { name: "NOVA MARINGA", value: "0111", uf: "MT" },
  { name: "TAMARANA", value: "0884", uf: "PR" },
  { name: "TURVELANDIA", value: "9765", uf: "GO" },
  { name: "POCINHOS", value: "2141", uf: "PB" },
  { name: "CAPITAO DE CAMPOS", value: "1047", uf: "PI" },
  { name: "SAO JOSE DE CAIANA", value: "2187", uf: "PB" },
  { name: "SANTO ANTONIO DO GRAMA", value: "5201", uf: "MG" },
  { name: "PALESTINA DE GOIAS", value: "9737", uf: "GO" },
  { name: "CERRO NEGRO", value: "5567", uf: "SC" },
  { name: "DOVERLANDIA", value: "9675", uf: "GO" },
  { name: "FARIAS BRITO", value: "1387", uf: "CE" },
  { name: "PORTO MAUA", value: "6065", uf: "RS" },
  { name: "SERRA NEGRA DO NORTE", value: "1865", uf: "RN" },
  { name: "UBAI", value: "5399", uf: "MG" },
  { name: "GOIATINS", value: "9533", uf: "TO" },
  { name: "BELA VISTA DO PIAUI", value: "0274", uf: "PI" },
  { name: "MUCURICI", value: "5671", uf: "ES" },
  { name: "CABIXI", value: "0037", uf: "RO" },
  { name: "NARANDIBA", value: "6745", uf: "SP" },
  { name: "PAULO BENTO", value: "1154", uf: "RS" },
  { name: "MORPARA", value: "3733", uf: "BA" },
  { name: "SANTA CRUZ", value: "2165", uf: "PB" },
  { name: "ITAUBAL", value: "0669", uf: "AP" },
  { name: "RIACHO DOS CAVALOS", value: "2157", uf: "PB" },
  { name: "SENADOR AMARAL", value: "2689", uf: "MG" },
  { name: "GUAMIRANGA", value: "0860", uf: "PR" },
  { name: "VERMELHO NOVO", value: "0756", uf: "MG" },
  { name: "LUIZIANIA", value: "6655", uf: "SP" },
  { name: "PARANA", value: "9521", uf: "TO" },
  { name: "TERRA SANTA", value: "0637", uf: "PA" },
  { name: "MAIRIPOTABA", value: "9447", uf: "GO" },
  { name: "ADUSTINA", value: "3253", uf: "BA" },
  { name: "WALL FERRAZ", value: "0410", uf: "PI" },
  { name: "BARRA DE SANTANA", value: "0454", uf: "PB" },
  { name: "SAO SEBASTIAO DA BOA VISTA", value: "0553", uf: "PA" },
  { name: "POCO DAS TRINCHEIRAS", value: "2843", uf: "AL" },
  { name: "AURILANDIA", value: "9251", uf: "GO" },
  { name: "JARDINOPOLIS", value: "5587", uf: "SC" },
  { name: "CAMPINA DO MONTE ALEGRE", value: "2999", uf: "SP" },
  { name: "CHOROZINHO", value: "1239", uf: "CE" },
  { name: "SANTANA DO GARAMBEU", value: "5173", uf: "MG" },
  { name: "PAUINI", value: "0271", uf: "AM" },
  { name: "JAPI", value: "1705", uf: "RN" },
  { name: "MOMBUCA", value: "6719", uf: "SP" },
  { name: "VITORIA DO XINGU", value: "0641", uf: "PA" },
  { name: "QUIXABA", value: "2637", uf: "PE" },
  { name: "IMIGRANTE", value: "7295", uf: "RS" },
  { name: "FORMOSO", value: "4523", uf: "MG" },
  { name: "PLANALTO ALEGRE", value: "5593", uf: "SC" },
  { name: "IPUACU", value: "5737", uf: "SC" },
  { name: "BOM JESUS DO AMPARO", value: "4153", uf: "MG" },
  { name: "CAPIVARI DO SUL", value: "0962", uf: "RS" },
  { name: "MOTUCA", value: "7263", uf: "SP" },
  { name: "NANTES", value: "0804", uf: "SP" },
  { name: "SAO BRAZ DO PIAUI", value: "2263", uf: "PI" },
  { name: "ARRAIAL", value: "1019", uf: "PI" },
  { name: "RELVADO", value: "7355", uf: "RS" },
  { name: "DAMIANOPOLIS", value: "9335", uf: "GO" },
  { name: "NATERCIA", value: "4887", uf: "MG" },
  { name: "EMAS", value: "2019", uf: "PB" },
  { name: "FEIRA NOVA DO MARANHAO", value: "0156", uf: "MA" },
  { name: "BOA VENTURA", value: "1943", uf: "PB" },
  { name: "IRACEMA", value: "1421", uf: "CE" },
  { name: "GUAJARA", value: "0967", uf: "AM" },
  { name: "CAXINGO", value: "0302", uf: "PI" },
  { name: "TANGUA", value: "0788", uf: "RJ" },
  { name: "SUZANAPOLIS", value: "2945", uf: "SP" },
  { name: "IGRAPIUNA", value: "3277", uf: "BA" },
  { name: "SANTA FE DO ARAGUAIA", value: "0195", uf: "TO" },
  { name: "TABAI", value: "1016", uf: "RS" },
  { name: "MIRA ESTRELA", value: "6701", uf: "SP" },
  { name: "MONCOES", value: "6721", uf: "SP" },
  { name: "PALMEIRANDIA", value: "0851", uf: "MA" },
  { name: "SANTA RITA DO NOVO DESTINO", value: "1062", uf: "GO" },
  { name: "NOVA ALVORADA", value: "7383", uf: "RS" },
  { name: "CASSIA DOS COQUEIROS", value: "6319", uf: "SP" },
  { name: "JENIPAPO DE MINAS", value: "0632", uf: "MG" },
  { name: "MESOPOLIS", value: "2983", uf: "SP" },
  { name: "OURICANGAS", value: "3767", uf: "BA" },
  { name: "PARISI", value: "2989", uf: "SP" },
  { name: "SANTO EXPEDITO", value: "7067", uf: "SP" },
  { name: "MARABA PAULISTA", value: "6675", uf: "SP" },
  { name: "JULIO MESQUITA", value: "6617", uf: "SP" },
  { name: "TREVISO", value: "0948", uf: "SC" },
  { name: "APICUM-ACU", value: "0112", uf: "MA" },
  { name: "NOVA GUATAPORANGA", value: "6763", uf: "SP" },
  { name: "SAO JOAO DO PAU D'ALHO", value: "7087", uf: "SP" },
  { name: "NOVA NAZARE", value: "1086", uf: "MT" },
  { name: "ALAGOINHA", value: "2311", uf: "PE" },
  { name: "PILAO ARCADO", value: "3789", uf: "BA" },
  { name: "IPIGUA", value: "0800", uf: "SP" },
  { name: "FERNAO", value: "0796", uf: "SP" },
  { name: "SAO JOAO DE IRACEMA", value: "2941", uf: "SP" },
  { name: "EMBAUBA", value: "7251", uf: "SP" },
  { name: "UMBURATIBA", value: "5405", uf: "MG" },
  { name: "CANTAGALO", value: "0582", uf: "MG" },
  { name: "RIBEIRAO GRANDE", value: "3057", uf: "SP" },
  { name: "ARVOREDO", value: "5599", uf: "SC" },
  { name: "CAICARA DO RIO DO VENTO", value: "1637", uf: "RN" },
  { name: "GABRIEL MONTEIRO", value: "6431", uf: "SP" },
  { name: "PALMOPOLIS", value: "2661", uf: "MG" },
  { name: "MANFRINOPOLIS", value: "0864", uf: "PR" },
  { name: "FIGUEIRAO", value: "1178", uf: "MS" },
  { name: "SANTANA DE PIRAPAMA", value: "5169", uf: "MG" },
  { name: "NOVA CANAA PAULISTA", value: "2985", uf: "SP" },
  { name: "BURITIZAL", value: "6265", uf: "SP" },
  { name: "RIFAINA", value: "6973", uf: "SP" },
  { name: "JARI", value: "0992", uf: "RS" },
  { name: "SANTA CRUZ DE MINAS", value: "0702", uf: "MG" },
  { name: "MILTON BRANDAO", value: "0348", uf: "PI" },
  { name: "TOMAR DO GERU", value: "3249", uf: "SE" },
  { name: "BONOPOLIS", value: "1056", uf: "GO" },
  { name: "BERNARDO DO MEARIM", value: "0124", uf: "MA" },
  { name: "PRESIDENTE NEREU", value: "8277", uf: "SC" },
  { name: "SERRA NOVA DOURADA", value: "1100", uf: "MT" },
  { name: "JANDAIRA", value: "3659", uf: "BA" },
  { name: "UNIAO DA SERRA", value: "5999", uf: "RS" },
  { name: "GARRAFAO DO NORTE", value: "0585", uf: "PA" },
  { name: "JURUAIA", value: "4737", uf: "MG" },
  { name: "LORETO", value: "0821", uf: "MA" },
  { name: "TOCOS DO MOJI", value: "0740", uf: "MG" },
  { name: "JUCAS", value: "1449", uf: "CE" },
  { name: "IELMO MARINHO", value: "1689", uf: "RN" },
  { name: "GOVERNADOR NEWTON BELLO", value: "0166", uf: "MA" },
  { name: "CANAS", value: "0794", uf: "SP" },
  { name: "FREI PAULO", value: "3145", uf: "SE" },
  { name: "LUIZIANA", value: "8481", uf: "PR" },
  { name: "NOVA PADUA", value: "5991", uf: "RS" },
  { name: "GUATAMBU", value: "5583", uf: "SC" },
  { name: "SAO JOSE DA SAFIRA", value: "5259", uf: "MG" },
  { name: "SAO LOURENCO DO PIAUI", value: "2265", uf: "PI" },
  { name: "BELA VISTA DA CAROBA", value: "0834", uf: "PR" },
  { name: "VENTUROSA", value: "2619", uf: "PE" },
  { name: "SANTA LUCIA", value: "7039", uf: "SP" },
  { name: "LAJEADO NOVO", value: "0188", uf: "MA" },
  { name: "SENHORA DOS REMEDIOS", value: "5323", uf: "MG" },
  { name: "URUANA", value: "9635", uf: "GO" },
  { name: "CORRENTES", value: "2393", uf: "PE" },
  { name: "MEDICILANDIA", value: "0589", uf: "PA" },
  { name: "SAO FELIPE D'OESTE", value: "0018", uf: "RO" },
  { name: "MONTE NEGRO", value: "0685", uf: "RO" },
  { name: "ELISIARIO", value: "2975", uf: "SP" },
  { name: "SENADOR JOSE PORFIRIO", value: "0555", uf: "PA" },
  { name: "GENERAL CARNEIRO", value: "9077", uf: "MT" },
  { name: "ITAPUCA", value: "6027", uf: "RS" },
  { name: "PINTADAS", value: "3983", uf: "BA" },
  { name: "DUQUE BACELAR", value: "0775", uf: "MA" },
  { name: "PINGO D'AGUA", value: "0688", uf: "MG" },
  { name: "GRACA", value: "1249", uf: "CE" },
  { name: "LUISBURGO", value: "0644", uf: "MG" },
  { name: "PALESTINA DO PARA", value: "0379", uf: "PA" },
  { name: "EDEALINA", value: "9795", uf: "GO" },
  { name: "IGARAPE DO MEIO", value: "0170", uf: "MA" },
  { name: "GUARANI D'OESTE", value: "6461", uf: "SP" },
  { name: "JOANESIA", value: "4721", uf: "MG" },
  { name: "CORREGO FUNDO", value: "0594", uf: "MG" },
  { name: "NOVO BARREIRO", value: "5985", uf: "RS" },
  { name: "CHAPADA DO NORTE", value: "4321", uf: "MG" },
  { name: "SAGRES", value: "6995", uf: "SP" },
  { name: "NOVA BOA VISTA", value: "5953", uf: "RS" },
  { name: "SAO JOSE DO SUL", value: "1170", uf: "RS" },
  { name: "ALEGRETE DO PIAUI", value: "2269", uf: "PI" },
  { name: "JUSSARA", value: "3671", uf: "BA" },
  { name: "CACHOEIRA DO PIRIA", value: "0046", uf: "PA" },
  { name: "FAZENDA VILANOVA", value: "0984", uf: "RS" },
  { name: "ANGICO", value: "0165", uf: "TO" },
  { name: "TAQUARAL", value: "0824", uf: "SP" },
  { name: "SANTO ANTONIO DO CAIUA", value: "7861", uf: "PR" },
  { name: "SAO JOAO DA LAGOA", value: "0716", uf: "MG" },
  { name: "ORIZANIA", value: "0674", uf: "MG" },
  { name: "LAGOA", value: "2063", uf: "PB" },
  { name: "IARAS", value: "7259", uf: "SP" },
  { name: "ADOLFO", value: "6103", uf: "SP" },
  { name: "DOURADOQUARA", value: "4469", uf: "MG" },
  { name: "SANTA CRUZ DA ESPERANCA", value: "0820", uf: "SP" },
  { name: "AMERICANO DO BRASIL", value: "9661", uf: "GO" },
  { name: "MAXARANGUAPE", value: "1749", uf: "RN" },
  { name: "BIRITINGA", value: "3371", uf: "BA" },
  { name: "MIRAGUAI", value: "8747", uf: "RS" },
  { name: "BOM SUCESSO DE ITARARE", value: "3059", uf: "SP" },
  { name: "NOVA RAMADA", value: "1006", uf: "RS" },
  { name: "FILADELFIA", value: "9355", uf: "TO" },
  { name: "SAGRADA FAMILIA", value: "5987", uf: "RS" },
  { name: "VILA NOVA DO PIAUI", value: "0408", uf: "PI" },
  { name: "ITAPIRAPUA PAULISTA", value: "3055", uf: "SP" },
  { name: "TAVARES", value: "8971", uf: "RS" },
  { name: "NOVO ORIENTE", value: "1489", uf: "CE" },
  { name: "GONCALVES", value: "4547", uf: "MG" },
  { name: "BARRA DO CHAPEU", value: "2997", uf: "SP" },
  { name: "BARAO DE ANTONINA", value: "6201", uf: "SP" },
  { name: "PLANALTO", value: "8797", uf: "RS" },
  { name: "MONTEZUMA", value: "2697", uf: "MG" },
  { name: "PLATINA", value: "6895", uf: "SP" },
  { name: "VITORIA DAS MISSOES", value: "6053", uf: "RS" },
  { name: "FERNANDES TOURINHO", value: "4515", uf: "MG" },
  { name: "BONFIM DO PIAUI", value: "2251", uf: "PI" },
  { name: "BOM JESUS DO SUL", value: "0838", uf: "PR" },
  { name: "MARILAC", value: "4801", uf: "MG" },
  { name: "PRATINHA", value: "5059", uf: "MG" },
  { name: "CHIADOR", value: "4323", uf: "MG" },
  { name: "DIVINO DAS LARANJEIRAS", value: "4441", uf: "MG" },
  { name: "VARZEA BRANCA", value: "2267", uf: "PI" },
  { name: "CAPINZAL DO NORTE", value: "0142", uf: "MA" },
  { name: "DONA INES", value: "2015", uf: "PB" },
  { name: "SANTA EFIGENIA DE MINAS", value: "5149", uf: "MG" },
  { name: "CACHOEIRA GRANDE", value: "0138", uf: "MA" },
  { name: "AGUIAR", value: "1903", uf: "PB" },
  { name: "CORONEL JOSE DIAS", value: "2255", uf: "PI" },
  { name: "SAO FELIX DE BALSAS", value: "0913", uf: "MA" },
  { name: "DUERE", value: "9347", uf: "TO" },
  { name: "NOVA GUARITA", value: "0121", uf: "MT" },
  { name: "DEZESSEIS DE NOVEMBRO", value: "8429", uf: "RS" },
  { name: "DOIS IRMAOS DAS MISSOES", value: "5971", uf: "RS" },
  { name: "ANTONIO MARTINS", value: "1617", uf: "RN" },
  { name: "TACIMA", value: "2229", uf: "PB" },
  { name: "IVORA", value: "7395", uf: "RS" },
  { name: "SANTA SALETE", value: "0822", uf: "SP" },
  { name: "SAO JOAO DO ARRAIAL", value: "0388", uf: "PI" },
  { name: "JARDIM DE ANGICOS", value: "1707", uf: "RN" },
  { name: "PORTO ALEGRE DO TOCANTINS", value: "9723", uf: "TO" },
  { name: "MARAJA DO SENA", value: "0192", uf: "MA" },
  { name: "NICOLAU VERGUEIRO", value: "5937", uf: "RS" },
  { name: "NOVA BANDEIRANTES", value: "0117", uf: "MT" },
  { name: "ESMERALDA", value: "8645", uf: "RS" },
  { name: "TRACUNHAEM", value: "2609", uf: "PE" },
  { name: "COLINAS", value: "6029", uf: "RS" },
  { name: "PLACAS", value: "0060", uf: "PA" },
  { name: "SITIO DO QUINTO", value: "3043", uf: "BA" },
  { name: "ARAPEI", value: "2991", uf: "SP" },
  { name: "TEJUPA", value: "7185", uf: "SP" },
  { name: "NILO PECANHA", value: "3753", uf: "BA" },
  { name: "PADRE CARVALHO", value: "0676", uf: "MG" },
  { name: "BOA VISTA DO INCRA", value: "1126", uf: "RS" },
  { name: "ITAUEIRA", value: "1101", uf: "PI" },
  { name: "PONTA DE PEDRAS", value: "0513", uf: "PA" },
  { name: "RIACHUELO", value: "3217", uf: "SE" },
  { name: "PRACINHA", value: "0812", uf: "SP" },
  { name: "PRIMEIRA CRUZ", value: "0885", uf: "MA" },
  { name: "JANDUIS", value: "1701", uf: "RN" },
  { name: "MALHADA DE PEDRAS", value: "3707", uf: "BA" },
  { name: "CAPAO DO CIPO", value: "1134", uf: "RS" },
  { name: "PRUDENTE DE MORAIS", value: "5071", uf: "MG" },
  { name: "LOBATO", value: "7665", uf: "PR" },
  { name: "GRAMADO XAVIER", value: "5763", uf: "RS" },
  { name: "BARRA DO OURO", value: "0076", uf: "TO" },
  { name: "AGUA LIMPA", value: "9203", uf: "GO" },
  { name: "ARATACA", value: "3073", uf: "BA" },
  { name: "SAO DOMINGOS DO AZEITAO", value: "0228", uf: "MA" },
  { name: "SANTO ANTONIO DO RIO ABAIXO", value: "5209", uf: "MG" },
  { name: "SANTO INACIO DO PIAUI", value: "1189", uf: "PI" },
  { name: "AMPARO DA SERRA", value: "4049", uf: "MG" },
  { name: "CANUDOS DO VALE", value: "1130", uf: "RS" },
  { name: "ALMIRANTE TAMANDARE DO SUL", value: "1120", uf: "RS" },
  { name: "LINHA NOVA", value: "6047", uf: "RS" },
  { name: "MONTIVIDIU DO NORTE", value: "0079", uf: "GO" },
  { name: "JUMIRIM", value: "0802", uf: "SP" },
  { name: "RIO DAS ANTAS", value: "8283", uf: "SC" },
  { name: "CATURITE", value: "0470", uf: "PB" },
  { name: "SAO GONCALO DO RIO ABAIXO", value: "5237", uf: "MG" },
  { name: "VARGEM GRANDE DO RIO PARDO", value: "0748", uf: "MG" },
  { name: "VARZEDO", value: "3049", uf: "BA" },
  { name: "DARCINOPOLIS", value: "0179", uf: "TO" },
  { name: "CAPITAO ANDRADE", value: "2651", uf: "MG" },
  { name: "SENADOR ELOI DE SOUZA", value: "1859", uf: "RN" },
  { name: "PUREZA", value: "1807", uf: "RN" },
  { name: "SAO ROBERTO", value: "0246", uf: "MA" },
  { name: "ITAGUARU", value: "9407", uf: "GO" },
  { name: "ADELANDIA", value: "9769", uf: "GO" },
  { name: "JOSE BOITEUX", value: "9957", uf: "SC" },
  { name: "BURITI DO TOCANTINS", value: "9715", uf: "TO" },
  { name: "VICOSA", value: "1897", uf: "RN" },
  { name: "RIACHAO DAS NEVES", value: "3825", uf: "BA" },
  { name: "SAO GONCALO DO GURGUEIA", value: "0382", uf: "PI" },
  { name: "RAMILANDIA", value: "5527", uf: "PR" },
  { name: "RIACHINHO", value: "2901", uf: "MG" },
  { name: "VALE DO ANARI", value: "0024", uf: "RO" },
  { name: "FEIRA DA MATA", value: "3275", uf: "BA" },
  { name: "MUQUEM DE SAO FRANCISCO", value: "3005", uf: "BA" },
  { name: "FLORIANO PEIXOTO", value: "0986", uf: "RS" },
  { name: "JACARE DOS HOMENS", value: "2767", uf: "AL" },
  { name: "INHAPI", value: "2765", uf: "AL" },
  { name: "BANDEIRANTE", value: "0892", uf: "SC" },
  { name: "GLORINHA", value: "8407", uf: "RS" },
  { name: "DOUTOR SEVERIANO", value: "1663", uf: "RN" },
  { name: "SITIO NOVO DO TOCANTINS", value: "9613", uf: "TO" },
  { name: "SERRA DA RAIZ", value: "2213", uf: "PB" },
  { name: "RIACHAO DO POCO", value: "0506", uf: "PB" },
  { name: "SANTA CRUZ DE SALINAS", value: "0704", uf: "MG" },
  { name: "CAEM", value: "3401", uf: "BA" },
  { name: "LAJEADO DO BUGRE", value: "5983", uf: "RS" },
  { name: "ILHA DAS FLORES", value: "3153", uf: "SE" },
  { name: "LUZINOPOLIS", value: "0088", uf: "TO" },
  { name: "AXIXA DO TOCANTINS", value: "9257", uf: "TO" },
  { name: "VIEIROPOLIS", value: "0540", uf: "PB" },
  { name: "LAGOA DO SITIO", value: "0342", uf: "PI" },
  { name: "COIVARAS", value: "0995", uf: "PI" },
  { name: "CATURAMA", value: "3273", uf: "BA" },
  { name: "MADALENA", value: "1261", uf: "CE" },
  { name: "IRAJUBA", value: "3585", uf: "BA" },
  { name: "IGUATU", value: "5467", uf: "PR" },
  { name: "OURO VELHO", value: "2113", uf: "PB" },
  { name: "RANCHO ALEGRE D'OESTE", value: "5513", uf: "PR" },
  { name: "LAGOA DOS GATOS", value: "2473", uf: "PE" },
  { name: "MARAA", value: "0259", uf: "AM" },
  { name: "AROAZES", value: "1017", uf: "PI" },
  { name: "PAIVA", value: "4931", uf: "MG" },
  { name: "PEDRAO", value: "3783", uf: "BA" },
  { name: "ANTONIO JOAO", value: "9017", uf: "MS" },
  { name: "BREJINHO", value: "1635", uf: "RN" },
  { name: "CAJARI", value: "0747", uf: "MA" },
  { name: "CAMPOS LINDOS", value: "0173", uf: "TO" },
  { name: "PASSO DE CAMARAGIBE", value: "2829", uf: "AL" },
  { name: "CAMPESTRE DO MARANHAO", value: "0140", uf: "MA" },
  { name: "SANTANA DO MUNDAU", value: "2861", uf: "AL" },
  { name: "SAO JOAQUIM DO MONTE", value: "2565", uf: "PE" },
  { name: "SANTANA DO MARANHAO", value: "0224", uf: "MA" },
  { name: "PRESIDENTE JANIO QUADROS", value: "3815", uf: "BA" },
  { name: "SAO VICENTE FERRER", value: "0927", uf: "MA" },
  { name: "PRATA DO PIAUI", value: "1171", uf: "PI" },
  { name: "BREJETUBA", value: "0758", uf: "ES" },
  { name: "PONTE PRETA", value: "5967", uf: "RS" },
  { name: "BREJO DO PIAUI", value: "0284", uf: "PI" },
  { name: "MANARI", value: "0554", uf: "PE" },
  { name: "CANDEAL", value: "3427", uf: "BA" },
  { name: "AMARALINA", value: "1054", uf: "GO" },
  { name: "POCO DE JOSE DE MOURA", value: "0498", uf: "PB" },
  { name: "POCO DAS ANTAS", value: "7365", uf: "RS" },
  { name: "BURITINOPOLIS", value: "0061", uf: "GO" },
  { name: "TAPURAH", value: "9763", uf: "MT" },
  { name: "MORMACO", value: "5933", uf: "RS" },
  { name: "PONTO DOS VOLANTES", value: "0694", uf: "MG" },
  { name: "PRAIA NORTE", value: "9725", uf: "TO" },
  { name: "APARECIDA DO RIO DOCE", value: "0071", uf: "GO" },
  { name: "CACHOEIRINHA", value: "0171", uf: "TO" },
  { name: "BONINAL", value: "3379", uf: "BA" },
  { name: "SANTO AMARO DO MARANHAO", value: "0226", uf: "MA" },
  { name: "CAROEBE", value: "0030", uf: "RR" },
  { name: "ALTO PARAGUAI", value: "9009", uf: "MT" },
  { name: "PIRAMBU", value: "3205", uf: "SE" },
  { name: "MULUNGU DO MORRO", value: "3299", uf: "BA" },
  { name: "ANAHY", value: "5463", uf: "PR" },
  { name: "PE DE SERRA", value: "3981", uf: "BA" },
  { name: "ITAU", value: "1695", uf: "RN" },
  { name: "SERRINHA", value: "1867", uf: "RN" },
  { name: "BOQUEIRAO DO PIAUI", value: "0282", uf: "PI" },
  { name: "BREJO DO CRUZ", value: "1957", uf: "PB" },
  { name: "MALTA", value: "2077", uf: "PB" },
  { name: "ANTONIO CARDOSO", value: "3333", uf: "BA" },
  { name: "CARNAUBA DOS DANTAS", value: "1647", uf: "RN" },
  { name: "ARAGUANA", value: "0114", uf: "MA" },
  { name: "MARAVILHA", value: "2791", uf: "AL" },
  { name: "PUTINGA", value: "8807", uf: "RS" },
  { name: "FERREIRA GOMES", value: "0611", uf: "AP" },
  { name: "BREJOES", value: "3385", uf: "BA" },
  { name: "CRISTALANDIA DO PIAUI", value: "1059", uf: "PI" },
  { name: "ALTO PARAISO", value: "5523", uf: "PR" },
  { name: "SAO SEBASTIAO DO RIO PRETO", value: "5295", uf: "MG" },
  { name: "LENCOIS", value: "3687", uf: "BA" },
  { name: "SERRA DO MEL", value: "1927", uf: "RN" },
  { name: "COLNIZA", value: "1080", uf: "MT" },
  { name: "REDUTO", value: "0696", uf: "MG" },
  { name: "CONCEICAO DA FEIRA", value: "3463", uf: "BA" },
  { name: "ABEL FIGUEIREDO", value: "0375", uf: "PA" },
  { name: "MONJOLOS", value: "4849", uf: "MG" },
  { name: "SANTA RITA DO PARDO", value: "9745", uf: "MS" },
  { name: "PORTO ALEGRE DO NORTE", value: "9895", uf: "MT" },
  { name: "RESERVA DO CABACAL", value: "9879", uf: "MT" },
  { name: "LAGOA DOS TRES CANTOS", value: "5951", uf: "RS" },
  { name: "CAMPO REDONDO", value: "1641", uf: "RN" },
  { name: "AFUA", value: "0405", uf: "PA" },
  { name: "FRANCISCOPOLIS", value: "0608", uf: "MG" },
  { name: "MARANHAOZINHO", value: "0194", uf: "MA" },
  { name: "OLINDA NOVA DO MARANHAO", value: "0204", uf: "MA" },
  { name: "ENTRE RIOS DO SUL", value: "8421", uf: "RS" },
  { name: "MATUREIA", value: "0492", uf: "PB" },
  { name: "DOURADINA", value: "8465", uf: "PR" },
  { name: "CURVELANDIA", value: "1084", uf: "MT" },
  { name: "SETUBINHA", value: "0732", uf: "MG" },
  { name: "RIO DOS INDIOS", value: "5955", uf: "RS" },
  { name: "BOCAINA", value: "1035", uf: "PI" },
  { name: "JATI", value: "1445", uf: "CE" },
  { name: "TUPANATINGA", value: "2615", uf: "PE" },
  { name: "PORTALEGRE", value: "1803", uf: "RN" },
  { name: "PAU BRASIL", value: "3779", uf: "BA" },
  { name: "TRES PALMEIRAS", value: "7327", uf: "RS" },
  { name: "SAO JOSE DOS RAMOS", value: "0526", uf: "PB" },
  { name: "MONTE DO CARMO", value: "9469", uf: "TO" },
  { name: "TESOURO", value: "9161", uf: "MT" },
  { name: "VISTA ALEGRE DO PRATA", value: "7305", uf: "RS" },
  { name: "PRATA", value: "2145", uf: "PB" },
  { name: "PALESTINA", value: "2823", uf: "AL" },
  { name: "VITORIA DO JARI", value: "0070", uf: "AP" },
  { name: "VISTA GAUCHA", value: "7303", uf: "RS" },
  { name: "JOSE DE FREITAS", value: "1109", uf: "PI" },
  { name: "CELSO RAMOS", value: "9943", uf: "SC" },
  { name: "CAMPOS DE JULIO", value: "1032", uf: "MT" },
  { name: "BRACO DO TROMBUDO", value: "5557", uf: "SC" },
  { name: "LAGUNA CARAPA", value: "0163", uf: "MS" },
  { name: "FEIRA GRANDE", value: "2751", uf: "AL" },
  { name: "BARRA DO GUARITA", value: "6069", uf: "RS" },
  { name: "TEREZOPOLIS DE GOIAS", value: "0057", uf: "GO" },
  { name: "GOIANA", value: "0620", uf: "MG" },
  { name: "TANQUE D'ARCA", value: "2879", uf: "AL" },
  { name: "MIRANTE", value: "3297", uf: "BA" },
  { name: "GRACCHO CARDOSO", value: "3151", uf: "SE" },
  { name: "JACOBINA DO PIAUI", value: "2273", uf: "PI" },
  { name: "CAMPO LARGO DO PIAUI", value: "0294", uf: "PI" },
  { name: "SANTANA DO SAO FRANCISCO", value: "2647", uf: "SE" },
  { name: "MAMONAS", value: "2895", uf: "MG" },
  { name: "PEDRO TEIXEIRA", value: "4987", uf: "MG" },
  { name: "SAO PEDRO DOS CRENTES", value: "0242", uf: "MA" },
  { name: "APORE", value: "9229", uf: "GO" },
  { name: "AMATURA", value: "0291", uf: "AM" },
  { name: "CUPARAQUE", value: "0598", uf: "MG" },
  { name: "ANTONIO GONCALVES", value: "3335", uf: "BA" },
  { name: "NOVO TRIUNFO", value: "3015", uf: "BA" },
  { name: "FARTURA DO PIAUI", value: "2257", uf: "PI" },
  { name: "MONTE HOREBE", value: "2093", uf: "PB" },
  { name: "AREIA DE BARAUNAS", value: "0448", uf: "PB" },
  { name: "BELAGUA", value: "0120", uf: "MA" },
  { name: "NOVA SANTA RITA", value: "0370", uf: "PI" },
  { name: "BARRA DE GUABIRABA", value: "2325", uf: "PE" },
  { name: "SAO JOAO DE PIRABAS", value: "0393", uf: "PA" },
  { name: "XEXEU", value: "2293", uf: "PE" },
  { name: "MOITA BONITA", value: "3181", uf: "SE" },
  { name: "MATO GROSSO", value: "0490", uf: "PB" },
  { name: "RAFAEL JAMBEIRO", value: "3985", uf: "BA" },
  { name: "JAQUEIRA", value: "0548", uf: "PE" },
  { name: "ERMO", value: "0914", uf: "SC" },
  { name: "SAMPAIO", value: "9727", uf: "TO" },
  { name: "SIMAO PEREIRA", value: "5349", uf: "MG" },
  { name: "BOA VENTURA DE SAO ROQUE", value: "0836", uf: "PR" },
  { name: "ALBERTINA", value: "4027", uf: "MG" },
  { name: "LUCENA", value: "2073", uf: "PB" },
  { name: "SAO MARTINHO DA SERRA", value: "5793", uf: "RS" },
  { name: "BARRA DO RIO AZUL", value: "5959", uf: "RS" },
  { name: "SAO FELIX DE MINAS", value: "0712", uf: "MG" },
  { name: "PIEDADE DE CARATINGA", value: "0686", uf: "MG" },
  { name: "ILHA GRANDE", value: "0322", uf: "PI" },
  { name: "TUNAS", value: "7323", uf: "RS" },
  { name: "ICARAI DE MINAS", value: "2693", uf: "MG" },
  { name: "AIUABA", value: "1307", uf: "CE" },
  { name: "PASSA E FICA", value: "1781", uf: "RN" },
  { name: "PEREIRO", value: "1517", uf: "CE" },
  { name: "JURIPIRANGA", value: "2059", uf: "PB" },
  { name: "ANGUERA", value: "3329", uf: "BA" },
  { name: "RIO DO FOGO", value: "0422", uf: "RN" },
  { name: "TANQUE DO PIAUI", value: "0404", uf: "PI" },
  { name: "CHAPADA DE AREIA", value: "0078", uf: "TO" },
  { name: "ANAMA", value: "0293", uf: "AM" },
  { name: "LIZARDA", value: "9569", uf: "TO" },
  { name: "SANTA TEREZINHA", value: "5555", uf: "SC" },
  { name: "ITAIPAVA DO GRAJAU", value: "0172", uf: "MA" },
  { name: "ENTRE RIOS DO OESTE", value: "5529", uf: "PR" },
  { name: "ANITAPOLIS", value: "8021", uf: "SC" },
  { name: "QUIXABA", value: "2153", uf: "PB" },
  { name: "SENADOR RUI PALMEIRA", value: "2891", uf: "AL" },
  { name: "SAO SALVADOR DO TOCANTINS", value: "0333", uf: "TO" },
  { name: "CONQUISTA D'OESTE", value: "1082", uf: "MT" },
  { name: "MIMOSO DE GOIAS", value: "9931", uf: "GO" },
  { name: "BURITI DOS MONTES", value: "1297", uf: "PI" },
  { name: "SANTA FE DE MINAS", value: "5151", uf: "MG" },
  { name: "BANABUIU", value: "1233", uf: "CE" },
  { name: "NAQUE", value: "0656", uf: "MG" },
  { name: "PALMEIRAS DO TOCANTINS", value: "0185", uf: "TO" },
  { name: "BARRA DO JACARE", value: "7451", uf: "PR" },
  { name: "LARANJAL", value: "5501", uf: "PR" },
  { name: "PIRPIRITUBA", value: "2137", uf: "PB" },
  { name: "SAO PEDRO DAS MISSOES", value: "1172", uf: "RS" },
  { name: "VESPASIANO CORREA", value: "1028", uf: "RS" },
  { name: "NOVORIZONTE", value: "0668", uf: "MG" },
  { name: "PATIS", value: "0680", uf: "MG" },
  { name: "SERRINHA DOS PINTOS", value: "0432", uf: "RN" },
  { name: "GAMELEIRAS", value: "0614", uf: "MG" },
  { name: "GOIOXIM", value: "0858", uf: "PR" },
  { name: "SANTA TEREZA", value: "5995", uf: "RS" },
  { name: "SEVERIANO DE ALMEIDA", value: "8913", uf: "RS" },
  { name: "SANTA BARBARA DO TUGURIO", value: "5145", uf: "MG" },
  { name: "JAGUARIPE", value: "3657", uf: "BA" },
  { name: "RIO DA CONCEICAO", value: "0323", uf: "TO" },
  { name: "RAFAEL FERNANDES", value: "1809", uf: "RN" },
  { name: "OURO VERDE DO OESTE", value: "9965", uf: "PR" },
  { name: "QUIXABEIRA", value: "3025", uf: "BA" },
  { name: "SAO MIGUEL DA BOA VISTA", value: "5755", uf: "SC" },
  { name: "IRATI", value: "5585", uf: "SC" },
  { name: "SENADOR LA ROCQUE", value: "0252", uf: "MA" },
  { name: "CURRAL VELHO", value: "2007", uf: "PB" },
  { name: "PRESIDENTE VARGAS", value: "0883", uf: "MA" },
  { name: "CACIQUE DOBLE", value: "8563", uf: "RS" },
  { name: "NOSSA SENHORA DE NAZARE", value: "0356", uf: "PI" },
  { name: "VARZEA GRANDE", value: "1227", uf: "PI" },
  { name: "BARRO PRETO", value: "3365", uf: "BA" },
  { name: "SAO JOSE DO JACUIPE", value: "3033", uf: "BA" },
  { name: "JOSE GONCALVES DE MINAS", value: "0634", uf: "MG" },
  { name: "BURITI", value: "0741", uf: "MA" },
  { name: "BOM JESUS", value: "1633", uf: "RN" },
  { name: "NOVA SANTA HELENA", value: "1088", uf: "MT" },
  { name: "TUPIRATINS", value: "0365", uf: "TO" },
  { name: "SAO LUIS GONZAGA DO MARANHAO", value: "0805", uf: "MA" },
  { name: "OURO VERDE DE MINAS", value: "4923", uf: "MG" },
  { name: "QUATRO PONTES", value: "5535", uf: "PR" },
  { name: "MATOES", value: "0831", uf: "MA" },
  { name: "PIMENTEIRAS DO OESTE", value: "0014", uf: "RO" },
  { name: "DOMINGOS MOURAO", value: "1141", uf: "PI" },
  { name: "FLORIDA", value: "7559", uf: "PR" },
  { name: "BARRA BONITA", value: "0894", uf: "SC" },
  { name: "MILAGRES DO MARANHAO", value: "0198", uf: "MA" },
  { name: "MUANA", value: "0497", uf: "PA" },
  { name: "UMBUZEIRO", value: "2241", uf: "PB" },
  { name: "ITAQUARA", value: "3635", uf: "BA" },
  { name: "GUAPOREMA", value: "7577", uf: "PR" },
  { name: "AMAPA", value: "0601", uf: "AP" },
  { name: "IBIRACATU", value: "0624", uf: "MG" },
  { name: "NOVO MUNDO", value: "1044", uf: "MT" },
  { name: "PALMEIRANTE", value: "0189", uf: "TO" },
  { name: "RIO FORTUNA", value: "8293", uf: "SC" },
  { name: "CARANAIBA", value: "4261", uf: "MG" },
  { name: "CHARRUA", value: "5965", uf: "RS" },
  { name: "ACRELANDIA", value: "0643", uf: "AC" },
  { name: "SAO JOSE DA VITORIA", value: "3035", uf: "BA" },
  { name: "PAIM FILHO", value: "8775", uf: "RS" },
  { name: "SANTA CRUZ DO XINGU", value: "1094", uf: "MT" },
  { name: "MILHA", value: "1597", uf: "CE" },
  { name: "NOSSA SENHORA DOS REMEDIOS", value: "1135", uf: "PI" },
  { name: "JIQUIRICA", value: "3665", uf: "BA" },
  { name: "AGUIA BRANCA", value: "5733", uf: "ES" },
  { name: "ISRAELANDIA", value: "9401", uf: "GO" },
  { name: "JATOBA DO PIAUI", value: "0324", uf: "PI" },
  { name: "DOM INOCENCIO", value: "1289", uf: "PI" },
  { name: "ALFREDO VASCONCELOS", value: "2681", uf: "MG" },
  { name: "QUELUZITO", value: "5075", uf: "MG" },
  { name: "SEBASTIAO LEAL", value: "0398", uf: "PI" },
  { name: "CUITEGI", value: "2005", uf: "PB" },
  { name: "CUMARU", value: "2397", uf: "PE" },
  { name: "COLINAS DO SUL", value: "9791", uf: "GO" },
  { name: "JAPURA", value: "0245", uf: "AM" },
  { name: "SANTO ANTONIO DA BARRA", value: "0083", uf: "GO" },
  { name: "ITAPE", value: "3625", uf: "BA" },
  { name: "RIBEIRA DO PIAUI", value: "0376", uf: "PI" },
  { name: "SANTA CRUZ DA BAIXA VERDE", value: "2639", uf: "PE" },
  { name: "PAINEL", value: "0930", uf: "SC" },
  { name: "PAQUETA", value: "0364", uf: "PI" },
  { name: "BUGRE", value: "0576", uf: "MG" },
  { name: "CARLINDA", value: "1034", uf: "MT" },
  { name: "PACOTI", value: "1497", uf: "CE" },
  { name: "PORTO ESTRELA", value: "0101", uf: "MT" },
  { name: "SAO MIGUEL DOS MILAGRES", value: "2873", uf: "AL" },
  { name: "GALILEIA", value: "4545", uf: "MG" },
  { name: "ESTACAO", value: "7301", uf: "RS" },
  { name: "GEMINIANO", value: "0318", uf: "PI" },
  { name: "RUY BARBOSA", value: "1819", uf: "RN" },
  { name: "ICATU", value: "0799", uf: "MA" },
  { name: "SAO PEDRO DA CIPA", value: "0093", uf: "MT" },
  { name: "RECURSOLANDIA", value: "0357", uf: "TO" },
  { name: "CURRALINHO", value: "0455", uf: "PA" },
  { name: "BONITO", value: "0431", uf: "PA" },
  { name: "CARRANCAS", value: "4291", uf: "MG" },
  { name: "IRETAMA", value: "7609", uf: "PR" },
  { name: "CASA GRANDE", value: "4297", uf: "MG" },
  { name: "CARIRE", value: "1361", uf: "CE" },
  { name: "IMBUIA", value: "8145", uf: "SC" },
  { name: "OURO VERDE", value: "5741", uf: "SC" },
  { name: "SAO JOAO DA PONTA", value: "0064", uf: "PA" },
  { name: "ITAETE", value: "3601", uf: "BA" },
  { name: "BOA VISTA DO GURUPI", value: "0126", uf: "MA" },
  { name: "GAVIAO", value: "3093", uf: "BA" },
  { name: "HERVEIRAS", value: "0988", uf: "RS" },
  { name: "NOVO SANTO ANTONIO", value: "1090", uf: "MT" },
  { name: "PINHAO", value: "3203", uf: "SE" },
  { name: "CARAIBAS", value: "3271", uf: "BA" },
  { name: "PINHAL DA SERRA", value: "1158", uf: "RS" },
  { name: "SERRANO DO MARANHAO", value: "0254", uf: "MA" },
  { name: "DIOGO DE VASCONCELOS", value: "4433", uf: "MG" },
  { name: "JOAO COSTA", value: "0326", uf: "PI" },
  { name: "BOM PRINCIPIO DO PIAUI", value: "2287", uf: "PI" },
  { name: "MARQUINHO", value: "0866", uf: "PR" },
  { name: "SAO JOSE DOS AUSENTES", value: "6015", uf: "RS" },
  { name: "PIMENTEIRAS", value: "1161", uf: "PI" },
  { name: "JAPOATA", value: "3167", uf: "SE" },
  { name: "ESPIRITO SANTO", value: "1669", uf: "RN" },
  { name: "SANTA CRUZ DO ESCALVADO", value: "5147", uf: "MG" },
  { name: "CORONEL PACHECO", value: "4391", uf: "MG" },
  { name: "GRANITO", value: "2425", uf: "PE" },
  { name: "SALINAS DA MARGARIDA", value: "3847", uf: "BA" },
  { name: "LAMIM", value: "4757", uf: "MG" },
  { name: "SAO BRAS DO SUACUI", value: "5217", uf: "MG" },
  { name: "MORRO CABECA NO TEMPO", value: "0350", uf: "PI" },
  { name: "CENTENARIO", value: "0343", uf: "TO" },
  { name: "CAPAO BONITO DO SUL", value: "1132", uf: "RS" },
  { name: "AGUIARNOPOLIS", value: "0072", uf: "TO" },
  { name: "PEROLANDIA", value: "0075", uf: "GO" },
  { name: "AVELINOPOLIS", value: "9255", uf: "GO" },
  { name: "BABACULANDIA", value: "9259", uf: "TO" },
  { name: "SAO VALERIO DA NATIVIDADE", value: "9691", uf: "TO" },
  { name: "DORMENTES", value: "2299", uf: "PE" },
  { name: "JANDAIRA", value: "1699", uf: "RN" },
  { name: "COXILHA", value: "5797", uf: "RS" },
  { name: "TOROPI", value: "1018", uf: "RS" },
  { name: "PARECIS", value: "0012", uf: "RO" },
  { name: "JUNCO DO MARANHAO", value: "0180", uf: "MA" },
  { name: "SANTA ROSA DE LIMA", value: "3229", uf: "SE" },
  { name: "SANTA HELENA", value: "2167", uf: "PB" },
  { name: "SAO JOAO DA FRONTEIRA", value: "0384", uf: "PI" },
  { name: "IBITIRAMA", value: "6011", uf: "ES" },
  { name: "TRAVESSEIRO", value: "6037", uf: "RS" },
  { name: "LAFAIETE COUTINHO", value: "3675", uf: "BA" },
  { name: "SANTANA DO SERIDO", value: "1825", uf: "RN" },
  { name: "LAGOA DE VELHOS", value: "1727", uf: "RN" },
  { name: "QUATRO IRMAOS", value: "1162", uf: "RS" },
  { name: "RIACHAO DO BACAMARTE", value: "0504", uf: "PB" },
  { name: "VALE DO PARAISO", value: "0979", uf: "RO" },
  { name: "ARACU", value: "9231", uf: "GO" },
  { name: "PARAISO DAS AGUAS", value: "1196", uf: "MS" },
  { name: "RIACHINHO", value: "0193", uf: "TO" },
  { name: "MANOEL URBANO", value: "0155", uf: "AC" },
  { name: "GRUPIARA", value: "4557", uf: "MG" },
  { name: "ENTRE RIOS", value: "0912", uf: "SC" },
  { name: "ZORTEA", value: "0950", uf: "SC" },
  { name: "CUBATI", value: "2001", uf: "PB" },
  { name: "FELIPE GUERRA", value: "1673", uf: "RN" },
  { name: "BENJAMIN CONSTANT DO SUL", value: "0958", uf: "RS" },
  { name: "VILA NOVA DOS MARTIRIOS", value: "0264", uf: "MA" },
  { name: "LAJES PINTADAS", value: "1735", uf: "RN" },
  { name: "GONGOGI", value: "3529", uf: "BA" },
  { name: "SAO SEBASTIAO DO UMBUZEIRO", value: "2205", uf: "PB" },
  { name: "SANTA HELENA", value: "5751", uf: "SC" },
  { name: "BELEM", value: "2715", uf: "AL" },
  { name: "ITAPOROROCA", value: "2043", uf: "PB" },
  { name: "FAGUNDES", value: "2023", uf: "PB" },
  { name: "LEOPOLDO DE BULHOES", value: "9443", uf: "GO" },
  { name: "MALHADA DOS BOIS", value: "3175", uf: "SE" },
  { name: "JOAQUIM PIRES", value: "1107", uf: "PI" },
  { name: "VERTENTE DO LERIO", value: "2291", uf: "PE" },
  { name: "CAPELA NOVA", value: "4243", uf: "MG" },
  { name: "NOVO ORIENTE DO PIAUI", value: "1137", uf: "PI" },
  { name: "GOVERNADOR JORGE TEIXEIRA", value: "0693", uf: "RO" },
  { name: "GENTIL", value: "5799", uf: "RS" },
  { name: "TERRA ALTA", value: "0373", uf: "PA" },
  { name: "PORTELANDIA", value: "9557", uf: "GO" },
  { name: "PINHEIRINHO DO VALE", value: "5975", uf: "RS" },
  { name: "PAULISTAS", value: "4967", uf: "MG" },
  { name: "PONTE ALTA DO BOM JESUS", value: "9551", uf: "TO" },
  { name: "SENHORA DE OLIVEIRA", value: "5319", uf: "MG" },
  { name: "SENADOR GEORGINO AVELINO", value: "1861", uf: "RN" },
  { name: "URUPEMA", value: "9975", uf: "SC" },
  { name: "SAO JOSE DOS CORDEIROS", value: "2197", uf: "PB" },
  { name: "IMACULADA", value: "2035", uf: "PB" },
  { name: "TRAIRAO", value: "0635", uf: "PA" },
  { name: "MIRAVANIA", value: "0652", uf: "MG" },
  { name: "SOLIDAO", value: "2587", uf: "PE" },
  { name: "CACHOEIRA DE GOIAS", value: "9283", uf: "GO" },
  { name: "ARAGUAIANA", value: "9869", uf: "MT" },
  { name: "ANTONINA DO NORTE", value: "1315", uf: "CE" },
  { name: "SANTO ANTONIO DO PALMA", value: "5941", uf: "RS" },
  { name: "PALMACIA", value: "1503", uf: "CE" },
  { name: "SANTA CRUZ", value: "2297", uf: "PE" },
  { name: "SAO BENTINHO", value: "0518", uf: "PB" },
  { name: "SAO CRISTOVAO DO SUL", value: "5573", uf: "SC" },
  { name: "CONCEICAO DO LAGO-ACU", value: "0152", uf: "MA" },
  { name: "CORONEL XAVIER CHAVES", value: "4393", uf: "MG" },
  { name: "SANTO ANTONIO DO PLANALTO", value: "5957", uf: "RS" },
  { name: "RAFAEL GODEIRO", value: "1893", uf: "RN" },
  { name: "CONDADO", value: "1991", uf: "PB" },
  { name: "INGAI", value: "4615", uf: "MG" },
  { name: "JESUPOLIS", value: "0049", uf: "GO" },
  { name: "AGRONOMICA", value: "8005", uf: "SC" },
  { name: "SALTO VELOSO", value: "8303", uf: "SC" },
  { name: "ALCANTARAS", value: "1309", uf: "CE" },
  { name: "ORATORIOS", value: "0672", uf: "MG" },
  { name: "ANORI", value: "0203", uf: "AM" },
  { name: "BOA ESPERANCA DO IGUACU", value: "5471", uf: "PR" },
  { name: "POCO BRANCO", value: "1801", uf: "RN" },
  { name: "PORTO VERA CRUZ", value: "6067", uf: "RS" },
  { name: "PATROCINIO DO MURIAE", value: "4963", uf: "MG" },
  { name: "SANTA MARIA DO SALTO", value: "5161", uf: "MG" },
  { name: "PAJEU DO PIAUI", value: "0362", uf: "PI" },
  { name: "BALIZA", value: "9261", uf: "GO" },
  { name: "SAO JOAO DO CARU", value: "0232", uf: "MA" },
  { name: "IBITURUNA", value: "4599", uf: "MG" },
  { name: "SANTA TEREZINHA DO TOCANTINS", value: "0098", uf: "TO" },
  { name: "RIO DOCE", value: "5099", uf: "MG" },
  { name: "ASSUNCAO DO PIAUI", value: "0270", uf: "PI" },
  { name: "VILA BOA", value: "0067", uf: "GO" },
  { name: "CHA PRETA", value: "2737", uf: "AL" },
  { name: "MATA ROMA", value: "0827", uf: "MA" },
  { name: "SARDOA", value: "5309", uf: "MG" },
  { name: "BARREIRAS DO PIAUI", value: "1025", uf: "PI" },
  { name: "SANTA BARBARA DO LESTE", value: "2667", uf: "MG" },
  { name: "OLHO D'AGUA DO PIAUI", value: "0360", uf: "PI" },
  { name: "SANTA LUZIA DO NORTE", value: "2857", uf: "AL" },
  { name: "MILAGRES", value: "3727", uf: "BA" },
  { name: "CORACAO DE MARIA", value: "3477", uf: "BA" },
  { name: "GENERAL SAMPAIO", value: "1393", uf: "CE" },
  { name: "BAIA FORMOSA", value: "1627", uf: "RN" },
  { name: "AGUA SANTA", value: "8499", uf: "RS" },
  { name: "NOVA ROMA", value: "9495", uf: "GO" },
  { name: "ARNEIROZ", value: "1329", uf: "CE" },
  { name: "OLIVEIRA FORTES", value: "4913", uf: "MG" },
  { name: "PEDRAS ALTAS", value: "1156", uf: "RS" },
  { name: "CORREGO DANTA", value: "4395", uf: "MG" },
  { name: "SANTA RITA DE MINAS", value: "2669", uf: "MG" },
  { name: "PALMEIRA", value: "0932", uf: "SC" },
  { name: "MARECHAL THAUMATURGO", value: "0655", uf: "AC" },
  { name: "LAGO VERDE", value: "0817", uf: "MA" },
  { name: "ROSARIO DO IVAI", value: "8473", uf: "PR" },
  { name: "THEOBROMA", value: "0975", uf: "RO" },
  { name: "SAO VALENTIM", value: "8897", uf: "RS" },
  { name: "GOIABEIRA", value: "0618", uf: "MG" },
  { name: "ARROIO DO PADRE", value: "1122", uf: "RS" },
  { name: "CARAUBAS DO PIAUI", value: "0298", uf: "PI" },
  { name: "ITAGUARI", value: "9919", uf: "GO" },
  { name: "PEDRA DOURADA", value: "4979", uf: "MG" },
  { name: "LIVRAMENTO", value: "2071", uf: "PB" },
  { name: "MATO CASTELHANO", value: "5931", uf: "RS" },
  { name: "CASSERENGUE", value: "0468", uf: "PB" },
  { name: "CARDEAL DA SILVA", value: "3439", uf: "BA" },
  { name: "FLOR DO SERTAO", value: "0916", uf: "SC" },
  { name: "TIMBAUBA DOS BATISTAS", value: "1883", uf: "RN" },
  { name: "SAO JOSE DE UBA", value: "0784", uf: "RJ" },
  { name: "ITABI", value: "3161", uf: "SE" },
  { name: "LAGOA DO PIAUI", value: "0340", uf: "PI" },
  { name: "IVATUBA", value: "7625", uf: "PR" },
  { name: "MATO RICO", value: "5503", uf: "PR" },
  { name: "GOVERNADOR LUIZ ROCHA", value: "0164", uf: "MA" },
  { name: "SANTA MARGARIDA DO SUL", value: "1168", uf: "RS" },
  { name: "MONSENHOR GIL", value: "1127", uf: "PI" },
  { name: "DESTERRO DO MELO", value: "4429", uf: "MG" },
  { name: "PESCARIA BRAVA", value: "1194", uf: "SC" },
  { name: "ACORIZAL", value: "9001", uf: "MT" },
  { name: "AURORA DO PARA", value: "0389", uf: "PA" },
  { name: "SANTA RITA DO TOCANTINS", value: "0096", uf: "TO" },
  { name: "JABOTICABA", value: "7393", uf: "RS" },
  { name: "CAMPO GRANDE", value: "1625", uf: "RN" },
  { name: "LAGOA ALEGRE", value: "0999", uf: "PI" },
  { name: "ABREULANDIA", value: "0337", uf: "TO" },
  { name: "TONANTINS", value: "9851", uf: "AM" },
  { name: "ATALAIA DO NORTE", value: "0205", uf: "AM" },
  { name: "SATUBINHA", value: "0248", uf: "MA" },
  { name: "BOM JESUS DO TOCANTINS", value: "0341", uf: "TO" },
  { name: "LAGOINHA DO PIAUI", value: "0336", uf: "PI" },
  { name: "PLANALTO DA SERRA", value: "0091", uf: "MT" },
  { name: "DIVINOPOLIS DE GOIAS", value: "9309", uf: "GO" },
  { name: "INHACORA", value: "6051", uf: "RS" },
  { name: "GUARINOS", value: "9993", uf: "GO" },
  { name: "NOVA PALMEIRA", value: "2107", uf: "PB" },
  { name: "RUBELITA", value: "5129", uf: "MG" },
  { name: "SAIRE", value: "2539", uf: "PE" },
  { name: "ITAMARATI", value: "9837", uf: "AM" },
  { name: "JUAZEIRO DO PIAUI", value: "0330", uf: "PI" },
  { name: "SANTANA DOS MONTES", value: "5181", uf: "MG" },
  { name: "LEME DO PRADO", value: "0642", uf: "MG" },
  { name: "PIAU", value: "5001", uf: "MG" },
  { name: "AGUA COMPRIDA", value: "4013", uf: "MG" },
  { name: "OLIVEIRA DE FATIMA", value: "0092", uf: "TO" },
  { name: "CABECEIRA GRANDE", value: "0578", uf: "MG" },
  { name: "EUGENIO DE CASTRO", value: "8413", uf: "RS" },
  { name: "SAO SEBASTIAO DO ANTA", value: "0728", uf: "MG" },
  { name: "CARAUBAS", value: "0466", uf: "PB" },
  { name: "SAO JOAO DA VARJOTA", value: "0386", uf: "PI" },
  { name: "BOM PROGRESSO", value: "6071", uf: "RS" },
  { name: "SAO JOSE DO PIAUI", value: "1203", uf: "PI" },
  { name: "BOM JESUS DA SERRA", value: "3263", uf: "BA" },
  { name: "CHIAPETTA", value: "8605", uf: "RS" },
  { name: "MONTADAS", value: "2091", uf: "PB" },
  { name: "CACHOEIRA DA PRATA", value: "4191", uf: "MG" },
  { name: "CALDEIRAO GRANDE DO PIAUI", value: "2271", uf: "PI" },
  { name: "MORRO GRANDE", value: "5539", uf: "SC" },
  { name: "CAPELA DO ALTO ALEGRE", value: "3081", uf: "BA" },
  { name: "SEBASTIAO LARANJEIRAS", value: "3899", uf: "BA" },
  { name: "BREJO DE AREIA", value: "0132", uf: "MA" },
  { name: "PINTOPOLIS", value: "0690", uf: "MG" },
  { name: "ITAJU DO COLONIA", value: "3609", uf: "BA" },
  { name: "JERICO", value: "2049", uf: "PB" },
  { name: "MATERLANDIA", value: "4811", uf: "MG" },
  { name: "VIRMOND", value: "5483", uf: "PR" },
  { name: "PEDRA BRANCA DO AMAPARI", value: "0663", uf: "AP" },
  { name: "CAMPO AZUL", value: "0580", uf: "MG" },
  { name: "IPUEIRA", value: "1693", uf: "RN" },
  { name: "HUGO NAPOLEAO", value: "1091", uf: "PI" },
  { name: "TALISMA", value: "0100", uf: "TO" },
  { name: "CIDELANDIA", value: "0150", uf: "MA" },
  { name: "WESTFALIA", value: "1176", uf: "RS" },
  { name: "CARVALHOPOLIS", value: "4293", uf: "MG" },
  { name: "PINTO BANDEIRA", value: "1160", uf: "RS" },
  { name: "NAZARIA", value: "1180", uf: "PI" },
  { name: "CENTENARIO", value: "5963", uf: "RS" },
  { name: "SERTAOZINHO", value: "0532", uf: "PB" },
  { name: "SAO SEBASTIAO DO ALTO", value: "5905", uf: "RJ" },
  { name: "ITAINOPOLIS", value: "1099", uf: "PI" },
  { name: "FORTUNA DE MINAS", value: "4527", uf: "MG" },
  { name: "IPIRANGA DO SUL", value: "7399", uf: "RS" },
  { name: "GOVERNADOR LINDENBERG", value: "1114", uf: "ES" },
  { name: "ALTO FELIZ", value: "6045", uf: "RS" },
  { name: "DARIO MEIRA", value: "3499", uf: "BA" },
  { name: "PARARI", value: "0494", uf: "PB" },
  { name: "NOVA CANDELARIA", value: "1004", uf: "RS" },
  { name: "WENCESLAU BRAZ", value: "5421", uf: "MG" },
  { name: "PRESIDENTE LUCENA", value: "6023", uf: "RS" },
  { name: "SAO PATRICIO", value: "1064", uf: "GO" },
  { name: "JARAMATAIA", value: "2773", uf: "AL" },
  { name: "FORTALEZA DO TABOCAO", value: "0345", uf: "TO" },
  { name: "CUMARU DO NORTE", value: "0385", uf: "PA" },
  { name: "DILERMANDO DE AGUIAR", value: "0974", uf: "RS" },
  { name: "DOM EXPEDITO LOPES", value: "1067", uf: "PI" },
  { name: "IRAMAIA", value: "3587", uf: "BA" },
  { name: "ACAIACA", value: "4007", uf: "MG" },
  { name: "CRISTAL DO SUL", value: "0972", uf: "RS" },
  { name: "GALINHOS", value: "1679", uf: "RN" },
  { name: "TAIPAS DO TOCANTINS", value: "0325", uf: "TO" },
  { name: "PRESIDENTE CASTELLO BRANCO", value: "8273", uf: "SC" },
  { name: "CORONEL BARROS", value: "6055", uf: "RS" },
  { name: "SAO JOSE DOS BASILIOS", value: "0238", uf: "MA" },
  { name: "DERRUBADAS", value: "6073", uf: "RS" },
  { name: "ALTO ALEGRE", value: "8495", uf: "RS" },
  { name: "NOVO JARDIM", value: "0321", uf: "TO" },
  { name: "SANTA ROSA DO PIAUI", value: "2261", uf: "PI" },
  { name: "ROTEIRO", value: "2855", uf: "AL" },
  { name: "CURRAIS", value: "0308", uf: "PI" },
  { name: "PRESIDENTE JUSCELINO", value: "0881", uf: "MA" },
  { name: "CURUA", value: "0050", uf: "PA" },
  { name: "PLANALTINA DO PARANA", value: "7773", uf: "PR" },
  { name: "ASSUNCAO", value: "0450", uf: "PB" },
  { name: "CARMOLANDIA", value: "0175", uf: "TO" },
  { name: "SENADOR ALEXANDRE COSTA", value: "0250", uf: "MA" },
  { name: "TAMBORIL DO PIAUI", value: "0402", uf: "PI" },
  { name: "RIO RUFINO", value: "5571", uf: "SC" },
  { name: "ITATIBA DO SUL", value: "8715", uf: "RS" },
  { name: "SAO GERALDO DO BAIXIO", value: "0714", uf: "MG" },
  { name: "VENHA-VER", value: "0438", uf: "RN" },
  { name: "SERRA DE SAO BENTO", value: "1863", uf: "RN" },
  { name: "CASCALHO RICO", value: "4299", uf: "MG" },
  { name: "MATINHAS", value: "0488", uf: "PB" },
  { name: "ALOANDIA", value: "9209", uf: "GO" },
  { name: "MIRAIMA", value: "1263", uf: "CE" },
  { name: "SANTA CRUZ DOS MILAGRES", value: "1295", uf: "PI" },
  { name: "FLORESTAL", value: "4519", uf: "MG" },
  { name: "NOVA BELEM", value: "0662", uf: "MG" },
  { name: "CANHOBA", value: "3121", uf: "SE" },
  { name: "ITAMBE DO MATO DENTRO", value: "4655", uf: "MG" },
  { name: "SAO BENTO DO TRAIRI", value: "1833", uf: "RN" },
  { name: "AFONSO CUNHA", value: "0701", uf: "MA" },
  { name: "FREI LAGONEGRO", value: "0610", uf: "MG" },
  { name: "CAJURI", value: "4203", uf: "MG" },
  { name: "CACIMBA DE AREIA", value: "1969", uf: "PB" },
  { name: "SITIO NOVO", value: "1871", uf: "RN" },
  { name: "SENADOR JOSE BENTO", value: "5315", uf: "MG" },
  { name: "LAMARAO", value: "3683", uf: "BA" },
  { name: "FERNANDO PEDROZA", value: "0416", uf: "RN" },
  { name: "MONTE ALEGRE DOS CAMPOS", value: "1000", uf: "RS" },
  { name: "SANTA CECILIA DO SUL", value: "1166", uf: "RS" },
  { name: "OLHO D'AGUA", value: "2109", uf: "PB" },
  { name: "SOBRADO", value: "0534", uf: "PB" },
  { name: "VARGEM BONITA", value: "5411", uf: "MG" },
  { name: "FRANCISCO DANTAS", value: "1677", uf: "RN" },
  { name: "NOVO TIRADENTES", value: "5973", uf: "RS" },
  { name: "NOVO HORIZONTE", value: "5591", uf: "SC" },
  { name: "SEBASTIAO BARROS", value: "0396", uf: "PI" },
  { name: "CUNHATAI", value: "0910", uf: "SC" },
  { name: "CERRO GRANDE", value: "8437", uf: "RS" },
  { name: "TRIUNFO POTIGUAR", value: "0436", uf: "RN" },
  { name: "ANGELIM", value: "2319", uf: "PE" },
  { name: "PINHAL", value: "7369", uf: "RS" },
  { name: "CASTANHEIRA", value: "9783", uf: "MT" },
  { name: "CARVALHOS", value: "4295", uf: "MG" },
  { name: "JAMPRUCA", value: "2655", uf: "MG" },
  { name: "NOVA IGUACU DE GOIAS", value: "0087", uf: "GO" },
  { name: "PANAMA", value: "9517", uf: "GO" },
  { name: "PRIMAVERA", value: "0521", uf: "PA" },
  { name: "BURITI DE GOIAS", value: "0063", uf: "GO" },
  { name: "CANABRAVA DO NORTE", value: "0129", uf: "MT" },
  { name: "CAICARA", value: "1973", uf: "PB" },
  { name: "NOVA OLINDA", value: "2105", uf: "PB" },
  { name: "PILOEZINHOS", value: "2135", uf: "PB" },
  { name: "APARECIDA DO RIO NEGRO", value: "9713", uf: "TO" },
  { name: "PORTO ALEGRE DO PIAUI", value: "0372", uf: "PI" },
  { name: "PASSAGEM", value: "1783", uf: "RN" },
  { name: "SANTA FILOMENA DO MARANHAO", value: "0222", uf: "MA" },
  { name: "ARAGOMINAS", value: "0167", uf: "TO" },
  { name: "MATOES DO NORTE", value: "0196", uf: "MA" },
  { name: "SAO JOSE DO HERVAL", value: "7345", uf: "RS" },
  { name: "URUANA DE MINAS", value: "0744", uf: "MG" },
  { name: "ALMINO AFONSO", value: "1611", uf: "RN" },
  { name: "ANGULO", value: "5509", uf: "PR" },
  { name: "BONITO DE MINAS", value: "0572", uf: "MG" },
  { name: "LAGOA DO BARRO DO PIAUI", value: "2259", uf: "PI" },
  { name: "PAU D'ARCO", value: "0191", uf: "TO" },
  { name: "RIBEIRAOZINHO", value: "0099", uf: "MT" },
  { name: "TELHA", value: "3245", uf: "SE" },
  { name: "NOVA MARILANDIA", value: "0103", uf: "MT" },
  { name: "MACAMBIRA", value: "3173", uf: "SE" },
  { name: "QUIXELO", value: "9853", uf: "CE" },
  { name: "SAO LUIS DO PIAUI", value: "0390", uf: "PI" },
  { name: "AURORA DO TOCANTINS", value: "9253", uf: "TO" },
  { name: "AMAJARI", value: "0026", uf: "RR" },
  { name: "CATAS ALTAS DA NORUEGA", value: "4307", uf: "MG" },
  { name: "CUMBE", value: "3137", uf: "SE" },
  { name: "BOZANO", value: "1128", uf: "RS" },
  { name: "BERNARDINO BATISTA", value: "0456", uf: "PB" },
  { name: "PEDRA MOLE", value: "3199", uf: "SE" },
  { name: "CORONEL MARTINS", value: "5735", uf: "SC" },
  { name: "TIGRINHOS", value: "0946", uf: "SC" },
  { name: "COCAL DOS ALVES", value: "0306", uf: "PI" },
  { name: "SAO JOSE DO PEIXE", value: "1201", uf: "PI" },
  { name: "ISAIAS COELHO", value: "1097", uf: "PI" },
  { name: "FRUTA DE LEITE", value: "0612", uf: "MG" },
  { name: "COXIXOLA", value: "0472", uf: "PB" },
  { name: "SAO SEBASTIAO DO RIO VERDE", value: "5297", uf: "MG" },
  { name: "PONTE ALTA DO NORTE", value: "5569", uf: "SC" },
  { name: "SANTANA DO DESERTO", value: "5171", uf: "MG" },
  { name: "CARRASCO BONITO", value: "0177", uf: "TO" },
  { name: "SAO JOAO DO JAGUARIBE", value: "1551", uf: "CE" },
  { name: "NOVA SANTA BARBARA", value: "5457", uf: "PR" },
  { name: "NOVA ALIANCA DO IVAI", value: "7715", uf: "PR" },
  { name: "RESERVA DO IGUACU", value: "0878", uf: "PR" },
  { name: "OLHO D'AGUA GRANDE", value: "2817", uf: "AL" },
  { name: "PEDRO LAURENTINO", value: "0368", uf: "PI" },
  { name: "GRANJEIRO", value: "1397", uf: "CE" },
  { name: "VILA FLOR", value: "1899", uf: "RN" },
  { name: "OLHO D'AGUA DO BORGES", value: "1767", uf: "RN" },
  { name: "GRACA ARANHA", value: "0791", uf: "MA" },
  { name: "SANTO ANTONIO DO AVENTUREIRO", value: "5199", uf: "MG" },
  { name: "ONCA DE PITANGUI", value: "4915", uf: "MG" },
  { name: "TAPARUBA", value: "0738", uf: "MG" },
  { name: "BAGRE", value: "0421", uf: "PA" },
  { name: "MATA", value: "8743", uf: "RS" },
  { name: "CONSOLACAO", value: "4369", uf: "MG" },
  { name: "CONCEICAO DAS PEDRAS", value: "4343", uf: "MG" },
  { name: "LAJEADO GRANDE", value: "5739", uf: "SC" },
  { name: "SANTA TEREZINHA DO PROGRESSO", value: "0938", uf: "SC" },
  { name: "CANAVIEIRA", value: "2247", uf: "PI" },
  { name: "RIO GRANDE DO PIAUI", value: "1179", uf: "PI" },
  { name: "FRANCISCO AYRES", value: "1081", uf: "PI" },
  { name: "JEQUIA DA PRAIA", value: "0562", uf: "AL" },
  { name: "BRASILANDIA DO TOCANTINS", value: "0339", uf: "TO" },
  { name: "NAZARE", value: "9483", uf: "TO" },
  { name: "CRIXAS DO TOCANTINS", value: "0082", uf: "TO" },
  { name: "NOVO SANTO ANTONIO", value: "0358", uf: "PI" },
  { name: "MARCOS PARENTE", value: "1119", uf: "PI" },
  { name: "ARAGUANA", value: "0169", uf: "TO" },
  { name: "LUISLANDIA", value: "0646", uf: "MG" },
  { name: "PAVUSSU", value: "0366", uf: "PI" },
  { name: "CASTELANDIA", value: "0081", uf: "GO" },
  { name: "PITANGUEIRAS", value: "5461", uf: "PR" },
  { name: "AGRICOLANDIA", value: "1001", uf: "PI" },
  { name: "MONTE SANTO DO TOCANTINS", value: "0090", uf: "TO" },
  { name: "CEDRO DO ABAETE", value: "4311", uf: "MG" },
  { name: "CORONEL PILAR", value: "1138", uf: "RS" },
  { name: "ABDON BATISTA", value: "9939", uf: "SC" },
  { name: "LAGOA DO MATO", value: "0182", uf: "MA" },
  { name: "CRUZMALTINA", value: "0848", uf: "PR" },
  { name: "PINDOBA", value: "2839", uf: "AL" },
  { name: "QUEVEDOS", value: "5789", uf: "RS" },
  { name: "PAU D'ARCO", value: "0387", uf: "PA" },
  { name: "SAO SEBASTIAO DE LAGOA DE ROCA", value: "2203", uf: "PB" },
  { name: "CAMACHO", value: "4207", uf: "MG" },
  { name: "TUPIRAMA", value: "0102", uf: "TO" },
  { name: "TARRAFAS", value: "1275", uf: "CE" },
  { name: "MORRO DA GARCA", value: "4871", uf: "MG" },
  { name: "JOSE RAYDAN", value: "0636", uf: "MG" },
  { name: "ACAUA", value: "0266", uf: "PI" },
  { name: "OROCO", value: "2495", uf: "PE" },
  { name: "CURRAL NOVO DO PIAUI", value: "0312", uf: "PI" },
  { name: "SAO JOSE DO DIVINO", value: "2285", uf: "PI" },
  { name: "PARAU", value: "1773", uf: "RN" },
  { name: "CAMPO ALEGRE DO FIDALGO", value: "0290", uf: "PI" },
  { name: "SENADOR SALGADO FILHO", value: "1012", uf: "RS" },
  { name: "SANTA CECILIA", value: "0510", uf: "PB" },
  { name: "MORRO AGUDO DE GOIAS", value: "9935", uf: "GO" },
  { name: "SAO JOSE DO INHACORA", value: "6059", uf: "RS" },
  { name: "PASSAGEM FRANCA DO PIAUI", value: "1293", uf: "PI" },
  { name: "DOM VICOSO", value: "4455", uf: "MG" },
  { name: "BARAUNA", value: "0452", uf: "PB" },
  { name: "PARAISO", value: "5747", uf: "SC" },
  { name: "COQUEIRO BAIXO", value: "1136", uf: "RS" },
  { name: "SUL BRASIL", value: "5595", uf: "SC" },
  { name: "IOMERE", value: "0922", uf: "SC" },
  { name: "ITAPORA DO TOCANTINS", value: "9417", uf: "TO" },
  { name: "MAMPITUBA", value: "0996", uf: "RS" },
  { name: "SERRANOPOLIS DE MINAS", value: "0736", uf: "MG" },
  { name: "OLHO D'AGUA DO CASADO", value: "2815", uf: "AL" },
  { name: "LAVANDEIRA", value: "0086", uf: "TO" },
  { name: "SAO JOSE DO BONFIM", value: "2193", uf: "PB" },
  { name: "PEIXE-BOI", value: "0511", uf: "PA" },
  { name: "MORRO DO CHAPEU DO PIAUI", value: "0352", uf: "PI" },
  { name: "IPIRANGA DO PIAUI", value: "1095", uf: "PI" },
  { name: "SAO FRANCISCO", value: "0524", uf: "PB" },
  { name: "UIRAMUTA", value: "0038", uf: "RR" },
  { name: "FAGUNDES VARELA", value: "8411", uf: "RS" },
  { name: "MATUTINA", value: "4823", uf: "MG" },
  { name: "TENORIO", value: "0538", uf: "PB" },
  { name: "ANHANGUERA", value: "9223", uf: "GO" },
  { name: "SAO ROMAO", value: "5283", uf: "MG" },
  { name: "RODOLFO FERNANDES", value: "1817", uf: "RN" },
  { name: "TEIXEIROPOLIS", value: "0022", uf: "RO" },
  { name: "PORTO DO MANGUE", value: "0426", uf: "RN" },
  { name: "SOSSEGO", value: "0536", uf: "PB" },
  { name: "SUCUPIRA DO NORTE", value: "0931", uf: "MA" },
  { name: "SAO RAIMUNDO DO DOCA BEZERRA", value: "0244", uf: "MA" },
  { name: "RIACHO FRIO", value: "0374", uf: "PI" },
  { name: "SAO FERNANDO", value: "1835", uf: "RN" },
  { name: "CARLOS GOMES", value: "5961", uf: "RS" },
  { name: "JACUIPE", value: "2769", uf: "AL" },
  { name: "JAUPACI", value: "9435", uf: "GO" },
  { name: "MINADOR DO NEGRAO", value: "2805", uf: "AL" },
  { name: "ARAPUA", value: "0830", uf: "PR" },
  { name: "VALE DE SAO DOMINGOS", value: "1102", uf: "MT" },
  { name: "BOA VISTA DO CADEADO", value: "1124", uf: "RS" },
  { name: "VILA LANGARO", value: "1030", uf: "RS" },
  { name: "CAPIM", value: "0464", uf: "PB" },
  { name: "BODO", value: "0412", uf: "RN" },
  { name: "DAMIAO", value: "0478", uf: "PB" },
  { name: "SAO JOAO DO TIGRE", value: "2183", uf: "PB" },
  { name: "MESSIAS TARGINO", value: "1721", uf: "RN" },
  { name: "CABACEIRAS", value: "1963", uf: "PB" },
  { name: "PUGMIL", value: "0094", uf: "TO" },
  { name: "FAROL", value: "5511", uf: "PR" },
  { name: "PAIAL", value: "0928", uf: "SC" },
  { name: "SAO JOSE DO BREJO DO CRUZ", value: "0530", uf: "PB" },
  { name: "SAO JOAO DO PACUI", value: "0720", uf: "MG" },
  { name: "MIGUEL LEAO", value: "1125", uf: "PI" },
  { name: "PAI PEDRO", value: "0678", uf: "MG" },
  { name: "LAGO DOS RODRIGUES", value: "0184", uf: "MA" },
  { name: "MAURILANDIA DO TOCANTINS", value: "0183", uf: "TO" },
  { name: "CATUTI", value: "0586", uf: "MG" },
  { name: "CUMARI", value: "9333", uf: "GO" },
  { name: "CORONEL EZEQUIEL", value: "1655", uf: "RN" },
  { name: "DORESOPOLIS", value: "4467", uf: "MG" },
  { name: "SAO MIGUEL DA BAIXA GRANDE", value: "0392", uf: "PI" },
  { name: "SANTA MONICA", value: "5519", uf: "PR" },
  { name: "NOVO ALEGRE", value: "9703", uf: "TO" },
  { name: "VISTA SERRANA", value: "2011", uf: "PB" },
  { name: "TUPANCI DO SUL", value: "5979", uf: "RS" },
  { name: "LAGOA DE SAO FRANCISCO", value: "0338", uf: "PI" },
  { name: "FRANCISCO MACEDO", value: "0316", uf: "PI" },
  { name: "IPIRANGA DE GOIAS", value: "1074", uf: "GO" },
  { name: "SANTANA DO PIAUI", value: "2281", uf: "PI" },
  { name: "SAO JOSE DE PRINCESA", value: "0528", uf: "PB" },
  { name: "SAO BENTO DO NORTE", value: "1831", uf: "RN" },
  { name: "ALTO BELA VISTA", value: "0886", uf: "SC" },
  { name: "JUNDIA", value: "1108", uf: "RN" },
  { name: "CORONEL JOAO PESSOA", value: "1657", uf: "RN" },
  { name: "SITIO D'ABADIA", value: "9611", uf: "GO" },
  { name: "JARDIM OLINDA", value: "7645", uf: "PR" },
  { name: "PEDRA BRANCA", value: "2121", uf: "PB" },
  { name: "ALAGOA", value: "4025", uf: "MG" },
  { name: "SERRA REDONDA", value: "2217", uf: "PB" },
  { name: "SAO DOMINGOS", value: "0522", uf: "PB" },
  { name: "GODOY MOREIRA", value: "9947", uf: "PR" },
  { name: "PEDRA PRETA", value: "1791", uf: "RN" },
  { name: "FREI MARTINHO", value: "2025", uf: "PB" },
  { name: "JOAO DIAS", value: "1715", uf: "RN" },
  { name: "FERNANDO FALCAO", value: "0158", uf: "MA" },
  { name: "SUCUPIRA DO RIACHAO", value: "0256", uf: "MA" },
  { name: "SANTO ANTONIO DOS MILAGRES", value: "0378", uf: "PI" },
  { name: "SANTO AFONSO", value: "0115", uf: "MT" },
  { name: "PILOES", value: "1799", uf: "RN" },
  { name: "PARANA", value: "1771", uf: "RN" },
  { name: "AMPARO DE SAO FRANCISCO", value: "3101", uf: "SE" },
  { name: "SAO FRANCISCO DE ASSIS DO PIAUI", value: "0380", uf: "PI" },
  { name: "AMPARO", value: "0444", uf: "PB" },
  { name: "SAO MIGUEL DO FIDALGO", value: "0394", uf: "PI" },
];
