export const tiposEmprestimos: any = {
  "98": "98 - Empréstimo por Consignação",
  "76": "76 - Reserva de Margem para Cartão (RMC)",
  "44": "44 - Reserva de Cartão Consignado (RCC)",
};

export const bancosContratos: any = {
  "1": "1 - Banco do Brasil",
  "12": "12 - Banco Inbursa",
  "29": "29 - Banco Itaú Consignado",
  "33": "33 - Banco Santander",
  "41": "41 - Banrisul",
  "69": "69 - Banco Crefisa",
  "79": "Original do Agronegócio S.A",
  "104": "104 - Caixa Econ. Federal",
  "121": "121 - Banco Agibank",
  "169": "169 - Banco Olé",
  "237": "237 - Banco Bradesco",
  "254": "254 - Paraná Banco",
  "290": "290 - PagBank",
  "318": "318 - Banco BMG",
  "394": "394 - Banco Bradesco Financiamentos",
  "341": "341 - Banco Itaú",
  "422": "422 - Banco Safra",
  "623": "623 - Banco PAN",
  "626": "626 - Banco C6",
  "707": "707 - Banco Daycoval",
  "739": "739 - Banco Cetelem",
  "935": "935 - Facta Financeira",
  "752": "752 - Banco Cetelem",
};

export const parseEnumbancoToCodBanco: { [k: string]: number } = {
  BB: 1,
  BMG: 29,
  MASTER: 243,
  PAN: 623,
  SAFRA: 422,
  ITAU: 29,
  OLE: 169,
  DAYCOVAL: 707,
  C6: 626,
  BRADESCO: 394,
  BANRISUL: 41,
  PAGBANK: 81,
  CREFISA: 69,
  BANCO_CETELEM: 739,
};

export const parseCodBancoToEnumBanco: any = Object.entries(
  parseEnumbancoToCodBanco
).reduce((acc, [k, v]) => {
  acc[v] = k;
  return acc;
});
