import { Box, Button, Center, Flex, Grid, Input, Text } from "@chakra-ui/react";
import { ContratoInss } from "components/atendimentos-components/atendimento-form/types";
import { AttendanceActionsTypes } from "contexts/attendance-context/actions-reducer";
import { Checkbox } from "components/checkbox";
import { Dropdown } from "components/dropdown";
import { Dispatch, useEffect, useRef, useState } from "react";
import { AnimateElement } from "utils/animations";
import { useAttendanceContext } from "contexts/attendance-context";
import { colorsMap } from "components/atendimentos-components/pagina-atendimento/attendance-field/attendance-input";
import { banks } from "components/mailing-components/mailing-modal-filter/mailing-filter/consts";
import { AttendanceTabKey } from "components/atendimentos-components/pagina-atendimento/attendance-data-form";
import { DropdownField } from "components/dropdown-field";
import { dropdownPrazo } from "../../margens/simulacao-fields/utils";
import { useSimulacao } from "./use-simulacao";
import { bancosDigitacaoMailingPipeline } from "components/mailing-components/mailing-options-modal/parts/digitacao-filter";
import {
  parseCodBancoToEnumBanco,
  parseEnumbancoToCodBanco,
} from "components/atendimentos-components/atendimento-form/contracts-table/consts";

export function CardEmprestimoBancario({
  emprestimo,
  dispatch,
  emprestimoList,
  index,
  activeTab,
}: {
  emprestimo: ContratoInss;
  emprestimoList: ContratoInss[];
  dispatch: Dispatch<AttendanceActionsTypes>;
  index: number;
  activeTab: AttendanceTabKey;
}) {
  let isOpen = !!emprestimo.simular;
  const { formControl } = useAttendanceContext();
  const [isInsideDOM, setIsInsideDOM] = useState(isOpen);
  const convenio = formControl.values.convenio;
  const { isLoadingTabelas, onChangeSimulacao, simulacao, tabelasList } =
    useSimulacao({ convenio });
  const containerRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const [errorBanco, setErrorBanco] = useState<string>("");

  const calculateFields = (c: ContratoInss) => {
    // const isChangedTipoOperacao = c.tipoOperacao !== emprestimo.tipoOperacao;

    // if (c.tipoOperacao === "REFIN_TROCO") {
    //   if (isChangedTipoOperacao) {
    //     c.novaTaxa = undefined;
    //     c.novoPrazo = undefined;
    //   }
    //   c.novaParcela = c.valorParcela;
    //   c.valorReducao = 0;
    //   c.codBancoNovaOperacao = c.codBanco;

    //   let valorNovoEmprestimo = calculateValorEmprestimo(
    //     c.novaParcela,
    //     c.novaTaxa,
    //     c.novoPrazo
    //   );
    //   c.troco =
    //     valorNovoEmprestimo && c.saldoQuitacao
    //       ? Math.floor((valorNovoEmprestimo - c.saldoQuitacao) * 100) / 100
    //       : undefined;
    // } else if (c.tipoOperacao === "REFIN_REDUCAO_PARCELA") {
    //   if (isChangedTipoOperacao) {
    //     c.novaTaxa = undefined;
    //     c.novoPrazo = undefined;
    //     c.troco = undefined;
    //   }
    //   c.novaParcela = calculateParcela(
    //     c.saldoQuitacao,
    //     c.novaTaxa,
    //     c.novoPrazo
    //   );

    //   c.codBancoNovaOperacao = c.codBanco;
    //   c.valorReducao =
    //     c.valorParcela && c.novaParcela
    //       ? Math.floor((c.valorParcela - c.novaParcela) * 100) / 100
    //       : undefined;
    // } else if (c.tipoOperacao === "PORT_REDUCAO_PARCELA") {
    //   if (isChangedTipoOperacao) {
    //     c.novaTaxa = undefined;
    //     c.codBancoNovaOperacao = undefined;
    //     c.troco = undefined;
    //   }
    //   c.novoPrazo = c.quantidadeParcelasEmAberto;
    //   c.novaParcela = calculateParcela(
    //     c.saldoQuitacao,
    //     c.novaTaxa,
    //     c.novoPrazo
    //   );

    //   c.valorReducao =
    //     c.valorParcela && c.novaParcela
    //       ? Math.floor((c.valorParcela - c.novaParcela) * 100) / 100
    //       : undefined;
    // } else if (c.tipoOperacao === "PORT_REFIN_REDUCAO_PARCELA") {
    //   if (isChangedTipoOperacao) {
    //     c.novaTaxa = undefined;
    //     c.codBancoNovaOperacao = undefined;
    //     c.novoPrazo = undefined;
    //     c.troco = undefined;
    //   }
    //   c.novaParcela = calculateParcela(
    //     c.saldoQuitacao,
    //     c.novaTaxa,
    //     c.novoPrazo
    //   );

    //   c.valorReducao =
    //     c.valorParcela && c.novaParcela
    //       ? Math.floor((c.valorParcela - c.novaParcela) * 100) / 100
    //       : undefined;
    // } else if (c.tipoOperacao === "PORT_REFIN_TROCO") {
    //   if (isChangedTipoOperacao) {
    //     c.novaTaxa = undefined;
    //     c.codBancoNovaOperacao = undefined;
    //     c.novoPrazo = undefined;
    //   }
    //   c.novaParcela = c.valorParcela;
    //   c.valorReducao = 0;
    //   let valorNovoEmprestimo = calculateValorEmprestimo(
    //     c.novaParcela,
    //     c.novaTaxa,
    //     c.novoPrazo
    //   );

    //   c.troco =
    //     valorNovoEmprestimo && c.saldoQuitacao
    //       ? Math.floor((valorNovoEmprestimo - c.saldoQuitacao) * 100) / 100
    //       : undefined;
    // }

    return c;
  };

  const onChange = (newValue: ContratoInss) => {
    dispatch({
      type: "changeField",
      payload: {
        data: emprestimoList?.map((contract, i) => {
          if (i === index) {
            return calculateFields(newValue);
          }
          return contract;
        }),
        fieldName: "contratosEmprestimosInss",
      },
    });
  };

  const onOpen = () => {
    dispatch({
      type: "changeField",
      payload: {
        data: emprestimoList?.map((contract, i) => {
          if (i === index) {
            contract.simular = true;
          }
          return contract;
        }),
        fieldName: "contratosEmprestimosInss",
      },
    });
    setIsInsideDOM(true);
    isOpen = true;
  };

  const onClose = () => {
    dispatch({
      type: "changeField",
      payload: {
        data: emprestimoList?.map((contract, i) => {
          if (i === index) {
            contract.simular = false;
          }
          return contract;
        }),
        fieldName: "contratosEmprestimosInss",
      },
    });
    isOpen = false;
  };

  const onToggle = () => {
    if (isOpen) onClose();
    else onOpen();
  };

  const openAnimation = () => {
    const maxAnimationTiming = 200;
    const container = containerRef.current;
    const content = contentRef.current;
    if (container && content) {
      const currentProgress = Number(getComputedStyle(container).opacity);
      const duration = (1 - currentProgress) * maxAnimationTiming;
      container.style.overflow = `hidden`;
      AnimateElement(
        duration,
        [currentProgress, 1],
        (progress) => {
          if (container) {
            const maxHeight = content.clientHeight;
            container.style.opacity = `${progress}`;
            container.style.height = `${progress * maxHeight}px`;
          }
        },
        () => {
          if (isOpen && container) container.style.overflow = `visible`;
        }
      );
    }
  };

  const closeAnimation = () => {
    const content = contentRef.current;
    const container = containerRef.current;
    if (content && container) {
      const currentProgress = Number(getComputedStyle(container).opacity);
      const duration = currentProgress * 200;
      container.style.overflow = `hidden`;
      AnimateElement(
        duration,
        [1, 0],
        (progress) => {
          if (content && container) {
            const maxHeight = content.clientHeight;
            container.style.opacity = `${progress}`;
            container.style.height = `${progress * maxHeight}px`;
          }
        },
        () => (!isOpen ? setIsInsideDOM(false) : null)
      );
    }
  };

  useEffect(() => {
    if (isOpen) openAnimation();
    else closeAnimation();
  }, [isOpen]);

  const parseTagText = new Map([
    ["API_BANCO_CARTAO", "CARTÃO ATUALIZADO"],
    ["DATAFAST", "REFIN BANCO"],
    ["CONSULTA OFFLINE", ""],
    ["EXTRATO_ONLINE", "EXTR. ONLINE"],
    ["IN100", "IN100"],
  ]);

  const bankDesc = banks.find(
    (curr) => Number(curr.value) === Number(emprestimo.codBanco)
  )?.shortName;

  return (
    <Flex
      key={emprestimo.contrato}
      flexDir="column"
      border="1px solid #E8EAED"
      bg="#F5F7F9"
      p="10px 15px"
      borderRadius="5px"
      _hover={{ bg: "gray.100" }}
      transition="0.2s"
      cursor="pointer"
      onClick={onToggle}
    >
      <Flex
        alignItems="center"
        justifyContent="space-between"
        w="100%"
        pos="relative"
      >
        {emprestimo.origem ? (
          <Center
            p="2px 5px"
            fontSize="11"
            borderRadius="5px"
            bg={colorsMap.get(emprestimo.origem)}
            color="#fff"
            ml="auto"
            pos="absolute"
            top="0"
            right="0"
          >
            {parseTagText.get(emprestimo.origem)}
          </Center>
        ) : null}
      </Flex>
      <Grid templateColumns="1fr 1fr" gap="10px">
        <Text>
          <b>Situação:</b> <br />
          <Text
            as="span"
            p="4px"
            borderRadius="5px"
            color={emprestimo.situacao === "Ativo" ? "#00cc00" : ""}
          >
            {emprestimo.situacao} &nbsp;
          </Text>
        </Text>
        <Text>
          <b>Banco:</b> <br />
          {emprestimo.codBanco ? (
            <Text as="span" whiteSpace="nowrap">
              {`${emprestimo.codBanco}${bankDesc ? " - " + bankDesc : ""}`}{" "}
            </Text>
          ) : null}{" "}
          &nbsp;
        </Text>
        <Text>
          <b>Contrato:</b> <br />
          <Text as="span" whiteSpace="nowrap">
            {typeof emprestimo.contrato === "string"
              ? emprestimo.contrato.length > 25
                ? emprestimo.contrato.slice(0, 25) + "..."
                : emprestimo.contrato
              : null}{" "}
            &nbsp;
          </Text>
        </Text>
        <Text>
          <b>Valor Parcelas:</b> <br />
          <Text as="span" whiteSpace="nowrap">
            R${" "}
            {typeof emprestimo.valorParcela === "number"
              ? emprestimo.valorParcela.toFixed(2)
              : emprestimo.valorParcela}{" "}
            &nbsp;
          </Text>
        </Text>
        <Text>
          <b>Valor Empréstimo:</b> <br />
          <Text as="span">
            {emprestimo.valorEmprestado ? (
              <Text as="span" whiteSpace="nowrap">
                R${" "}
                {typeof emprestimo.valorEmprestado === "number"
                  ? emprestimo.valorEmprestado.toFixed(2)
                  : emprestimo.valorEmprestado}{" "}
              </Text>
            ) : null}{" "}
            &nbsp;
          </Text>
        </Text>
        <Text>
          <b>Taxa:</b> <br />
          <Text as="span" whiteSpace="nowrap">
            {typeof emprestimo.taxa === "number"
              ? emprestimo.taxa.toFixed(2)
              : emprestimo.taxa}{" "}
            &nbsp;
          </Text>
        </Text>
        <Text>
          <b>Qtd. Parcelas:</b> <br />
          <Text as="span" whiteSpace="nowrap">
            {emprestimo.quantidadeParcelas} &nbsp;
          </Text>
        </Text>
        <Text>
          <b>Em Aberto:</b> <br />
          <Text as="span" whiteSpace="nowrap">
            {emprestimo.quantidadeParcelasEmAberto} &nbsp;
          </Text>
        </Text>
        <Text>
          <b>Saldo Dev. Aprox.:</b> <br />
          {emprestimo.saldoQuitacao ? (
            <Text as="span" whiteSpace="nowrap">
              R${" "}
              {typeof emprestimo.saldoQuitacao === "number"
                ? emprestimo.saldoQuitacao.toFixed(2)
                : emprestimo.saldoQuitacao}{" "}
            </Text>
          ) : null}{" "}
          &nbsp;
        </Text>

        {/* <Text>
          <b>Início Desconto:</b>{" "}
          <Text as="span">{emprestimo.competenciaInicioDesconto}</Text>
        </Text> */}
        {/* <Text>
          <b>Fim Desconto:</b>{" "}
          <Text as="span">{emprestimo.competenciaFimDesconto}</Text>
        </Text> */}
        {/* <Text>
          <b>Data Averbação:</b>{" "}
          <Text as="span">{emprestimo.dataInicioContrato}</Text>
        </Text> */}
        <Flex alignItems="center" pt="5px" gridArea="auto / span 2">
          <Checkbox
            isChecked={emprestimo.simular}
            onChange={onToggle}
            mr="5px"
          />
          <Text fontWeight="bold">Simular</Text>
        </Flex>
      </Grid>

      <Flex
        ref={containerRef}
        flexDir="column"
        w="100%"
        opacity="0"
        overflow="hidden"
        height="0"
      >
        {isInsideDOM ? (
          <Grid
            pt="15px"
            templateColumns="repeat(1, 1fr)"
            gap="15px"
            w="100%"
            ref={contentRef}
            onClick={(e) => e.stopPropagation()}
          >
            <Box>
              <Flex alignItems="center">
                <Text mb="8px" fontWeight="bold">
                  Tipo Operação:
                </Text>
              </Flex>
              <Dropdown
                onChange={(value) => {
                  const enumbanco = dropdownBancosNovaOperacao.find(
                    ({ codBanco }) => `${emprestimo.codBanco}` === `${codBanco}`
                  )?.value;
                  onChangeSimulacao("tipoOperacao", value);
                  if (enumbanco) {
                    onChangeSimulacao("banco", enumbanco);
                    setErrorBanco("");
                  } else {
                    onChangeSimulacao("banco", undefined);
                    setErrorBanco("Banco não disponível para refinanciamento");
                  }
                  onChangeSimulacao("term", "96");
                }}
                options={dropdownTipoOperacao}
                value={simulacao.tipoOperacao}
                w="100%"
                variant="outline-custom"
              />
            </Box>
            <Box>
              <Flex alignItems="center">
                <Text mb="8px" fontWeight="bold">
                  Banco (Nova Operação):
                </Text>
              </Flex>
              <DropdownField
                onChange={(value) => onChangeSimulacao("banco", value)}
                options={dropdownBancosNovaOperacao}
                value={simulacao.banco}
                dropdownProps={{
                  w: "100%",
                  variant: "outline-custom",
                  placeholder: errorBanco ? errorBanco : undefined,
                }}
                isDisabled={true}
              />
            </Box>
            {/* <Box>
              <Flex alignItems="center">
                <Text mb="8px" fontWeight="bold">
                  Taxa (Nova Operação):
                </Text>
              </Flex>
              <Input
                type="number"
                value={emprestimo.novaTaxa ?? ""}
                onChange={({ target }) =>
                  onChange({
                    ...emprestimo,
                    novaTaxa: target.value
                      ? Number(onlyNumberValidMask(target.value).raw)
                      : undefined,
                  })
                }
                variant="outline-custom"
                _disabled={{ pointerEvents: "none", opacity: 0.8 }}
              />
            </Box> */}

            <Box>
              <Flex alignItems="center">
                <Text mb="8px" fontWeight="bold">
                  Prazo (Nova Operação):
                </Text>
              </Flex>
              <DropdownField
                onChange={(value) => onChangeSimulacao("term", value)}
                options={dropdownPrazo}
                value={simulacao.term}
                dropdownProps={{ variant: "outline-custom", w: "100%" }}
                isDisabled
              />
            </Box>
            <Box>
              <Flex alignItems="center">
                <Text mb="8px" fontWeight="bold">
                  Tabela (Nova Operação):
                </Text>
              </Flex>
              <DropdownField
                onChange={(value) => {
                  onChangeSimulacao("tabela", value);
                  onChangeSimulacao("tableCode", value);
                  onChangeSimulacao("codeTable", value);
                }}
                options={tabelasList}
                isLoading={isLoadingTabelas}
                value={
                  simulacao.codeTable || simulacao.tabela || simulacao.tableCode
                }
                dropdownProps={{ variant: "outline-custom", w: "100%" }}
              />
            </Box>
            {/* <Box>
              <Flex alignItems="center">
                <Text mb="8px" fontWeight="bold">
                  Parcela (Nova Operação):
                </Text>
              </Flex>
              <Input
                type="number"
                value={emprestimo.novaParcela ?? ""}
                variant="outline-custom"
                isDisabled={true}
                _disabled={{ pointerEvents: "none", opacity: 0.8 }}
              />
            </Box> */}
            {/* <Box>
              <Flex alignItems="center">
                <Text mb="8px" fontWeight="bold">
                  Redução Parcela:
                </Text>
              </Flex>
              <Input
                value={emprestimo.valorReducao ?? ""}
                variant="outline-custom"
                isDisabled={true}
                _disabled={{ pointerEvents: "none", opacity: 0.8 }}
              />
            </Box> */}
            <Box>
              <Flex alignItems="center">
                <Text mb="8px" fontWeight="bold">
                  Troco (Valor liberado):
                </Text>
              </Flex>
              <Input
                // value={emprestimo.troco ?? ""}
                type="number"
                variant="outline-custom"
                isDisabled={true}
                _disabled={{ pointerEvents: "none", opacity: 0.8 }}
              />
            </Box>
            {/* <Box>
              <Flex alignItems="center">
                <Text mb="8px" fontWeight="bold">
                  ADE:
                </Text>
              </Flex>
              <Input
                onChange={({ target }) =>
                  onChange({ ...emprestimo, ade: target.value })
                }
                value={emprestimo.ade ?? ""}
                variant="outline-custom"
                _disabled={{ pointerEvents: "none", opacity: 0.8 }}
              />
            </Box> */}
            {/* <Box gridArea="auto / span 2">
              <Flex alignItems="center">
                <Text mb="8px" fontWeight="bold">
                  Link Formalização:
                </Text>
              </Flex>
              <Input
                onChange={({ target }) =>
                  onChange({ ...emprestimo, linkFormalizacao: target.value })
                }
                value={emprestimo.linkFormalizacao ?? ""}
                variant="outline-custom"
                _disabled={{ pointerEvents: "none", opacity: 0.8 }}
              />
            </Box> */}
            <Box>
              <Flex alignItems="center">
                <Text mb="8px" fontWeight="bold">
                  Observação:
                </Text>
              </Flex>
              <Input
                onChange={({ target }) => {
                  // onChange({
                  //   ...emprestimo,
                  //   observacaoDigitacao: target.value,
                  // })
                }}
                value={emprestimo.observacaoDigitacao}
                variant="outline-custom"
                _disabled={{ pointerEvents: "none", opacity: 0.8 }}
                as="textarea"
                height="60px"
                minH="60px"
              />
            </Box>
            <Flex w="100%" justifyContent="flex-end">
              {/* <Button onClick={() => {

              }}>Simular</Button> */}
            </Flex>
          </Grid>
        ) : null}
      </Flex>
    </Flex>
  );
}
export const dropdownTipoOperacao = [
  { name: "REFIN TROCO", value: "REFIN_TROCO" },
];

export const dropdownTipoOperacaoREFIN = [
  { name: "REFIN TROCO", value: "REFIN_TROCO" },
  { name: "REFIN REDUCAO PARCELA", value: "REFIN_REDUCAO_PARCELA" },
  { name: "PORT REDUCAO PARCELA", value: "PORT_REDUCAO_PARCELA" },
  { name: "PORT REFIN REDUCAO PARCELA", value: "PORT_REFIN_REDUCAO_PARCELA" },
  { name: "PORT REFIN TROCO", value: "PORT_REFIN_TROCO" },
];

const dropdownBancosNovaOperacao = [
  { name: "C6", codBanco: 626, value: "C6" },
  { name: "Daycoval", codBanco: 707, value: "DAYCOVAL" },
];
