import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Box,
  BoxProps,
  Button,
  ButtonProps,
  FlexProps,
  useColorModeValue,
} from "@chakra-ui/react";
import { defaultScroll } from "chakra/theme";
import { memo, useEffect, useId, useRef, useState } from "react";
import { DropdownItem } from "./options";
import "./style.css";
import { AnimateElement } from "utils/animations";
import { useEventListener } from "services/events";
import { isClickedOut } from "components/popover";
export interface DropdownOptionProps {
  name: string;
  value?: any;
  subitems?: { name: string; value: any }[];
  isDisabled?: () => boolean;
}

export interface DropdownProps extends Omit<ButtonProps, "onChange" | "value"> {
  options?: DropdownOptionProps[] | any[];
  placeholder?: string;
  onChange?: (value: any, option: any) => void;
  optionLabel?: "value" | "name" | string;
  value?: any | any[];
  openHover?: boolean;
  isInvalid?: boolean;
  multiSelect?: boolean;
  openTop?: boolean;
  optionValue?: string;
  containerStyles?: BoxProps;
  onCloseEvent?: (target: HTMLDivElement) => void;
  onOpenEvent?: (target: HTMLDivElement) => void;
  menuListProps?: BoxProps;
  listenerId?: string;
  containerOptionProps?: FlexProps;
}

export function Dropdown({
  listenerId,
  value,
  onChange,
  options = [],
  openHover = false,
  placeholder = "Selecione",
  optionLabel = "name",
  isInvalid,
  multiSelect,
  optionValue = "value",
  openTop,
  containerStyles,
  children,
  onCloseEvent,
  onOpenEvent,
  menuListProps,
  containerOptionProps,
  ...rest
}: DropdownProps) {
  let [isOpen, setIsOpen] = useState(openHover);
  const [isRemovedList, setIsRemovedList] = useState(false);
  const id = useId();
  const menuList = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const animationDuration = 200;
  const { variant } = rest;
  const findSelected = multiSelect
    ? undefined
    : options.find(
        (curr) =>
          curr[optionValue] === value ||
          curr.subitems?.find((subI: any) => subI[optionValue] === value)
      );

  const selectedName =
    findSelected && !multiSelect
      ? findSelected[optionLabel]
      : multiSelect
      ? value?.join(",")
      : null;

  const bg = useColorModeValue("#fff", "#1c1c1c");
  const color = useColorModeValue("primary.500", "#ededed");
  const border = useColorModeValue("1px solid #EAEAEA", "default-border-dark");

  const onOpen = () => {
    setIsOpen(true);
    setIsRemovedList(true);
    onOpenEvent?.(menuList.current!);
    isOpen = true;
  };

  const onClose = () => {
    setIsOpen(false);
    onCloseEvent?.(menuList.current!);
    isOpen = false;
  };

  const onToggle = () => {
    if (isOpen) onClose();
    else onOpen();
  };

  const onOpenAnimation = () => {
    const target = menuList.current;
    const container = containerRef.current;
    if (target) {
      const from = Number(getComputedStyle(target).opacity);
      const to = 1;
      if (from !== to) {
        const menuListHeight = target.clientHeight;
        const rect = target.getBoundingClientRect();
        const isOpenOnTop =
          rect.y > window.innerHeight - (menuListHeight + 117);
        // const duration = currentProgress * 200;
        AnimateElement(animationDuration, [from, to], (progress) => {
          if (container && target) {
            // Change Styles
            target.style.opacity = `${progress}`;
            target.style.transform = `scale(${progress * 0.2 + 0.8})`;
            // Change position
            if (isOpenOnTop) {
              target.style.top = "auto";
              target.style.bottom = "calc(100% + 2px)";
              target.style.transformOrigin = "0 100%";
            } else {
              target.style.top = "calc(100% + 2px)";
              target.style.bottom = "auto";
              target.style.transformOrigin = "0 0";
            }
          }
        });
      }
    }
  };

  const onCloseAnimation = () => {
    const target = menuList.current;
    const container = containerRef.current;
    if (target && container) {
      const from = Number(getComputedStyle(target).opacity);
      const to = 0;
      if (from !== to) {
        AnimateElement(
          animationDuration,
          [from, to],
          (progress) => {
            if (target) {
              target.style.opacity = `${progress}`;
              target.style.transform = `scale(${progress * 0.2 + 0.8})`;
            }
          },
          () => (isOpen === false ? setIsRemovedList(false) : null)
        );
      }
    }
  };

  useEffect(() => {
    if (isOpen) onOpenAnimation();
    else onCloseAnimation();
  }, [isOpen]);

  useEventListener(
    "onClickBody",
    (event) => {
      if (isClickedOut(event, containerRef) && isOpen) onClose();
    },
    listenerId ?? id
  );

  const { onClick, ...dropdownRest } = rest;

  return (
    <Box
      ref={containerRef}
      pos="relative"
      role="group"
      // onBlur={openHover ? undefined : onClose}
      color={color}
      {...containerStyles}
    >
      <Button
        ref={buttonRef}
        rightIcon={<ChevronDownIcon w="20px" h="20px" />}
        bg={variant ? undefined : "none"}
        color={variant ? undefined : "primary.500"}
        h={variant ? undefined : "32px"}
        fontWeight="medium"
        _hover={{ bg: "bg-gray" }}
        _disabled={{
          _hover: { bg: "gray.200" },
          opacity: "50%",
          cursor: "not-allowed",
        }}
        onClick={(e) => {
          if (!openHover) {
            if (isOpen) onClose();
            else onOpen();
          }
          onClick?.(e);
        }}
        border={
          variant ? undefined : "1px solid var(--chakra-colors-custom-gray)"
        }
        justifyContent="space-between"
        className={isInvalid ? "invalid-input" : undefined}
        spinnerPlacement="end"
        {...dropdownRest}
      >
        {children ? children : selectedName ? selectedName : placeholder}
      </Button>
      {isRemovedList ? (
        <Box
          ref={menuList}
          pos="absolute"
          top="calc(100% + 2px)"
          left="0"
          minW="180px"
          bg={bg}
          borderRadius="4"
          w="100%"
          // visibility={openHover ? "hidden" : undefined}
          // opacity={openHover ? "0" : undefined}
          // transition="visibility .2s, opacity .2s, transform .2s"
          // _groupHover={
          //   openHover
          //     ? {
          //         opacity: 1,
          //         visibility: "visible",
          //         transform: "scale(1)",
          //       }
          //     : undefined
          // }
          overflow="auto"
          // transform={openHover ? "scale(0.9)" : undefined}
          transformOrigin="0 0"
          zIndex="10000"
          border={border}
          whiteSpace="nowrap"
          userSelect="none"
          maxH="200px"
          sx={defaultScroll}
          onMouseDown={(e: any) => e.preventDefault()}
          animation="DropdownListOpen 0.2s"
          {...menuListProps}
        >
          {options.map((option, index) => {
            if (multiSelect && option[optionValue] == null) return null;
            return (
              <DropdownItem
                key={index}
                option={option}
                onChange={onChange}
                selectedValue={value}
                onClose={onClose}
                multiSelect={multiSelect}
                optionValueKey={optionValue}
                optionLabelKey={optionLabel}
                containerOptionProps={containerOptionProps}
              />
            );
          })}
        </Box>
      ) : null}
    </Box>
  );
}

export default memo(Dropdown);
