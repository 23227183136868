import { useEffect, useState } from "react";
import {
  SimulacaoDataProps,
  TabelaProps,
} from "../../../margens/simulacao-fields/types";
import { SimulacaoApi } from "../../../margens/simulacao-fields/requests";
import { Convenio } from "components/atendimentos-components/atendimento-form/types";

export function useSimulacao({ convenio }: { convenio: Convenio | undefined }) {
  const [isLoadingTabelas, setIsLoadingTabelas] = useState(false);
  const [simulacao, setSimulacao] = useState<SimulacaoDataProps>({});

  const [tabelasList, setTabelasList] = useState<
    { name: string; value: string; completedTable: TabelaProps }[]
  >([]);
  const simulacaoApi = SimulacaoApi();

  const onChangeSimulacao = (key: keyof SimulacaoDataProps, value: any) => {
    setSimulacao((prev) => ({ ...prev, [key]: value }));
    // setErrors((errors) => {
    //   delete errors[key];
    //   return { ...errors };
    // });
  };

  useEffect(() => {
    if (simulacao.banco && convenio) {
      simulacaoApi.getTabelasDigitacao(
        simulacao.banco,
        convenio,
        setTabelasList,
        setIsLoadingTabelas
      );
    }
  }, [simulacao.banco, convenio]);

  return { isLoadingTabelas, simulacao, onChangeSimulacao, tabelasList };
}
