import { Center, Flex, Grid, Text } from "@chakra-ui/react";
import {
  ContratoRCCInss,
  ContratoRMCInss,
} from "components/atendimentos-components/atendimento-form/types";
import { colorsMap } from "components/atendimentos-components/pagina-atendimento/attendance-field/attendance-input";
import { Checkbox } from "components/checkbox";
import { banks } from "components/mailing-components/mailing-modal-filter/mailing-filter/consts";
import { useAttendanceContext } from "contexts/attendance-context";

type Emprestimo = ContratoRCCInss & ContratoRMCInss;

export function CardEmprestimoCartao({
  emprestimo,
  field,
}: {
  emprestimo: Emprestimo;
  field: "contratosRCCInss" | "contratosRMCInss";
}) {
  const { formControl, dispatch } = useAttendanceContext();
  const contratosList = formControl.values[field] || [];

  const parseTagText = new Map([
    ["API_BANCO_CARTAO", "CARTÃO ATUALIZADO"],
    ["DATAFAST", "REFIN BANCO"],
    ["CONSULTA OFFLINE", ""],
    ["EXTRATO_ONLINE", "EXTR. ONLINE"],
    ["IN100", "IN100"],
  ]);

  const bankDesc = banks.find(
    (curr) => Number(curr.value) === Number(emprestimo.codBanco)
  )?.name;

  return (
    <Flex
      key={emprestimo.contrato}
      flexDir="column"
      border="1px solid #E8EAED"
      bg="#F5F7F9"
      p="10px 15px"
      borderRadius="5px"
    >
      <Flex w="100%" mb="12px">
        {emprestimo.origem ? (
          <Center
            p="2px 5px"
            fontSize="11"
            borderRadius="5px"
            bg={colorsMap.get(emprestimo.origem)}
            color="#fff"
            ml="auto"
            mb="8px"
          >
            {parseTagText.get(emprestimo.origem)}
          </Center>
        ) : null}
      </Flex>

      <Grid templateColumns="1fr 1fr 1fr 1fr" gap="10px">
        <Text>
          <b>Banco:</b>{" "}
          {emprestimo.codBanco ? (
            <Text as="span" whiteSpace="nowrap">
              {`${emprestimo.codBanco}${bankDesc ? " - " + bankDesc : ""}`}
            </Text>
          ) : null}
        </Text>
        <Text>
          <b>Contrato:</b> {emprestimo.contrato}
        </Text>
        <Text>
          <b>Limite cartão:</b> R${" "}
          {emprestimo.limiteCartao ? emprestimo.limiteCartao : "--"}
        </Text>
        <Text>
          <b>Início Contrato:</b> {emprestimo.dataInicioContrato}
        </Text>
        <Text>
          <b>Valor Reservado:</b>{" "}
          <Text as="span"> R$ {emprestimo.valorReservado ?? "--"}</Text>
        </Text>
        <Text>
          <b>Valor Saque Máximo:</b>{" "}
          <Text as="span"> R$ {emprestimo.valorSaqueMaximo ?? "--"}</Text>
        </Text>
        <Text>
          <b>Valor Saque Minimo:</b>{" "}
          <Text as="span"> R$ {emprestimo.valorSaqueMinimo ?? "--"}</Text>
        </Text>
        <Text>
          <b>Situação:</b>{" "}
          <Text
            as="span"
            p="4px"
            borderRadius="5px"
            color={emprestimo.situacao === "Ativo" ? "#00cc00" : ""}
          >
            {emprestimo.situacao}
          </Text>
        </Text>
        {field === "contratosRMCInss" && Number(emprestimo.codBanco) === 318 ? (
          <>
            <Text>
              <b>Observação:</b> {emprestimo.observacao}
            </Text>
            <Text>
              <b>Mensagem Impedimento:</b>{" "}
              <Text as="span">{emprestimo.mensagemImpedimento}</Text>
            </Text>
            <Text>
              <b>Modalidade Saque:</b>{" "}
              <Text as="span">{emprestimo.modalidadeSaque}</Text>
            </Text>
            <Text>
              <b>Número Adesão:</b>{" "}
              <Text as="span">{emprestimo.numeroAdesao}</Text>
            </Text>
            <Text>
              <b>Número Cartão:</b>{" "}
              <Text as="span">{emprestimo.numeroCartao}</Text>
            </Text>
            <Text>
              <b>Número Conta Interna:</b>{" "}
              <Text as="span">{emprestimo.numeroContaInterna}</Text>
            </Text>
          </>
        ) : null}
      </Grid>
    </Flex>
  );
}
