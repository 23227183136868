import { DeleteIcon } from "@chakra-ui/icons";
import { IconButton, Text } from "@chakra-ui/react";
import api from "api/api";
import { bancosContratos } from "components/atendimentos-components/atendimento-form/contracts-table/consts";

import { Attendance } from "components/atendimentos-components/atendimento-form/types";
import {
  AttendanceActionsTypes,
  FormControl,
} from "contexts/attendance-context/actions-reducer";
import { CustomTableColorColumn } from "components/dynamic-table/color-table";
import { Dispatch, SetStateAction } from "react";
import { GETConsultationStatusProps } from "./contratos";
import { openModalUpdateContract } from "./contratos/modal-update-contract";
import {
  ErrorMessageList,
  ErrorMessageProps,
  InfoMessageList,
  InfoMessageProps,
} from "./contratos/error-message";
import { valorParcelaReduzida } from "./simulacoes/utils";
import { KeyForward } from "components/atendimentos-components/atendimento-form/functions/mapeamentos/types";
import { mapObject } from "components/atendimentos-components/atendimento-form/functions/mapeamentos";
import { Checkbox } from "components/checkbox";
import { Toast } from "components/toast";

export const contractColumns = ({
  dispatch,
  formValues,
  isNovoPrazo,
}: {
  dispatch: Dispatch<AttendanceActionsTypes>;
  formValues: Attendance | Partial<Attendance>;
  isNovoPrazo: boolean;
}): CustomTableColorColumn[] => [
  {
    name: "AÇÕES",
    render: (row) => {
      return (
        <IconButton
          aria-label=""
          onClick={() => {
            dispatch({
              type: "changeField",
              payload: {
                data: formValues.contratosEmprestimosInss?.map((contract) => {
                  if (contract.contrato === row.contrato) {
                    contract.simular = false;
                  }
                  return contract;
                }),
                fieldName: "contratosEmprestimosInss",
              },
            });
          }}
          variant="danger"
          size="sm"
          icon={<DeleteIcon />}
        />
      );
    },
  },
  {
    name: "BANCO",
    render: ({ codBanco }) => {
      return bancosContratos[codBanco];
    },
  },
  {
    name: "VALOR PARCELA ORIGINAL",
    render: ({ valorParcela }) => {
      if (valorParcela) return <Text color="#0c0">{`R$ ${valorParcela}`}</Text>;
    },
  },
  {
    name: "TAXA ORIGINAL",
    render: ({ taxa }) => {
      if (taxa) return `${taxa}%`;
    },
  },
  {
    name: "NOVA TAXA",
    render: () => {
      if (formValues.simulacaoNovaTaxa)
        return formValues.simulacaoNovaTaxa + "%";
    },
  },
  {
    name: "VALOR PARCELA REDUZIDA",
    render: ({ taxa, saldoQuitacao, quantidadeParcelasEmAberto }) => {
      if (
        (!["REFIN", "PORT_REFIN"].includes(formValues.simulacaoOperacao!) ||
          formValues.simulacaoNovoPrazo) &&
        formValues.simulacaoNovaTaxa &&
        saldoQuitacao &&
        formValues.simulacaoOperacao
      ) {
        const result = valorParcelaReduzida(
          formValues.simulacaoNovaTaxa,
          saldoQuitacao,
          formValues as Attendance,
          quantidadeParcelasEmAberto
        );
        return `R$ ${result}`;
      }
    },
  },
  {
    name: "VALOR REDUÇÃO",
    render: ({
      valorParcela,
      taxa,
      saldoQuitacao,
      quantidadeParcelasEmAberto,
    }) => {
      const result = valorParcelaReduzida(
        formValues.simulacaoNovaTaxa,
        saldoQuitacao,
        formValues as Attendance,
        quantidadeParcelasEmAberto
      );
      const valorReducao = result ? valorParcela - result : null;
      if (valorReducao) return `R$ ${valorReducao.toFixed(2)}`;
    },
  },
  {
    name: "CONTRADO NOVO (ORIUNDO)",
    render: ({
      valorParcela,
      taxa,
      saldoQuitacao,
      quantidadeParcelasEmAberto,
    }) => {
      if (
        formValues.simulacaoTroco?.length &&
        !isNaN(Number(formValues.simulacaoTroco))
      ) {
        const result = valorParcelaReduzida(
          formValues.simulacaoNovaTaxa,
          saldoQuitacao + Number(formValues.simulacaoTroco),
          formValues as Attendance,
          quantidadeParcelasEmAberto
        );
        const valorReducao = result ? parseFloat(valorParcela) - result : null;
        if (valorReducao && formValues.simulacaoCoeficiente) {
          const contratoNovo = valorReducao / formValues.simulacaoCoeficiente;
          return `R$ ${contratoNovo?.toFixed(2)}`;
        }
      }
    },
  },
  //   {
  //     name: "Saldo Dev. Aprox.",
  //     render: ({ saldoQuitacao }) => {
  //       if (saldoQuitacao != null)
  //         return <Text color="#00bfff">{`R$ ${saldoQuitacao}`}</Text>;
  //     },
  //   },
  //   {
  //     name: "Parcelas em Aberto",
  //     render: ({ quantidadeParcelasEmAberto }) => {
  //       return quantidadeParcelasEmAberto;
  //     },
  //   },
  //   {
  //     name: "Quantidade Parcelas",
  //     render: ({ quantidadeParcelas }) => {
  //       return quantidadeParcelas;
  //     },
  //   },
  //   {
  //     name: "Novo Prazo",
  //     render: () => {
  //       return formValues.simulacaoNovoPrazo;
  //     },
  //     isVisible: isNovoPrazo,
  //   },
  //   {
  //     name: "Valor Empréstimo",
  //     render: ({ valorEmprestado }) => {
  //       if (valorEmprestado) return `R$ ${valorEmprestado}`;
  //     },
  //   },
  //   {
  //     name: "Contrato",
  //     render: (row) => row.contrato,
  //   },
];

// Atualização de Contratos e Higienização (Tela de atendimento aba contratos)

// Criado para resolver o problema de referência do array de contratos
let contratosEmprestimosInss: Attendance["contratosEmprestimosInss"];

const setConsultationREFIN = ({
  consultation,
  attendance,
  dispatch,
  setAccordionErrors,
  setAccordionInfos,
}: {
  consultation: GETConsultationStatusProps[0];
  attendance: Attendance;
  dispatch: Dispatch<AttendanceActionsTypes>;
  setAccordionErrors: Dispatch<
    SetStateAction<{
      RCC?: ErrorMessageProps["errorMessage"];
      RMC?: ErrorMessageProps["errorMessage"];
      REFIN?: ErrorMessageProps["errorMessage"];
    }>
  >;
  setAccordionInfos: Dispatch<
    SetStateAction<{
      RCC?: string | InfoMessageList | undefined;
      RMC?: string | InfoMessageList | undefined;
      REFIN?: string | InfoMessageList | undefined;
    }>
  >;
}) => {
  const {
    contratos,
    banco,
    finalizado,
    error,
    dataFinalizacao,
    mensagem,
    codBanco,
  } = consultation;
  if (finalizado && !!contratos?.length && error == null) {
    contratosEmprestimosInss =
      contratosEmprestimosInss || attendance.contratosEmprestimosInss;
    const mergedContracts = contratos?.map((contract) => {
      const currentContract = attendance.contratosEmprestimosInss?.find(
        (curr) => contract.id === curr.id
      );
      // CAMPOS EDITAVEIS
      const simular = currentContract?.simular;
      const troco = currentContract?.troco;
      const novoPrazo = currentContract?.novoPrazo;
      const novaTaxa = currentContract?.novaTaxa;
      const tipoOperacao = currentContract?.tipoOperacao;
      const novaParcela = currentContract?.novaParcela;
      const valorReducao = currentContract?.valorReducao;
      const ade = currentContract?.ade;
      const linkFormalizacao = currentContract?.linkFormalizacao;
      const observacaoDigitacao = currentContract?.observacaoDigitacao;

      return {
        ...contract,
        simular,
        troco,
        novoPrazo,
        novaTaxa,
        tipoOperacao,
        novaParcela,
        valorReducao,
        ade,
        linkFormalizacao,
        observacaoDigitacao,
      };
    });
    const removedCodBancoContrato =
      contratosEmprestimosInss?.filter(
        (curr) => `${curr.codBanco}` !== `${codBanco}`
      ) ?? [];

    contratosEmprestimosInss = [...mergedContracts, ...removedCodBancoContrato];

    dispatch({
      type: "changeFieldWithInitial",
      payload: {
        fieldName: "contratosEmprestimosInss",
        data: contratosEmprestimosInss,
      },
    });
  }
  if (error != null) {
    setAccordionErrors((errors) => {
      const refinErrors = (errors.REFIN! || []) as ErrorMessageList;
      return {
        ...errors,
        REFIN: [
          ...refinErrors,
          {
            errorTitle: `${banco} - ${new Date(
              dataFinalizacao!
            ).toLocaleString()}`,
            errorList: [error],
          },
        ],
      };
    });
  }
  if (mensagem != null) {
    setAccordionInfos((info) => {
      const refinInfo = (info.REFIN! || []) as InfoMessageList;

      return {
        ...info,
        REFIN: [
          ...refinInfo,
          {
            messageTitle: `${banco} - ${new Date(
              dataFinalizacao!
            ).toLocaleString()}`,
            messageList: [mensagem],
          },
        ],
      };
    });
  }
};

const setConsultationRMC = ({
  consultation,
  attendance,
  dispatch,
  setAccordionErrors,
  setAccordionInfos,
}: {
  consultation: GETConsultationStatusProps[0];
  attendance: Attendance;
  dispatch: Dispatch<AttendanceActionsTypes>;
  setAccordionErrors: Dispatch<
    SetStateAction<{
      RCC?: ErrorMessageProps["errorMessage"];
      RMC?: ErrorMessageProps["errorMessage"];
      REFIN?: ErrorMessageProps["errorMessage"];
    }>
  >;
  setAccordionInfos: Dispatch<
    SetStateAction<{
      RCC?: string | InfoMessageList | undefined;
      RMC?: string | InfoMessageList | undefined;
      REFIN?: string | InfoMessageList | undefined;
    }>
  >;
}) => {
  const { contratoRMC, finalizado, error, banco, dataFinalizacao, mensagem } =
    consultation;
  if (finalizado && contratoRMC != null && error == null) {
    const prevContratoRMC = attendance.contratosRMCInss?.[0];
    let contatoCopy = { ...contratoRMC };
    if (prevContratoRMC) {
      contatoCopy = {
        ...contratoRMC,
      };
    }
    dispatch({
      type: "changeFieldWithInitial",
      payload: {
        fieldName: "contratosRMCInss",
        data: [contatoCopy],
      },
    });
  }
  if (error != null) {
    setAccordionErrors((errors) => {
      const rmcErrors = (errors.RMC! || []) as ErrorMessageList;
      return {
        ...errors,
        RMC: [
          ...rmcErrors,
          {
            errorTitle: `${banco} - ${new Date(
              dataFinalizacao!
            ).toLocaleString()}`,
            errorList: [error],
          },
        ],
      };
    });
  }
  if (mensagem != null) {
    setAccordionInfos((info) => {
      const rmcInfo = (info.RMC! || []) as InfoMessageList;
      return {
        ...info,
        RMC: [
          ...rmcInfo,
          {
            messageTitle: `${banco} - ${new Date(
              dataFinalizacao!
            ).toLocaleString()}`,
            messageList: [mensagem],
          },
        ],
      };
    });
  }
};

const setConsultationRCC = ({
  consultation,
  attendance,
  dispatch,
  setAccordionErrors,
  setAccordionInfos,
}: {
  consultation: GETConsultationStatusProps[0];
  attendance: Attendance;
  dispatch: Dispatch<AttendanceActionsTypes>;
  setAccordionErrors: Dispatch<
    SetStateAction<{
      RCC?: ErrorMessageProps["errorMessage"];
      RMC?: ErrorMessageProps["errorMessage"];
      REFIN?: ErrorMessageProps["errorMessage"];
    }>
  >;
  setAccordionInfos: Dispatch<
    SetStateAction<{
      RCC?: string | InfoMessageList | undefined;
      RMC?: string | InfoMessageList | undefined;
      REFIN?: string | InfoMessageList | undefined;
    }>
  >;
}) => {
  const { contratoRCC, finalizado, error, banco, dataFinalizacao, mensagem } =
    consultation;
  if (finalizado && contratoRCC != null && error == null) {
    dispatch({
      type: "changeFieldWithInitial",
      payload: {
        fieldName: "contratosRCCInss",
        data: [contratoRCC],
      },
    });
  }
  if (error != null) {
    setAccordionErrors((errors) => {
      const rccErrors = (errors.RCC! || []) as ErrorMessageList;
      return {
        ...errors,
        RCC: [
          ...rccErrors,
          {
            errorTitle: `${banco} - ${new Date(
              dataFinalizacao!
            ).toLocaleString()}`,
            errorList: [error],
          },
        ],
      };
    });
  }
  if (mensagem != null) {
    setAccordionInfos((info) => {
      const rccInfo = (info.RCC! || []) as InfoMessageList;
      return {
        ...info,
        RCC: [
          ...rccInfo,
          {
            messageTitle: `${banco} - ${new Date(
              dataFinalizacao!
            ).toLocaleString()}`,
            messageList: [mensagem],
          },
        ],
      };
    });
  }
};

export async function updateContratos({
  type,
  attendance,
  dispatch,
  setHigienizacaoLoadings,
  setAccordionErrors,
  setAccordionInfos,
  forceRequest,
}: {
  type: "RCC" | "RMC" | "REFIN";
  attendance: Attendance;
  dispatch: Dispatch<AttendanceActionsTypes>;
  setHigienizacaoLoadings: Dispatch<
    SetStateAction<{
      RCC: boolean;
      RMC: boolean;
      REFIN: boolean;
    }>
  >;
  setAccordionErrors: Dispatch<
    SetStateAction<{
      RCC?: ErrorMessageProps["errorMessage"];
      RMC?: ErrorMessageProps["errorMessage"];
      REFIN?: ErrorMessageProps["errorMessage"];
    }>
  >;
  setAccordionInfos: Dispatch<
    SetStateAction<{
      RCC?: InfoMessageProps["infoMessage"];
      RMC?: InfoMessageProps["infoMessage"];
      REFIN?: InfoMessageProps["infoMessage"];
    }>
  >;
  forceRequest?: boolean;
}) {
  const setConsultation = {
    REFIN: setConsultationREFIN,
    RMC: setConsultationRMC,
    RCC: setConsultationRCC,
  }[type];

  const cleanConsultationData = {
    REFIN: { pendingEmprestimos: [] },
    RMC: { hasPendingRMC: false },
    RCC: { hasPendingRCC: false },
  }[type];

  const isPendingConsultation = {
    REFIN:
      !attendance.statusHigienizacoesAtendimento?.pendingEmprestimos?.length,
    RMC: !attendance.statusHigienizacoesAtendimento?.hasPendingRMC,
    RCC: !attendance.statusHigienizacoesAtendimento?.hasPendingRCC,
  }[type];

  const url = `/atendimento-higienizacao/${attendance?.id}/${type}`;

  if (isPendingConsultation && !forceRequest) {
    openModalUpdateContract({
      multiSelect: ["REFIN"].includes(type),
      type,
      idAtendimento: attendance.id!,
      onClose: () =>
        setAccordionErrors((prevErrors) => ({
          ...prevErrors,
          [type]: undefined,
        })),
    });
  } else {
    setAccordionErrors((prevErrors) => ({ ...prevErrors, [type]: undefined }));
    setAccordionInfos((prevInfo) => ({ ...prevInfo, [type]: undefined }));
    setHigienizacaoLoadings((higienizacaoLoadings) => ({
      ...higienizacaoLoadings,
      [type]: true,
    }));
    try {
      const { data } = await api.get(url);
      const consult = data as GETConsultationStatusProps;
      const isAllFinalized =
        consult?.filter((crr) => crr.finalizado)?.length === consult?.length;
      if (consult?.length) {
        consult.forEach((consultation) => {
          setConsultation({
            attendance,
            consultation,
            dispatch,
            setAccordionErrors,
            setAccordionInfos,
          });
        });
        contratosEmprestimosInss = null;
      }
      if (consult.length === 0 || isAllFinalized) {
        dispatch({
          type: "changeFieldWithInitial",
          payload: {
            fieldName: "statusHigienizacoesAtendimento",
            data: {
              ...attendance.statusHigienizacoesAtendimento,
              ...cleanConsultationData,
            },
          },
        });
      }
    } catch (e: any) {
      const errorMessage =
        typeof e.response.data === "string" ? e.response.data : null;
      Toast({
        title: errorMessage ?? "Erro ao consultar",
      });
    } finally {
      setHigienizacaoLoadings((higienizacaoLoadings) => ({
        ...higienizacaoLoadings,
        [type]: false,
      }));
    }
  }
}

export function valorLiberadoCliente(margens: Attendance["margens"]) {
  const valorMargemLivreNovo = margens?.valorMargemLivreNovo;
  const coeficiente = margens?.coeficienteNovo;
  const canCalc =
    valorMargemLivreNovo &&
    valorMargemLivreNovo >= 0 &&
    coeficiente &&
    coeficiente > 0;
  return canCalc ? (valorMargemLivreNovo / coeficiente).toFixed(2) : "";
}

export interface CronowiseHigienizationResultProps {
  codigoTabela: null;
  descricaoTabela: null;
  valorMargemLivreNovo: number;
  bancoMargemLivreNovo: null;
  coeficienteNovo: null;
  prazoNovo: null;
  oportunidadeMargemLivreNovo: null;
  bancoRMC: null;
  multiplicadorRMC: null;
  valorMargemCartaoRMC: number;
  valorLimiteCartaoRMC: null;
  valorSaqueCartaoRMC: null;
  porcentagemSaqueRMC: null;
  observacaoRMC: null;
  fonteRMC: null | string;
  oportunidadeRMC: null;
  bancoRCC: null;
  multiplicadorRCC: null;
  valorMargemCartaoRCC: number;
  valorLimiteCartaoRCC: null;
  valorSaqueCartaoRCC: null;
  porcentagemSaqueRCC: null;
  observacaoRCC: null;
  fonteRCC: null | string;
  fonteEmprestimoNovo: null | string;
  oportunidadeRCC: null;
  bancoCreditoPessoal: null;
  valorCreditoPessoal: null;
  prazoCreditoPessoal: null;
  valorLiberadoClienteCreditoPessoal: null;
  oportunidadeCreditoPessoal: null;
  margemBrutaEmprestimo: number;
  margemBrutaCartaoRMC: number;
  margemBrutaCartaoRCC: number;
  origem: "API_MARGEM_CONVENIO";
}

export interface CronowiseHigienizationResultadoGOVProps {
  cpf: string;
  data: {
    orgao: string;
    lotacao: string;
    identificacao: string;
    tipoDeVinculo: string;
    dataDeNomeacao: string;
    mbConsignacoes: number;
    mdConsignacoes: number;
    dataFimContrato: string;
    mbCartaoCredito: number;
    mdCartaoCredito: number;
    mbCartaoBeneficio: number;
    mdCartaoBeneficio: number;
    id?: number;
  }[];
  nome: string;
}

export function mapeamentoHigienizacaoCronowiseGOV({
  dispatch,
  attendance,
  data,
}: {
  attendance: Attendance;
  data: CronowiseHigienizationResultadoGOVProps["data"];
  dispatch: Dispatch<AttendanceActionsTypes>;
}) {
  let margens = attendance.margens! || {};
  let atendimentoBeneficio = attendance.atendimentoBeneficio || {};

  const keyForward: KeyForward<Attendance> = {
    mbConsignacoes: (data) => {
      margens = { ...margens, margemBrutaEmprestimo: data };
      margens = { ...margens, fonteEmprestimoNovo: "API_MARGEM_CONVENIO" };
      return { data: margens, field: "margens" };
    },
    mdConsignacoes: (data) => {
      margens = { ...margens, valorMargemLivreNovo: data };
      margens = { ...margens, fonteEmprestimoNovo: "API_MARGEM_CONVENIO" };
      return { data: margens, field: "margens" };
    },
    mbCartaoCredito: (data) => {
      margens = { ...margens, margemBrutaCartaoRMC: data };
      margens = { ...margens, fonteRMC: "API_MARGEM_CONVENIO" };
      return { data: margens, field: "margens" };
    },
    mdCartaoCredito: (data) => {
      margens = { ...margens, valorMargemCartaoRMC: data };
      margens = { ...margens, fonteRMC: "API_MARGEM_CONVENIO" };
      return { data: margens, field: "margens" };
    },
    mbCartaoBeneficio: (data) => {
      margens = { ...margens, margemBrutaCartaoRCC: data };
      margens = { ...margens, fonteRCC: "API_MARGEM_CONVENIO" };
      return { data: margens, field: "margens" };
    },
    mdCartaoBeneficio: (data) => {
      margens = { ...margens, valorMargemCartaoRCC: data };
      margens = { ...margens, fonteRCC: "API_MARGEM_CONVENIO" };
      return { data: margens, field: "margens" };
    },
    dataDeNomeacao: (data) => {
      return {
        data,
        field: "ddb",
        assignJson: { flag: "API_MARGEM_CONVENIO" },
      };
    },
    tipoDeVinculo: (data) => {
      atendimentoBeneficio = { ...atendimentoBeneficio, tipoVinculo: data };

      return {
        data: atendimentoBeneficio,
        field: "atendimentoBeneficio",
        assignJson: {
          flag: "API_MARGEM_CONVENIO",
          attendanceKeyPath: "atendimentoBeneficio.tipoVinculo",
        },
      };
    },
    lotacao: (data) => {
      atendimentoBeneficio = { ...atendimentoBeneficio, lotacao: data };

      return {
        data: atendimentoBeneficio,
        field: "atendimentoBeneficio",
        assignJson: {
          flag: "API_MARGEM_CONVENIO",
          attendanceKeyPath: "atendimentoBeneficio.lotacao",
        },
      };
    },
    orgao: (data) => {
      atendimentoBeneficio = { ...atendimentoBeneficio, orgao: data };

      return {
        data: atendimentoBeneficio,
        field: "atendimentoBeneficio",
        assignJson: {
          flag: "API_MARGEM_CONVENIO",
          attendanceKeyPath: "atendimentoBeneficio.orgao",
        },
      };
    },
  };
  mapObject({ keyForward, data, dispatch });
}

export type GovDataRow = CronowiseHigienizationResultadoGOVProps["data"][0] & {
  dataAtualizacao: string;
};

export const getCronowiseSelectResultColumns = ({
  checkedRow,
  setCheckedRow,
}: {
  checkedRow: GovDataRow | null;
  setCheckedRow: Dispatch<SetStateAction<GovDataRow | null>>;
}): CustomTableColorColumn[] => {
  return [
    {
      name: "",
      render: (row: GovDataRow) => {
        return (
          <Checkbox
            isChecked={row.id === checkedRow?.id}
            onChange={(isChecked) => {
              if (isChecked) {
                setCheckedRow(row);
              } else {
                setCheckedRow(null);
              }
            }}
          />
        );
      },
    },
    {
      name: "Orgão",
      render: (row: GovDataRow) => {
        return row.orgao;
      },
    },
    {
      name: "Lotação",
      render: (row: GovDataRow) => {
        return row.lotacao;
      },
    },
    {
      name: "Identificação",
      render: (row: GovDataRow) => {
        return row.identificacao;
      },
    },
    {
      name: "Data atualização",
      render: (row: GovDataRow) => {
        return row.dataAtualizacao
          ? new Date(row.dataAtualizacao).toLocaleDateString()
          : null;
      },
    },
    {
      name: "Tipo de vínculo",
      render: (row: GovDataRow) => {
        return row.tipoDeVinculo;
      },
    },
    {
      name: "Data de nomeação",
      render: (row: GovDataRow) => {
        return row.dataDeNomeacao;
      },
    },
    {
      name: "Data fim contrato",
      render: (row: GovDataRow) => {
        return row.dataFimContrato;
      },
    },
    {
      name: "Margem bruta consignado",
      render: (row: GovDataRow) => {
        return row.mbConsignacoes;
      },
    },
    {
      name: "Margem disponível livre consignado",
      render: (row: GovDataRow) => {
        return row.mdConsignacoes;
      },
    },
    {
      name: "Margem bruta RMC",
      render: (row: GovDataRow) => {
        return row.mbCartaoCredito;
      },
    },
    {
      name: "Margem disponível RMC",
      render: (row: GovDataRow) => {
        return row.mdCartaoCredito;
      },
    },
    {
      name: "Margem bruta RCC",
      render: (row: GovDataRow) => {
        return row.mbCartaoBeneficio;
      },
    },
    {
      name: "Margem disponível RCC",
      render: (row: GovDataRow) => {
        return row.mdCartaoBeneficio;
      },
    },
  ];
};

export const updateAtendimento = async ({
  setLoading,
  formControl,
  dispatch,
}: {
  dispatch: Dispatch<AttendanceActionsTypes>;
  setLoading?: Dispatch<SetStateAction<boolean>>;
  formControl: FormControl;
}) => {
  setLoading?.(true);
  const { id } = formControl.values;
  try {
    const response = await api.put(
      `/atendimentos/${id}/kanban`,
      formControl.values
    );
    dispatch({ type: "initAttendance", payload: response.data });
  } catch (error) {
    Toast({
      title: "Erro ao salvar alterações no atendimento!",
      status: "error",
    });
    throw new Error("Erro ao salvar alterações no atendimento!");
  } finally {
    setLoading?.(false);
  }
};
